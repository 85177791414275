import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { AddressParts } from './mapForm.interfaces'
import { IWaterCounterAddressDto } from '@services/types'

export const mapClasses: { root: string } = makeClasses({
	root: {
		width: '100%',
		maxWidth: '100%',
		height: '650px',
		overflow: 'hidden',
		cursor: 'pointer',
		[Breakpoints.maxWidth('lg')]: {
			height: '450px'
		},
		[Breakpoints.maxWidth('sm')]: {
			height: '350px'
		},
		'& .esri-zoom .esri-widget--button:first-child': {
			marginBottom: '3px !important'
		}
	}
})

type MapFormClasses = {
	inputWithOutLabel: string
	root: string
	inputGroup: string
	searchGroup: string
	aptGroup: string
	input: string
	addressRoot: string
	addressItem: string
	addressSelected: string
	notification: string
}

export const mapFormClasses: MapFormClasses = makeClasses({
	notification: {
		marginTop: 30,
		'.notification': {
			marginBottom: 0
		}
	},
	root: {
		display: 'flex',
		alignItems: 'flex-end',
		width: '100%',
		maxWidth: '100%',
		flexWrap: 'wrap' as 'wrap'
	},
	inputWithOutLabel: {
		padding: '20px 0',
		'.error-text': {
			position: 'absolute',
			top: '-24px'
		}
	},
	inputGroup: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',
		padding: '10px 0',
		label: {
			paddingBottom: '5px'
		}
	},
	searchGroup: {
		position: 'relative' as 'relative',
		maxWidth: '75%',
		paddingRight: '15px',
		'&.soleField': {
			maxWidth: '100%',
			paddingRight: '0'
		},
		[Breakpoints.maxWidth('lg')]: {
			maxWidth: '70%',
			paddingRight: '10px'
		},
		[Breakpoints.maxWidth('sm')]: {
			maxWidth: '100%',
			paddingRight: 0
		}
	},
	aptGroup: {
		maxWidth: '25%',
		// marginBottom: '10px',
		[Breakpoints.maxWidth('lg')]: {
			maxWidth: '30%',
			paddingLeft: '10px'
		},
		[Breakpoints.maxWidth('sm')]: {
			maxWidth: '100%',
			paddingLeft: 0
		}
	},
	input: {
		background: Colors.white,
		color: Colors.brown,
		padding: '8px 12px',
		fontSize: '16px',
		width: '100%',
		fontWeight: 400,
		lineHeight: '26px',
		border: `1px solid ${Colors.grey}`,
		marginTop: '0px'
	},
	addressRoot: {
		position: 'absolute' as 'absolute',
		top: 'calc(100% - 10px)',
		boxShadow: `0 5px 10px ${Colors.transparentBlack}`,
		FontSize: '16px',
		color: Colors.brown,
		left: 0,
		width: 'calc(100% - 15px)',
		zIndex: 5,
		'&.soleField': {
			width: '100%'
		}
	},
	addressItem: {
		background: Colors.white,
		padding: '10px',
		cursor: 'pointer',
		'&:hover': {
			background: Colors.porcelainGrey
		}
	},
	addressSelected: {
		background: Colors.porcelainGrey
	}
})

export const splitAddress = (address: string): AddressParts | null => {
	const regex = /^(.*?),?\s*(\([^)]+\))?\s*([A-Z]\d[A-Z] \d[A-Z]\d)$/i
	const match = address.match(regex)

	if (match) {
		return {
			name: match[1].trim(),
			town: match[2]?.trim() || '',
			postalCode: match[3].trim()
		}
	}

	return null
}
