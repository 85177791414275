import React, { FC } from 'react'
import { makeClasses } from '@utils/styles'

import '@components/layouts/Fonts.css'
import '@components/layouts/global.scss'
import 'react-datepicker/dist/react-datepicker.css'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import { Partials } from '@utils/css-variables'

const classes: { Container: string } = makeClasses(Partials)

const LayoutFullPage: FC<{ children: React.ReactNode }> = ({ children }) => {
	return <div className={classes.Container}>{children}</div>
}

export default LayoutFullPage
