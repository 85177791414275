import React, { FC, useEffect, useRef, useState } from 'react'
import { loadModules, setDefaultOptions } from 'esri-loader'
import { Coordinate, MapProps } from '@components/ui/map/mapForm.interfaces'
import * as pageUtils from '@components/ui/map/__map.utils'
import { pinIcon } from '@images/icons'

const Map: FC<MapProps> = ({ coordinates, getCoordinatesFromPoint }) => {
	const mapRef = useRef<HTMLDivElement>(null)
	const [view, setView] = useState<any>()
	const [graphic, setGraphic] = useState<any>()

	const coordinatesDefault: Coordinate = {
		longitude: -73.75,
		latitude: 45.6
	}

	const simpleMarkerSymbol = {
		type: 'picture-marker',
		url: pinIcon,
		width: '55px',
		height: '55px'
	}

	const loadMap = async () => {
		if (mapRef.current !== null) {
			setDefaultOptions({ css: true })
			const [WebMap, TileLayer, MapView, Graphic] = await loadModules([
				'esri/WebMap',
				'esri/layers/TileLayer',
				'esri/views/MapView',
				'esri/Graphic'
			])

			const layer = new TileLayer(
				'https://gis.laval.ca/arcgis/rest/services/basemaps/CacheVecteur_wm/MapServer',
				{
					crossOrigin: 'text/html'
				}
			)

			const map = new WebMap({
				layers: [layer]
			})

			const viewMap = new MapView({
				container: 'viewDiv',
				map: map,
				center: [coordinatesDefault.longitude, coordinatesDefault.latitude],
				zoom: 0,
				navigation: {
					mouseWheelZoomEnabled: false,
					browserTouchPanEnabled: false
				}
			})

			const graphicTmp = new Graphic({
				symbol: simpleMarkerSymbol
			})

			if (getCoordinatesFromPoint) {
				viewMap.on('click', getCoordinatesFromPoint)
			}

			setView(viewMap)

			setGraphic(graphicTmp)
		}
	}

	const doGraphics = () => {
		if (coordinates) {
			const point = {
				type: 'point',
				longitude: coordinates.longitude,
				latitude: coordinates.latitude
			}

			graphic.geometry = point

			return view.graphics.add(graphic)
		}

		return view.graphics.remove(graphic)
	}

	useEffect(() => {
		loadMap()
	}, [])

	useEffect(() => {
		if (view) {
			const coordinatesTemps: Coordinate = coordinates
				? coordinates
				: graphic.geometry

			doGraphics()

			if (coordinates) {
				view
					.goTo({
						center: [coordinatesTemps.longitude, coordinatesTemps.latitude],
						zoom: 4 //coordinates ? 4 : 0
					})
					.catch(function (error) {
						if (error.name != 'AbortError') {
							console.error(error)
						}
					})
			}
		}
	}, [coordinates])

	return <div id="viewDiv" className={pageUtils.mapClasses.root} ref={mapRef} />
}
export default Map
