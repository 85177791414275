import React, {
	CSSProperties,
	forwardRef,
	ForwardRefRenderFunction,
	useState
} from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors, Constants } from '@utils/css-variables'
import Error from '@components/requestForms/inputs/error.input'
import Text from '@components/ui/text'
import Select from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'

interface Option {
	label: string
	value: string
}

type Props = {
	id: string
	label: string
	value: string
	required?: boolean
	hasError?: boolean
	onChange: (selected: string) => void
	hasDescriptionLabel?: string
	descriptionAlignment?: string
	displayDescriptionTop?: boolean
	options: { label: string; value: string }[] | []
	disabled?: boolean
	isWithFilterOptions?: boolean
}

type Classes = {
	root: string
	formInput: string
	error: string
	inputDescLabel: string
	fieldHeight: string
	required: string
	contentRoot: string
	noWrap: string
	select: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '20px'
	},
	formInput: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',
		width: '100%',
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400 as any,
		padding: '12px',
		borderRadius: 0,
		lineHeight: '26px',
		marginBottom: '10px',
		'&:focus': {
			outline: 'none'
		}
	},
	inputDescLabel: {
		display: 'block',
		margin: '5px 0 0',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		textarea: {
			borderColor: Colors.errorRed,
			'&:focus': {
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		}
	},
	fieldHeight: {
		height: '150px'
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	},
	select: {
		'& .css-13cymwt-control': {
			width: '100%',
			height: '43.6px',
			maxWidth: '100%',
			// padding: '8px 12px',
			fontSize: '16px',
			fontWeight: '500',
			color: Colors.lightBlack,
			background: Colors.white,
			border: `1px solid ${Colors.grey}`,
			borderRadius: '0',
			outline: 'none',
			'&:focus': {
				outline: 'none'
			}
		}
	}
})

const SearchableSelectInputForwardRef: ForwardRefRenderFunction<
	HTMLSelectElement,
	Props
> = (
	{
		id,
		label,
		required,
		hasError,
		value,
		onChange,
		hasDescriptionLabel,
		descriptionAlignment,
		displayDescriptionTop,
		options,
		disabled,
		isWithFilterOptions
	},
	ref
) => {
	const [closeMenu, setCloseMenu] = useState<boolean>(false)

	const displayDescription = (
		descriptionAlignment?: string,
		addMargin?: boolean
	) => {
		if (hasDescriptionLabel) {
			return (
				<span
					className={classes.inputDescLabel}
					style={
						{
							textAlign: descriptionAlignment || 'right',
							marginBottom: addMargin ? '10px' : ''
						} as CSSProperties
					}
				>
					<Text content={hasDescriptionLabel} />
				</span>
			)
		}
	}

	const getLabel = () => {
		const hasQuestionMark = label?.endsWith('?')
		if (hasQuestionMark) {
			return (
				<div className={classes.contentRoot}>
					{label?.replace('?', '')}
					<span className={required ? classes.noWrap : ''}>?</span>
				</div>
			)
		} else {
			return <span className={required ? classes.required : ''}>{label}</span>
		}
	}

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			width: '100%',
			height: '43.6px',
			maxWidth: '100%',
			fontSize: '16px',
			fontWeight: '500',
			color: Colors.lightBlack,
			background: Colors.white,
			borderRadius: '0',
			outline: 'none',
			border: `1px solid ${state.isFocused ? Colors.black : Colors.grey}`,
			boxShadow: 'none',
			'&:hover': {
				outline: 'none !important'
			},
			'&:focus': {
				outline: 'none !important',
				borderColor: Colors.grey
			},
			'&:active': {
				outline: 'none !important',
				border: `1px solid ${Colors.grey} !important`
			}
		}),
		menu: (provided) => ({
			...provided,
			zIndex: 3
		})
	}

	const handleSelectChanges = (selectedOption) => {
		onChange(selectedOption?.value)
		setCloseMenu(true)
	}

	const getValue = (value) => {
		const newValue = options?.find((option) => option.value === value)

		if (newValue) {
			return newValue
		} else if (!newValue && value != '') {
			return {
				label: value,
				value
			}
		}

		return {
			label: '',
			value: ''
		}
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		const target = event.target as HTMLInputElement
		if (
			(event.key == 'Backspace' || event.keyCode == 8) &&
			target.value.trim() == ''
		) {
			setCloseMenu(true)
			onChange('')
		}
	}

	const handleInputChange = (input: string) => {
		if (input != '') onChange(input)
	}

	const filterOptions = (
		options: FilterOptionOption<Option>,
		inputValue: string
	) => {
		if (options.value.includes(inputValue)) {
			setCloseMenu(true)
			return true
		} else {
			setCloseMenu(true)
			return false
		}
	}
	return (
		<div className={joinClasses([classes.root, hasError ? classes.error : ''])}>
			<label htmlFor={id}>
				{getLabel()}
				<Error hasError={hasError} />
			</label>
			{displayDescriptionTop &&
				displayDescription(descriptionAlignment, displayDescriptionTop)}
			{isWithFilterOptions ? (
				<Select
					options={options}
					id={id}
					classNamePrefix=" "
					styles={customStyles}
					isSearchable
					autoFocus={false}
					value={getValue(value)}
					onChange={handleSelectChanges}
					onInputChange={handleInputChange}
					// onChange={handleSelectChange}
					isDisabled={disabled}
					required={required}
					onKeyDown={handleKeyDown}
					closeMenuOnSelect={closeMenu}
					filterOption={filterOptions}
				/>
			) : (
				<Select
					options={options}
					id={id}
					classNamePrefix=" "
					styles={customStyles}
					isSearchable
					autoFocus={false}
					value={getValue(value)}
					onChange={handleSelectChanges}
					isDisabled={disabled}
					required={required}
					onKeyDown={handleKeyDown}
					closeMenuOnSelect={closeMenu}
				/>
			)}

			{!displayDescriptionTop && displayDescription(descriptionAlignment)}
		</div>
	)
}

const SearchableSelectInput = forwardRef(SearchableSelectInputForwardRef)

export default SearchableSelectInput
