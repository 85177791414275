import logoLavalIcon from '@images/logo-laval.svg'
import searchIcon from '@images/search.svg'
import searchSmallIcon from '@images/search-cropped.svg'
import searchMobileIcon from '@images/search-mobile.svg'
import messageIcon from '@images/messages.svg'
import userIcon from '@images/user-avatar.svg'
import chevronRightIcon from '@images/chevron-grey.svg'
import chevronIcon from '@images/chevron.svg'
import chevronDownIcon from '@images/chevron-down.svg'
import chevronDownBlueIcon2 from '@images/chevron-down-blue-2.svg'
import downloadIcon from '@images/download-white.svg'
import closeIcon from '@images/x-close.svg'
import closeIconGreen from '@images/x-close-green.svg'
import logoLavalFooterIcon from '@images/laval-logo-white.svg'
import genericMenuItemIcon from '@images/311.svg'
import myRequestIcon from '@images/mesrequetes.svg'
import homeIcon from '@images/accueil.svg'
import homeBlueIcon from '@images/blue-house.svg'
import createRequestIcon from '@images/creerrequete.svg'
import activitiesIcon from '@images/activites.svg'
import menuIcon from '@images/menu.svg'
import homeAndHammerIcon from '@images/icone-permis-reglements-municipaux.svg'
import monDossierLogoIcon from '@images/logo-mondossier-shapes.svg'
import magnifyingGlassIcon from '@images/loupe.svg'
import bellIcon from '@images/notification.svg'
import logoutIcon from '@images/logout.svg'
import environmentIcon from '@images/icon-environment.svg';
import speedIcon from '@images/icon-speed.svg';
import treesIcon from '@images/icon-trees.svg';
import potholeIcon from '@images/icon-potholes.svg';
import snowRemovalIcon from '@images/icon-snowremoval.svg';
import taxesIcon from '@images/icon-taxes.svg';
import lineServicesIcon from '@images/icon-line-services.svg';
import externalLink from '@images/externallink.svg';
import formCheck from '@images/icon-form-check.svg';
import paymentPurpleIcon from '@images/payment-purple.svg';
import notificationDarkBlueIcon from '@images/notification-darkblue.svg';
import notificationLightBlueIcon from '@images/notification-lightblue.svg';
import accueilDarkBlueV2 from '@images/accueil-darkblue2.svg';
// @TODO: to remove closeWhiteIcon and replace with closeWhiteIconV2
// closeWhiteIcon is only being use in component title...
import closeWhiteIcon from '@images/x-close-white.svg';
import requestListIcon from '@images/icon-requests.svg';
import reportsIcon from '@images/reports.svg';
import permitIcon from '@images/permit.svg';
import programsIcon from '@images/programs.svg';
import reclamationsLightBlue from '@images/reclamations-lightblue.svg';
import inscriptions from '@images/inscriptions.svg';
import demandes from '@images/demandes.svg';
import chevronDownBlueIcon from '@images/chevron-down-blue.svg'
import cancelIcon from '@images/cancel.svg'
import chevronDoubleBlueIcon from '@images/chevron-double-blue.svg'
import chevronUpIcon from '@images/chevron-up.svg'
import editIcon from '@images/edit.svg'
import editWhiteIcon from '@images/edit-white.svg'
import cancelWhiteIcon from '@images/cancel-white.svg'
import chevronBlueIcon from '@images/chevron.svg'
import envelopeBlueIcon from '@images/envelope-blue.svg'
import warningIcon from '@images/warning.svg'
import warningYellowIcon from '@images/warning-yellow.svg'
import userWhiteIcon from '@images/user-white2.svg'
import dotMenuIcon from '@images/dots.svg'
import logoLavalLIcon from '@images/logo-L.svg'
import paperGreyClipIcon from '@images/paperclip-grey.svg'
import pdfIcon from '@images/pdf.svg'
import infoIcon from '@images/icon-info.svg'
import questionIcon from '@images/question.svg'
import constatInfractionIcon from '@images/constat-infraction.svg'
import evalutationIcon from '@images/evaluation.svg'
import onlineServiceIcon from '@images/online-services.svg'
import permisIcon from '@images/permis.svg'
import activityGrayIcon from '@images/activitesGray.svg'
import abonnementIcon from '@images/abonnement.svg'
import plusBlueIcon from '@images/plus-blue.svg'
import plusWhiteIcon from '@images/plus-white.svg'
import clockIcon from '@images/clock.svg'
import userGrayIcon from '@images/user-gray.svg'
import myRequestGreyIcon from '@images/my-requests-gray.svg'
import printIcon from '@images/print.svg'
import printWhiteIcon from '@images/print-white.svg'
import shareIcon from '@images/share.svg'
import shareWhiteIcon from '@images/share-white.svg'
import magnifierWhiteIcon from '@images/magnifying-glass-white.svg'
import magnifierGreyIcon from '@images/magnifying-glass-grey.svg'
import chevronWhiteIcon from '@images/chevron-white.svg'
import closeWhiteIconV2 from '@images/x-close-white-v2.svg'
import closeGreyIcon from '@images/x-close-grey.svg'
import pinIcon from '@images/pin.svg'
import locationIcon from '@images/location.svg'
import chevronLightBlueIcon from '@images/chevron-lightblue.svg'
import chevronDownLightBlueIcon from '@images/chevron-down-lightblue.svg'
import pendingBlueIcon from '@images/pending.svg'
import eauLightGreyIcon from '@images/eau-lightgrey.svg'
import ecoResponsibilityIcon from '@images/eco-responsibility-lightgrey.svg'
import calendar from '@images/calendar.svg'
import dogIcon from '@images/dog.svg'
import catIcon from '@images/cat.svg'
import checkBlueIcon from '@images/check-blue.svg'
// socials
import facebookIcon from '@images/icon-facebook-white.svg'
import facebookBlackIcon from '@images/icon-facebook-black.svg'
import twitterIcon from '@images/icon-twitter-white.svg'
import twitterNewIcon from '@images/twitter-mondossier.svg'
import twitterBlackIcon from '@images/icon-twitter-black.svg'
import youtubeIcon from '@images/icon-youtube-white.svg'
import instagramIcon from '@images/icon-instagram-white.svg'
import linkedinIcon from '@images/icon-linkedin-white.svg'
import linkedinBlackIcon from '@images/icon-linkedin-black.svg'

// thumbnails
import thumbnail1 from '@images/thumbnail1.jpg'
import thumbnail2 from '@images/thumbnail2.jpg'
import thumbnail3 from '@images/thumbnail3.jpg'
import thumbnail4 from '@images/thumbnail4.jpg'

// request potholes
import potholesStreet from '@images/ndp1.jpg'
import potholesDamagedPavement from '@images/ndp2.jpg'
import potholesSaggingSewerCover from '@images/ndp3.jpg'

// request curb and sidewalk
import curbImage from '@images/curb.jpg'
import sidewalkImage from '@images/sidewalk.jpg'

// tutorial icons
import tutorialIcon1 from '@images/tutorial-img1.svg'
import tutorialIcon2 from '@images/tutorial-img2.svg'
import tutorialIcon3 from '@images/tutorial-img3.png'

// infor icons
import infoBlue from '@images/info-blue.svg'
import infoDarkblue from '@images/info-darkblue.svg'
import infoWhite from '@images/info-white.svg'

// images
import mondossierOg from '@images/mondossier-OG.png'
import paymentImage from '@images/payment.png'

// Oil Heating declaration TN (Chauffage au mazout)
import thermopompe from '@images/thermopompe.jpg'
import electrique from '@images/electrique.jpg'
import airpulse from '@images/airpulse.jpg'
import convecteur from '@images/convecteur.jpg'
import eauchaude from '@images/eauchaude.jpg'
import geothermie from '@images/geothermie.jpg'
import plinthes  from '@images/plinthes.jpg'
import radiant from '@images/radiant.jpg'

// FireHydrantsTempAqueductNetworkForm 
import reseauTemp from '@images/reseau-temporaire-img.png'

// FireDeclarationForm fireplaceTypeToDeclareInput
import chaudiereABois from '@images/chaudiere-a-bois.jpg'
import cuisiniereAuBois from '@images/cuisiniere-a-bois.jpg'
import fourCommercial from '@images/four-commercial.jpg'
import foyerDecoratif from '@images/foyer-decoratif.jpg'
import foyerDeMasse from '@images/foyer-de-masse.jpg'
import foyerEncastre from '@images/foyer-encastre.jpg'
import foyerMaconnerie from '@images/foyer-maconnerie.jpg'
import poeleABois from '@images/poele-a-bois.jpg'
import poeleAGranules from '@images/poele-a-granules.jpg'
import poeleAnthracite from '@images/poele-anthracite.jpg'
import poeleCombustionLente from '@images/poele-combustion-lente.jpg'
import fournaiseAuBois from '@images/fournaise-au-bois.jpg'

// Parking Permit
import stationnementIcon from '@images/stationnement.svg'
import licenceNumberImage from '@images/licence-number.png'
import roadStation from '@images/road-station.png'
import obstaclePosition from '@images/obstacle-position-example.png'
import permitOccupationImage from '@images/permit-occupation.png'


// swimmingPoolSpaPermit
import poolForme1 from '@images/pool-forme1.svg'
import poolForme2 from '@images/pool-forme2.svg'
import poolForme3 from '@images/pool-forme3.svg'
import enceinte1 from '@images/enceinte1.jpg'
import enceinte2 from '@images/enceinte2.jpg'
import enceinte3 from '@images/enceinte3.jpg'
import enceinte4 from '@images/enceinte4.jpg'
import enceinte5 from '@images/enceinte5.jpg'
import enceinte6 from '@images/enceinte6.jpg'


// amenagement
import amenagementBalcon from '@images/amenagement-balcon.png'
import amenagementGalerie from '@images/amenagement-galerie.png'
import amenagementperron from '@images/amenagement-perron.png'
import amenagementporche from '@images/amenagement-porche.png'

import amenagementBalconDimensions from '@images/amenagement-balcon-dimensions.png'
import amenagementGalerieDimensions from '@images/amenagement-galerie-dimensions.png'
import amenagementperronDimensions from '@images/amenagement-perron-dimensions.png'
import amenagementporcheDimensions from '@images/amenagement-porche-dimensions.png'


export {
  logoLavalIcon,
  searchIcon,
  searchMobileIcon,
  messageIcon,
  userIcon,
  chevronRightIcon, 
  downloadIcon,
  closeIcon,
  logoLavalFooterIcon,
  facebookIcon,
  twitterIcon,
  twitterNewIcon,
  youtubeIcon,
  instagramIcon,
  linkedinIcon,
  genericMenuItemIcon,
  homeIcon,
  createRequestIcon,
  menuIcon,
  homeAndHammerIcon,
  monDossierLogoIcon,
  magnifyingGlassIcon,
  bellIcon,
  logoutIcon,
  chevronIcon,
  chevronDownIcon,
  searchSmallIcon,
	environmentIcon,
	speedIcon,
	treesIcon,
	potholeIcon,
	snowRemovalIcon,
	taxesIcon,
	lineServicesIcon,
	externalLink,
	formCheck,
	closeWhiteIcon,
  magnifierWhiteIcon,
	requestListIcon,
	reportsIcon,
	permitIcon,
	programsIcon,
  reclamationsLightBlue,
  inscriptions,
  demandes,
  activitiesIcon,
  myRequestIcon,
	chevronDownBlueIcon,
  cancelIcon,
  cancelWhiteIcon,
  editIcon,
  editWhiteIcon,
  chevronDoubleBlueIcon,
  chevronUpIcon,
  chevronBlueIcon,
  envelopeBlueIcon,
  warningYellowIcon,
	warningIcon,
  userWhiteIcon,
  dotMenuIcon,
  logoLavalLIcon,
  paperGreyClipIcon,
  pdfIcon,
  chevronDownBlueIcon2,
	infoIcon,
	questionIcon,
  closeIconGreen,
  constatInfractionIcon,
  evalutationIcon,
  onlineServiceIcon,
  permisIcon,
  activityGrayIcon,
  abonnementIcon,
  plusBlueIcon,
  plusWhiteIcon,
	clockIcon,
	userGrayIcon,
	myRequestGreyIcon,
	facebookBlackIcon,
	twitterBlackIcon,
	linkedinBlackIcon,
	printIcon,
	shareIcon,
  chevronWhiteIcon,
	magnifierGreyIcon,
	closeWhiteIconV2,
	closeGreyIcon,
	shareWhiteIcon,
	printWhiteIcon,
	homeBlueIcon,
	pinIcon,
	locationIcon,
  chevronDownLightBlueIcon,
  chevronLightBlueIcon,
  pendingBlueIcon,
  paymentPurpleIcon,
  notificationDarkBlueIcon,
  notificationLightBlueIcon,
  accueilDarkBlueV2,
  eauLightGreyIcon,
  ecoResponsibilityIcon,
  calendar,
	// request potholes
	potholesStreet,
	potholesDamagedPavement,
	potholesSaggingSewerCover,
	// request curbAndSidewalk
	curbImage,
	sidewalkImage,
  // thumbnails
  thumbnail1,
  thumbnail2,
  thumbnail3,
  thumbnail4,
  // tutorial icons
  tutorialIcon1,
  tutorialIcon2,
  tutorialIcon3,
  // info icons
  infoBlue,
  infoDarkblue,
  infoWhite,
  // images
  mondossierOg,
  paymentImage,
  // Oil Heating declaration TN (Chauffage au mazout)
  thermopompe,
  electrique,
  airpulse,
  convecteur,
  eauchaude,
  geothermie,
  plinthes,
  radiant,
  // FireHydrantsTempAqueductNetworkForm 
  reseauTemp,
  dogIcon,
  catIcon,
  checkBlueIcon,
  // FireplaceDeclaration
  chaudiereABois,
  cuisiniereAuBois,
  fourCommercial,
  foyerDecoratif,
  foyerDeMasse,
  foyerEncastre,
  foyerMaconnerie,
  poeleABois,
  poeleAGranules,
  poeleAnthracite,
  poeleCombustionLente,
  fournaiseAuBois,
  // Parking permit
  stationnementIcon,
  licenceNumberImage,
  roadStation,
  obstaclePosition,
  permitOccupationImage,
  // swimmingPoolSpaPermit
  poolForme1,
  poolForme2,
  poolForme3,
  enceinte1,
  enceinte2,
  enceinte3,
  enceinte4,
  enceinte5,
  enceinte6,
  // amenagement
  amenagementBalcon,
  amenagementGalerie,
  amenagementperron,
  amenagementporche,
  amenagementBalconDimensions,
  amenagementGalerieDimensions,
  amenagementperronDimensions,
  amenagementporcheDimensions,

}
