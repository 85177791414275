import { chevronDownBlueIcon } from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { LIVING_IN_LAVAL } from '@services/constants'
import * as Yup from 'yup'

export type ClassType = {
	section: string
	container: string
	tab: string
	secondaryColorBox: string
	box: string
	buttonOutline: string
	boxQuestion: string
	h2: string
	row: string
	column1: string
	column2: string
	section2: string
	small: string
	large: string
	infoIcon: string
	required: string
	select: string
	fieldContainers: string
	h3: string
	fieldContainers2: string
	radio: string
	select2: string
	blockHeader: string
	modalForm: string
	map: string
	fieldContainers3: string
	mainBtn: string
	submissionContainer: string
	modalInputs: string
	loaderContainer: string
	profileTooltip: string
	text: string
	noMargin: string
	blueBackground: string
}

export const classes: ClassType = makeClasses({
	section: {
		margin: '40px 100px',
		display: 'block',
		[Breakpoints.minWidth('md')]: {
			margin: '50px'
		},
		[Breakpoints.maxWidth('md')]: {
			margin: '20px'
		}
	},
	tab: {
		display: 'flex',
		marginBottom: '40px',
		background: '#fff',
		padding: '0 50px',
		'& > div': {
			cursor: 'pointer',
			padding: '20px 4px',
			fontSize: '18px',
			fontWeight: '400',
			color: '#666',
			marginRight: '20px'
		},
		'& > div.active': {
			'box-shadow': 'inset 0 -4px #0054a6',
			'font-weight': '700',
			color: '#0054a6'
		},
		[Breakpoints.minWidth('md')]: {
			padding: '0 50px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '0 20px'
		}
	},
	container: {
		display: 'grid',
		gridTemplateColumns: '2fr 1fr',
		alignItems: 'start',
		gridAutoRows: 'minmax("fit-content", auto)',
		gridGap: '20px',
		[Breakpoints.maxWidth('xl')]: {
			gridTemplateColumns: 'repeat(1, 1fr)'
		}
	},
	row: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
		[Breakpoints.maxWidth('lg')]: {
			flexDirection: 'column'
		}
	},
	column1: {
		position: 'relative',
		width: '100%',
		'& > form > div': {
			marginBottom: '30px',
			background: Colors.white,
			padding: '15px 30px',
			transition: '0.3s'
		},
		'& > form > div.noBackground': {
			background: 'transparent',
			padding: '0'
		},
		'& fieldset': {
			margin: '0 0 15px 0',
			border: 0,
			padding: 0,
			clear: 'both'
		},
		'& input:not([type=checkbox]):not([type=radio])': {
			width: '100%',
			height: '44px',
			marginBottom: 0
		}
	},
	column2: {
		position: 'relative',
		width: '100%',
		padding: '30px',
		'& h3': {
			padding: '0px'
		}
	},
	buttonOutline: {
		border: `1px solid ${Colors.secondary}`,
		color: `${Colors.secondary}`,
		background: 'transparent',
		padding: '10px 20px',
		fontSize: '13px',
		marginTop: '05px',
		marginBottom: '15px',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		display: 'inline-block',
		lineHeight: '1em',
		cursor: 'pointer',
		':hover': {
			borderColor: Colors.darkBlue2,
			color: `${Colors.white}`,
			background: Colors.darkBlue2
		}
	},
	boxQuestion: {
		padding: '40px 50px',
		fontSize: '22px',
		'& > p': {
			margin: '0 0 1rem 0'
		}
	},
	h2: {
		margin: '50px 0 15px',
		padding: 0,
		fontWeight: 500,
		fontSize: '28px',
		lineHeight: '31px',
		color: Colors.darkBlue1
	},
	infoIcon: {
		verticalAlign: 'top',
		width: 18
	},
	required: {
		':after': {
			content: "'*'",
			marginLeft: '5px',
			color: Colors.ligthPurple
		}
	},
	select: {
		display: 'block',
		padding: '0 12px',
		width: '100%',
		height: '44px',
		lineHeight: '25px',
		WebkitFontSmoothing: 'antialiased !important',
		fontWeight: '500px',
		fontSize: '15px',
		color: Colors.lightBlack,
		border: `1px solid ${Colors.grey}`,
		MozAppearance: 'none',
		WebkitAppearance: 'none',
		appearance: 'none',
		backgroundImage: `url(${chevronDownBlueIcon})`,
		backgroundColor: Colors.white,
		backgroundRepeat: 'no-repeat,repeat',
		backgroundPosition: 'right 0.7em top 50%,0 0',
		backgroundSize: '24px'
	},
	fieldContainers: {
		marginBottom: '05px',
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: 'repeat(3, 1fr)',
			gridGap: '20px'
		}
	},
	fieldContainers2: {
		marginBottom: '30px',
		display: 'grid',
		alignItems: 'end',
		gridGap: '20px',
		gridTemplateColumns: '33.33333333%16.66666667%',
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '58.333333%37.766667%'
		}
	},
	section2: {
		background: Colors.white,
		p: {
			margin: 0
		},
		'& > div': {
			padding: '15px 30px',
			[Breakpoints.maxWidth('md')]: {
				padding: '30px 20px'
			}
		}
	},
	small: {
		flex: '0 0 33.333333%',
		maxWidth: '33.333333%',
		position: 'relative',
		width: '100%',
		padding: '0px 15px',
		height: 'fit-content',
		'& > div > h3': {
			padding: '0'
		},
		[Breakpoints.maxWidth('lg')]: {
			flex: '100%',
			maxWidth: '100%',
			margin: '20px 0 30px',
			padding: '0 15px 0 0'
		}
	},
	large: {
		flex: '0 0 66.666666%',
		maxWidth: '66.666666%',
		position: 'relative',
		width: '100%',
		paddingRight: '15px',
		[Breakpoints.maxWidth('lg')]: {
			flex: '100%',
			maxWidth: '100%'
		}
	},
	h3: {
		color: Colors.white,
		marginTop: 0,
		marginBottom: 0,
		backgroundColor: Colors.secondary,
		padding: '12px 30px',
		fontWeight: 'bold',
		fontSize: '22px',
		[Breakpoints.maxWidth('md')]: {
			padding: '15px 20px'
		}
	},
	select2: {
		[Breakpoints.minWidth('sm')]: {
			width: '30%'
		}
	},
	radio: {
		'& label': {
			display: 'inline'
		},
		'& input[type=radio]': {
			width: 'fit-content',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		'& span': {
			display: 'block',
			margin: '10px 0 10px 0'
		},
		'& span:first-child': {
			margin: '0px 0 10px 0'
		}
	},
	modalForm: {
		margin: 0,
		padding: '0 !important',
		'& > *': {
			marginBottom: '25px !important'
		}
	},
	map: {
		'& p': {
			clear: 'both',
			display: 'block',
			margin: '5px 0 8px',
			padding: 0,
			fontSize: '0.85em',
			lineHeight: '1.25em',
			color: Colors.lightGrey2
		},
		'& a': {
			textDecoration: 'none',
			cursor: 'pointer',
			color: Colors.secondaryLighten1,
			':visited': {
				color: Colors.secondary
			}
		}
	},
	fieldContainers3: {
		display: 'grid',
		gridGap: '20px',
		alignItems: 'end',
		[Breakpoints.minWidth('lg')]: {
			gridTemplateColumns: 'repeat(2, 1fr)'
		}
	},
	mainBtn: {
		padding: '15px 30px',
		fontSize: '16px',
		cursor: 'pointer',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		lineHeight: '1em',
		fontWeight: 500,
		display: 'inline-block',
		marginTop: 0
	},
	submissionContainer: {
		display: 'flex',
		'& button': {
			margin: '0px 5px 0px 0px'
		}
	},
	modalInputs: {
		'& input': {
			marginBottom: '10px'
		}
	},
	loaderContainer: {
		[Breakpoints.minWidth('xl')]: {
			marginLeft: '17rem'
		}
	},
	profileTooltip: {
		'& div > div > div': {
			[Breakpoints.maxWidth('xl')]: {
				left: 0
			}
		}
	},
	text: {
		color: Colors.darkGrey2,
		marginTop: '-5px'
	},
	noMargin: {
		'& > p': {
			margin: '0px'
		}
	},
	blueBackground: {
		background: `${Colors.secondary} !important`,
		padding: '30px!important',
		h3: {
			color: `${Colors.white} !important`
		},
		p: {
			color: `${Colors.white} !important`
		},
		a: {
			background: `${Colors.white} !important`,
			color: `${Colors.secondary} !important`,
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			textDecoration: 'none'
		}
	}
})

export const PHONE_NUMBER_REGEX =
	/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
export const POSTAL_CODE_REGEX = /^\w\d\w[ -]?\d\w\d$/i
export const MAIL_REGEX =
	/^[A-Za-z0-9+_.-]+@+[A-Za-z0-9_-]+\.+[A-Za-z0-9]{2,3}$/

export const SignupSchema = Yup.object().shape({
	firstName: Yup.string()
		.trim()
		.min(2, 'label_tooShortErrorText')
		.max(50, 'label_tooLongErrorText')
		.matches(/^[a-zA-ZÀ-ÿ-. ]*$/, 'label_firstNameErrorText')
		.required('required_text'),
	lastName: Yup.string()
		.trim()
		.min(2, 'label_tooShortErrorText')
		.max(50, 'label_tooLongErrorText')
		.matches(/^[a-zA-ZÀ-ÿ-. ]*$/, 'label_lastNameErrorText')
		.required('required_text'),
	phoneNumber: Yup.string().when(['phoneNumberEvening', 'phoneNumberOther'], {
		is: (phoneNumberEvening: string, phoneNumberOther: string) => {
			return phoneNumberEvening || phoneNumberOther
		},
		then: Yup.string().nullable(true),
		otherwise: Yup.string()
			.min(10, 'label_tooShortErrorText')
			.max(14, 'label_tooLongErrorText')
			.matches(PHONE_NUMBER_REGEX, 'label_phoneNumberErrorText')
			.required('required_text')
	}),

	phoneNumberEvening: Yup.string()
		.min(10, 'label_tooShortErrorText')
		.max(14, 'label_tooLongErrorText')
		.matches(PHONE_NUMBER_REGEX, 'label_phoneNumberErrorText')
		.nullable(true),
	phoneNumberOther: Yup.string()
		.min(10, 'label_tooShortErrorText')
		.max(14, 'label_tooLongErrorText')
		.matches(PHONE_NUMBER_REGEX, 'label_phoneNumberErrorText')
		.nullable(true),
	address: Yup.object().shape({
		address: Yup.string().when(
			['inMunicipality', 'hasAddressEnteredManually'],
			{
				is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
					return (
						inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
					)
				},
				then: Yup.string().nullable(true),
				otherwise: Yup.string().nullable(true)
			}
		),
		city: Yup.string().when(['inMunicipality', 'hasAddressEnteredManually'], {
			is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
				return (
					inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
				)
			},
			then: Yup.string().nullable(true),
			otherwise: Yup.string().nullable(true)
		}),
		country: Yup.string().when(
			['inMunicipality', 'hasAddressEnteredManually'],
			{
				is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
					return (
						inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
					)
				},
				then: Yup.string().nullable(true),
				otherwise: Yup.string().nullable(true)
			}
		),
		state: Yup.string().when(['inMunicipality', 'hasAddressEnteredManually'], {
			is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
				return (
					inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
				)
			},
			then: Yup.string().nullable(true),
			otherwise: Yup.string().nullable(true)
		}),
		postalCode: Yup.string().when(
			['inMunicipality', 'hasAddressEnteredManually'],
			{
				is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
					return (
						inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
					)
				},
				then: Yup.string()
					.min(6, 'label_tooShortErrorText')
					.max(7, 'label_tooLongErrorText')
					.matches(POSTAL_CODE_REGEX, 'label_addressErrorText')
					.nullable(true),
				otherwise: Yup.string().nullable(true)
			}
		),
		mail: Yup.string()
			.min(1, 'label_tooShortErrorText')
			.max(14, 'label_tooLongErrorText')
			.matches(MAIL_REGEX, 'label_phoneNumberErrorText')
			.nullable(true),
		mail2: Yup.string()
			.min(1, 'label_tooShortErrorText')
			.max(14, 'label_tooLongErrorText')
			.matches(MAIL_REGEX, 'label_phoneNumberErrorText')
			.nullable(true),
		mail3: Yup.string()
			.min(4, 'label_tooShortErrorText')
			.max(14, 'label_tooLongErrorText')
			.matches(MAIL_REGEX, 'label_can_only_containt_numbers')
			.nullable(true)
	})
})

export 	enum TAB {
	INFOS = '0',
	PREFERENCES = '1',
	PROPERTY = '2'
}
