import React, {
	useState,
	useCallback,
	useContext,
	useEffect,
	ReactElement,
	useRef
} from 'react'
import { useDropzone } from 'react-dropzone'
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import RequestsRepository from '@services/repositories/requests.repository'
import { joinClasses, makeClasses } from '@utils/styles'
import Link from '@components/ui/link'
import * as pageUtils from '@components/ui/requestFiles/__index.utils'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Icon } from '@components/ui/Icon'
import { closeIcon, pdfIcon } from '@images/icons'
import { RequestImgUrl } from '@utils/request'
import { formatFileSize } from '@components/ui/Attachment/__index.utils'
import { Colors } from '@utils/css-variables'
import RequiredIcon from '@components/ui/requiredIcon'
import Text from '@components/ui/text'
import Error from '@components/requestForms/inputs/error.input'
import { thirdStepSubSteps } from '@templates/requests/create_tn'

type props = {
	hasError: boolean
	files: Set<File> // Fichiers
	// uploadedFileNames: Set<string> // Noms des fichiers déjà uploadés
	// uploadedFiles: string[] // URLs des fichiers déjà uploadés
	uploadedFilesMap: Map<string, string> | undefined // Nom et URLs des fichiers déjà uploadés
	setUploadedFilesMap: (newMap: Map<string, string>) => void
	children?: ReactElement
	name?: string
	title?: string
	subtitle?: string
	required?: boolean
	removeSectionMarginTop?: boolean
	removeSectionPaddingTop?: boolean
	maxFiles?: number
	minFiles?: number
	namingValidation?: string
	formPosition?: number
	subSteps?: thirdStepSubSteps
	description?: string
	onSetHasError: (hasError: boolean, inputName: string) => void
	onSetFiles: (newFiles: Set<File>) => void
	// onSetUploadedFileNames: (newFileNames: Set<string>) => void
	// onSetUploadedFiles: (newUploadedFiles: string[]) => void
	isSummary?: boolean
	removePadding?: boolean
	equivalentMargin?: boolean
}

const BlobUploader: React.FC<props> = ({
	name = '',
	children,
	hasError,
	title,
	subtitle,
	required,
	files,
	// uploadedFileNames, // Noms des fichiers déjà uploadés
	// uploadedFiles, // URLs des fichiers déjà uploadés
	uploadedFilesMap = new Map(),
	setUploadedFilesMap,
	removeSectionMarginTop,
	removeSectionPaddingTop,
	namingValidation,
	maxFiles,
	formPosition,
	subSteps,
	onSetFiles,
	// onSetUploadedFileNames,
	// onSetUploadedFiles,
	onSetHasError,
	isSummary,
	description,
	removePadding,
	equivalentMargin
}) => {
	type Classes = {
		container: string
		dropzone: string
		fileList: string
		uploadButton: string
		progressContainer: string
		progressBarContainer: string
		progressBar: string
		uploadedFiles: string
		errorContainer: string
		loader: string
		justifyContent: string
		fileItemStatus: string
		fileItemName: string
	}

	const classes: Classes = makeClasses({
		container: {
			padding: '16px',
			border: '1px solid #ccc',
			borderRadius: '8px',
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
			marginBottom: '10px !important',
			marginTop: '10px !important'
		},
		dropzone: {
			border: '2px dashed #007bff',
			padding: '20px',
			borderRadius: '8px',
			textAlign: 'center',
			cursor: 'pointer',
			transition: 'background 0.2s',

			'&:hover': {
				background: '#f8f9fa'
			}
		},

		fileList: {
			marginTop: '16px',
			ul: {
				listStyle: 'none',
				padding: 0
			}
		},

		uploadButton: {
			background: '#007bff',
			color: 'white',
			padding: '8px 16px',
			border: 'none',
			borderRadius: '4px',
			cursor: 'pointer',
			marginTop: '10px',

			'&:hover': {
				background: '#0056b3'
			},

			'&:disabled': {
				background: '#aaa',
				cursor: 'not-allowed'
			}
		},

		progressContainer: {
			marginTop: '16px'
		},

		progressBarContainer: {
			width: '100%',
			background: '#ddd',
			borderRadius: 4,
			marginTop: 4
		},

		progressBar: {
			background: '#28a745',
			color: 'white',
			textAlign: 'center',
			padding: 4,
			borderRadius: 4,
			transition: 'width 0.3s'
		},

		uploadedFiles: {
			marginTop: 16,
			fontSize: 10
		},

		errorContainer: {
			marginTop: 16,
			color: 'red'
		},

		loader: {
			border: `4px solid ${Colors.lightGrey}`,
			borderTop: `4px solid ${Colors.blue}`,
			borderRadius: '50%',
			width: '20px',
			height: '20px',

			animation: 'spin 2s linear infinite'
		},
		justifyContent: {
			justifyContent: 'space-between'
		},
		fileItemStatus: {
			fontSize: '12px',
			color: Colors.darkGrey,
			display: 'flex',
			textAlign: 'right',
			width: '27%',
			justifyContent: 'center',
			'& span': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'left',
				'& div': {
					marginRight: '5px'
				}
			}
		},
		fileItemName: {
			display: 'flex',
			width: '70%'
		}
	})

	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const [addTransitionClassContainer, setAddTransitionClassContainer] =
		useState<boolean>(false)

	// blob
	// const [files, setFiles] = useState<File[]>([])
	const prevFilesRef = useRef<Set<File>>()
	const [uploading, setUploading] = useState<boolean>(false)
	const [progress, setProgress] = useState<Record<string, number>>({})
	const [errors, setErrors] = useState<string[]>([])
	const [uploadedFilesMaplocal, setUploadedFilesMapLocal] =
		useState<Map<string, string>>(uploadedFilesMap)

	const getSasToken = async (): Promise<string | undefined> => {
		try {
			const requestsRepository = new RequestsRepository()
			return await requestsRepository.getSASBlobStorage('objectId=sprintDemo')
		} catch (error) {
			console.error('Erreur lors de la récupération du SAS Token', error)
			setErrors((prev) => [
				...prev,
				'Erreur lors de la récupération du SAS Token'
			])
		}
	}

	const addTimestampToFilename = (filename: string) => {
		const dotIndex = filename.lastIndexOf('.')
		if (dotIndex === -1) return `${filename}_${Date.now()}`
		const namePart = filename.substring(0, dotIndex)
		const extension = filename.substring(dotIndex)
		return `${namePart}_${Date.now()}${extension}`
	}

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const newFiles = acceptedFiles.filter(
				(file) => !files.has(file) && !uploadedFilesMap.has(file.name)
			)

			if (maxFiles && files.size + newFiles.length > maxFiles) {
				console.error(
					'Vous ne pouvez uploader que ' + maxFiles + ' fichiers maximum.'
				)
				return
			}

			if (newFiles.length > 0) {
				onSetFiles(new Set([...files, ...newFiles]))
				// uploadNewFiles(newFiles);
			}
		},
		[files, uploadedFilesMap]
	)

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: true,
		maxFiles
	})

	useEffect(() => {
		// // Check if files have changed
		// if (prevFilesRef.current !== files) {
		// 	prevFilesRef.current = files
		// }

		// uploadNewFiles()

		console.log(prevFilesRef.current)
		console.log('----files',files)

		if (prevFilesRef.current !== files) {
			console.log('Files have changed, uploading new files...');
			
			uploadNewFiles()
		}
		prevFilesRef.current = files
	}, [files])

	const uploadNewFiles = useCallback(async () => {
		if (files.size === 0 || uploading) return

		setUploading(true)
		setErrors([])
		setProgress({})

		const sasToken = await getSasToken()
		if (!sasToken) {
			setUploading(false)
			// !error
			onSetHasError(true, name)
			return
		}

		const blobServiceClient = new BlobServiceClient(
			`https://vdlresodataqc01sa.blob.core.windows.net/?${sasToken}`
		)
		const containerClient: ContainerClient =
			blobServiceClient.getContainerClient('mondossier-contacts-files')

		let uploadProgress: Map<string, string> = new Map(uploadedFilesMap)

		for (const file of files) {
			if (uploadedFilesMaplocal.has(file.name)) {
				console.log(`${file.name} déjà téléversé, il sera ignoré. ******`)
				continue
			}

			const blobClient = containerClient.getBlockBlobClient(
				`sprintDemo/${addTimestampToFilename(file.name)}`
			)
			try {
				await blobClient.uploadData(file, {
					blobHTTPHeaders: { blobContentType: file.type },
					onProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loadedBytes / file.size) * 100
						)
						setProgress((prevProgress) => ({
							...prevProgress,
							[file.name]: percentCompleted
						}))
					}
				})

				uploadProgress.set(file.name, blobClient.url)

				setUploadedFilesMapLocal((prevMap) => {
					const newMap = new Map(prevMap)
					newMap.set(file.name, blobClient.url)
					return newMap
				})
			} catch (error) {
				console.error(`Erreur lors de l'upload de ${file.name}`, error)
				setErrors((prev) => [
					...prev,
					`Erreur lors de l'upload de ${file.name}`
				])
				// !error
				onSetHasError(true, name)
			}
		}

		setUploadedFilesMap(new Map(uploadProgress))

		setUploading(false)
	}, [files, uploadedFilesMap, uploading])

	useEffect(() => {
		// keep transition style if there is a file
		if (files.size > 0) {
			setAddTransitionClassContainer(true)
		}
	}, [])

	const getFileExtension = (file: File): string =>
		`.${file?.name?.split('.').pop()}`

	const displayThumbnail = (requestImg: RequestImgUrl | undefined) => {
		if (!requestImg) {
			return <></>
		}

		if (requestImg.isPDF) {
			return (
				<img
					id={requestImg.id}
					src={pdfIcon}
					alt={requestImg.name}
					className={pageUtils.classes.img}
				/>
			)
		}
		return (
			<img
				id={requestImg.id}
				src={requestImg.url}
				alt={requestImg.name}
				className={pageUtils.classes.img}
			/>
		)
	}

	const getRequestImg = (file: File): RequestImgUrl => {
		let url: string = URL.createObjectURL(file)
		return {
			url,
			id: `${file.size}-${file.name}`,
			name: file.name,
			isPDF: file.type.includes('pdf')
		}
	}

	const handleRemoveFile = (file: File) => (e) => {
		e.preventDefault()

		uploadedFilesMaplocal.delete(file.name)
		setUploadedFilesMapLocal(new Map(uploadedFilesMaplocal))

		// delete file from files
		const newFiles = new Set(files)
		newFiles.delete(file)
		onSetFiles(newFiles)

		// delete file from uploadedFilesMap
		const newMap = new Map(uploadedFilesMap)
		newMap.delete(file.name)
		setUploadedFilesMap(newMap)
	}

	return (
		<div className={classes.container}>
			{title && (
				<h3 className={pageUtils.classes.h3}>{formatStrapiText(title)}</h3>
			)}
			{((isSummary && files.size > 0) || (!isSummary && subtitle)) && (
				<h4
					className={joinClasses([
						pageUtils.classes.h4,
						!title ? pageUtils.classes.marginTop0 : '',
						hasError && required ? pageUtils.classes.error : ''
					])}
					style={{ fontSize: isSummary ? '14px' : '16px' }}
				>
					{formatStrapiText(subtitle)}{' '}
					{required && !isSummary && <RequiredIcon />}
				</h4>
			)}

			{!isSummary && description && (
				<span
					className={joinClasses([
						pageUtils.classes.description,
						hasError ? pageUtils.classes.error : ''
					])}
				>
					<Text content={description} />
				</span>
			)}

			{hasError && required && (
				<Error hasError errorText={pageData?.assets?.required_file} />
			)}

			{/* blob */}
			<div
				{...getRootProps()}
				className={joinClasses([
					// hasError || notificationError || (isSameFileUploaded && !isDragOver)
					// 							? pageUtils.classes.errorBorder
					// 							: '',
					pageUtils.classes.dragAndDropArea,
					// isDragOver ? pageUtils.classes.dragOver : '',
					maxFiles && files.size >= maxFiles ? pageUtils.classes.disabled : ''
				])}
			>
				<input {...getInputProps()} />
				<p style={{ marginBottom: '0.25rem' }}>
					{pageData?.assets?.page_requestInfo_drop_file}
				</p>
				<Link
					id={name}
					className={joinClasses([
						pageUtils.classes.btn,
						pageUtils.classes.inputFileBtn,
						maxFiles && files.size >= maxFiles
							? pageUtils.classes.btnDisabled
							: ''
					])}
					to="#"
					// onClick={(e) => onOpenUploadFiles(e)}
				>
					{formatStrapiText(pageData?.assets?.page_requestInfo_inputFile)}
				</Link>
			</div>

			<TransitionGroup
				className={
					addTransitionClassContainer ? pageUtils.classes.fileList : ''
				}
			>
				{files.size > 0 &&
					[...files].map((file: File, key: number) => (
						<CSSTransition
							timeout={500}
							key={key}
							classNames={{
								enter: pageUtils.classes.enter,
								enterActive: pageUtils.classes.enterActive,
								exit: pageUtils.classes.exit,
								exitActive: pageUtils.classes.exitActive
							}}
							onEnter={() => setAddTransitionClassContainer(true)}
							onExited={() => {
								if ([...files].length === 0) {
									setAddTransitionClassContainer(false)
								}
							}}
						>
							<div className={pageUtils.classes.fileItem} key={key}>
								<div
									className={joinClasses([
										pageUtils.classes.fileItemDetails,
										classes.justifyContent
									])}
									// onClick={onOpenFileOnBrowser(file)}
								>
									<div className={classes.fileItemName}>
										{displayThumbnail(getRequestImg(file))}
										{file.name}{' '}
										{
											// isSummary &&
											`(${formatFileSize(file.size)})`
										}{' '}
									</div>
									<div className={classes.fileItemStatus}>
										{uploadedFilesMaplocal.has(file.name) ? (
											<>
												<span>
													{' '}
													<div>✅</div> Téléversé avec succès !
												</span>
											</>
										) : (
											<span>
												<div className={classes.loader} /> Téléversement en
												cours...
											</span>
										)}
									</div>
								</div>
								{/* {!isSummary && ( */}
								<div
									className={pageUtils.classes.fileItemCloseIcon}
									onClick={handleRemoveFile(file)}
								>
									<Icon
										src={closeIcon}
										className={pageUtils.classes.imgCloseIcon}
									/>
								</div>
								{/* )} */}
							</div>
						</CSSTransition>
					))}
			</TransitionGroup>

			{uploadedFilesMap.size > 0 && (
				<div className={classes.uploadedFiles}>
					<strong>Fichiers téléversés :</strong>
					<ul>
						{Array.from(uploadedFilesMap).map(([fileName, url], index) => (
							<li key={index}>
								<a href={url} target="_blank" rel="noopener noreferrer">
									{fileName}
								</a>
							</li>
						))}
					</ul>
				</div>
			)}

			{errors.length > 0 && (
				<div className={classes.errorContainer}>
					<strong>Erreurs :</strong>
					<ul>
						{errors.map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</div>
			)}
		</div>
	)
}

export default BlobUploader
