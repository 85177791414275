import Breakpoints from '@utils/breakpoints'
import { chevronDownBlueIcon } from '@images/icons'
import { ButtonStyle, Colors, Constants } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { Coordinate, MapFormProps } from '@components/ui/map/mapForm.interfaces'
import {
	AddressParts,
	AdministrativeRiviewInfo,
	CheckboxList,
	inputTablesFields,
	RadioList,
	RequestInputs,
	SelectList
} from '@utils/request'
import { Address } from '@services/models'
import { Dictionary, TempAllTextType } from 'typings/shared'
import moment from 'moment'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { Dispatch, ReactElement, SetStateAction } from 'react'
import { IWaterCounterAddressDto } from '@services/types'
export interface MapTNProps extends MapFormProps {
	form?: string
	errors?: string[]
	inputs?: RequestInputs[]
	onFixError?: React.Dispatch<React.SetStateAction<string[]>>
	onSetInputs?: (inputs: RequestInputs[]) => void
	hasError?: boolean
	setIsRequired?: React.Dispatch<React.SetStateAction<boolean>>
	buildLocation?: (
		address: string,
		apartment: string,
		city: string,
		postalCode: string,
		coordinates?: Coordinate,
		cadastralAddress?: string
	) => Address
	onSetCadastralAddress?: React.Dispatch<
		React.SetStateAction<string | undefined>
	>
	onSetCity?: React.Dispatch<React.SetStateAction<string>>
	onSetPostalCode?: React.Dispatch<React.SetStateAction<string | undefined>>
	setMapLabel?: React.Dispatch<React.SetStateAction<string>>
	setUseDefaultUserLocation?: React.Dispatch<React.SetStateAction<boolean>>
	isRequired?: boolean
	setDisableNextButton?: React.Dispatch<React.SetStateAction<boolean>>
	closeLink?: string
	renderAddressValidation?: () => React.ReactNode
	setShouldCheckAddress?: React.Dispatch<React.SetStateAction<boolean>>
	setIsRequestAddressOutsideLaval?: React.Dispatch<
		React.SetStateAction<boolean>
	>
	isAddressOutsideLavalMessageShow?: boolean
	setIsAddressOutsideLavalMessageShow?: React.Dispatch<
		React.SetStateAction<boolean>
	>
}

export type CheckboxType = {
	key: string
	value: string
	checked: boolean
	extraText?: string
	details?: ReactElement
}
export type FileSectionProps = {
	isSummary?: boolean
}

export type CheckboxListType = {
	value: string
	key: string
	extraText?: string[]
}

type classType = {
	h4: string
	sectionNoPadding: string
	h5: string
	h6: string
	fieldsContainer: string
	smallInputs: string
	select: string
	select2: string
	answer: string
	noMargin: string
	buttonOutline: string
	button: string
	btn: string
	customInput: string
	error: string
	inputTitle: string
	filesInput: string
	radioLabel: string
	textFieldContainer: string
	flexItem: string
	applyMargin: string
	anchorTag: string
	marginBottom: string
	bottomMargin: string
	fieldWrapper: string
	formInput: string
	inputError: string
	label: string
	wrapField: string
	subTitle: string
	fileHelperSection: string
	fileContainer: string
	fileInfo: string
	notification: string
	whiteSpaces: string
	removeMargins: string
	minusMargin: string
	collapse: string
	heightAuto: string
	summaryCollapseButton: string
	toPrintTitle: string
	summarySubTitles: string
	answerURB: string
}

export const classes: classType = makeClasses({
	h4: {
		fontSize: '22px',
		lineHeight: '26px',
		margin: '0 0 30px 0',
		color: Colors.darkBlue1,
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '20px'
		}
	},
	sectionNoPadding: {
		'& section': {
			paddingRight: '0px !important',
			paddingLeft: '0px !important',
			paddingTop: '0px !important'
		}
	},
	h5: {
		fontSize: '20px',
		fontWeight: '600',
		margin: '40px 0 15px 0',
		color: Colors.secondary
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	smallInputs: {
		'& input': {
			maxWidth: '200px'
		}
	},
	select: {
		display: 'block',
		padding: '0 12px',
		width: '100%',
		height: '44px',
		lineHeight: '25px',
		WebkitFontSmoothing: 'antialiased !important',
		fontWeight: '500px',
		fontSize: '15px',
		color: Colors.lightBlack,
		border: `1px solid ${Colors.grey}`,
		MozAppearance: 'none',
		WebkitAppearance: 'none',
		appearance: 'none',
		backgroundImage: `url(${chevronDownBlueIcon})`,
		backgroundColor: Colors.white,
		backgroundRepeat: 'no-repeat,repeat',
		backgroundPosition: 'right 0.7em top 50%,0 0',
		backgroundSize: '24px'
	},
	select2: {
		[Breakpoints.minWidth('sm')]: {
			'& select': {
				width: '30%'
			}
		}
	},
	answer: {
		display: 'block',
		margin: '0px 0px 15px',
		whiteSpace: 'normal'
	},
	noMargin: {
		margin: '0px !important'
	},
	btn: {
		...ButtonStyle,
		'&:hover': {
			background: Colors.primary
		}
	},
	buttonOutline: {
		backgroundColor: 'transparent',
		border: `1px solid ${Colors.secondary}`,
		color: `${Colors.secondary}`
	},
	button: {
		cursor: 'pointer',
		padding: '15px 30px',
		fontSize: '16px',
		marginLeft: '40px',
		height: '48px'
	},
	customInput: {
		'& input': {
			width: '40%'
		}
	},
	error: {
		color: Colors.errorRed
	},
	filesInput: {
		'& section': {
			paddingTop: '10px!important',
			paddingBottom: '0px!important',
			':first-of-type': {
				paddingBottom: '0px !important'
			},
			':last-of-type': {
				paddingBottom: '40px!important'
			}
		},
		'& h4': {
			fontWeight: '500',
			lineHeight: '1.62em',
			fontSize: '16px'
		}
	},
	inputTitle: {
		margin: '20px 0 5px',
		padding: '0',
		fontWeight: '500',
		fontSize: '20px',
		lineHeight: '22px',
		color: Colors.lightBlack
	},
	radioLabel: {
		[Breakpoints.maxWidth('sm')]: {
			'& label': {
				display: 'block'
			}
		}
	},
	textFieldContainer: {
		marginBottom: '10px !important'
	},
	flexItem: {
		display: 'flex',
		justifyContent: 'start',
		span: {
			color: Colors.ligthPurple,
			marginLeft: '5px'
		}
	},
	applyMargin: {
		margin: '10px 0'
	},
	anchorTag: {
		textDecoration: 'none',
		color: Colors.blue
	},
	marginBottom: {
		marginBottom: '10px'
	},
	bottomMargin: {
		marginBottom: '20px !important'
	},
	fieldWrapper: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	formInput: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400 as any,
		padding: '12px',
		borderRadius: '0px',
		lineHeight: '26px',
		marginBottom: '10px',
		'&:focus': {
			outline: 'none'
		}
	},
	inputError: {
		border: `1px solid ${Colors.errorRed}`,
		'&:focus': {
			borderColor: Colors.errorRed,
			boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
		}
	},
	label: {
		display: 'block',
		padding: '0 0 5px',
		fontWeight: 'bold',
		fontSize: '16px',
		lineHeight: '1.2em'
	},
	wrapField: {
		'& label.css-17qsju7, & label.css-16y4tb2': {
			// display: 'flex !important',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '10px 12px'
		},
		'& div.css-uewl2b  ': {
			background: '#fff',
			border: '1px solid #ddd',
			borderTop: 'none',
			padding: '14px 12px 5px',
			marginTop: '-5px',
			marginBottom: '5px'
		},
		'& input.css-1jip86f': {
			marginBottom: '15px'
		}
	},
	subTitle: {
		marginBottom: '20px !important',
		padding: 0,
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '25px'
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	},
	fileInfo: {
		marginBottom: '30px',
		display: 'flex',
		'font-size': '14px'
	},
	notification: {
		backgroundColor: Colors.lightBlueTransparent20,
		borderColor: `${Colors.lightBlue} !important`,
		display: 'flex',
		'-webkit-box-pack': 'justify',
		'-webkit-justify-content': 'space-between',
		justifyContent: 'space-between',
		'-webkit-align-items': 'center',
		'-webkit-box-align': 'center',
		'-ms-flex-align': 'center',
		alignItems: 'center',
		border: '1px solid',
		padding: '20px',
		marginBottom: '20px',
		width: '100%'
	},
	removeMargins: {
		'& p': {
			marginBottom: '0px',
			marginTop: '0px'
		}
	},
	minusMargin: {
		marginTop: '-20px',
		marginBottom: '-20px'
	},
	h6: {
		color: Colors.secondary,
		margin: '30px 0 15px',
		fontSize: '18px',
		fontWeight: 500,
		lineHeight: 1.2
	},
	collapse: {
		'& > div[id^="collapse-"i]': {
			borderBottom: `1px solid ${Colors.grey}`,
			':first-of-type': {
				borderTop: `1px solid ${Colors.grey}`
			}
		}
	},
	heightAuto: {
		height: 'auto!important'
	},
	summaryCollapseButton: {
		position: 'absolute',
		right: '10px',
		top: '-42px',
		padding: '14px 25px',
		height: '40px',
		fontSize: '15px'
	},
	toPrintTitle: {
		'& > div > div[id^="collapse-button-"i]': {
			display: 'none !important',
			'&::before': {
				content: '""',
				width: '0px !important',
				height: '0px !important'
			}
		},
		'& div>.css-15sa9uj': {
			display: 'none !important'
		}
	},
	summarySubTitles: {
		'& h5': {
			fontSize: '18px !important',
			margin: '10px 0px !important'
		},
		'& h6': {
			fontSize: '17px !important',
			margin: '10px 0px !important'
		}
	},
	answerURB: {
		display: 'block',
		margin: '0px 0px 8px',
		whiteSpace: 'normal'
	}
})

export const trafficLightAndPedestrianLightKeys = {
	wishTrafficLight:
		'request_form_TrafficLightAndPedestrianLight_wishList_traffic_light',
	wishTrafficLightRepair:
		'request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_repaired',
	wishTrafficLightModified:
		'request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_modified',
	wishTrafficLightPedestrian:
		'request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_pedestrian',
	wishTrafficLightRepairPedestrian:
		'request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_repaired_pedestrian',
	wishTrafficLightModifiedPedestrian:
		'request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_modified_pedestrian'
}

export const addLabelToList = (
	list: any,
	assets?: Dictionary
): CheckboxList[] => {
	if (list.length > 0) {
		const clonedList = JSON.parse(JSON.stringify(list))
		return clonedList?.map((listElement) => {
			if (Array.isArray(listElement.extraText)) {
				listElement.extraText = listElement.extraText.map((el) => assets?.[el])
			}

			return {
				...listElement,
				label: assets?.[listElement.key]!
			}
		})
	}
	return list
}

export const onCheckValue = (
	key: string,
	list: CheckboxList[] | undefined,
	setList: (value: React.SetStateAction<CheckboxList[] | undefined>) => void,
	setInput: (value: React.SetStateAction<RequestInputs>) => void,
	isSingleElement?: boolean
) => {
	if (!key) {
		return
	}

	const result = list?.map((item) => {
		if (item.key == key) {
			item.checked = !item.checked
		}

		return item
	})

	setList(result)

	if (isSingleElement) {
		setInput((oldInput) => ({
			...oldInput,
			value: result
				?.map((item) => `${item.checked ? 'Oui' : 'Non'}`)
				.filter((item) => !!item)
				.join('')!
		}))
	} else {
		setInput((oldInput) => ({
			...oldInput,
			value: result
				?.map((item) => `[${item.checked ? 'x' : ''}] ${item.value}`)
				.filter((item) => !!item)
				.join(';\n')!
		}))
	}
}

export const onSelectTableValue = (
	updatedList: inputTablesFields | undefined,
	setTableInput: (value: React.SetStateAction<RequestInputs>) => void,
	setTableList: React.Dispatch<React.SetStateAction<inputTablesFields>>
) => {
	let value = ''
	for (let i = 0; i < 5; i++) {
		const header = updatedList?.headers[i]
		const morning = updatedList?.rows[0][i].label
		const isMorning = updatedList?.rows[0][i].selected
		const evening = updatedList?.rows[1][i].label
		const isEvening = updatedList?.rows[1][i].selected
		value += `[${isMorning || isEvening ? 'x' : ''}] ${header} ${
			isMorning ? morning : ''
		} ${isEvening ? evening : ''};\n`
	}

	setTableInput((oldInput) => ({
		...oldInput,
		value
	}))
	setTableList(updatedList!)
}

export const generateCheckboxListFromAnswer = (
	list: CheckboxType[],
	answer?: string,
	assets?: Dictionary
) => {
	const stringList = answer?.split(';\n')
	let newArray: CheckboxType[] = []
	const othersArray: CheckboxList[] = []
	stringList?.forEach((ele) => {
		if (ele.includes('[x]')) {
			const value = ele.split('[x]')[1].trim()
			const selectedValue = list.filter((item) => item.value.trim() === value)
			if (selectedValue.length > 0)
				newArray.push({
					...selectedValue[0],
					checked: true
				})
			else {
				othersArray.push({
					...generateInput(value),
					checked: true
				})
			}
		} else {
			const value = ele.split('[]')[1]
			const unselectedValue = list.filter(
				(item) => item?.value?.trim() === value.trim()
			)
			newArray.push({
				...unselectedValue[0]
			})
		}
	})
	const newAnswer = addLabelToList(newArray, assets)
	newAnswer.push(...othersArray)
	return newAnswer
}
export const doesFileExist = (file: any) => {
	return typeof file != 'undefined' && !(Object.keys(file).length === 0)
}
export const getListfromString = (answers?: string) => {
	if (!answers) return

	const newAnswer = answers
		.split(';\n')
		.filter((answer) => answer.includes('[x]'))
		.map((answer) => answer.split('[x]')[1].trim())
	return newAnswer
}

export const generateTableInputFromAnswer = (
	availabilityTable: inputTablesFields,
	assets: Dictionary,
	answers?: string
) => {
	if (!answers) return

	const availabilityList = answers.split(';\n')
	const clonedAvailabilityTable: inputTablesFields = JSON.parse(
		JSON.stringify(availabilityTable)
	)

	const headerLength = availabilityTable.headers.length
	for (let i = 0; i < headerLength; i++) {
		if (availabilityList[i].includes('[x]')) {
			if (availabilityList[i].includes(assets.label_day)) {
				clonedAvailabilityTable.rows[0][i].selected = true
			}
			if (availabilityList[i].includes(assets.label_evening)) {
				clonedAvailabilityTable.rows[1][i].selected = true
			}
		}
	}

	return clonedAvailabilityTable
}

export const trainingAndExperienceListAndValues = [
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_archaeology',
		value: 'Archéologie / Muséologie / Histoire / Anthropologie',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_arts',
		value: 'Arts',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_biology',
		value: 'Biologie / Sciences de la vie',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_accounting',
		value: 'Comptabilité / Audit / Finances / Bancaire',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_mentorship',
		value: 'Développement des affaires / Entrepreneuriat / Mentorat',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_law',
		value: 'Droit',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_social_economy',
		value: 'Économie / Économie sociale',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_education',
		value: 'Éducation',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_environnement',
		value: 'Environnement',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_management',
		value: 'Gestion',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_project_management',
		value: 'Gestion de projets',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_project_governance',
		value: 'Gouvernance',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_project_engineering',
		value: 'Ingénierie',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_project_linguistics_and_toponymy',
		value: 'Linguistique / Toponymie',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_project_marketing_and_communications',
		value: 'Marketing / Communications',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_project_construction_trades',
		value: 'Métiers de la construction',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_project_human_resources',
		value: 'Ressources humaines/ SST',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_health_psychology',
		value: 'Santé / Psychologie / Travail social',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_information_technologies',
		value: 'Technologies de l’information',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_transport_mobility',
		value: 'Transport / Mobilité',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_first_question_urbanism_architecture',
		value: 'Urbanisme / Architecture',
		checked: false
	}
]
export const experienceListAndValues = [
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_arts',
		value: 'Arts',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_volunteer',
		value: 'Bénévolat',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_accounting',
		value: 'Comptabilité / Finances / Audit',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_board_of_directors',
		value: 'Conseil d’administration',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_education_domain',
		value: 'Domaine de l’éducation',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_economy',
		value: 'Économie',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_social_economy',
		value: 'Économie sociale',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_environment',
		value: 'Environnement',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_management',
		value: 'Gestion',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_project_management',
		value: 'Gestion de projets',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_governance',
		value: 'Gouvernance',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_histoire',
		value: 'Histoire',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_engineering_construction',
		value: 'Ingénierie / Construction',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_fundraising',
		value: 'Levées de fonds',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_linguistic',
		value: 'Linguistique',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_social_housing',
		value: 'Logement social',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_marketing_communications',
		value: 'Marketing / Communications',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_healthcare',
		value: 'Milieu de la santé',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_planification_stratégique',
		value: 'Planification stratégique',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_psychology_social_work',
		value: 'Psychologie / Travail social',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_human_ressource',
		value: 'Ressources humaines',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_information_technology',
		value: 'Technologies de l’information',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question_town_planning',
		value: 'Urbanisme',
		checked: false
	}
]

export const interestedPostListAndValues = [
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_advisory',
		value: 'Comité consultatif Fonds Place-du-Souvenir',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_environmental_advisory',
		value: 'Comité consultatif en environnement',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_seniors_advisory',
		value: 'Comité consultatif des personnes aînées',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_intercultural_relations',
		value: 'Comité consultatif sur les relations interculturelles',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_youth_advisory',
		value: 'Comité consultatif jeunesse',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_young_promoters',
		value: 'Comité de recommandation du Fonds jeunes-promoteurs',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_board_of_directors_of_the_metropolitan',
		value:
			'Conseil d’administration du Réseau de transport métropolitain (EXO)',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_toponymy_committee',
		value: 'Comité de toponymie',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_planning_advisory',
		value: 'Comité consultatif en urbanisme',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_employees_retirement',
		value: 'Comité du Régime de retraite des employés de la Ville de Laval',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_audit_committee',
		value: 'Comité de vérification',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_joint_investment_committee',
		value: 'Comité d’investissement conjoint FLI / FLS',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_social_economy_fund',
		value: 'Comité Fonds d’économie sociale',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_municipaljoint_industrial',
		value: 'Comité mixte municipal industriel',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_council_of_lavaloises',
		value: 'Conseil des Lavalloises',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_board_of_directors',
		value: 'Conseil d’administration du CPE La Relève',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_board_of_directors_of_the_quebec_center',
		value:
			'Conseil d’administration du Centre québécois d’innovation en biotechnologie',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_board_of_directors_of_the_city',
		value: 'Conseil d’administration de la Cité de la culture et du sport',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_multi_Sports_complex',
		value: 'Conseil d’administration du Complexe Multi-Sports',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_board_of_directors_of_the_corporation',
		value: 'Conseil d’administration de la Corporation du Centre du Sablon',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_eco_nature_board_of_directors',
		value: 'Conseil d’administration de Éco-Nature',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_board_of_directors_of_the_office_municipale',
		value:
			'Conseil d’administration de l’Office Municipale d’Habitation de Laval',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_board_of_directors_societe_de_transport',
		value: 'Conseil d’administration de la Société de transport de Laval',
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_third_question_board_of_directors_of_aeroports',
		value: 'Conseil d’administration de Aéroports de Montréal',
		checked: false
	}
]

export const infestationListTypeAndValues = [
	{
		key: 'request_form_temporaryUseOfPesticide_grain_bugs',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.grainBugs,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_white_worms',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.whiteWorms,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_spiders',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.spider,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_crabgrass',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.crabgrass,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_wild_strawberry',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.wildStrawberry,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_ground_ivy',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.groundIvy,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_dandelion',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.dandelion,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_plantain',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.plantain,
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_others',
		value: REQUEST_CONFIGS_KEYS.typeOfInfestation.others,
		checked: false
	}
]

export const natureOfInventionListTypeAndValues = [
	{
		key: 'request_form_storage_of_construction',
		value: REQUEST_CONFIGS_KEYS.natureOfInvention.storageOfConstruction,
		checked: false
	},
	{
		key: 'request_form_storage_of_container',
		value: REQUEST_CONFIGS_KEYS.natureOfInvention.storageOfAContainer,
		checked: false
	},
	{
		key: 'request_form_position_of_machinery',
		value: REQUEST_CONFIGS_KEYS.natureOfInvention.positioningOfMachinery,
		checked: false
	},
	{
		key: 'request_form_select_label_other',
		value: REQUEST_CONFIGS_KEYS.natureOfInvention.others,
		checked: false
	}
]
export const impactsOnTrafficListTypeAndValues = [
	{
		key: 'request_form_sidewalk',
		value: REQUEST_CONFIGS_KEYS.impactsOnTraffic.sidewalk,
		checked: false
	},
	{
		key: 'request_form_bicycle_lane',
		value: REQUEST_CONFIGS_KEYS.impactsOnTraffic.bicycleLane,
		checked: false
	},
	{
		key: 'request_form_parking_or_shoulder_lanes',
		value: REQUEST_CONFIGS_KEYS.impactsOnTraffic.parkingOrShoulderLane,
		checked: false
	},
	{
		key: 'request_form_lanes_of_traffic',
		value: REQUEST_CONFIGS_KEYS.impactsOnTraffic.lanesOfTraffic,
		checked: false
	},
	{
		key: 'request_form_reserved_lane',
		value: REQUEST_CONFIGS_KEYS.impactsOnTraffic.reservedLane,
		checked: false
	}
]

export const locationOfInfestationListAndValues = [
	{
		key: 'request_form_temporaryUseOfPesticide_in_front',
		value: REQUEST_CONFIGS_KEYS.locationOfInfestation.inFront,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_back_yard',
		value: REQUEST_CONFIGS_KEYS.locationOfInfestation.backYard,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_left_side_yard',
		value: REQUEST_CONFIGS_KEYS.locationOfInfestation.leftSideYard,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_right_side_yard',
		value: REQUEST_CONFIGS_KEYS.locationOfInfestation.rightSideYard,
		checked: false
	}
]

export const spaceToBeTreatedListAndValues = [
	{
		key: 'request_form_temporaryUseOfPesticide_trees_shrubs',
		value: REQUEST_CONFIGS_KEYS.spaceTobeTreated.treesShrubs,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_lawn',
		value: REQUEST_CONFIGS_KEYS.spaceTobeTreated.Lawn,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_building_foundations',
		value: REQUEST_CONFIGS_KEYS.spaceTobeTreated.buildingFoundations,
		checked: false
	},
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_others',
		value: REQUEST_CONFIGS_KEYS.spaceTobeTreated.others,
		checked: false
	}
]

export const controlMethodsAlreadyAppliedListValues = [
	{
		key: 'request_form_temporaryUseOfPesticide_agronomic',
		value: REQUEST_CONFIGS_KEYS.controlMethods.agronomic,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_mechanical',
		value: REQUEST_CONFIGS_KEYS.controlMethods.mechanical,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_physical',
		value: REQUEST_CONFIGS_KEYS.controlMethods.physical,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_biological',
		value: REQUEST_CONFIGS_KEYS.controlMethods.biological,
		checked: false
	},
	{
		key: 'request_form_temporaryUseOfPesticide_low_impact_chemicals',
		value: REQUEST_CONFIGS_KEYS.controlMethods.chemicals,
		checked: false
	}
]

export const declarationValues = [
	{
		key: 'request_form_annualPermitAndTagForAnimals_not_having_been_convicted',
		value: '000000',
		checked: false
	},
	{
		key: 'request_form_annualPermitAndTagForAnimals_not_having_been_convicted_an_offence',
		value: '11111',
		checked: false
	},
	{
		key: 'request_form_annualPermitAndTagForAnimals_not_been_found_guilty',
		value: '22222',
		checked: false
	},
	{
		key: 'request_form_annualPermitAndTagForAnimals_that_my_dog_is_not_trained',
		value: '333333',
		checked: false
	},
	{
		key: 'request_form_annualPermitAndTagForAnimals_not_having_had_in_my_care',
		value: '44444',
		checked: false
	}
]

export const studyLevelRawList = [
	{
		label: '',
		value: '',
		key: ''
	},
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_secondary',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_secondary'
	},
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_college',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_college'
	},
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_university_1st_cycle',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_university_1st_cycle'
	},
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_university_2st_cycle',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_university_2st_cycle'
	},
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_university_3st_cycle',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_study_level_university_3st_cycle'
	},
	{
		value: 'label_other',
		key: 'label_other'
	}
]

export const reasonForRenewalRawList = [
	{
		label: '',
		value: '',
		key: ''
	},
	{
		value: REQUEST_CONFIGS_KEYS.reasonForNonRenewal.lost_animal,
		key: 'request_form_annualPermitAndTagForAnimals_lost_animal'
	},
	{
		value: REQUEST_CONFIGS_KEYS.reasonForNonRenewal.given_animal,
		key: 'request_form_annualPermitAndTagForAnimals_given_animal'
	},
	{
		value: REQUEST_CONFIGS_KEYS.reasonForNonRenewal.sold_animal,
		key: 'request_form_annualPermitAndTagForAnimals_sold_animal'
	},
	{
		value: REQUEST_CONFIGS_KEYS.reasonForNonRenewal.death_animal,
		key: 'request_form_annualPermitAndTagForAnimals_death_animal'
	},
	{
		value: REQUEST_CONFIGS_KEYS.reasonForNonRenewal.other_reason,
		key: 'request_form_annualPermitAndTagForAnimals_other_reason'
	}
]

export const selectedDaysListTypeAndValues = [
	{
		value: '',
		key: ''
	},
	{
		value: REQUEST_CONFIGS_KEYS.selectedDays.all_dates,
		key: 'label_all_dates'
	},
	{
		value: REQUEST_CONFIGS_KEYS.selectedDays.week_days,
		key: 'label_days_of_the_week'
	},
	{
		value: REQUEST_CONFIGS_KEYS.selectedDays.weekend,
		key: 'label_weekend'
	}
]

export const fireHydrantsLocations: RadioList[] = [
	{
		label: 'Rue Ernest-Cormier & Montée St-François - AQB11341 (Secteur 1)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.firstOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.firstOption
	},
	{
		label: '2135 Montée Masson - AQB18196 (Secteur 1)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.secondOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.secondOption
	},
	{
		label: '1295 Boulevard Lesage - AQB7497 (Secteur 1)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.thirdOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.thirdOption
	},
	{
		label: '1490 Rue Cunard - AQB4678 (Secteur 2)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.fourthOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.fourthOption
	},
	{
		label: '720 Rue Montgolfier - AQB2421 (Secteur 3)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.fifthOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.fifthOption
	},
	{
		label: '5490 Dagenais Ouest - AQB13856 (Secteur 4)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.sixthOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.sixthOption
	},
	{
		label: '3985 Rue Séguin - AQB779 (Secteur 4)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.seventhOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.seventhOption
	},
	{
		label: '2975 Boulevard Dagenais Ouest - AQB10522 (Secteur 5)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.eighthOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.eighthOption
	},
	{
		label: '65 Chemin de l’Équerre - AQB3100 (Secteur 5)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.tenthOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.tenthOption
	},
	{
		label: '5730 Rue de Prince-Rupert - AQB4224 (Secteur 6)',
		value: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.ninthOption,
		key: REQUEST_CONFIGS_KEYS.sectorFireHydrantLocation.ninthOption
	}
]

export const targetedPositionRadioList = [
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_position_application_all_position',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_position_application_all_position'
	},
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_position_application_specific_position',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_position_application_specific_position'
	}
]

export const setGenderRadioList = [
	{
		value: 'label_woman',
		key: 'label_woman'
	},
	{
		value: 'label_man',
		key: 'label_man'
	},
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_i_prefer_no_to_answer',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_i_prefer_no_to_answer'
	}
]

export const specialDisabilityList = [
	{
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_special_disability',
		value: 'Non',
		checked: false
	}
]

export const identificationGroupRawList = [
	{
		value: 'newRequestPage_RecruitmentAndCitizenInvolvement_aboriginal_people',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_aboriginal_people',
		extraText: [
			'newRequestPage_RecruitmentAndCitizenInvolvement_aboriginal_people_firstNation',
			'newRequestPage_RecruitmentAndCitizenInvolvement_aboriginal_people_metis_of_canada',
			'newRequestPage_RecruitmentAndCitizenInvolvement_aboriginal_people_inuit'
		]
	},
	{
		value: 'newRequestPage_RecruitmentAndCitizenInvolvement_visible_minorities',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_visible_minorities',
		extraText: [
			'newRequestPage_RecruitmentAndCitizenInvolvement_visible_minorities_non_aboriginal_people',
			'newRequestPage_RecruitmentAndCitizenInvolvement_visible_minorities_race_or_skin_color'
		]
	},
	{
		value:
			'newRequestPage_RecruitmentAndCitizenInvolvement_ethnic_minority_groups',
		key: 'newRequestPage_RecruitmentAndCitizenInvolvement_ethnic_minority_groups',
		extraText: [
			'newRequestPage_RecruitmentAndCitizenInvolvement_ethnic_minority_groups_aboriginal',
			'newRequestPage_RecruitmentAndCitizenInvolvement_ethnic_minority_groups_neither_french_nor_english'
		]
	}
]
export const useTypeListAndValues = [
	{
		label: 'request_form_historicalArchive_useType_firstOption_label',
		value: '103720000',
		key: 'request_form_historicalArchive_useType_firstOption_label',
		checked: false
	},
	{
		label: 'request_form_historicalArchive_useType_secondOption_label',
		value: '103720001',
		key: 'request_form_historicalArchive_useType_secondOption_label',
		checked: false
	},
	{
		label: 'request_form_historicalArchive_useType_thirdOption_label',
		value: '103720002',
		key: 'request_form_historicalArchive_useType_thirdOption_label',
		checked: false
	},
	{
		label: 'request_form_historicalArchive_useType_fourthOption_label',
		value: '103720003',
		key: 'request_form_historicalArchive_useType_fourthOption_label',
		checked: false
	},
	{
		label:
			'request_form_historicalArchive_isOrganizationRequest_fifthOption_label',
		value: '103720004',
		key: 'request_form_historicalArchive_isOrganizationRequest_fifthOption_label',
		checked: false
	}
]

export const heatingSourceTypeListAndValues = [
	{
		key: 'request_form_fireplaceDeclaration_electricity',
		value: REQUEST_CONFIGS_KEYS.heatingSource.electricity,
		checked: false
	},
	{
		key: 'request_form_fireplaceDeclaration_natural_gas',
		value: REQUEST_CONFIGS_KEYS.heatingSource.naturalGas,
		checked: false
	},
	{
		key: 'request_form_fireplaceDeclaration_propane',
		value: REQUEST_CONFIGS_KEYS.heatingSource.propane,
		checked: false
	},
	{
		key: 'request_form_fireplaceDeclaration_oil_fuel',
		value: REQUEST_CONFIGS_KEYS.heatingSource.oilFuel,
		checked: false
	},
	{
		key: 'request_form_fireplaceDeclaration_dual_energy',
		value: REQUEST_CONFIGS_KEYS.heatingSource.dualEnergy,
		checked: false
	},
	{
		key: 'request_form_fireplaceDeclaration_wood_pellets',
		value: REQUEST_CONFIGS_KEYS.heatingSource.woodPellets,
		checked: false
	},
	{
		key: 'request_form_fireplaceDeclaration_others',
		value: REQUEST_CONFIGS_KEYS.heatingSource.others,
		checked: false
	}
]

export const annualPermitAndTagForAnimalsFileNames = {
	proof_of_microchipped: 'Preuve de micropuçage',
	proof_of_microchipped_contract: 'Preuve de contre-indication de micropuçage',
	proof_of_sterilization: 'Preuve de stérilisation',
	proof_of_sterilization_exemption: "Preuve d'exemption de stérilisation",
	proof_of_rabies_vaccination: 'Preuve de vaccination contre la rage',
	proof_of_need_of_service_animal:
		"Preuve attestant de la nécessité d’un animal d'assistance",
	proof_of_damaged_medal: 'Preuve de la médaille abîmée'
}

export const parkingPermitFileNames = {
	applicant_proof_of_recidence: 'Preuve de résidence',
	vehicle_registration: 'Immatriculation du véhicule',
	proof_of_insurance: 'Preuve d’assurance'
}

export const percentageListAndValues = [
	{
		label: '',
		value: '',
		key: ''
	},
	{
		label: '90%',
		value: '90',
		key: '90'
	},
	{
		label: '80%',
		value: '80',
		key: '80'
	},
	{
		label: '70%',
		value: '70',
		key: '70'
	},
	{
		label: '60%',
		value: '60',
		key: '60'
	},
	{
		label: '50%',
		value: '50',
		key: '50'
	},
	{
		label: '40%',
		value: '40',
		key: '40'
	},
	{
		label: '30%',
		value: '30',
		key: '30'
	},
	{
		label: '20%',
		value: '20',
		key: '20'
	},
	{
		label: '10%',
		value: '10',
		key: '10'
	}
]

export const situationsListAndValues = [
	{
		value: REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.firstOption,
		key: 'select_form_sustainableHygiene_situation_firstOption',
		checked: false
	},
	{
		value: REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption,
		key: 'select_form_sustainableHygiene_situation_secondOption',
		checked: false
	}
]

export const generateInput = (value: string) => {
	const formatedValue = value.replace(' ', '_')
	return {
		key: `newRequestPage_RecruitmentAndCitizenInvolvement_first_question_${formatedValue}`,
		label: value,
		value,
		checked: false,
		isOther: true
	}
}

export const keyCodeToKey = (keyCode: number): string => {
	// Lookup table to map key codes to key values
	const keyMap: { [key: number]: string } = {
		8: 'Backspace',
		190: '.',
		48: '0',
		49: '1',
		50: '2',
		51: '3',
		52: '4',
		53: '5',
		54: '6',
		55: '7',
		56: '8',
		57: '9'
	}
	// return empty string if key code is not recognized
	return keyMap[keyCode] || ''
}
interface TimeObject {
	label: string
	value: string
	key: string
}

interface TimelineObject {
	yearList: TimeObject[]
	currentList: TimeObject[]
}

export const generateTimeList = (
	start: string,
	end: string,
	interval: number
): TimeObject[] => {
	const timeList: TimeObject[] = []
	let currentTime = moment(start, 'HH:mm')
	const endTime = moment(end, 'HH:mm')
	timeList.push({
		label: '',
		value: '',
		key: ''
	})

	while (currentTime <= endTime) {
		const label = currentTime.format('HH:mm')
		const value = label
		const key = `select_appointmentTime_${label.replace(':', 'h')}`
		if (label !== '12:00' && label !== '12:30') {
			timeList.push({ label, value, key })
		}

		currentTime.add(interval, 'minutes')
	}

	return timeList
}

const changingYears = {
	2024: '15/09/2024',
	2026: '01/05/2026'
}

export const generateYearList = (
	start: string,
	end: string,
	interval: number
): TimelineObject => {
	const currentDate = moment()
	const yearList: TimeObject[] = []
	let currentList: TimeObject[] = []
	const changeDate = moment(changingYears[0], 'yyyy')
	let currentTime = moment(start, 'yyyy')
	// const endTime = moment(end, 'yyyy')
	// let firstTimeframe = true
	yearList.push({ label: '', value: '', key: 'select_financialExercise_0' })

	const lengthOfList = 4

	const initialValue = 588640000
	let count = 0

	while (yearList.length < lengthOfList) {
		let label = ''
		let value = ''
		for (let index = 0; index < interval; index++) {
			label += Number(currentTime.format('yyyy')) + index
			if (index < interval - 1) {
				label += ', '
			}
		}

		value = String(initialValue + count)

		const key = `select_financialExercise_${label}`
		yearList.push({ label, value, key })
		count++
		currentTime.add(interval, 'year')
	}

	if (currentDate.isBefore(moment(changingYears[2024], 'DD/MM/YYYY'))) {
		currentList = yearList.slice(0, 1).concat(yearList.slice(2, 3))
	} else if (
		currentDate.isBetween(
			moment(changingYears[2024], 'DD/MM/YYYY'),
			moment(changingYears[2026], 'DD/MM/YYYY')
		)
	) {
		currentList = yearList.slice(0, 1).concat(yearList.slice(2, 5))
	} else if (currentDate.isAfter(moment(changingYears[2026], 'DD/MM/YYYY'))) {
		currentList = yearList.slice(0, 1).concat(yearList.slice(3, 4))
	} else {
		currentList = yearList
	}

	return { yearList, currentList }
}

export const getLocaleDate = (value: string) => {
	if (value) {
		return moment(value).utc().format('DD/MM/YYYY')
	}
	return null
}

export const removeStrFromStrArray = (value: string, list: string) => {
	const values = list?.split(',')?.map((val) => val?.trim())
	const index = values?.indexOf(value)
	if (index !== -1) {
		values?.splice(index, 1)
		return values?.join(', ')
	}
	return list
}

export const createSearchableList = (strapiString: string) => {
	return strapiString?.split(',')?.map((value) => {
		let item = value?.split(':')
		return { label: item[0]?.trim(), value: item[1]?.trim() }
	})
}

export const getNumberOfSelectedFromStrArray = (list: string) => {
	const values = list?.split(',')?.map((val) => val?.trim())

	return values?.length
}

export const handleCheckboxWithOthers = (
	value: string,
	questionInput: RequestInputs,
	setQuestionInput: (value: React.SetStateAction<RequestInputs>) => void,
	questionOtherInput: RequestInputs,
	setQuestionOtherInput: (value: React.SetStateAction<RequestInputs>) => void,
	list: CheckboxList[]
) => {
	const isNumberValue = !isNaN(parseInt(value))

	if (isNumberValue) {
		let newValue
		if (questionInput.value?.includes(value)) {
			newValue = removeStrFromStrArray(value, questionInput.value)
		} else {
			newValue =
				questionInput.value == '' ? value : questionInput.value + `, ${value}`
		}

		setQuestionInput({
			...questionInput,
			value: newValue
		})

		// check if the other option is selected
		// the other input should turn to required or not
		if (newValue.includes(list[list?.length - 1]?.value)) {
			setQuestionOtherInput({
				...questionOtherInput,
				required: true
			})
		} else {
			setQuestionOtherInput({
				...questionOtherInput,
				required: false
			})
		}
	} else {
		let newOtherValue
		if (questionOtherInput.value?.includes(value)) {
			newOtherValue = removeStrFromStrArray(value, questionOtherInput.value)
		} else {
			newOtherValue =
				questionOtherInput.value == ''
					? value
					: questionOtherInput.value + `, ${value}`
		}

		setQuestionOtherInput({
			...questionOtherInput,
			value: newOtherValue
		})
	}
}

export const transformData = (
	dateTimeList?: AdministrativeRiviewInfo[],
	useDefault?: boolean
) => {
	const transformedData: AdministrativeRiviewInfo[] = []

	dateTimeList?.forEach((item) => {
		const isWeekend =
			moment(item.date).weekday() === 0 || moment(item.date).weekday() === 6

		const transformedItem = {
			occpation_value: useDefault
				? item.occpation_value
				: isWeekend
				? selectedDaysListTypeAndValues[3].key
				: selectedDaysListTypeAndValues[2].key,
			start_time: !item.is_24_7 ? item.start_time : '',
			end_time: !item.is_24_7 ? item.end_time : '',
			is_24_7: item.is_24_7,
			is_weekend: item.is_weekend,
			is_from_config: item.is_from_config,
			date: item.date
		}

		transformedData.push(transformedItem)
	})

	if (useDefault) {
		return removeDuplicates(transformedData, 'occpation_value')
	}

	return transformedData?.filter((item, index, self) => {
		const foundIndex = self.findIndex(
			(elem) =>
				elem.start_time === item.start_time && elem.end_time === item.end_time
		)
		return foundIndex === index
	})
}

const removeDuplicates = (array: any[], key: string): any[] => {
	const seen = new Set()
	return array.filter((item) => {
		const value = item[key]
		if (!seen.has(value)) {
			seen.add(value)
			return true
		}
		return false
	})
}

export const onKeyPressNumberInput = (event) => {
	const charCode = event.which ? event.which : event.keyCode
	const char = String.fromCharCode(charCode)
	const value = event.target.value + char

	// Autoriser uniquement les chiffres et un seul point décimal ou virgule
	// Explication de l'expression régulière
	// ^(?!0{2,}): L'expression ?!0{2,} est un "negative lookahead" qui empêche la chaîne de commencer par deux ou plusieurs zéros.
	// \d*: Zéro ou plusieurs chiffres.
	// \[.,]?: Une virgule optionnelle.
	// \d{0,2}$: Zéro à deux chiffres après la virgule.
	//              ( ^_^ )
	if (!/^(?!0{2,})\d*[.,]?\d{0,2}$/.test(value)) {
		event.preventDefault()
	}
}

export const onKeyPressIntegerInput = (event) => {
	const charCode = event.which ? event.which : event.keyCode
	const char = String.fromCharCode(charCode)
	const value = event.target.value + char

	if (!/^\d*$/.test(value)) {
		event.preventDefault()
	}
}

export const generateRadioList = (list: string[]): RadioList[] => {
	return list?.map((item: string) => {
		return {
			label: item,
			value: item,
			key: item
		}
	})
}

export const generateRadioListWithOriginals = <T>(
	list: T[],
	property: string
): RadioList[] => {
	return list?.map((item: T) => {
		const aprt = extractApartment(item[property])
		return {
			label: aprt,
			value: aprt,
			key: aprt,
			other: item
		}
	})
}

export const extractApartment = (address: string): string => {
	const aptStreetPart = address.split(',')[0].trim().split('-')
	const apartment =
		aptStreetPart.length > 2
			? aptStreetPart.slice(0, 2).join('-')
			: aptStreetPart[0]
	return apartment
}

export const splitAddress = (address: string): AddressParts | null => {
	const regex =
		/^(\d+[-\d\w]*),?\s*(.*?)(\([^)]+\))?\s*([A-Z]\d[A-Z] \d[A-Z]\d)?$/i
	const match = address.match(regex)

	if (match) {
		return {
			name: `${match[1].trim()}, ${match[2].trim()}`,
			streetNumber: match[1].trim(),
			town: match[3]?.trim() || '',
			postalCode: match[4]?.trim() || ''
		}
	}

	return null
}

export const findMatchingAddressInInterval = (
	addresses: IWaterCounterAddressDto[],
	search: string
): IWaterCounterAddressDto[] => {
	const searchAddress = splitAddress(search)

	if (!searchAddress?.streetNumber) return []

	// Extraire uniquement la partie numérique de l'adresse entrée
	const searchStreetNumber = parseInt(
		searchAddress.streetNumber.replace(/[^\d]/g, ''),
		10
	)

	const matchedAddresses: {
		address: IWaterCounterAddressDto
		distance: number
	}[] = []

	for (const address of addresses) {
		const parsedAddress = splitAddress(address.adresse_idf)

		if (
			!parsedAddress?.streetNumber.includes('-') &&
			!parsedAddress?.streetNumber.match(/\d/)
		)
			continue

		if (parsedAddress?.streetNumber.includes('-')) {
			const [startRaw, endRaw] = parsedAddress.streetNumber.split('-')
			const start = parseInt(startRaw.replace(/[^\d]/g, ''), 10)
			const end = parseInt(endRaw.replace(/[^\d]/g, ''), 10)

			if (isNaN(start) || isNaN(end)) continue
			const distance = Math.min(
				Math.abs(searchStreetNumber - start),
				Math.abs(searchStreetNumber - end)
			)
			if (
				(searchStreetNumber >= start && searchStreetNumber <= end) ||
				searchStreetNumber === start ||
				searchStreetNumber === end
			) {
				matchedAddresses.push({ address, distance })
			}
		} else {
			const parsedStreetNumber = parseInt(
				parsedAddress.streetNumber.replace(/[^\d]/g, ''),
				10
			)
			if (isNaN(parsedStreetNumber)) continue

			const distance = Math.abs(searchStreetNumber - parsedStreetNumber)
			matchedAddresses.push({ address, distance })
		}
	}

	// Trier les résultats par distance croissante
	matchedAddresses.sort((a, b) => a.distance - b.distance)

	// Retourner uniquement les adresses triées
	return matchedAddresses.map((item) => item.address)
}

// Format a number with spaces every 3 digits (thousands separator)
export const formatNumberWithSpaces = (value: string | undefined) => {
	if (!value) return value

	// Split the value into integer and decimal parts
	const [integerPart, decimalPart] = value.split(/[.,]/)

	// Format the integer part with spaces
	const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	// Return the formatted number with the original decimal part
	return decimalPart
		? `${formattedIntegerPart},${decimalPart}`
		: formattedIntegerPart
}

// Building Construction or Addition Permit
export const radioListMainUseOfNewBuilding = (pageAssets): RadioList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option2,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option3,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option3'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option4,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option4',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option4_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option5,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.fifthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option5',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option5_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option6,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.sixthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option6',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option6_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option7,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.seventhOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option7',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option7_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option8,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.eighthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option8',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option8_extraText
		}
	]
}

export const radioListMainUseOfExistingBuilding = (pageAssets): RadioList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option1',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option1_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option2,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option2',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option2_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option3,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option3',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option3_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option4,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option4',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option4_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option5,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fifthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option5',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option5_extraText
		}
	]
}

export const radioListWhatToBuildOrImprove = (pageAssets): RadioList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option1,
			value: REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option1',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option1_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option2,
			value: REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option2',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option2_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option3,
			value: REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option3'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option4,
			value: REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option4'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option5,
			value: REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option5'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option6,
			value: REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.sixthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option6',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option6_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option7,
			value: REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option7',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove_option7_extraText
		}
	]
}

export const getListWhatToBuildOrImproveByMainUseOfExistingBuilding = (
	value: string, // mainUseOfExistingBuilding
	Q10_1: string // ExistingMainBuildingHasHousing
) => {
	switch (value) {
		case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption:
		case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fourthOption:
			return [
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.firstOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.secondOption,
				// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.thirdOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.sixthOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption
			]

		case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption:
			if (Q10_1.toString() == 'true') {
				return [
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.firstOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.secondOption,
					// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.thirdOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.sixthOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption
				]
			} else {
				return [
					// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.firstOption,
					// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.secondOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.thirdOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.sixthOption,
					REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption
				]
			}

		case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption:
			return [
				// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.firstOption,
				// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.secondOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.thirdOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.sixthOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption
			]
		case REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fifthOption:
			return [
				// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.firstOption,
				// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.secondOption,
				// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.thirdOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
				REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.sixthOption
				// REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption
			]
	}
}

export const radioListStructureType = (pageAssets): RadioList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_structureType_option1,
			value: REQUEST_CONFIGS_KEYS.structureType.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_structureType_option1',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_structureType_option1_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_structureType_option2,
			value: REQUEST_CONFIGS_KEYS.structureType.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_structureType_option2',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_structureType_option2_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_structureType_option3,
			value: REQUEST_CONFIGS_KEYS.structureType.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_structureType_option3',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_structureType_option3_extraText
		}
	]
}

export const radioListWhoWillBeInChargeOfWork = (pageAssets): RadioList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork_firstOption,
			value: REQUEST_CONFIGS_KEYS.whoWillBeInChargeOfWork.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork_firstOption'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork_secondOption,
			value: REQUEST_CONFIGS_KEYS.whoWillBeInChargeOfWork.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork_secondOption'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork_thirdOption,
			value: REQUEST_CONFIGS_KEYS.whoWillBeInChargeOfWork.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork_thirdOption'
		}
	]
}

export const radioListIsPropertyConnectedToMunicipalConduits = (
	pageAssets
): CheckboxList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_firstOption',
			value:
				REQUEST_CONFIGS_KEYS.isPropertyConnectedToMunicipalConduits.firstOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_firstOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_secondOption',
			value:
				REQUEST_CONFIGS_KEYS.isPropertyConnectedToMunicipalConduits
					.secondOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_secondOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_thirdOption',
			value:
				REQUEST_CONFIGS_KEYS.isPropertyConnectedToMunicipalConduits.thirdOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_thirdOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_fourthOption',
			value:
				REQUEST_CONFIGS_KEYS.isPropertyConnectedToMunicipalConduits
					.fourthOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_fourthOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_fifthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits_fifthOption',
			value:
				REQUEST_CONFIGS_KEYS.isPropertyConnectedToMunicipalConduits.fifthOption,
			checked: false
		}
	]
}

export const listPlannedWork = (pageAssets): CheckboxList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_firstOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.firstOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_firstOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_secondOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.secondOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_secondOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_thirdOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.thirdOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_thirdOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_fourthOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.fourthOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_fourthOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_fifthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_fifthOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.fifthOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_sixthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_sixthOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.sixthOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_sixthOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_seventhOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_seventhOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.seventhOption,
			checked: false
		}
	]
}

export const listPlannedWorkMovingBuilding = (pageAssets): CheckboxList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_plannedWork_firstOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_plannedWork_firstOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.firstOption,
			checked: false,
			extraText:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_plannedWork_firstOption_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_fifthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_fifthOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.secondOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork_seventhOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_plannedWork_seventhOption',
			value: REQUEST_CONFIGS_KEYS.plannedWork.thirdOption,
			checked: false
		}
	]
}

export const radioListConstructionType = (pageAssets): SelectList[] => {
	return [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionType_option1,
			value: REQUEST_CONFIGS_KEYS.constructionType.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_constructionType_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionType_option2,
			value: REQUEST_CONFIGS_KEYS.constructionType.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_constructionType_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionType_option3,
			value: REQUEST_CONFIGS_KEYS.constructionType.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_constructionType_option3'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionType_option4,
			value: REQUEST_CONFIGS_KEYS.constructionType.fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_constructionType_option4'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionType_option5,
			value: REQUEST_CONFIGS_KEYS.constructionType.fifthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_constructionType_option5'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionType_option6,
			value: REQUEST_CONFIGS_KEYS.constructionType.sixthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_constructionType_option6'
		}
	]
}

export const radioListFoundationType = (pageAssets): SelectList[] => {
	return [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationType_option1,
			value: REQUEST_CONFIGS_KEYS.foundationType.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_foundationType_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationType_option2,
			value: REQUEST_CONFIGS_KEYS.foundationType.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_foundationType_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationType_option3,
			value: REQUEST_CONFIGS_KEYS.foundationType.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_foundationType_option3'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationType_option4,
			value: REQUEST_CONFIGS_KEYS.foundationType.fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_foundationType_option4'
		}
	]
}

export const radioListPlaceOnTheTerrain = (pageAssets): SelectList[] => {
	return [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option1,
			value: REQUEST_CONFIGS_KEYS.placeOnTheTerrain.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option2,
			value: REQUEST_CONFIGS_KEYS.placeOnTheTerrain.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option3,
			value: REQUEST_CONFIGS_KEYS.placeOnTheTerrain.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option3'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option4,
			value: REQUEST_CONFIGS_KEYS.placeOnTheTerrain.fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option4'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option5,
			value: REQUEST_CONFIGS_KEYS.placeOnTheTerrain.fifthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option5'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option6,
			value: REQUEST_CONFIGS_KEYS.placeOnTheTerrain.sixthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain_option6'
		}
	]
}
export const radioListWorkTypeEnlargementBuilding = (
	pageAssets,
	existiingBuilding
): CheckboxList[] => {
	let listWorkTypeEnlargementBuilding = [
		{
			label:
				pageAssets?.request_form_buildingEnlargementPermit_workType_option1,
			value: REQUEST_CONFIGS_KEYS.workTypeBuilding.firstOption,
			key: 'request_form_buildingEnlargementPermit_workType_option1',
			checked: false
		},
		{
			label:
				pageAssets?.request_form_buildingEnlargementPermit_workType_option2,
			//todo when formulaire is available: ajust the value
			value: REQUEST_CONFIGS_KEYS.workTypeBuilding.secondOption,
			key: 'request_form_buildingEnlargementPermit_workType_option2',
			checked: false
		},
		{
			label:
				pageAssets?.request_form_buildingEnlargementPermit_workType_option3,
			//todo when formulaire is available: ajust the value
			value: REQUEST_CONFIGS_KEYS.workTypeBuilding.thirdOption,
			key: 'request_form_buildingEnlargementPermit_workType_option3',
			checked: false
		},
		{
			label:
				pageAssets?.request_form_buildingEnlargementPermit_workType_option4,
			//todo when formulaire is available: ajust the value
			value: REQUEST_CONFIGS_KEYS.workTypeBuilding.fourthOption,
			key: 'request_form_buildingEnlargementPermit_workType_option4',
			checked: false
		},
		{
			label:
				pageAssets?.request_form_buildingEnlargementPermit_workType_option5,
			//todo when formulaire is available: ajust the value
			value: REQUEST_CONFIGS_KEYS.workTypeBuilding.fifthOption,
			key: 'request_form_buildingEnlargementPermit_workType_option5',
			checked: false
		},
		{
			label:
				pageAssets?.request_form_buildingEnlargementPermit_workType_option6,
			//todo when formulaire is available: ajust the value
			value: REQUEST_CONFIGS_KEYS.workTypeBuilding.sixthOption,
			key: 'request_form_buildingEnlargementPermit_workType_option6',
			checked: false
		},
		{
			label:
				pageAssets?.request_form_buildingEnlargementPermit_workType_option7,
			//todo when formulaire is available: ajust the value
			value: REQUEST_CONFIGS_KEYS.workTypeBuilding.seventhOption,
			key: 'request_form_buildingEnlargementPermit_workType_option7',
			checked: false
		}
	]
	if (!!existiingBuilding && existiingBuilding == 'false') {
		listWorkTypeEnlargementBuilding = listWorkTypeEnlargementBuilding.filter(
			(item) =>
				item.value !== REQUEST_CONFIGS_KEYS.workTypeBuilding.fourthOption &&
				item.value !== REQUEST_CONFIGS_KEYS.workTypeBuilding.thirdOption
		)
	}
	return listWorkTypeEnlargementBuilding
}

export const radioListMainUseEnlargementBuilding = (
	pageAssets
): RadioList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingEnlargementPermit_installationType_option1,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.firstOption,
			key: 'request_form_buildingEnlargementPermit_installationType_option1',
			extraText:
				pageAssets?.request_form_buildingEnlargementPermit_installationType_option1_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option4,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option4',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option4_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option5,
			value: REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option5',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option3_extraText
		}
	]
}

export const radioListMainUseOfExistingBuildingRenovation = (
	pageAssets
): RadioList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1_extraText
		},
		{
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_mainUseOfExistingBuilding_option2,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption,
			key: 'request_form_residentialRenovationOrRepairPermit_mainUseOfExistingBuilding_option2',
			extraText:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_mainUseOfExistingBuilding_option2_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option4,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option4',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding_option4_extraText
		}
	]
}

// Case 1 ="Ajout d'une entrée extérieur pour le sous-sol"
// Case 2 ="Ajout d'une rampe d'accès ou d'un ascenseur extérieur pour personnes à mobilié réduite"
// Case 3 = "Ajout d'ouvertures (fenêtres, porte ou autres)"
// Case 4 = " Construction d'un avant-toit"
// Case 5 = "Modification de la configuration des escaliers extérieurs d'un bâtiment" "
// Case 7 = " Nouvelle fondation"
// Case 8 = " Remplacement d'un système de chauffage par un système de géothermie"
// Case 9 = " Remplacement de portes ou des fenêtres (nouvelles dimensions)"
// Case 10 = " Remplacement du revêtement de toiture (en utilisant un nouveau matériau)"
// Case 11 = " Remplacement du revêtement extérieur des murs (nouveaux matériaux ou autres propriétés de combustible ou PIAA)"
// Case 12 = " Remplacement ou ajout d'un drain français "
// Case 13 = " Rénovation, modification ou construction d'une cheminée et/ou d'un foyer"
// Case 14 = " Réparation de structure ou de fondation "
// Case 15 = " Autre "

export const radioListTypesOfWorkRenovationExterior = (
	pageAssets
): CheckboxList[] => {
	return [
		{
			label: pageAssets?.request_form_typesOfWorkRenovationExterior_firstOption,
			key: 'request_form_TypesOfWorkRenovationExterior_firstOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.firstOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_secondOption,
			key: 'request_form_TypesOfWorkRenovationExterior_secondOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.secondOption,
			checked: false
		},
		{
			label: pageAssets?.request_form_TypesOfWorkRenovationExterior_thirdOption,
			key: 'request_form_TypesOfWorkRenovationExterior_thirdOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.thirdOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_fourthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_fourthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fourthOption,
			checked: false
		},
		{
			label: pageAssets?.request_form_TypesOfWorkRenovationExterior_fifthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_fifthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fifthOption,
			checked: false
		},
		{
			label: pageAssets?.request_form_TypesOfWorkRenovationExterior_sixthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_sixthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.sixthOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_seventhOption,
			key: 'request_form_TypesOfWorkRenovationExterior_seventhOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.seventhOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_eighthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_eighthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.eighthOption,
			checked: false
		},
		{
			label: pageAssets?.request_form_TypesOfWorkRenovationExterior_ninthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_ninthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.ninthOption,
			checked: false
		},

		{
			label: pageAssets?.request_form_TypesOfWorkRenovationExterior_tenthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_tenthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.tenthOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_eleventhOption,
			key: 'request_form_TypesOfWorkRenovationExterior_eleventhOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.eleventhOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_twelfthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_twelfthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.twelfthOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_thirteenthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_thirteenthOption',
			value:
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.thirteenthOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_fourteenthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_fourteenthOption',
			value:
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fourteenthOption,
			checked: false
		}
	]
}

export const checkboxesListWallWhereAddOpeningsOrReplaceDoors = (
	pageAssets
): CheckboxList[] => {
	return [
		{
			label:
				pageAssets?.request_form_wallWhereAddOpeningsOrReplaceDoorsOrWindows_firstOption,
			key: 'request_form_wallWhereAddOpeningsOrReplaceDoorsOrWindows_firstOption',
			value:
				REQUEST_CONFIGS_KEYS.wallWhereAddOpeningsOrReplaceDoorsOrWindows
					.firstOption,
			checked: false
			
		},
		{
			label:
				pageAssets?.request_form_wallWhereAddOpeningsOrReplaceDoorsOrWindows_secondOption,
			key: 'request_form_wallWhereAddOpeningsOrReplaceDoorsOrWindows_secondOption',
			value:
				REQUEST_CONFIGS_KEYS.wallWhereAddOpeningsOrReplaceDoorsOrWindows
					.secondOption,
			checked: false
			
		},
		{
			label:
				pageAssets?.request_form_wallWhereAddOpeningsOrReplaceDoorsOrWindows_thirdOption,
			key: 'request_form_wallWhereAddOpeningsOrReplaceDoorsOrWindows_thirdOption',
			value:
				REQUEST_CONFIGS_KEYS.wallWhereAddOpeningsOrReplaceDoorsOrWindows
					.thirdOption,
			checked: false},
		{
			label:
				pageAssets?.request_form_wallWhereAddOpeningsOrReplaceDoorsOrWindows_fourthOption,
			key: 'request_form_wallWhereAddOpeningsOrReplaceDoorsOrWindows_fourthOption',
			value:
				REQUEST_CONFIGS_KEYS.wallWhereAddOpeningsOrReplaceDoorsOrWindows
					.fourthOption,
			checked: false}
	]
}

// radioListTypesOfWorkRenovationInterior
export const radioListTypesOfWorkRenovationInterior = (
	pageAssets
): CheckboxList[] => {
	return [
		{
			label: pageAssets?.request_form_typesOfWorkRenovationInterior_firstOption,
			key: 'request_form_TypesOfWorkRenovationInterior_firstOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.firstOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationInterior_secondOption,
			key: 'request_form_TypesOfWorkRenovationInterior_secondOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.secondOption,
			checked: false
		},
		{
			label: pageAssets?.request_form_TypesOfWorkRenovationInterior_thirdOption,
			key: 'request_form_TypesOfWorkRenovationInterior_thirdOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.thirdOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationInterior_fourthOption,
			key: 'request_form_TypesOfWorkRenovationInterior_fourthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.fourthOption,
			checked: false
		},
		{
			label: pageAssets?.request_form_TypesOfWorkRenovationInterior_fifthOption,
			key: 'request_form_TypesOfWorkRenovationInterior_fifthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.fifthOption,
			checked: false
		},
		{
			label: pageAssets?.request_form_TypesOfWorkRenovationInterior_sixthOption,
			key: 'request_form_TypesOfWorkRenovationInterior_sixthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.sixthOption,
			checked: false
		},
		{
			label:
				pageAssets?.request_form_TypesOfWorkRenovationExterior_fourteenthOption,
			key: 'request_form_TypesOfWorkRenovationExterior_fourteenthOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.seventhOption,
			checked: false
		}
	]
}

// LandDevelopmentWorkPermitForm
export const radioListMainUseOfExistingBuildingLandDevWorkPermit = (pageAssets): RadioList[] => {
	return [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding_option1_extraText
		},
		{
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_mainUseOfExistingBuilding_option2,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption,
			key: 'request_form_residentialRenovationOrRepairPermit_mainUseOfExistingBuilding_option2',
			extraText:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_mainUseOfExistingBuilding_option2_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option7,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option7',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option7_extraText
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option4,
			value: REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.fourthOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_installationType_option4',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_installationType_option4_extraText
		}
	]
}

export const radioListTypesOfWorkBalcony = (
	pageAssets
): CheckboxList[] => {
	return [
		{
			label: pageAssets?.request_form_landDevWorkPermit_typesOfWorkBalcony_firstOption,
			key: 'request_form_landDevWorkPermit_typesOfWorkBalcony_firstOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWork.firstOption,
			checked: false,
			extraText:
				pageAssets?.request_form_landDevWorkPermit_typesOfWorkBalcony_firstOption_extraText
		},
		{
			label:
				pageAssets?.request_form_landDevWorkPermit_typesOfWorkBalcony_secondOption,
			key: 'request_form_landDevWorkPermit_typesOfWorkBalcony_secondOption',
			value: REQUEST_CONFIGS_KEYS.typesOfWork.secondOption,
			checked: false,
			extraText:
				pageAssets?.request_form_landDevWorkPermit_typesOfWorkBalcony_secondOption_extraText
		}
	]
}

export const radioListFoundationTypeBalcony = (pageAssets): SelectList[] => {
	return [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_landDevWorkPermit_fondationType_option1,
			value: REQUEST_CONFIGS_KEYS.fondationTypeBalcony.firstOption,
			key: 'request_form_landDevWorkPermit_fondationType_option1'
		},
		{
			label:
				pageAssets?.request_form_landDevWorkPermit_fondationType_option2,
			value: REQUEST_CONFIGS_KEYS.fondationTypeBalcony.secondOption,
			key: 'request_form_landDevWorkPermit_fondationType_option2'
		},
		{
			label:
				pageAssets?.request_form_landDevWorkPermit_fondationType_option3,
			value: REQUEST_CONFIGS_KEYS.fondationTypeBalcony.thirdOption,
			key: 'request_form_landDevWorkPermit_fondationType_option3'
		},
		{
			label:
				pageAssets?.request_form_select_label_other,
			value: REQUEST_CONFIGS_KEYS.fondationTypeBalcony.fourthOption,
			key: 'request_form_select_label_other'
		}
	]
}


export const radioListIsTerraceInclude = (
	pageAssets
): CheckboxList[] => {
	return [
		{
			label: pageAssets?.request_form_landDevWorkPermit_isTerraceInclude_firstOption,
			key: 'request_form_landDevWorkPermit_isTerraceInclude_firstOption',
			value: REQUEST_CONFIGS_KEYS.isTerraceInclude.firstOption,
			checked: false
			
		},
		{
			label: pageAssets?.request_form_landDevWorkPermit_isTerraceInclude_secondOption,
			key: 'request_form_landDevWorkPermit_isTerraceInclude_secondOption',
			value: REQUEST_CONFIGS_KEYS.isTerraceInclude.secondOption,
			checked: false
			
		},
		{
			label: pageAssets?.request_form_landDevWorkPermit_isTerraceInclude_thirdOption,
			key: 'request_form_landDevWorkPermit_isTerraceInclude_thirdOption',
			value: REQUEST_CONFIGS_KEYS.isTerraceInclude.thirdOption,
			checked: false
		},
		{
			label: pageAssets?.request_form_landDevWorkPermit_isTerraceInclude_fourthOption,
			key: 'request_form_landDevWorkPermit_isTerraceInclude_fourthOption',
			value: REQUEST_CONFIGS_KEYS.isTerraceInclude.fourthOption,
			checked: false,
		}

	]
}