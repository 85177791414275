import React, {
	Dispatch,
	forwardRef,
	ForwardRefRenderFunction,
	RefObject,
	SetStateAction,
	useEffect,
	useState
} from 'react'
import {
	TooltipProp,
	CheckboxList,
	RequestInputs,
	UpdateRequestInputsArray
} from '@utils/request'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Error from '@components/requestForms/inputs/error.input'
import Tooltip from '@components/ui/tooltip'
import Breakpoints from '@utils/breakpoints'
import CheckBox from '@components/ui/checkbox'
import requestForm from '../request.form'
import TextInput from './text.inputs'
import Text from '@components/ui/text'
import { CheckboxType } from '../__forms.utils'
import { Collapse } from 'react-collapse'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'

type Props = TooltipProp & {
	name: string
	label: string
	list?: CheckboxList[]
	value: string
	required?: boolean
	hasError?: boolean
	disabled?: boolean
	onChange: (value: string, key: string) => void
	others?: CheckboxType
	setList?: React.Dispatch<React.SetStateAction<CheckboxList[] | undefined>>
	setInput?: React.Dispatch<React.SetStateAction<RequestInputs>>
	errors?: string[]
	onFixError?: React.Dispatch<React.SetStateAction<string[]>>
	shouldNotDivide?: boolean
	description?: string
	hasBackgroundWrapper?: boolean
	newCheckValue?: boolean
	otherInput?: RequestInputs
	setOtherInput?: React.Dispatch<React.SetStateAction<RequestInputs>>
	otherInputPlaceholder?: string
	subTextInputs?: EmotionJSX.Element[]
	isMaxOptionsSelected?: boolean
	UpdateRequestInputsErrorsArray?: (
		errors: string[],
		inputName: string
	) => string[]
	disabledValues?: string[]
}

type Classes = {
	details: string
	root: string
	labelPopup: string
	radio: string
	radioLabel: string
	error: string
	extraText: string
	radioLabelExtraText: string
	listContainer: string
	listItem: string
	description: string
	required: string
	wrapTextField: string
	contentRoot: string
	noWrap: string
	reducedOpacity: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '30px'
	},
	labelPopup: {
		display: 'inline-flex',
		alignItems: 'center',
		[Breakpoints.maxWidth('md')]: {
			justifyContent: 'space-between',
			width: '100%'
		}
	},
	details: {
		maxWidth: '100%',
		background: '#fff',
		padding: '16px 16px 16px 16px',
		border: '1px solid #ddd',
		borderTop: 'none',
		color: Colors.darkGrey,
		flexBasis: '100%',
		p: {
			fontSize: 12,
			lineHeight: '18px'
			// margin: 0
		}
	},
	radio: {
		display: 'inline-flex',
		marginRight: '10px',
		position: 'relative',
		width: '13px',
		height: '13px',
		transform: 'scale(1.5)',
		boxSizing: 'border-box',
		padding: '0',
		outline: 'none',
		'&:hover': {
			cursor: 'pointer'
		},
		'&:before': {
			width: '13px',
			height: '13px',
			borderRadius: '100%',
			top: '-2px',
			left: '-1px',
			position: 'absolute',
			backgroundColor: Colors.white,
			content: "''",
			display: 'inline-block',
			visibility: 'visible',
			border: `1px solid ${Colors.grey}`
		},
		'&:checked': {
			'&:after': {
				content: "''",
				width: '7px',
				height: '7px',
				borderRadius: '100%',
				top: '2px',
				left: '3px',
				position: 'absolute',
				backgroundColor: Colors.secondary
			}
		}
	},
	radioLabel: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		marginTop: '5px',
		cursor: 'pointer',
		fontWeight: 'normal',
		color: Colors.lightBlack
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		input: {
			'&:before': {
				borderColor: Colors.errorRed
			},
			'&:focus': {
				'&:before': {
					boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
				}
			}
		}
	},
	extraText: {
		marginBottom: '0 !important',
		'& div.extraText': {
			maxWidth: '100%',
			color: Colors.lightGrey2,
			flexBasis: '100%',
			marginLeft: 28,
			p: {
				fontSize: 14,
				lineHeight: '18px'
			}
		}
	},
	reducedOpacity: {
		opacity: '0.6'
	},
	radioLabelExtraText: {
		flexWrap: 'wrap'
	},
	listContainer: {
		display: 'flex'
	},
	listItem: {
		flex: 1,
		'& > div': {
			marginBottom: '5px'
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		fontWeight: 400,
		color: Colors.lightGrey2
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	wrapTextField: {
		background: '#fff',
		border: '1px solid #ddd',
		borderTop: 'none',
		padding: '14px 12px 5px',
		marginTop: '-5px',
		marginBottom: '5px',

		'& input.css-1jip86f': {
			marginBottom: '15px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	},
	checkboxWrapper: {
		marginBottom: '5px !important'
	}
})

const CheckboxInputForwardRef: ForwardRefRenderFunction<
	HTMLInputElement,
	Props
> = (
	{
		name,
		label,
		list,
		value,
		required,
		hasError,
		disabled,
		showInfo,
		infoLabel,
		onChange,
		others,
		setList,
		setInput,
		errors,
		onFixError,
		shouldNotDivide,
		description,
		hasBackgroundWrapper,
		newCheckValue,
		otherInput,
		setOtherInput,
		otherInputPlaceholder,
		subTextInputs,
		isMaxOptionsSelected,
		UpdateRequestInputsErrorsArray,
		disabledValues
	},
	ref
) => {
	const { pageAssets } = requestForm()
	const [checkboxListItems, setCheckboxListItems] = useState<
		CheckboxList[] | undefined
	>(list)
	const isSingleValue = otherInput?.value.split(', ').length === 1
	const [otherValue, setOtherValue] = useState<string>(
		isSingleValue ? otherInput?.value : ''
	)
	const [shouldAddValue, setShouldAddValue] = useState<boolean>(true)

	const onSelectOtherInput = (value: string) => {
		if (
			otherInput &&
			shouldAddValue &&
			setOtherInput &&
			onFixError &&
			errors &&
			UpdateRequestInputsErrorsArray
		) {
			setOtherInput({
				...otherInput,
				value
			})
			onFixError(UpdateRequestInputsErrorsArray(errors, otherInput.name))
		}
		setOtherValue(value)
	}

	const onPressEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			if (setOtherInput && otherInput && !!otherValue?.trim()) {
				let newValue: string
				if (
					!!otherInput?.value.trim() &&
					!otherInput.value?.split(', ')?.includes(otherValue)
				) {
					newValue =
						otherInput.value == ''
							? otherValue
							: otherInput.value + `, ${otherValue}`
				} else if (!!otherInput.value?.trim()) {
					newValue = otherInput.value
				} else {
					newValue = otherValue
				}
				// generates an object of CheckboxList and adds it to the checkbox list
				populateWithNewList(newValue)

				setOtherInput({
					...otherInput,
					value: newValue
				})
				setOtherValue('')
				setShouldAddValue(false)
			}
		}
	}

	const getLabel = () => {
		const hasQuestionMark = label?.endsWith('?')
		if (hasQuestionMark) {
			return (
				<div className={classes.contentRoot}>
					{label?.replace('?', '')}
					<span className={required ? classes.noWrap : ''}>?</span>
				</div>
			)
		} else {
			return <span className={required ? classes.required : ''}>{label}</span>
		}
	}

	const isChecked = (item: string): boolean => {
		if (otherInput?.value?.includes(item)) {
			return true
		}
		return value.includes(item)
	}

	const displayCheckbox = (item: CheckboxList) => {
		return (
			<div key={item.key}>
				<CheckBox
					className={joinClasses([
						classes.extraText,
						isMaxOptionsSelected && !isChecked(item.value)
							? classes.reducedOpacity
							: '',
						disabledValues?.includes(item.value) ? classes.reducedOpacity : ''
					])}
					labelText={item.label}
					onChecked={() =>
						disabledValues?.includes(item.value)
							? null
							: onChange(item.value, item.key)
					}
					checked={newCheckValue ? isChecked(item.value) : item.checked}
					hasBackgroundWrapper={hasBackgroundWrapper}
					extraText={typeof item.extraText == 'string' ? item.extraText : ''}
				/>

				{subTextInputs && (
					<Collapse
						isOpened={newCheckValue ? isChecked(item.value) : item.checked}
					>
						<div className={classes.details}>{subTextInputs[item.value]}</div>
					</Collapse>
				)}
			</div>
		)
	}

	const getList = (): CheckboxList[] | undefined => {
		return checkboxListItems?.filter((item) => item.key !== others?.key)
	}

	const getCheckboxList = (stringArray: string[]): CheckboxList[] => {
		return stringArray
			?.map((item) => {
				return {
					value: item,
					label: item,
					key: item?.replace(/ /g, '_'),
					checked: true
				}
			})
			?.filter((item) => item.value !== '')
	}

	const populateWithNewList = (value: string) => {
		if (otherInput && others) {
			const othersList: string[] = value.split(', ')
			const newOtherList: CheckboxList[] = getCheckboxList(othersList)

			if (value !== '' && !!list?.length && !!newOtherList.length) {
				const updatedCheckboxList: CheckboxList[] = [...list, ...newOtherList]
				setCheckboxListItems(updatedCheckboxList)
			} else {
				setCheckboxListItems(list)
			}
		} else {
			setCheckboxListItems(list)
		}
	}

	useEffect(() => {
		if (!isSingleValue) {
			populateWithNewList(otherInput?.value ?? '')
		} else {
			setCheckboxListItems(list)
		}
	}, [list])

	useEffect(() => {
		if (otherInput?.value.split(', ').length === 1) {
			setShouldAddValue(true)
		} else {
			setShouldAddValue(false)
		}

		if (!shouldAddValue) populateWithNewList(otherInput?.value ?? '')
		else if (otherInput?.value.trim() == '') {
			populateWithNewList('')
		}
	}, [otherInput?.value])

	useEffect(() => {
		if (
			!others ||
			!setOtherInput ||
			!otherInput ||
			!onFixError ||
			!errors ||
			!UpdateRequestInputsErrorsArray ||
			!list?.find((element) => element.details)
		)
			return

		if (otherInput.value?.trim() === '' && value.includes(others?.value)) {
			setOtherInput({
				...otherInput,
				required: true
			})
		} else {
			setOtherInput({
				...otherInput,
				required: false
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, otherInput.name))
		}
	}, [value, otherInput?.value])

	const listLength = getList()?.length
	const halfList = shouldNotDivide
		? 0
		: listLength
		? Math.ceil(listLength / 2)
		: 0

	return (
		<div className={joinClasses([classes.root, hasError ? classes.error : ''])}>
			<label>
				<div className={classes.labelPopup}>
					{getLabel()}
					{showInfo && infoLabel && <Tooltip showInfo infoLabel={infoLabel} />}
				</div>
				<Error hasError={hasError} />
				<span className={classes.description}>
					<Text content={description} />
				</span>
			</label>
			<div className={classes.listContainer}>
				{!shouldNotDivide && (
					<div className={classes.listItem}>
						{getList()
							?.slice(0, halfList)
							.map((item) => displayCheckbox(item))}
					</div>
				)}
				<div className={classes.listItem}>
					{getList()
						?.slice(halfList, listLength)
						.map((item) => displayCheckbox(item))}

					{others && (
						<div>
							<CheckBox
								className={joinClasses([
									classes.extraText,
									isMaxOptionsSelected && !isChecked(others?.value)
										? classes.reducedOpacity
										: ''
								])}
								labelText={others?.key ? pageAssets[others?.key] : ''}
								onChecked={() => onChange(others?.value, others?.key)}
								checked={value.includes(others?.value)}
								hasBackgroundWrapper={hasBackgroundWrapper}
								extraText={
									typeof others.extraText == 'string' ? others.extraText : ''
								}
							/>

							{value.includes(others?.value) && otherInput && (
								<TextInput
									id={otherInput.name}
									value={otherValue}
									onChange={onSelectOtherInput}
									required={otherInput.required}
									requiredIcon={false}
									hasError={errors?.includes(otherInput?.name)}
									onKeyUp={onPressEnterKey}
									classWrapper={
										hasBackgroundWrapper ? classes.wrapTextField : ''
									}
									label={otherInput.label}
									placeholder={
										otherInputPlaceholder ??
										pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_specify
									}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

const CheckboxInput = forwardRef(CheckboxInputForwardRef)

export default CheckboxInput
