import React, { CSSProperties, FC, useEffect, useState } from 'react'
import { TooltipProp } from '@utils/request'
import { joinClasses, makeClasses } from '@utils/styles'
import Text from '@components/ui/text'
import { Colors, Indexes, TransitionStyles } from '@utils/css-variables'
import OutsideClickHandler from 'react-outside-click-handler'
import Breakpoints from '@utils/breakpoints'
import { infoIcon } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import { Transition, TransitionStatus } from 'react-transition-group'
import config from '@utils/config'
import { TransitionStyleT } from 'typings/shared'
import DisplayInforModal from '../modals/displayInfoModal'

type Classes = {
	root: string
	popupIcon: string
	popupContent: string
	img: string
}

const classes: Classes = makeClasses({
	root: {
		position: 'relative' as 'relative',
		display: 'inline-flex',
		minWidth: '22px',
		top: '-1px'
	},
	popupIcon: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		width: '100%',
		maxWidth: '18px',
		height: '18px',
		marginLeft: '5px',
		fontSize: '14px',
		lineHeight: 1,
		color: Colors.white,
		cursor: 'pointer'
	},
	img: {
		width: '100%',
		height: 'auto'
	},
	popupContent: {
		position: 'absolute' as 'absolute',
		width: '400px',
		color: Colors.black,
		background: Colors.white,
		boxShadow: `${Colors.blackTransparent15} 0 0 15px`,
		fontSize: '14px',
		lineHeight: '22px',
		fontWeight: 400,
		padding: '20px',
		zIndex: Indexes.index1,
		overflow: 'auto',
		bottom: 'calc(100% + 10px)',
		borderRadius: '3px',
		left: '5px',
		p: {
			margin: 0
		},
		[Breakpoints.maxWidth('xl')]: {
			left: 'auto'
		},
		[Breakpoints.maxWidth('xs')]: {
			width: '280px'
		},
		'& img': {
			maxHeight: '100%',
			maxWidth: '100%'
		}
	}
})

const Tooltip: FC<TooltipProp> = ({
	showInfo,
	infoLabel,
	toolTipMedia,
	children,
	changeOnShow = false,
	duration = config.transition.duration,
	isModal
}) => {
	const defaultStyle: CSSProperties = {
		transition: `opacity ${duration}ms ease-in-out`,
		opacity: 0
	}

	const transitionStyles: TransitionStyleT = TransitionStyles

	const [onShow, setOnShow] = useState<boolean>(false)

	const showPopupContent = () => setOnShow(!onShow)

	useEffect(() => {
		setOnShow(changeOnShow)
	}, [changeOnShow])

	if ((showInfo && infoLabel) || (showInfo && toolTipMedia)) {
		return (
			<OutsideClickHandler
				onOutsideClick={() => setOnShow(false)}
				display="flex"
			>
				<div className={joinClasses([classes.root, 'tooltip'])}>
					{!isModal ? (
						<Transition
							in={onShow}
							timeout={duration}
							onExited={() => setOnShow(false)}
						>
							{(state: TransitionStatus) => (
								<div
									style={{
										...defaultStyle,
										...transitionStyles[state]
									}}
								>
									<div
										className={joinClasses([
											classes.popupContent,
											'tooltip-content'
										])}
									>
										<Text content={infoLabel} />
										{toolTipMedia && <img src={toolTipMedia} alt="tooltip" />}
									</div>
								</div>
							)}
						</Transition>
					) : (
						<DisplayInforModal
							isModalOpen={onShow}
							close={() => setOnShow(false)}
							mediaContent={toolTipMedia}
							onPrimaryButton={() => {}}
							showExitBtn
						/>
					)}

					<span className={classes.popupIcon} onClick={showPopupContent}>
						<Icon src={infoIcon} className={classes.img} />
					</span>
				</div>
			</OutsideClickHandler>
		)
	}

	if (infoLabel && children) {
		return (
			<OutsideClickHandler
				onOutsideClick={() => setOnShow(false)}
				display="flex"
			>
				<div className={joinClasses([classes.root, 'tooltip'])}>
					<Transition
						in={onShow}
						timeout={duration}
						onExited={() => setOnShow(false)}
					>
						{(state: TransitionStatus) => (
							<div
								style={{
									...defaultStyle,
									...transitionStyles[state]
								}}
							>
								<div
									className={joinClasses([
										classes.popupContent,
										'tooltip-content'
									])}
								>
									<Text content={infoLabel} />
								</div>
							</div>
						)}
					</Transition>
					{children}
				</div>
			</OutsideClickHandler>
		)
	}

	return <></>
}

export default Tooltip
