/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, { FC } from 'react'

import '@components/layouts/Fonts.css'
import '@components/layouts/global.scss'

import { Colors } from '@utils/css-variables'
import { jsx, Global } from '@emotion/react'

import Sidebar from '@components/ui/sidebar'
import Header from '@components/ui/header'
import Footer from '@components/ui/footer'
import Breakpoints from '@utils/breakpoints'
import { makeClasses, makeStyles } from '@utils/styles'
import CookiesConsentBanner from '@components/ui/cookiesConsentBanner'

type ClassType = {
	container: string
}

const classes: ClassType = makeClasses({
	container: {
		marginTop: '60px',
		background: Colors.ligthGrey2,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		minHeight: 'calc(100vh - 60px)',
		[Breakpoints.maxWidth('xl')]: {
			marginBottom: '77px',
			minHeight: 'calc(100vh - 60px - 77px)'
		},
		[Breakpoints.maxWidth('xs')]: {
			marginBottom: '64px',
			minHeight: 'calc(100vh - 60px - 64px)'
		},
		[Breakpoints.widthBetween('xl', 'xxl')]: {
			marginLeft: '13rem',
			maxWidth: 'calc(100% - 13em)'
		},
		[Breakpoints.minWidth('xxl')]: {
			marginLeft: '17rem',
			maxWidth: 'calc(100% - 17rem)'
		}
	}
})

const styles = makeStyles({
	root: `
		body {
			background-color: ${Colors.ligthGrey2};
		}
		* {
			box-sizing: border-box;
		}
	`
})

const LayoutWithSidebarWithProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<>
			<Global styles={styles.root} />
			<Sidebar />
			<Header />
			<div className={classes.container}>
				<div>{children}</div>
				<Footer />
				<CookiesConsentBanner />
			</div>
		</>
	)
}

export default LayoutWithSidebarWithProvider
