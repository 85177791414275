import React, { FC, useEffect } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Text from '../text'
import { Collapse } from 'react-collapse'

type Props = {
	className?: string
	labelText: string
	checked: boolean
	extraText?: string
	onChecked: (e) => void
	hasBackgroundWrapper?: boolean
}

type Classes = {
	checkBox: string
	checkBoxChecked: string
	checkBoxLabel: string
	extraText: string
	selfAlign: string
	checkboxWrapper: string
}

const classes: Classes = makeClasses({
	checkBox: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '16px',
		height: '16px',
		marginRight: '10px',
		border: `1px solid ${Colors.lightGrey3}`
	},
	checkBoxChecked: {
		position: 'relative',
		'&::before': {
			content: '""',
			width: '100%',
			maxWidth: '10px',
			height: '10px',
			marginLeft: '2px',
			background: Colors.secondary
		}
	},
	checkBoxLabel: {
		display: 'flex',
		alignItems: 'start',
		cursor: 'pointer',
		fontWeight: 'normal',
		'&.disabled': {
			cursor: 'default',
			'.checkbox': {
				position: 'relative',
				borderColor: Colors.grey,
				'&::before': {
					content: '""',
					width: '100%',
					maxWidth: '10px',
					height: '10px',
					marginLeft: '2px',
					background: Colors.lightGrey4
				}
			}
		}
	},
	extraText: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 0px',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		fontWeight: 400,
		color: Colors.lightGrey2
	},
	selfAlign: {
		alignSelf: 'flex-start'
	},
	checkboxWrapper: {
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '10px 12px',
		'& > span': {
			background: Colors.white
		}
	}
})

const CheckBox: FC<Props> = ({
	className = '',
	labelText,
	checked,
	extraText,
	onChecked,
	hasBackgroundWrapper
}) => {
	return (
		<label
			className={joinClasses([
				hasBackgroundWrapper ? classes.checkboxWrapper : '',
				classes.checkBoxLabel,
				className,
				'checkbox-link'
			])}
			onClick={onChecked}
		>
			<span
				className={joinClasses([
					'checkbox',
					classes.checkBox,
					extraText ? classes.selfAlign : '',
					checked ? classes.checkBoxChecked : ''
				])}
			></span>
			<div>
				<Text content={labelText} />
				{extraText && (
					<div className={classes.extraText}>
						<Collapse isOpened={!!extraText}>
							<Text content={extraText} />
						</Collapse>
					</div>
				)}
			</div>
		</label>
	)
}

export default CheckBox
