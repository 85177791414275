import React, {
	ForwardedRef,
	forwardRef,
	ForwardRefRenderFunction,
	InputHTMLAttributes,
	useContext,
	useEffect,
	useState
} from 'react'
import { ButtonStyle, Colors } from '@utils/css-variables'
import { joinClasses, makeClasses } from '@utils/styles'
import RequiredIcon from '@components/ui/requiredIcon'
import Error from '@components/requestForms/inputs/error.input'
import { Icon } from '@components/ui/Icon'
import { closeGreyIcon, homeBlueIcon, locationIcon } from '@images/icons'
import Tooltip from '@components/ui/tooltip'
import Breakpoints from '@utils/breakpoints'
import { Collapse } from 'react-collapse'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { IMaskInput } from 'react-imask'

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
	rootClass?: string
	label?: string | Element
	labelClass?: string
	labelRequired?: boolean
	info?: string
	infoClass?: string
	ref?: string
	children?: React.ReactNode
	doLoader?: boolean
	spinToggle?: boolean
	hasError?: boolean
	showUserLocation?: boolean
	showButtonGroup?: boolean
	containerRef?: ForwardedRef<HTMLDivElement>
	doClearValue?: boolean
	disableLocationIcon?: boolean
	isAddressNotFound?: boolean
	isSearchByCadastralNumberEnabled?: boolean
	isSearchByRegistrationNumberEnabled?: boolean
	addressLabelNoWrapper?: boolean
	onUseUserLocationButtton?: () => void
	onUseCurrentLocationButton?: () => void
	onClearValue?: () => void
}

const loaderGroupClassName: string = 'loader-with-button-group'
const loaderGroupLocationClassName: string =
	'loader-with-button-group-and-location'

type Classes = {
	loaderContainer: string
	loader: string
	error: string
	button: string
	buttonGroup: string
	buttonGroupError: string
	clearButton: string
	clearButtonAlone: string
	notFoundError: string
}

const classes: Classes = makeClasses({
	loaderContainer: {
		position: 'relative',
		'& input': {
			MozAppearance: 'textfield'
		},
		'& input::-webkit-outer-spin-button,  input::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0
		}
	},
	loader: {
		position: 'absolute' as 'absolute',
		top: 9,
		right: 10,
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '26px',
		height: '26px',
		animation: 'spin 2s linear infinite',
		[`&.${loaderGroupClassName}`]: {
			right: 61,
			[Breakpoints.maxWidth('md')]: {
				right: 51
			}
		},
		[`&.${loaderGroupLocationClassName}`]: {
			right: 111,
			[Breakpoints.maxWidth('md')]: {
				right: 91
			}
		}
	},
	error: {
		// marginTop: '20px',
		label: {
			color: Colors.errorRed
		},
		'.error': {
			input: {
				borderColor: Colors.errorRed,
				'&:focus': {
					boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
				}
			}
		}
	},
	button: {
		...ButtonStyle,
		justifyContent: 'center',
		background: Colors.white,
		height: '100%',
		maxHeight: '42px',
		width: '50px',
		borderRadius: '0',
		borderColor: Colors.grey,
		borderTop: 0,
		borderBottom: 0,
		borderRight: 0,
		'&.disabled': {
			background: Colors.lightGrey4,
			pointerEvents: 'none',
			'&:hover': {
				background: Colors.lightGrey4
			}
		},
		img: {
			width: '22px'
		},
		'&:hover': {
			backgroundColor: Colors.darkWhite,
			cursor: 'pointer'
		},
		'.tooltip-content': {
			fontSize: '12px',
			lineHeight: '18px',
			width: '160px',
			background: Colors.black,
			color: Colors.white,
			padding: '5px 10px',
			left: 'calc(((160px / 2) - 11px) * -1)',
			bottom: 'calc(100% + 15px)',
			overflow: 'unset',
			'&:after': {
				content: '""',
				position: 'absolute',
				bottom: '-10px',
				left: 'calc(50% - 5px)',
				borderWidth: '5px',
				borderStyle: 'solid',
				borderColor: `transparent transparent ${Colors.black} transparent`,
				transform: 'rotate(180deg)'
			},
			'&:before': {
				content: '""',
				position: 'absolute',
				left: 0,
				bottom: -5,
				background: 'transparent',
				height: 20,
				width: 160
			},
			[Breakpoints.maxWidth('lg')]: {
				display: 'none'
			}
		},
		[Breakpoints.maxWidth('md')]: {
			width: '40px',
			img: {
				width: '15px'
			},
			'.tooltip': {
				minWidth: '15px'
			}
		},
		'@media screen and(-webkit-min-device-pixel-ratio:0)': {
			top: '2px'
		}
	},
	buttonGroup: {
		position: 'absolute',
		top: '1px',
		height: `calc(100% - 2px)`,
		right: 1,
		'.ReactCollapse--collapse': {
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			'.ReactCollapse--content': {
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				height: '42px',
				top: -4,
				[Breakpoints.maxWidth('md')]: {
					top: -1
				}
			}
		}
	},
	clearButton: {
		...ButtonStyle,
		background: Colors.darkWhite,
		borderColor: Colors.darkWhite,
		borderRadius: '50%',
		padding: '0',
		marginRight: '5px',
		cursor: 'pointer',
		img: {
			width: '15px'
		},
		'&:hover': {
			background: Colors.greyAlto,
			borderColor: Colors.greyAlto
		}
	},
	clearButtonAlone: {
		marginRight: '10px',
		[Breakpoints.minWidth('md')]: {
			marginTop: '5px'
		}
	},
	notFoundError: {
		'& .error-text': {
			top: '44px !important'
		}
	}
})

const Input: ForwardRefRenderFunction<HTMLInputElement, FormInputProps> = (
	{
		doLoader,
		spinToggle,
		rootClass,
		label,
		labelClass,
		labelRequired,
		info,
		infoClass,
		id,
		children,
		containerRef,
		hasError,
		showUserLocation,
		showButtonGroup,
		doClearValue = false,
		disableLocationIcon,
		isAddressNotFound,
		isSearchByCadastralNumberEnabled,
		isSearchByRegistrationNumberEnabled,
		addressLabelNoWrapper,
		onUseUserLocationButtton,
		onUseCurrentLocationButton,
		onClearValue,
		...otherProps
	},
	ref
) => {
	const { required } = otherProps
	const {
		pageData: { assets }
	} = useContext<AppContextProps>(AppStateContext)

	const loaderPositionClass: string = showButtonGroup
		? showUserLocation
			? loaderGroupLocationClassName
			: loaderGroupClassName
		: ''

	const [showUserLocationTooltip, setShowUserLocationTooltip] =
		useState<boolean>(false)
	const [showUseCurrentLocationTooltip, setShowUseCurrentLocationTooltip] =
		useState<boolean>(false)

	const Label = () => (
		<label className={labelClass} htmlFor={id}>
			{label || ''}
			{label && required && <RequiredIcon />}
			<Error hasError={hasError} />
		</label>
	)

	const regexLavalCadastral = /^(\d{7}|PC-\d{6})$/

	const groupButtonActions = () => (
		<div className={joinClasses([classes.buttonGroup])}>
			{onClearValue && (
				<Collapse isOpened={doClearValue}>
					<button
						onClick={onClearValue}
						type="button"
						className={joinClasses([
							classes.clearButton,
							disableLocationIcon && !showUserLocation
								? classes.clearButtonAlone
								: ''
						])}
					>
						<Icon src={closeGreyIcon} />
					</button>
				</Collapse>
			)}
			{showUserLocation && (
				<button
					type="button"
					onClick={onUseUserLocationButtton}
					onMouseEnter={() => setShowUserLocationTooltip(true)}
					onMouseLeave={() => setShowUserLocationTooltip(false)}
					className={joinClasses([
						classes.button,
						spinToggle ? 'disabled' : ''
					])}
					style={{ marginRight: '1px' }}
				>
					<Tooltip
						duration={100}
						infoLabel={assets?.map_use_my_home_location}
						changeOnShow={showUserLocationTooltip}
					>
						<Icon src={homeBlueIcon} />
					</Tooltip>
				</button>
			)}

			{!disableLocationIcon && (
				<button
					type="button"
					onClick={onUseCurrentLocationButton}
					onMouseEnter={() => setShowUseCurrentLocationTooltip(true)}
					onMouseLeave={() => setShowUseCurrentLocationTooltip(false)}
					className={joinClasses([
						classes.button,
						spinToggle ? 'disabled' : ''
					])}
				>
					<Tooltip
						duration={100}
						infoLabel={assets?.map_use_my_location}
						changeOnShow={showUseCurrentLocationTooltip}
					>
						<Icon src={locationIcon} />
					</Tooltip>
				</button>
			)}
		</div>
	)

	const doAddSpinLayout = () => {
		if (doLoader) {
			return (
				<>
					<div
						className={joinClasses([
							classes.loaderContainer,
							hasError || isAddressNotFound ? 'error' : ''
						])}
					>
						{!isSearchByCadastralNumberEnabled && (
							<>
								<input id={id} {...otherProps} type={'text'} ref={ref} />
							</>
						)}

						{isSearchByCadastralNumberEnabled && (
							<IMaskInput
								mask={''}
								autoComplete="off"
								minLength={7}
								maxLength={8}
								type="text"
								id={id}
								{...otherProps}
								ref={ref}
							/>
						)}

						{spinToggle && (
							<div
								className={joinClasses([
									classes.loader,
									'spin',
									loaderPositionClass,
									hasError || isAddressNotFound ? classes.buttonGroupError : ''
								])}
							/>
						)}
						{showButtonGroup && groupButtonActions()}
					</div>
				</>
			)
		}

		return (
			<div className={joinClasses([hasError ? 'error' : ''])}>
				{Label()}
				<input id={id} {...otherProps} ref={ref} />
				{showButtonGroup && groupButtonActions()}
			</div>
		)
	}

	const InfoSpan = () => <span className={infoClass}>{info}</span>
	return (
		<div
			className={joinClasses([
				rootClass ? rootClass : '',
				hasError ? classes.error : ''
			])}
			ref={containerRef}
		>
			{Label()}
			{info && InfoSpan()}
			{doAddSpinLayout()}
			{children}
		</div>
	)
}

const FormInput = forwardRef(Input)

export default FormInput
