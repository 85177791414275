import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import FormInput from '@components/ui/formInput'
import Map from '@components/ui/map'
import {
	AddressInfo,
	MapFormProps
} from '@components/ui/map/mapForm.interfaces'
import { joinClasses } from '@utils/styles'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { Coordinate } from '@components/ui/map/mapForm.interfaces'
import { AddressInputName } from '@utils/request'
import {
	StaticApiAddressProvidersEnum,
	StaticApiSearchTypeEnum
} from '@services/constants'
import * as pageUtils from '@components/ui/map/__map.utils'
import { formatStrapiText } from '@utils/methods'
import config from '@utils/config'
import Notification from '../notification'
import { useAppSelector } from '@services/store'
import GeoLocation from '@utils/config/geolocation'
import { WaterCounterAddresses } from '@services/models'
import { IWaterCounterAddressDto } from '@services/types'

const ARROW_UP_KEY: number = 38
const ARROW_DOWN_KEY: number = 40

const MapForm: FC<MapFormProps> = ({
	value,
	displayMap,
	hasError,
	displayAptInput,
	timer = 500,
	addressLabel,
	addressLabelNoWrapper,
	addressPlaceholder,
	apartmentLabel,
	addressRequired,
	displayAddressInput = true,
	isAddressInputDisabled,
	showButtonUserLocation,
	searchByCadastralNumber,
	searchByRegistrationNumber,
	isConcernedAdresseSection,
	onAddressInputChange,
	onAddressValueChange,
	onAddressChange,
	onApartmentInputChange,
	onRetreiveCoordinates,
	onAddError,
	iswaterCounterAdresses = false,
	onSetPostalCode,
	onSetCadastre,
	disableLocationIcon,
	classNames,
	onSetCity,
	searchTypeForm,
	getAddressFreatures
}) => {
	const maxItems: number = 5

	const { pageData, authUser } = useContext<AppContextProps>(AppStateContext)

	const customLocationState = useAppSelector((state) => state.request.location)
	const answers = useAppSelector((state) => state.request.configs)

	const { latitude, longitude } = config.geolocation.MUNICIPALITY_BOUNDARY_BOX

	const [addresses, setAddresses] = useState<AddressInfo[]>([])
	const [coordinates, setCoordinates] = useState<Coordinate>()
	const [address, setAddress] = useState<string>(
		// si utilisé pour concernedInfoSection, on prend l'adresse depuis answers... sinon customLocationAddresse
		isConcernedAdresseSection == true && answers.concernedAdresse != ''
			? answers.concernedAdresse!
			: customLocationState.useDefaultUserLocation == false &&
			  customLocationState.address !== ''
			? customLocationState.address
			: value || ''
	)
	const [apartment, setApartment] = useState<string>('')
	const [spin, setSpin] = useState<boolean>(false)
	const [loaded, setLoaded] = useState<boolean>(false)
	const [useUserLocation, setUseUserLocation] = useState<boolean>(false)
	const [isUserLocationFired, setIsUserLocationFired] = useState<boolean>(false)
	const [addressIsSelected, setAddressIsSelected] = useState<boolean>(
		isConcernedAdresseSection == true && answers.concernedAdresse != ''
			? true
			: customLocationState.useDefaultUserLocation == false &&
			  customLocationState.address !== ''
			? true
			: false
	)
	const [closeSelectAddress, setCloseSelectAddress] = useState<boolean>(false)
	const [currentAddressItem, setCurrentAddressItem] = useState<number>(0)
	const [notificationText, setNotificationText] = useState<string>('')
	const [serviceDownText, setServiceDownText] = useState<string>('')
	const [addressNotFound, setAddressNotFound] = useState<boolean>(false)
	const [cadastralAddress, setCadastralAddress] = useState<string>()
	const [formalAddress, setFormalAddress] = useState(value)

	const soleField: string = displayAptInput ? '' : ' soleField'
	let delayDebounceFn: ReturnType<typeof setTimeout>

	const inputRef = useRef<HTMLInputElement>(null)
	const container = useRef<HTMLDivElement>(null)
	const prevValueRef = useRef()

	useEffect(() => {
		if (customLocationState.useDefaultUserLocation == false) {
			setTimeout(() => {
				if (
					customLocationState.coordinates.latitude &&
					customLocationState.coordinates.longitude
				) {
					let coordinats: Coordinate = {
						longitude: customLocationState.coordinates.longitude,
						latitude: customLocationState.coordinates.latitude
					}
					setCoordinates(coordinats)

					setAddress(customLocationState.address)
				}
				setAddressIsSelected(true)
				setSpin(false)
				setNotificationText('')
				setAddresses([])
				clearTimeout(delayDebounceFn)
				if (onAddressInputChange) {
					onAddressInputChange(customLocationState.address)
				}

				onSetPostalCode && onSetPostalCode(customLocationState.postalCode)
			}, 200)
		}
	}, [])

	useEffect(() => {
		if (!address) {
			setAddressNotFound(false)
		}
	}, [address])

	const getAddressResults = async (
		search: string,
		searchType: StaticApiSearchTypeEnum,
		runBackup: boolean = false,
		maxRuns: number = 0
	): Promise<boolean> => {
		let success: boolean = false

		if (maxRuns >= 2) {
			setServiceDownText(pageData?.assets?.map_service_down)
			setSpin(false)
			return success
		}

		const serviceProvider = runBackup
			? StaticApiAddressProvidersEnum.PLACES
			: StaticApiAddressProvidersEnum.THINK_WHERE

		try {
			const response = await axios.get(`/api/addresses`, {
				params: {
					serviceProvider,
					search,
					searchType
				}
			})

			if (response.status === 200) {
				const { features } = response.data
				const cadastralOrRegistration = () => {
					if (searchByCadastralNumber) {
						return features[0].properties.cadastre
					} else if (searchByRegistrationNumber) {
						return features[0].properties.matricule
					}

					return features[0].place_name
				}

				getAddressFreatures && getAddressFreatures(features)
				switch (searchType) {
					case StaticApiSearchTypeEnum.address:
						if (features.length === 0) {
							setCoordinates(undefined)
							onSetCadastre && onSetCadastre('')
							setAddressNotFound(true)
							break
						}

						if (features.length === 1) {
							onSelectAddress({
								name: cadastralOrRegistration(),
								cadastralAddress: features[0].place_name,
								coordinates: {
									longitude: features[0].geometry.coordinates[0],
									latitude: features[0].geometry.coordinates[1]
								},
								civic: features[0].properties.civic,
								postalCode: features[0].properties.postal_code,
								cadastre: features[0].properties.cadastre
							})
							onSetCadastre && onSetCadastre(features[0].properties.cadastre)
							setNotificationText('')
							setAddressNotFound(false)
							success = true
						}
						break
					case StaticApiSearchTypeEnum.coordinate:
						if (features.length === 0) {
							setAddress('')

							break
						}

						if (features.length === 1) {
							onSelectAddress({
								name: features[0].place_name,
								coordinates: {
									longitude: features[0].geometry.coordinates[0],
									latitude: features[0].geometry.coordinates[1]
								},
								civic: features[0].properties.civic,
								postalCode: features[0].properties.postal_code,
								cadastre: features[0].properties.cadastre
							})

							setNotificationText('')
							setAddressNotFound(false)
							success = true
						}

						break
					default:
						if (features.length > 0) {
							let listOfAddresses: AddressInfo[] = []
							setCloseSelectAddress(false)

							const addFeatureToAddressList = (feature: any) => {
								listOfAddresses.push({
									civic: feature.properties.civic,
									coordinates: {
										longitude: feature.geometry?.coordinates[0] || 0,
										latitude: feature.geometry?.coordinates[1] || 0
									},
									postalCode: feature.properties.postal_code,
									name: feature.place_name,
									cadastre: feature.properties.cadastre,
									matricule: feature.properties.matricule
								})
							}
							features.forEach(addFeatureToAddressList)

							setAddresses(listOfAddresses)
							setAddressNotFound(false)
							success = true
						}
						break
				}

				setSpin(false)

				return success
			}

			return await getAddressResults(search, searchType, true, maxRuns + 1)
		} catch (e) {
			return await getAddressResults(search, searchType, true, maxRuns + 1)
		}
	}

	const getAddressByCoordinates = async (
		searchCoordinates: Coordinate,
		isPointLocation: boolean = false
	) => {
		const success: boolean = await getAddressResults(
			`${JSON.stringify(searchCoordinates)}`,
			StaticApiSearchTypeEnum.coordinate
		)

		if (success) {
			return
		}

		setNotificationText(
			isPointLocation
				? pageData?.assets?.map_pointLocation_warning_text
				: pageData?.assets?.map_currentLocation_warning_text
		)
	}

	const onUseUserLocation = async () => {
		if (useUserLocation) {
			setSpin(true)
			setLoaded(true)

			if (authUser?.profile?.address?.address) {
				setIsUserLocationFired(true)

				getAddressResults(
					authUser.profile.address.address,
					StaticApiSearchTypeEnum.address
				)

				if (onAddressInputChange) {
					onAddressInputChange(authUser.profile.address.address)
				}
			}

			setApartment(`${authUser?.profile?.address.apartment || ''}`)

			return setAddressIsSelected(true)
		}

		if (
			customLocationState.useDefaultUserLocation !== false &&
			isConcernedAdresseSection !== true
		) {
			setCoordinates(undefined)
			setAddress('')
		}
	}

	const onChangeInput = (e) => {
		setApartment(e.target.value)
		if (onApartmentInputChange) {
			onApartmentInputChange(e.target.value)
		}
	}

	const onSelectAddress = (address: AddressInfo) => {
		setAddressIsSelected(true)
		setSpin(false)
		setNotificationText('')
		setAddress(address.name)
		setCoordinates(address.coordinates)
		onSetCadastre && onSetCadastre(address.cadastre!)
		onSetPostalCode && onSetPostalCode(address.postalCode)
		setAddresses([])
		setCadastralAddress(address?.cadastralAddress)
		clearTimeout(delayDebounceFn)
		// Since we can only search for cities in laval hence the city by default should be Laval
		// @Todo: Change this if the conditions change
		onSetCity && onSetCity(GeoLocation.MUNICIPALITY_ADDRESS.city)
		if (onAddressInputChange) {
			onAddressInputChange(address.name)
		}

		onAddressChange && onAddressChange(address)
	}

	const getAddresses = async () => {
		if (address !== '') {
			let searchAddress = address
			const searchType =
				searchByCadastralNumber || searchByRegistrationNumber
					? StaticApiSearchTypeEnum.address
					: StaticApiSearchTypeEnum.addresses

			if (searchByCadastralNumber) {
				searchAddress = `cad-${address}`
			}

			if (searchByRegistrationNumber) {
				searchAddress = `matricule-${address}`
			}

			await getAddressResults(searchAddress, searchType)
		}
	}

	const onChangeSearch = (value: string) => {
		if (isAddressInputDisabled) {
			return false
		}
		setFormalAddress(value)
		setAddress(value)
		onAddressValueChange && onAddressValueChange(value)
	}

	const listOfAddresses = () => (
		<div className={`${pageUtils.mapFormClasses.addressRoot} ${soleField}`}>
			{addresses.slice(-maxItems).map((address, index) => (
				<div
					className={joinClasses([
						pageUtils.mapFormClasses.addressItem,
						currentAddressItem === index
							? pageUtils.mapFormClasses.addressSelected
							: '',
						`${index}`
					])}
					onClick={() => onSelectAddress(address)}
					key={index}
				>
					{address.name}
				</div>
			))}
		</div>
	)

	const onHandleOnClose = (e) => {
		if (
			addresses.length > 0 &&
			container &&
			!container.current?.contains(e.target)
		) {
			setCloseSelectAddress(true)
		}
	}

	const onHandlekeyDown = (e) => {
		if (e.keyCode === ARROW_DOWN_KEY) {
			if (currentAddressItem > maxItems - 1) {
				return setCurrentAddressItem(-1)
			}

			setCurrentAddressItem(currentAddressItem + 1)
		}

		if (e.keyCode === ARROW_UP_KEY) {
			if (currentAddressItem < 0) {
				return setCurrentAddressItem(maxItems - 1)
			}

			setCurrentAddressItem(currentAddressItem - 1)
		}
	}

	const onToggleUserLocation = () => {
		if (spin) {
			return
		}

		setUseUserLocation(!useUserLocation)
	}

	const onToggleCurrentLocation = () => {
		if (spin) {
			return
		}

		if (navigator.geolocation) {
			setSpin(true)
			setCoordinates(undefined)

			navigator.geolocation.getCurrentPosition(
				({ coords }: GeolocationPosition) =>
					verifyCoordinateAndSearchAddress(coords)
			)
		}
	}

	const verifyCoordinateAndSearchAddress = (
		coordinateTarget: Coordinate,
		isPointLocation: boolean = false
	) => {
		if (
			coordinateTarget.latitude === undefined ||
			coordinateTarget.longitude === undefined
		) {
			return
		}

		if (
			coordinateTarget.latitude >= latitude.min &&
			coordinateTarget.latitude <= latitude.max &&
			coordinateTarget.longitude >= longitude.min &&
			coordinateTarget.longitude <= longitude.max
		) {
			getAddressByCoordinates(
				{
					latitude: coordinateTarget.latitude,
					longitude: coordinateTarget.longitude
				},
				isPointLocation
			)

			return setAddressIsSelected(true)
		}

		if (address === '') {
			setSpin(false)
		}

		setAddress('')
		setNotificationText(
			isPointLocation
				? pageData?.assets?.map_pointLocation_warning_text
				: pageData?.assets?.map_currentLocation_warning_text
		)
	}

	const getCoordinatesFromPoint = (event) => {
		if (spin) {
			return
		}

		setSpin(true)
		setCoordinates(undefined)

		verifyCoordinateAndSearchAddress(
			{
				latitude: event?.mapPoint?.latitude as number,
				longitude: event?.mapPoint?.longitude as number
			},
			true
		)
	}
	const onClearMapValue = () => {
		setAddress('')
		setFormalAddress('')
		onSetCadastre && onSetCadastre('')
		onAddressInputChange && onAddressInputChange('')
		onAddressValueChange && onAddressValueChange('')
	}

	useEffect(() => {
		if (
			(!searchByRegistrationNumber &&
				!searchByCadastralNumber &&
				!addressIsSelected &&
				address !== '') ||
			(searchByRegistrationNumber && address !== '' && address?.length >= 23) ||
			(searchByCadastralNumber && address !== '' && address?.length >= 7)
		) {
			setSpin(true)

			delayDebounceFn = setTimeout(async () => {
				await getAddresses()
			}, timer)
		}

		if (address === '') {
			setAddressIsSelected(false)
			setCoordinates(undefined)
			setAddresses([])
		}

		return () => {
			clearTimeout(delayDebounceFn)
			setSpin(false)
		}
	}, [address])

	useEffect(() => {
		if (closeSelectAddress) {
			setAddresses([])
		}
	}, [])

	useEffect(() => {
		if (closeSelectAddress) {
			setAddresses([])
		}
	}, [closeSelectAddress])

	useEffect(() => {
		if (!coordinates && loaded && onAddError) {
			onAddError(AddressInputName)
		}

		if (onRetreiveCoordinates) {
			onRetreiveCoordinates(coordinates)
		}

		setLoaded(true)
	}, [coordinates])

	useEffect(() => {
		onUseUserLocation()
	}, [useUserLocation])

	useEffect(() => {
		if (useUserLocation && address === '' && !isUserLocationFired) {
			onUseUserLocation()
		}
	}, [authUser])

	useEffect(() => {
		if (hasError && inputRef?.current) {
			// searchByCadastralNumber || searchByRegistrationNumber
			// 	? inputRef.current?.focus()
			// 	:
			setTimeout(() => {
				inputRef?.current?.focus()
			}, 100)
		}
	}, [hasError])

	useEffect(() => {
		document.addEventListener('mousedown', onHandleOnClose)

		return () => {
			setIsUserLocationFired(false)
			document.removeEventListener('mousedown', onHandleOnClose)
		}
	}, [])

	useEffect(() => {
		// Check if the previous value is different from the current value
		if (
			prevValueRef.current !== undefined &&
			(prevValueRef.current !== searchByCadastralNumber ||
				prevValueRef.current !== searchByRegistrationNumber)
		) {
			// Do something when the value changes
			onClearMapValue()
		}

		// Update the ref with the current value
		prevValueRef.current = searchByCadastralNumber ?? searchByRegistrationNumber
	}, [searchByCadastralNumber, searchByRegistrationNumber])

	useEffect(() => {
		if (!address && !value) onClearMapValue()
	}, [address])

	useEffect(() => {
		if (searchTypeForm) setAddress('')
	}, [searchTypeForm])

	return (
		<>
			{serviceDownText !== '' && (
				<div className={pageUtils.mapFormClasses.notification}>
					<Notification
						type="error"
						hasHtml
						text={serviceDownText}
						onClickCancelBtn={() => setServiceDownText('')}
					/>
				</div>
			)}

			{notificationText !== '' && (
				<div className={pageUtils.mapFormClasses.notification}>
					<Notification type="warning" hasHtml text={notificationText} />
				</div>
			)}

			{displayAddressInput && (
				<div
					className={joinClasses([
						pageUtils.mapFormClasses.root
						// addressLabel != undefined
						// 	? pageUtils.mapFormClasses.inputWithOutLabel
						// 	: ''
					])}
				>
					<FormInput
						rootClass={joinClasses([
							pageUtils.mapFormClasses.inputGroup,
							pageUtils.mapFormClasses.searchGroup,
							soleField
						])}
						className={joinClasses([
							pageUtils.mapFormClasses.input,
							classNames?.address || ''
						])}
						autoComplete="off"
						value={address != '' ? address : formalAddress}
						ref={inputRef}
						name={AddressInputName}
						onChange={(e) => onChangeSearch(e.target.value)}
						doClearValue={address !== '' && !spin}
						onClearValue={onClearMapValue}
						doLoader
						required={addressRequired}
						spinToggle={spin}
						label={addressLabel}
						placeholder={addressPlaceholder}
						containerRef={container}
						showButtonGroup
						showUserLocation={showButtonUserLocation}
						onUseUserLocationButtton={onToggleUserLocation}
						onUseCurrentLocationButton={onToggleCurrentLocation}
						disabled={isAddressInputDisabled}
						hasError={hasError}
						onKeyDown={onHandlekeyDown}
						disableLocationIcon={disableLocationIcon}
						isAddressNotFound={addressNotFound}
						isSearchByCadastralNumberEnabled={searchByCadastralNumber}
						isSearchByRegistrationNumberEnabled={searchByRegistrationNumber}
						addressLabelNoWrapper={addressLabelNoWrapper}
					>
						{listOfAddresses()}
					</FormInput>
					{displayAptInput && (
						<FormInput
							rootClass={`${pageUtils.mapFormClasses.inputGroup} ${pageUtils.mapFormClasses.aptGroup}`}
							className={pageUtils.mapFormClasses.input}
							onChange={(e) => onChangeInput(e)}
							name={AddressInputName}
							label={apartmentLabel}
							placeholder={formatStrapiText(pageData?.assets?.apartment_label)}
							value={apartment}
							maxLength={100}
						/>
					)}
				</div>
			)}

			{displayMap && (
				<Map
					coordinates={coordinates}
					getCoordinatesFromPoint={getCoordinatesFromPoint}
				/>
			)}
		</>
	)
}

export default MapForm
