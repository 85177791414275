import React, { FC, useContext } from 'react'
import Modal, { ModalTypeProp } from '../../modal'
import Text from '@components/ui/text'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { makeClasses } from '@utils/styles'
import { Icon } from '../../Icon'

type propsTypes = {
	isModalOpen: boolean
	cancelButtonText?: string
	primaryButtonText?: string
	type?: ModalTypeProp
	swapButtonPositions?: boolean
	iconColor?: string
	close: () => void
	message?: string
	mediaContent?: string
	onPrimaryButton: () => void
	onCloseModal?: () => void
	showExitBtn?: boolean
}

type ClassType = {
	modalContent: string
	img: string,
	imageContainer: string
}

const classes: ClassType = makeClasses({
	modalContent: {
		padding: '20px 0',
		textAlign: 'center'
	},
	img: {
		width: '40px',
		height: '40px',
		marginBottom: '20px'
	},
	imageContainer: {
		position: 'relative',
		overflow: 'hidden',
		 '& img': {
			maxWidth: '100%',
			height: 'auto',
		  }
	}
})

const DisplayInforModal: FC<propsTypes> = ({
	isModalOpen,
	close,
	message,
	onPrimaryButton,
	cancelButtonText,
	primaryButtonText,
	type,
	swapButtonPositions,
	iconColor,
	onCloseModal,
	mediaContent,
	showExitBtn
}) => {
	const { pageData } = useContext(AppStateContext)

	return (
		<Modal
			cancelButtonText={cancelButtonText}
			primaryButtonText={primaryButtonText}
			isOpen={isModalOpen}
			onCancelBtn={() => {
				close()
			}}
			onPrimaryButton={() => {
				onPrimaryButton()
			}}
			onSetIsOpen={() => {}}
			swapButtonPositions={swapButtonPositions}
			onCloseModal={onCloseModal}
			alignBtn="center"
			type={type}
			iconColor={iconColor}
			showExitBtn={showExitBtn}
		>
			<div className={message? classes.modalContent : classes.imageContainer}>
				{message && 
				<Text content={message} />
				}
				{mediaContent &&
					<img src={mediaContent} alt="mediaContent" />
				}
			</div>
		</Modal>
	)
}

export default DisplayInforModal
