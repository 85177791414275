import React, {
	Dispatch,
	FC,
	RefObject,
	SetStateAction,
	useContext,
	useRef,
	useState
} from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import TextAreaInput from '@components/requestForms/inputs/textarea.inputs'
import config from '@utils/config'
import {
	RequestInputs,
	RequestInputFiles,
	SelectList,
	RequestNames
} from '@utils/request'
import Modal from '../modal'
import RequestFiles from '../requestFiles'
import Text from '@components/ui/text'
import * as pageUtils from '@pages/auth/requests/__index.utils'
import * as pageRequestDetailsUtils from '@pages/auth/requests/__[id].utils'
import { Request } from '@services/models'
import { Comment } from '@services/models/comments.model'
import { CustomDate } from '@services/models/shared.model'
import { ICommentsAttachment } from '@services/types'
import { toBase64 } from '@utils/methods'
import Loader from '../loader'
import { RequestCategoryEnum } from '@services/constants'

type props = {
	request: Request
	isOpen: boolean
	addDocumentsType?: boolean
	setModal: () => void
	onInformationAdded: (string) => void
	setRequestCode?: Dispatch<SetStateAction<string | undefined>>
	onSetInputFiles?: (input: RequestInputFiles[]) => void
}

const NUMBER_OF_MAX_FILES = 10

const AddInformationModal: FC<props> = ({
	setModal,
	request,
	isOpen,
	addDocumentsType,
	onInformationAdded,
	setRequestCode
}) => {
	const { pageData, authUser } = useContext<AppContextProps>(AppStateContext)
	const [errors, setErrors] = useState<string[]>([])
	const [hasFileError, setHasFileError] = useState<boolean>(false)
	const [files, setFiles] = useState<Set<File>>(new Set<File>())
	const [joinedFile, setJoinedFiles] = useState<RequestInputFiles>({
		name: 'joinedFile',
		label: pageData?.assets?.page_requestInfo_enclosed,
		labelKey: 'request_form_clothDiapers_ninthQuestion_label',
		description: pageData?.assets?.page_requestInfo_enclosed,
		files: new Set<File>(),
		required: false,
		numberFilesRequired: 0
	})
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [comment, setComment] = useState<RequestInputs>({
		name: 'newConsent',
		value: '',
		label: pageData?.assets?.myRequest_new_information_related_to_the_request,
		required: true,
		labelKey: request?.typeId,
		ref: useRef<HTMLTextAreaElement>(null)
	})

	const [documentTypeInput, setDocumentTypeInput] = useState<RequestInputs>({
		name: 'documentType',
		value: '',
		label: pageData?.assets?.page_myRequest_select_the_type_of_document,
		labelKey: 'request_form_documentType_select_label',
		required: addDocumentsType ? true : false,
		ref: useRef<HTMLSelectElement>(null)
	})

	const onSelectFileJoinedFiles = (files: Set<File>) => {
		setJoinedFiles({
			...joinedFile,
			files
		})
	}

	const MAX_CHAR_DESC = config.request.input.textareaMaxChar

	const documentTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageData?.assets?.select_documentType_proofOfResidency ??
				'Preuve de residence',
			value: String(RequestCategoryEnum.PROOF_OF_RESIDENCY),
			key: 'select_documentType_proofOfResidency'
		},
		{
			label:
				pageData?.assets?.select_documentType_ProofOfBirth ??
				'Preuve de naissance',
			value: String(RequestCategoryEnum.PROOF_OF_BIRTH),
			key: 'select_documentType_ProofOfBirth'
		},
		{
			label:
				pageData?.assets?.select_documentType_proofOfPurchase ??
				"Preuve d'achat / Facture",
			value: String(RequestCategoryEnum.PROOF_OF_PURCHASE),
			key: 'select_documentType_proofOfPurchase'
		},
		{
			label:
				pageData?.assets?.select_documentType_noticeOfContribution ??
				'Avis de cotisation',
			value: String(RequestCategoryEnum.NOTICE_OF_CONTRIBUTION),
			key: 'select_documentType_noticeOfContribution'
		},
		{
			label:
				pageData?.assets?.select_documentType_paperForm ?? 'Formulaire papier',
			value: String(RequestCategoryEnum.PAPER_FORM),
			key: 'select_documentType_paperForm'
		}
	]

	const onFixError = (listErrors: string[]) => setErrors(listErrors)

	const onChangeComment = (value: string) => {
		setComment({ ...comment, value })
		if (comment.required) {
			onFixError(errors.filter((error) => error !== comment.name))
		}
	}

	const onSelectDocumentTypeInput = (value: string) => {
		setDocumentTypeInput({ ...documentTypeInput, value })

		if (documentTypeInput.required && documentTypeInput.value !== '') {
			onFixError(errors.filter((error) => error !== documentTypeInput.name))
		}
	}

	const clearData = () => {
		setComment({ ...comment, value: '' })
		setFiles(new Set<File>())
		// setDocumentTypeInput({ ...documentTypeInput, value: '' })
		setErrors([])
		setHasFileError(false)
	}

	const processingDataAndValidation = () => {
		const listErrors: string[] = []

		if (comment.required && comment.value === '') {
			listErrors.push(comment.name)

			if (comment.ref) {
				comment.ref.current?.focus()
			}
		}

		// if (addDocumentsType && documentTypeInput.value == '') {
		// 	listErrors.push(documentTypeInput.name)
		// }

		setErrors(listErrors)

		if (listErrors.length === 0) {
			submitPost()
		}
	}

	const onSubmit = (processingData: () => void, hasError: boolean) => {
		if (hasError) {
			return false
		}

		processingData()
	}

	const submitPost = async () => {
		if (authUser) {
			setIsLoading(true)
			const rawFiles = [...files]

			let attachment: Promise<ICommentsAttachment>[] | ICommentsAttachment[] =
				!!rawFiles.length
					? rawFiles.map(
							async (file): Promise<ICommentsAttachment> => ({
								portalCommentId: '',
								id: '',
								fileSize: file.size,
								dateAdd: new CustomDate(
									new Date(file.lastModified).toUTCString()
								),
								mimeType: file.type,
								fileContent: (await toBase64(file)) as Promise<string>,
								fileName: file.name
							})
					  )
					: []

			Promise.all(attachment).then(async (result) => {
				const req = new Comment({
					portalMessageRead: false,
					requestId: request.id!,
					comment: comment.value,
					dateAdd: new CustomDate(new Date().toUTCString()),
					subject: comment.label,
					attachment: result as unknown as ICommentsAttachment[],
					files: rawFiles
					// documentType: documentTypeInput?.value
				})

				setRequestCode && setRequestCode(request.code)
				const res = await req.saveAsync()
				setIsLoading(false)
				onInformationAdded(res)

				// reload page
				setTimeout(() => window.location.reload(), 3000)
			})
		}
	}

	const getMaxCharsDesc = () => {
		if (request?.name !== RequestNames.othersRequests) {
			return MAX_CHAR_DESC
		} else {
			return config.request.input.textareaMaxCharForOtherRequest
		}
	}

	return (
		<Modal
			title={`${pageData?.assets?.label_add_information_to_request} <br/> ${
				request?.code
			} <br /> ${pageRequestDetailsUtils.getRequestTitle(
				pageData.requests,
				request
			)}`}
			primaryButtonText={pageData?.assets?.label_send}
			cancelButtonText={pageData?.assets?.cancel}
			showExitBtn
			isOpen={isOpen}
			onCancelBtn={() => {
				setModal()
				clearData()
			}}
			onSetIsOpen={() => {}}
			onPrimaryButton={() =>
				onSubmit(processingDataAndValidation, errors.length > 0)
			}
			disabled={isLoading}
			shouldNotClose={isLoading}
		>
			{isLoading ? (
				<Loader text={pageData?.assets?.loading} />
			) : (
				<div className={pageRequestDetailsUtils.classes.modalContent}>
					<form>
						<fieldset>
							<TextAreaInput
								id={comment.name}
								label={comment.label}
								value={comment.value}
								maxCharacters={getMaxCharsDesc()}
								onChange={onChangeComment}
								required={comment.required}
								hasError={errors.includes(comment.name)}
								hasDescriptionLabel={`${getMaxCharsDesc()} ${
									pageData?.assets?.myRequest_characters_max
								}`}
								descriptionAlignment="left"
								ref={comment.ref as RefObject<HTMLTextAreaElement>}
							/>
						</fieldset>
						<fieldset>
							<RequestFiles
								title={pageData?.assets?.page_requestInfo_enclosed}
								files={files}
								onSetFiles={onSelectFileJoinedFiles}
								hasError={hasFileError}
								onSetHasError={setHasFileError}
								maxFiles={NUMBER_OF_MAX_FILES}
							>
								<span className={pageUtils.classes.inputDescLabel}>
									<Text
										content={pageData?.assets?.page_requestInfo_inputFileDesc}
									/>
								</span>
							</RequestFiles>
						</fieldset>
						{/* {addDocumentsType && (
							<SelectInput
								id={documentTypeInput.name}
								label={documentTypeInput.label}
								value={documentTypeInput.value}
								list={documentTypeList}
								required
								hasError={errors.includes(documentTypeInput.name)}
								onChange={onSelectDocumentTypeInput}
								ref={documentTypeInput.ref as RefObject<HTMLSelectElement>}
							/>
						)} */}
					</form>
				</div>
			)}
		</Modal>
	)
}

export default AddInformationModal
