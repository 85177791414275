import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import Notification from '@components/ui/notification'
import { makeClasses } from '@utils/styles'
import React, { useState, useEffect, useContext } from 'react'
import * as XLSX from 'xlsx'
import TextAreaInput from '@components/requestForms/inputs/textarea.inputs'
import TextInput from '@components/requestForms/inputs/text.inputs'
import { RadioList } from '@utils/request'

type Classes = {
	root: string
	checkbox: string
	radio: string
}

const classes: Classes = makeClasses({
	root: {
		'& div[data-value]': {
			display: 'inline-block',
			width: '49%'
		},
		'& div[data-value]:first-of-type': {
			marginRight: '10px'
		}
	},
	checkbox: {
		display: 'flex !important',
		alignItems: 'center'
	},
	radio: {
		display: 'flex !important',
		alignItems: 'center'
	}
})

type FormData = {
	[fieldName: string]: string
}

type Field = {
	RequestId?: string
	InformationMessage?: string
	Name: string
	Label: string
	Type:
		| 'text'
		| 'number'
		| 'email'
		| 'phone'
		| 'select'
		| 'checkbox'
		| 'radio'
		| 'textarea'
	Options?: string
	Values?: string
	Required?: boolean
	Max?: number
	Min?: number
	Group?: number
}

type ExcelData = Field[]

type FormGeneratorProps = {
	excelFileUrl: string
}

const GeneratorFormFromExcel: React.FC<FormGeneratorProps> = ({
	excelFileUrl
}) => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const [formData, setFormData] = useState<ExcelData>([])
	//   const { register, handleSubmit } = useForm<FormData>();

	useEffect(() => {
		const fetchExcelFile = async () => {
			try {
				// file url from strapi
				const formFileUrl = pageData.formRequests[0].form[0].url

				const response = await fetch(excelFileUrl)

				const blob = await response.blob()
				const reader = new FileReader()

				reader.onload = (event) => {
					const data = event.target?.result as string
					const workbook = XLSX.read(data, { type: 'binary' })
					const worksheet = workbook.Sheets[workbook.SheetNames[0]]
					const jsonData = XLSX.utils.sheet_to_json(worksheet) as ExcelData
					setFormData(jsonData)
				}
				reader.readAsBinaryString(blob)
			} catch (error) {
				console.error('Error fetching Excel file:', error)
			}
		}

		fetchExcelFile()
	}, [excelFileUrl])

	const radioListInputs = (valueString?: string, keyString?: string) => {
		const keys = keyString?.split(', ')
		const values = valueString?.split(', ')
		if (
			keys?.length != values?.length ||
			keys == undefined ||
			values == undefined
		)
			return []

		const radioList: RadioList[] = []
		for (let i = 0; i < keys?.length; i++) {
			radioList.push({
				key: `${keys[i]}`,
				value: values[i],
				label: pageData.assets[keys[i]]
			})
		}

		return radioList
	}

	const renderFields = () => {
		if (!formData) return null

		return formData.map((field, index) => {
			const {
				Name,
				Label,
				Type,
				Options,
				Values,
				Required,
				Max,
				Min,
				InformationMessage,
				Group
			} = field
			const options =
				Options?.split(',').map((option) => {
					if (option.includes('\n')) {
						return option.replace('\n', '').trim()
					}
					return option.trim()
				}) ?? ([] as RadioList[])
			const values =
				Values?.split(',').map((values) => {
					if (values.includes('\n')) {
						return values.replace('\n', '').trim()
					}
					return values.trim()
				}) ?? []

			switch (Type) {
				case 'text':
				case 'number':
				case 'email':
				case 'phone':
					return (
						<>
							{InformationMessage && (
								<Notification
									type="info"
									text={pageData.assets[InformationMessage]}
								/>
							)}
							<div key={index} data-value={Group}>
								<TextInput
									label={pageData.assets[Label]}
									id={Name}
									required={Required}
									// type={Type}
									value=""
									onChange={() => {}}
								/>
							</div>
						</>
					)
				case 'textarea':
					return (
						<>
							{InformationMessage && (
								<Notification
									type="info"
									text={pageData.assets[InformationMessage]}
								/>
							)}
							<div key={index} data-value={Group}>
								<TextAreaInput
									label={pageData.assets[Label]}
									id={Name}
									required={Required}
									value=""
									onChange={() => {}}
									maxCharacters={300}
								/>
							</div>
						</>
					)
				case 'select':
					return (
						<>
							{InformationMessage && (
								<Notification
									type="info"
									text={pageData.assets[InformationMessage]}
								/>
							)}
							<div key={index} data-value={Group}>
								<label htmlFor={Name}>{pageData.assets[Label]}</label>
								<select name={Name} required={Required}>
									{options?.map((option, index) => (
										<option key={option} value={values[index]}>
											{pageData.assets[option]}
										</option>
									))}
								</select>
							</div>
						</>
					)
				case 'checkbox':
					return (
						<>
							{InformationMessage && (
								<Notification
									type="info"
									text={pageData.assets[InformationMessage]}
								/>
							)}
							<div key={index} data-value={Group}>
								<label htmlFor={Name} className={classes.checkbox}>
									<input type="checkbox" name={Name} required={Required} />
									<span>{pageData.assets[Label]}</span>
								</label>
							</div>
						</>
					)
				case 'radio':
					return (
						<>
							{InformationMessage && (
								<Notification
									type="info"
									text={pageData.assets[InformationMessage]}
								/>
							)}
							<div key={index} data-value={Group}>
								<label>{pageData.assets[Label]}</label>
								{options?.map((option, index) => (
									<label key={option} className={classes.radio}>
										<input
											type="radio"
											name={Name}
											value={values[index]}
											required={Required}
										/>
										<span>{pageData.assets[option]}</span>
									</label>
								))}
								{/* <RadioInput 
                      label={pageData.assets[Label]}
                      name={Name}
                      required={Required}
                      value=''
                      onChange={()=> {}}
                      list={radioListInputs(Values, Options)}
                    /> */}
							</div>
						</>
					)
				default:
					return null
			}
		})
	}

	return (
		<div>
			{formData ? (
				<form className={classes.root}>
					{renderFields()}
					<button type="submit">
						{pageData?.assets?.page_requestInfo_inputSubmit}
					</button>
				</form>
			) : (
				<p>{pageData?.assets?.loading}...</p>
			)}
		</div>
	)
}

export default GeneratorFormFromExcel
