import { RootState } from '@services/store/type'

export const getRequestStateModel = (state: RootState) => state.request.model
export const getRequestStateLocation = (state: RootState) =>
	state.request.location
export const getRequestStateConfigs = (state: RootState) =>
	state.request.configs
export const getRequestCreatedState = (state: RootState) =>
	state.request.requestCreated
export const getRequestIdForArticles = (state: RootState) =>
	state.request.listRequestIdsForArticles
