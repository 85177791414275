import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import Text from '@components/ui/text'
import Link, { navigate } from '@components/ui/link'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import {
	formatHtmlForStrapiText,
	formatStrapiText,
	getParamsFromSearchLocation
} from '@utils/methods'
import { ButtonLink } from '@components/ui/ButtonLink'
import { ButtonLinkTypeEnum, Colors } from '@utils/css-variables'
import { ButtonLinkType, Request, RequestTheme } from 'typings/shared'
import * as pageUtils from '@components/ui/requestConfirmation/__index.utils'
import config from '@utils/config'
import { joinClasses, makeClasses } from '@utils/styles'
import ReactToPrint from 'react-to-print'
import { classes } from '@templates/requests/__create.utils'
import Collapse from '../collapse'
import axios from 'axios'
import {
	buttonTextDic,
	numberMaxOfRequest,
	strapiDataRes,
	REQUEST_HIDDEN
} from '@pages/auth/requests/create/__index.utils'
import { RequestNames, TNRequestNames } from '@utils/request'
import Notification from '../notification'
import { Icon } from '../Icon'
import { warningYellowIcon } from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import RedirectModal from '../modals/redirectModal'
import {
	IDENTIFIERS_OF_TN_REQUEST_TYPE,
	INVOICE_STATUS,
	REQUEST_CONFIGS_KEYS
} from '@services/constants'
import { useAppSelector } from '@services/store'

type Classes = {
	greyBackground: string
	heading: string
	spaceTop: string
	collapse: string
	btn: string
	requestContainer: string
	warningIcon: string
	h3: string
	description: string
	table: string
	h4: string
	button: string
	h4WithoutMargins: string
}

const ownClass: Classes = makeClasses({
	greyBackground: {
		background: Colors.darkWhite,
		padding: '25px',
		marginBottom: '1rem'
	},
	heading: {
		marginTop: '3rem',
		fontSize: '20px',
		fontWeight: 600,
		margin: '20px 0'
	},
	spaceTop: {
		marginTop: '3rem'
	},
	collapse: {
		'& > div[id^="collapse-"i]': {
			borderBottom: `1px solid ${Colors.grey}`,
			':first-of-type': {
				borderTop: `1px solid ${Colors.grey}`
			}
		}
	},
	btn: {
		padding: '15px 30px',
		fontSize: '16px',
		display: 'inline-block',
		fontWeight: '500',
		lineHeight: '1em',
		MozBorderRadius: '23px',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: '#0054A6',
		textDecoration: 'none',
		color: Colors.white,
		cursor: 'pointer',
		border: '1px solid #0054a6',
		marginTop: '15px',
		':hover': {
			color: Colors.white,
			borderColor: Colors.darkBlue2,
			background: Colors.darkBlue2
		}
	},
	requestContainer: {
		margin: '20px 0 30px'
	},
	warningIcon: {
		// width: '100%',
		display: 'flex',
		justifyContent: 'center',
		img: {
			width: '20px',
			height: '20px'
		}
	},
	h3: {
		display: 'flex',
		alignItems: 'center',
		gap: '5px',
		color: Colors.lightYellow,
		marginTop: 0
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0px 3px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		marginTop: '10px !important',
		fontWeight: '400',
		'& a': {
			color: Colors.secondary,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	},
	table: {
		border: '1px solid #ccc',
		borderCollapse: 'collapse',
		width: '100%',
		backgroundColor: Colors.white,
		'& tr': {
			borderTop: '1px solid #ccc',
			'& td': {
				textAlign: 'right',
				padding: '8px 16px',
				fontSize: '14px',
				':first-child': {
					textAlign: 'left',
					fontWeight: 'bold',
					width: '50%'
				}
			}
		},
		'& tr.total': {
			fontWeight: 'bold',
			fontSize: '18px',
			background: '#e6e7e8',
			td: {
				fontSize: '16px !important'
			}
		},
		'& tr.thead': {
			'& td': {
				fontWeight: 'bold'
			}
		}
	},
	h4: {
		margin: '30px 0 20px !important'
	},
	h4WithoutMargins: {
		margin: '0 0 15px !important',
		fontSize: '20px'
	},
	button: {
		cursor: 'pointer',
		padding: '15px 30px',
		fontSize: '16px',
		height: '48px',
		[Breakpoints.maxWidth('sm')]: {
			padding: '15px 24px',
			fontSize: '15px',
			':first-child': {
				marginLeft: '20px'
			}
		}
	}
})

const RequestConfirmation: FC<pageUtils.Props> = ({
	requestCreated,
	useClassWrapper,
	request,
	closeLink,
	children,
	hasPayment,
	createdRequestWithInvoice
}) => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const summaryFormRef = useRef(null)
	// const group: string = closeLink?.split('group=')[1] ?? ''
	// const tempTheme: RequestTheme | undefined =
	// 	pageData?.collections?.requestThemes.filter(
	// 		(request: RequestTheme) => request.name === group
	// 	)[0]

	const answers = useAppSelector((state) => state.request.configs)

	const [displayedRequests, setDisplayedRequests] = useState<Request[]>([])
	// const [requests, setRequests] = useState<Request[]>([])
	const [tmpRequests, setTmpRequests] = useState<Request[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [redirect, setRedirect] = useState<boolean>(false)

	// useEffect(() => {
	// 	setRequests(tempTheme?.requests!)
	// }, [group, tempTheme])

	const getTitleTextByRequest = () => {
		switch (request?.name) {
			case TNRequestNames.replacementOilHeatingSystem:
				return pageData?.assets
					?.label_would_you_like_to_apply_for_heating_system
			case TNRequestNames.claimNotice:
				return pageData?.assets?.label_would_you_like_to_report_incident
			case TNRequestNames.fireplaceDeclaration:
				return pageData?.assets?.label_if_you_possess_combustion
			default:
				return pageData?.assets?.label_would_you_like_to_apply_for
		}
	}

	const getRequestListToFilterOn = () => {
		switch (request?.name) {
			// if on this request
			case TNRequestNames.replacementOilHeatingSystem:
				// the list of requests to show
				return [TNRequestNames.oilHeatingDeclaration]
			case TNRequestNames.fireplaceDeclaration:
				return [TNRequestNames.fireplaceDeclaration]
			case TNRequestNames.fireHydrantsTankFilling:
			case TNRequestNames.fireHydrantsTempAqueductNetwork:
			case TNRequestNames.hydraulicAndPressureTests:
				return [
					TNRequestNames.fireHydrantsTankFilling,
					TNRequestNames.fireHydrantsTempAqueductNetwork,
					TNRequestNames.hydraulicAndPressureTests
				]
			case TNRequestNames.claimNotice:
				return [
					RequestNames.curbAndSidewalk,
					RequestNames.pothole,
					RequestNames.snowClearingProblem
				]
			default:
				return []
		}
	}

	useEffect(() => {
		if (useClassWrapper) {
			setLoading(true)
			axios
				.get(
					`${process.env.STRAPI_API}/api/ptc-requests?pagination%5BpageSize%5D=${numberMaxOfRequest}`
				)
				.then(async (res) => {
					const data = res.data as strapiDataRes
					const hiddenRequestList = data.data?.map((request) => {
						if (REQUEST_HIDDEN[request.attributes.Cacher])
							return request.attributes.request_id
					})

					let requests: any[] = []
					pageData?.collections?.requestThemes.forEach((group) => {
						group.requests.forEach((request) => {
							if (
								!hiddenRequestList.includes(request.requestId) &&
								getRequestListToFilterOn()?.includes(request?.name ?? '')
							) {
								requests.push(request)
							}
						})
					})

					setTmpRequests(requests)
				})
				.catch((e) => {
					setTmpRequests([])
					console.error(e)
				})
				.finally(() => setLoading(false))
		}
	}, [])

	const filterRequests = (requests: Request[]) => {
		const temp = requests?.filter((requestData) => requestData.requestId)
		setDisplayedRequests(temp)
	}

	useEffect(() => {
		filterRequests(tmpRequests)
	}, [tmpRequests])

	const onNavigateToRequest = (requestId: string) => (e) => {
		e.preventDefault()

		if (requestId === request?.requestId) {
			location.reload()
		}

		const link: string = `${config.request.create.baseURL}/${requestId}`

		navigate(link)
	}

	const getRequestGroup = (requestId: string, attribut: string) => {
		let requestGroup = ''
		pageData?.collections?.requestThemes?.forEach((group) => {
			if (group?.requests?.find((req) => req?.requestId == requestId)) {
				requestGroup = group[attribut]
			}
		})

		return requestGroup
	}

	const displayRequests = (requestCard: Request, key: number) => {
		return (
			<Collapse id={key} buttonText={requestCard.title} key={key}>
				<div className={ownClass.requestContainer}>
					<Text content={requestCard.description} />
					<button
						onClick={onNavigateToRequest(requestCard.requestId)}
						className={ownClass.btn}
					>
						{formatStrapiText(
							pageData?.assets[
								buttonTextDic[
									`${getRequestGroup(requestCard.requestId, 'name')}`
								]
							]
						)}
					</button>
				</div>
			</Collapse>
		)
	}

	const displayBackToHomeBtn = () => {
		return (
			<ButtonLink
				link="/"
				type={ButtonLinkTypeEnum.LINK as ButtonLinkType}
				wrapperClassName={pageUtils.classes.confirmationButton}
				text={pageData?.assets?.request_form_confirmation_home_link}
			/>
		)
	}

	const printStyle = `
	@media print {
		@page {
			margin: 2cm;
    	}
		@page {
			size: auto;
			padding-top: 5cm !important;
			padding-bottom: 5cm !important;
			// margin: -5cm 0px;
		}
		@page :footer { 
			background-color: grey;
			color: #fff !important;
			display: none !important;
		} 
		@page :header { 
			color: #fff !important;
			display: none !important;
		} 

		/* Hide the default checkbox */
		input[type="checkbox"] {
			display: none;
		}

		/* Style the label's ::after to show a custom checkbox */
		label::after {
			content: '';
			display: inline-block;
			width: 10px;
			height: 10px;
			box-shadow: inset 0 0 0 8px #0054a6;
			vertical-align: middle;
		}

		/* When the checkbox is checked, change the background to blue */
		input[type="checkbox"]:checked + label::after {
			box-shadow: inset 0 0 0 8px #0054a6 !important;
		}
  }
`

	const goToRequestDetail = () => {
		const link: string = `${config.request.baseUrl}/${requestCreated.id}`
		navigate(link, {
			state: {
				hasInvoice: hasPayment
			}
		})
	}

	const hasNonRequiredPaymentStatus = () => {
		return (
			hasPayment &&
			createdRequestWithInvoice?.invoice?.status == INVOICE_STATUS.notRequired
		)
	}

	const hasWaitingForPaymentStatus = () => {
		return (
			hasPayment &&
			createdRequestWithInvoice?.invoice?.status ==
				INVOICE_STATUS.waitingForPayment
		)
	}

	const getInvoiceProductDescription = (invoice, item) => {
		if (item?.productDescription) {
			return item?.description + ' - ' + item.productDescription
		}

		if (invoice?.description) {
			return item.description + ' - ' + invoice.description
		}

		return item?.description
	}

	// const isProductForFree = (invoice) => {
	// 	return invoice?.description == 'Gratuité 65 ans et +'
	// }

	const getSortedDetailItems = (items) => {
		let detailItems = items

		detailItems?.sort((a, b) => {
			if (a?.description < b?.description) {
				return -1
			} else if (a?.description > b?.description) {
				return 1
			} else {
				return 0
			}
		})

		return detailItems
	}

	const getDescriptionTextForNonRequiredPayment = (request) => {
		if (
			request.typeId ==
			IDENTIFIERS_OF_TN_REQUEST_TYPE.ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS
		) {
			if (
				request.incidentForm?.whatToDo ==
				REQUEST_CONFIGS_KEYS.whatToDo.renewal_and_annual_permit
			) {
				return pageData?.assets
					?.request_form_annualPermitAndTagForAnimals_confirmation_renewPermit_text
			} else {
				return pageData?.assets
					?.request_form_annualPermitAndTagForAnimals_confirmation_newPermit_text
			}
		}
	}

	const isReplacementOrLostMedalOption = (request) => {
		return (
			request.typeId ==
				IDENTIFIERS_OF_TN_REQUEST_TYPE.ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS &&
			request.incidentForm?.whatToDo ==
				REQUEST_CONFIGS_KEYS.whatToDo.replace_lost_or_damaged_tag
		)
	}

	const renderInvoiceTable = () => {
		return (
			<table className={ownClass.table}>
				<tbody>
					<tr className="thead">
						<td>{pageData?.assets?.label_product}</td>
						{/* <td>{pageData?.assets?.label_quantity}</td> */}
						{!isReplacementOrLostMedalOption(createdRequestWithInvoice) ? (
							<td>{pageData?.assets?.label_nbr_of_years}</td>
						) : (
							<td></td>
						)}
						<td>{pageData?.assets?.label_unit_price}</td>
						<td>{pageData?.assets?.page_requestDetails_total}</td>
					</tr>

					{getSortedDetailItems(
						createdRequestWithInvoice?.invoice?.detailItems
					)?.map((item) => {
						return (
							<tr>
								<td>
									{getInvoiceProductDescription(
										createdRequestWithInvoice?.invoice,
										item
									)}
								</td>
								{!isReplacementOrLostMedalOption(createdRequestWithInvoice) ? (
									<td>{item?.quantity}</td>
								) : (
									<td></td>
								)}
								<td>{item?.pricePerUnit?.toFixed(2).replace('.', ',')}$</td>
								<td>
									<strong>{item?.total?.toFixed(2).replace('.', ',')}$</strong>
								</td>
							</tr>
						)
					})}

					<tr className="total">
						<td>{pageData?.assets?.page_requestDetails_total}</td>
						<td></td>
						<td></td>
						<td>
							{createdRequestWithInvoice?.invoice?.grandTotal
								?.toFixed(2)
								.replace('.', ',')}
							$
						</td>
					</tr>
				</tbody>
			</table>
		)
	}

	const requestHasPayementAfterAnalyzing = (request, isfireHydrants) => {
		const requestTypes = [
			TNRequestNames.temporaryPesticideApplicationPermitForIndividuals,
			TNRequestNames.annualRegistrationCertificateForContractors,
			TNRequestNames.permitOccupationOfPublicHighway,
			TNRequestNames.swimmingPoolSpaPermit,
			TNRequestNames.buildingConstructionOrAdditionPermit,
			TNRequestNames.wateringPermit,
			TNRequestNames.buildingDemolitionOrMovingPermit,
			TNRequestNames.buildingEnlargementPermit,
			TNRequestNames.residentialRenovationOrRepairPermit,
			TNRequestNames.landDevelopmentWorkPermit
		]

		const fireHydrantsRequests = [
			TNRequestNames.fireHydrantsTankFilling,
			TNRequestNames.fireHydrantsTempAqueductNetwork,
			TNRequestNames.hydraulicAndPressureTests
		]

		if (isfireHydrants) {
			return (
				fireHydrantsRequests.includes(request?.name ?? '') &&
				answers?.isContractWithCity == false
			)
		} else {
			return requestTypes.includes(request?.name ?? '')
		}
	}

	const isMyProperty = (requestId: string): boolean => {
		const requestIdentifiers = [
			IDENTIFIERS_OF_TN_REQUEST_TYPE.FIREPLACE_DECLARATION,
			IDENTIFIERS_OF_TN_REQUEST_TYPE.SELF_RELIEF_WATER_COUNTER_FORM
		]

		return requestIdentifiers.includes(
			requestId as IDENTIFIERS_OF_TN_REQUEST_TYPE
		)
	}

	return (
		<>
			<div className={classes.sectionBlueTitle}>
				<h3>
					{formatStrapiText(pageData?.assets?.request_form_confirmation_title)}
				</h3>
			</div>
			<div
				className={classes.textSection}
				style={{ marginBottom: hasWaitingForPaymentStatus() ? '15px' : 0 }}
			>
				<div className={pageUtils.classes.confirmationContent}>
					<Text
						content={pageData?.assets?.request_form_confirmation_thanks_text}
					/>
				</div>
				{hasWaitingForPaymentStatus() ? (
					<>
						{/* ///////////////////////  partie pour paiement requis //////////////// */}

						{redirect && (
							<RedirectModal
								shouldRedirect={redirect}
								onRedirect={() => {
									navigate('/auth/payment', {
										state: {
											invoice: createdRequestWithInvoice?.invoice,
											origin: `/auth/requests/${createdRequestWithInvoice?.id}`
										}
									})
								}}
								text={
									pageData?.assets
										?.redirectModal_you_will_be_redirected_to_the_payment_method
								}
							/>
						)}

						<div className={pageUtils.classes.confirmationContent}>
							<Text
								content={getDescriptionTextForNonRequiredPayment(
									createdRequestWithInvoice
								)}
							/>
						</div>

						<div>
							<Notification type="warning" text="">
								<div>
									<h3 className={ownClass.h3}>
										<div className={ownClass.warningIcon}>
											<Icon src={warningYellowIcon} />
										</div>
										{formatStrapiText(
											pageData?.assets?.label_payment_is_required
										)}
									</h3>
									<p>
										<Text
											content={pageData?.assets?.label_payment_information}
										/>
									</p>

									<h4 className={ownClass.h4}>
										{pageData?.assets?.paymentPage_fees_to_pay}
									</h4>

									{/* /////////////  Table de details de la facture ////////// */}
									{renderInvoiceTable()}

									<div
										className={joinClasses([
											ownClass.description,
											'link-redirect'
										])}
									>
										<Text
											content={
												pageData?.assets
													?.page_requestDetails_consult_payment_policy
											}
										/>
									</div>

									<button
										className={joinClasses([ownClass.btn, ownClass.button])}
										onClick={() => {
											setRedirect(true)
										}}
									>
										{pageData?.assets?.label_pay_now}
									</button>
								</div>
							</Notification>
						</div>
					</>
				) : (
					<>
						{hasNonRequiredPaymentStatus() && (
							<div className={pageUtils.classes.confirmationContent}>
								<Text
									content={getDescriptionTextForNonRequiredPayment(
										createdRequestWithInvoice
									)}
								/>
							</div>
						)}

						<div
							className={
								useClassWrapper || hasNonRequiredPaymentStatus()
									? ownClass.greyBackground
									: ''
							}
						>
							<div className={pageUtils.classes.confirmationContent}>
								<Text
									content={
										pageData?.assets
											?.request_form_confirmation_request_number_text
									}
								/>{' '}
								<span
									className={joinClasses([
										pageUtils.classes.confirmationRequestsLink,
										pageUtils.classes.confirmationRequestsLinkCode
									])}
									onClick={goToRequestDetail}
								>
									<p>{requestCreated.code}</p>
								</span>
							</div>

							<div className={pageUtils.classes.confirmationContent}>
								{request && isMyProperty(request.requestId) ? (
									<p>
										{formatStrapiText(
											pageData?.assets
												?.request_form_confirmation_my_property_declaration_text
										)}
										<Link
											to={'/auth/profile'}
											className={pageUtils.classes.confirmationRequestsLink}
											onClick={() => {
												localStorage.setItem(config.localStorage.activeTab, '2')
											}}
										>
											{formatStrapiText(
												pageData?.assets?.header_dropdown_my_property
											)}
										</Link>
									</p>
								) : (
									<p>
										{formatStrapiText(
											pageData?.assets
												?.request_form_confirmation_my_requests_text
										)}
										<Link
											to={'/auth/requests'}
											className={pageUtils.classes.confirmationRequestsLink}
										>
											{formatStrapiText(
												pageData?.assets
													?.request_form_confirmation_my_requests_link
											)}
										</Link>
									</p>
								)}
							</div>

							{request && requestHasPayementAfterAnalyzing(request, false) && (
								<Notification
									type="info"
									text={
										pageData.assets
											?.request_form_confirmation_after_analyzing_your_request
									}
									hasHtml
								/>
							)}

							<div className={pageUtils.classes.confirmationButton}>
								{!!children && (
									<>
										<ReactToPrint
											content={() => summaryFormRef.current}
											pageStyle={printStyle}
											trigger={() => (
												<button
													className={joinClasses([
														classes.btn,
														classes.button,
														classes.buttonOutline
													])}
													style={{
														marginRight: '10px',
														marginLeft: '0px'
													}}
												>
													{formatStrapiText(
														pageData?.assets?.page_create_request_print_pdf_form
													)}
												</button>
											)}
										/>
									</>
								)}
								{!useClassWrapper &&
									!hasNonRequiredPaymentStatus() &&
									displayBackToHomeBtn()}
							</div>
						</div>

						{request && requestHasPayementAfterAnalyzing(request, true) && (
							<Notification
								type="info"
								text={
									pageData.assets
										?.request_form_confirmation_after_analyzing_your_request
								}
								hasHtml
							/>
						)}

						{/* //////////////////// partie pour paiement non requis //////////////// */}
						{hasNonRequiredPaymentStatus() &&
							!!createdRequestWithInvoice?.invoice?.detailItems?.length && (
								<>
									<div className={ownClass.greyBackground}>
										<h4 className={ownClass.h4WithoutMargins}>
											{pageData?.assets?.paymentPage_no_fees_to_pay}
										</h4>
										{renderInvoiceTable()}
									</div>
								</>
							)}

						{useClassWrapper && (
							<div className={ownClass.spaceTop}>
								<h5 className={ownClass.heading}>{getTitleTextByRequest()}</h5>
								<div className={ownClass.collapse}>
									{displayedRequests?.map(displayRequests)}
								</div>
							</div>
						)}
					</>
				)}
			</div>

			<div style={{ display: 'none' }}>
				<div ref={summaryFormRef}>{children}</div>
			</div>

			{((!hasPayment && useClassWrapper) || hasNonRequiredPaymentStatus()) && (
				<div style={{ marginBottom: '30px' }}>
					<div className={pageUtils.classes.confirmationButton}>
						{displayBackToHomeBtn()}
					</div>
				</div>
			)}
		</>
	)
}

export default RequestConfirmation
