import React, {
	FC,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import {
	AnimalInformation,
	AnimalInformationFile,
	AnimalRequiredFileAttributes,
	AnimalType,
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction,
	requestCategories,
	LanguageEnum
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors, ButtonStyle } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import {
	formatHtmlForStrapiText,
	formatStrapiText,
	getFilesFromStore
} from '@utils/methods'
import Text from '@components/ui/text'
import { catIcon, checkBlueIcon, dogIcon } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import RequestFiles from '@components/ui/requestFiles'
import DateFilterInput from './inputs/datefilter.input'
import RadioInput from './inputs/radio.input'
import Notification from '@components/ui/notification'
import CheckboxInput from './inputs/checkbox.input'
import moment from 'moment'
import AnimalInforamtionModal from '@components/ui/modals/animalInformarionModal'
import SearchableSelectInput from './inputs/SearchableSelect.inputs'
import MaskedTextInput from './inputs/textWithValidation.inputs'
import { PHONE_NUMBER_REGEX } from '@pages/auth/profile/__index.utils'
import * as formUtils from '@pages/auth/__index.utils'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import Modal from '@components/ui/modal'
import SelectInput from './inputs/select.inputs'

type Classes = {
	button: string
	outlinedButton: string
	buttonContainer: string
	inputContainer: string
	checkbox2: string
	checkbox2Label: string
	checkbox2Container: string
	checkboxWrapper: string
	detailscontainer: string
	h5: string
	row: string
	description: string
	icon: string
	imageIcon: string
	animalContainer: string
	blocContainer: string
	animal: string
	animalContainerHover: string
	bgClick: string
	subContent: string
	card: string
	cardError: string
	animalWrapper: string
	buttonWrapper: string
	headerContainer: string
	linkButton: string
	btnPadding: string
	gridDisplay: string
	reduceMargin: string
	modalWrapper: string
	animalGrid: string
	animalIcon: string
	col6: string
	iconVissiblity: string
	selectedAnimal: string
	animalHover: string
	selectDropdown: string
	declarationList: string
	collapseWrapper: string
	collapseAutoHeight: string
	underlineOnHover: string
	requiredIcon: string
	notificationShowOnSmallScreen: string
	notificationShowOnLargeScreen: string
	reducedH5Margin: string
}

export const classes: Classes = makeClasses({
	button: {
		...ButtonStyle,
		background: Colors.secondary,
		color: Colors.white,
		cursor: 'pointer',
		padding: '10px 20px',
		fontSize: '16px',
		marginLeft: '40px',
		height: '48px',
		[Breakpoints.maxWidth('sm')]: {
			padding: '15px 24px',
			fontSize: '15px',
			':first-child': {
				marginLeft: '20px'
			}
		}
	},
	outlinedButton: {
		background: 'none',
		border: `1px solid ${Colors.secondary}`,
		color: Colors.secondary
	},
	buttonContainer: {
		display: 'flex',
		width: 'fit-content',
		columnGap: '4px'
	},
	inputContainer: {
		marginBottom: '20px'
	},
	checkbox2: {
		width: 'fit-content',
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	checkbox2Label: {
		fontSize: '16px',
		padding: '0',
		color: Colors.lightBlack,
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: `1px solid ${Colors.lightGrey3}`,
			background: Colors.white,
			outline: 'none !important'
		},
		':after': {
			content: "''",
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: Colors.secondary,
			outline: 'none',
			opacity: '0',
			transition: 'all .15s ease-out'
		},
		'&.error-check': {
			color: `${Colors.red} !important`,
			':before': {
				border: `1px solid ${Colors.red}`
			}
		},
		'& ul': {
			paddingLeft: '18px',
			margin: 0,
			'& li': {
				lineHeight: '20px',
				marginTop: '10px'
			},
			'& li::marker': {
				color: Colors.secondary
			}
		},
		'& > p': { display: 'inline' }
	},
	checkbox2Container: {
		'& input[value=true] ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxWrapper: {
		display: 'block',
		margin: '0 0 5px 0',
		background: `${Colors.ligthGrey2}`,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '14px 12px',
		marginBottom: '20px !important',
		'&.eligibility_clause': {
			marginBottom: '5px !important'
		}
	},
	h5: {
		fontSize: '20px',
		fontWeight: '600',
		margin: '15px 0 10px 0'
	},
	description: {
		clear: 'both',
		display: 'block',
		marginBottom: '5px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		fontWeight: '400'
	},
	icon: {
		width: '18px',
		position: 'absolute',
		marginTop: '-14px'
	},
	iconVissiblity: {
		visibility: 'hidden'
	},
	imageIcon: {
		width: '45px',
		verticalAlign: 'middle'
	},
	animalContainer: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 0 50%',
		maxWidth: 'fit-content',
		border: `1px solid ${Colors.greyAlto}`,
		background: `${Colors.darkWhite}`,
		padding: '30px',
		outline: '3px solid white',
		cursor: 'pointer'
		// transition: 'outline 0.3s ease',
	},
	animalContainerHover: {
		'&:hover': {
			boxShadow: 'inset 0 0 0 1px white',
			border: '1px solid white',
			outline: `3px solid ${Colors.darkWhite}`
		}
	},
	blocContainer: {
		border: `1px solid ${Colors.greyAlto}`,
		padding: '40px',
		[Breakpoints.maxWidth('sm')]: {
			padding: '20px'
		}
	},
	bgClick: {
		background: 'rgba(0, 84, 166, 0.2) !important',
		color: 'rgb(51, 51, 51)!important',
		borderColor: 'rgb(0, 84, 166)!important'
	},
	subContent: {
		display: 'grid',
		columnGap: '10px',
		gridTemplateColumns: '64%20%',
		alignItems: 'end',
		[Breakpoints.maxWidth('lg')]: {
			gridTemplateColumns: '45%20%'
		},
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '55%20%'
		},
		[Breakpoints.maxWidth('sm')]: {
			gridTemplateColumns: '48%20%'
		}
	},
	card: {
		background: Colors.darkWhite,
		marginBottom: '20px',
		display: 'flex',
		justifyContent: 'space-between',
		// cursor: 'pointer',
		alignItems: 'center',
		border: `3px solid ${Colors.darkWhite}`,
		transition: '0.2s',
		padding: '36px 30px',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			padding: '20px',
			'& .css-r1u628 ': {
				marginLeft: '0px ',
				marginRight: '30px ',
				marginTop: '10px'
			}
		}
	},
	cardError: {
		border: `3px solid ${Colors.errorRed} !important`
	},
	animalWrapper: {
		display: 'flex',
		alignItems: 'center',
		'& img': {
			marginRight: '10px'
		}
	},
	buttonWrapper: {
		display: 'flex',
		alignItems: 'center',
		[Breakpoints.maxWidth('lg')]: {
			'& button:nth-child(1)': {
				marginRight: '10px !important'
			},
			'& button:nth-child(2)': {
				marginRight: '0px !important'
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			flexDirection: 'row',
			alignItems: 'start',
			paddingTop: '10px',
			'& button:nth-child(1)': {
				padding: '5px 15px !important'
			}
		}
	},
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
		'& h5': {
			margin: '0',
			marginRight: '15px'
		}
	},
	linkButton: {
		backgroundColor: 'transparent',
		border: `none`,
		color: `${Colors.secondary}`,
		'&:hover': {
			backgroundColor: 'transparent !important',
			border: `none !important`,
			color: `${Colors.secondary} !important`
		}
	},
	btnPadding: {
		padding: '5px 20px !important',
		height: '38px',
		fontSize: '14px'
	},
	gridDisplay: {
		display: 'grid',
		gridTemplateColumns: '73%25%',
		alignItems: 'center',
		gap: '2%',
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr',
			'&:nth-child(2)': {
				gridRow: 2
			}
		}
	},
	reduceMargin: {
		marginBottom: '-25px',
		[Breakpoints.maxWidth('md')]: {
			marginBottom: '0px'
		}
	},
	modalWrapper: {
		'.css-q29xgf': {
			background: `${Colors.blackTransparent20} !important`
		}
	},
	row: {
		display: 'flex',
		'-ms-flex-wrap': 'wrap',
		flexWrap: 'wrap',
		marginRight: '-15px',
		marginLeft: '-15px'
	},
	animalGrid: {
		display: 'grid',
		columnGap: '10px',
		gridTemplateColumns: '48%48%',
		gap: '15px',
		margin: '0 0 0px'
	},
	animal: {
		display: 'flex',
		flexDirection: 'column',
		background: Colors.darkWhite,
		border: `3px solid ${Colors.darkWhite}`,
		borderRadius: '0px',
		padding: '36px 22px',
		cursor: 'pointer',
		gap: '10px',
		transition: '0.2s',
		fontSize: '16px',
		lineHeight: '22px',
		width: '100%',
		justifyContent: 'space-between',
		textAlign: 'center',
		'& > div': {
			display: 'flex',
			alignItems: 'center',
			gap: '10px',
			flexDirection: 'column'
		}
	},
	animalHover: {
		'&:hover': {
			border: `3px solid ${Colors.white}`,
			boxShadow: `0 0 15px ${Colors.blackTransparent15}`,
			transition: '0.2s'
		}
	},
	animalIcon: {
		width: '18px',
		position: 'absolute',
		marginTop: '-14px'
	},
	col6: {
		flex: '0 0 50%',
		maxWidth: '50%',
		[Breakpoints.maxWidth('lg')]: {
			maxWidth: '100%'
		}
	},
	selectedAnimal: {
		background: Colors.secondaryBackground,
		borderColor: Colors.secondary,
		'&:hover': {
			borderColor: `${Colors.secondary} !important`
		}
	},
	selectDropdown: {
		'& .css-1nmdiq5-menu': {
			zIndex: '999 !important',
			background: Colors.white
		}
	},
	declarationList: {
		'& li::before': {
			content: '""',
			position: 'absolute',
			width: '4px',
			height: '4px',
			top: '7px',
			left: '2px',
			borderRadius: '50%',
			backgroundColor: ' #0054A6'
		}
	},
	collapseWrapper: {
		maxHeight: 0,
		overflow: 'hidden',
		transition: 'max-height 0.5s ease-in-out'
	},
	collapseAutoHeight: {
		height: 'auto !important'
	},
	underlineOnHover: {
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	reducedH5Margin: {
		margin: '10px 0px 15px'
	},

	requiredIcon: {
		color: Colors.ligthPurple,
		fontSize: '1.2em',
		marginLeft: '2px'
	},
	notificationShowOnSmallScreen: {
		display: 'none',
		[Breakpoints.maxWidth('md')]: {
			display: 'flex'
		}
	},
	notificationShowOnLargeScreen: {
		display: 'flex',
		[Breakpoints.maxWidth('md')]: {
			display: 'none'
		}
	}
})

const AnnualPermitAndTagForAnimals: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	setDisableNextButton,
	setRequiredFieldAttributes,
	setRequiredFileAttributes
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray,
		// radioListYesNo,
		language
	} = requestForm()

	const requiredAttrNewPermit = [
		'name',
		'gender',
		'breed',
		'color',
		'birth_date',
		// 'birth_place',
		'is_micro_chipped',
		'is_sterilized',
		'is_rabies_vaccinated',
		'service_animal',
		'permit_term'
	]

	const requiredAttrRenewPermit = [
		'name',
		'tag_number',
		'service_animal',
		'permit_term'
		// 'tag_number_unknown',
		// 'non_renewal_reason'
	]

	const requiredAttrReplacePermit = ['name', 'tag_number']

	let answers = useAppSelector((state) => state.request.configs)
	const { tempAllText } = useContext(AppStateContext)

	const radioListWeightUnit: RadioList[] = [
		{
			label: pageAssets?.label_ib,
			value: REQUEST_CONFIGS_KEYS.weight_unit.lb_unit,
			key: 'label_ib'
		},
		{
			label: pageAssets?.label_kg,
			value: REQUEST_CONFIGS_KEYS.weight_unit.kg_unit,
			key: 'label_kg'
		}
	]

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]
	const radioListRenewPermit: RadioList[] = [
		{
			label: pageAssets?.label_renew,
			value: 'true',
			key: 'label_renew'
		},
		{
			label: pageAssets?.label_do_not_renew,
			value: 'false',
			key: 'label_do_not_renew'
		}
	]

	const radioListReplacePermit: RadioList[] = [
		{
			label: pageAssets?.label_lost_medal,
			value: REQUEST_CONFIGS_KEYS.replacePermit.lost,
			key: 'label_lost_medal'
		},
		{
			label: pageAssets?.label_damaged_medal,
			value: REQUEST_CONFIGS_KEYS.replacePermit.damaged,
			key: 'label_damaged_medal'
		}
	]

	const radioListWhatToDo: RadioList[] = [
		{
			label: pageAssets?.request_form_radio_request_new_annual_permit,
			value: REQUEST_CONFIGS_KEYS.whatToDo.request_new_annual_permit,
			key: 'request_form_radio_request_new_annual_permit'
		},
		{
			label: pageAssets?.request_form_radio_renewal_and_annual_permit,
			value: REQUEST_CONFIGS_KEYS.whatToDo.renewal_and_annual_permit,
			key: 'request_form_radio_renewal_and_annual_permit'
		},
		{
			label: pageAssets?.request_form_radio_replace_lost_or_damaged_tag,
			value: REQUEST_CONFIGS_KEYS.whatToDo.replace_lost_or_damaged_tag,
			key: 'request_form_radio_replace_lost_or_damaged_tag'
		}
	]

	const initialAnimalInfo = {
		display_information: true,
		type: '',
		name: '',
		gender: '',
		breed: '',
		cross_breed: false,
		color: '',
		distinctive_sign: '',
		birth_date: '',
		weight: '',
		weight_unit: radioListWeightUnit[0].value,
		birth_place: '',
		is_micro_chipped: '',
		micro_chipped_number: '',
		is_sterilized: '',
		sterilized_date: '',
		is_rabies_vaccinated: '',
		rabies_vaccinated_date: '',
		service_animal: '',
		permit_term: '',
		covered_exception: '',
		is_renewing: radioListRenewPermit[0].value,
		is_replacement: radioListReplacePermit[0].value,
		tag_number: '',
		renewal_duration: '',
		tag_number_unknown: '',
		non_renewal_reason: ''
	}
	const initialAnimalInfoFile: AnimalInformationFile = {
		proof_of_microchipped: [],
		proof_of_microchipped_contract: [],
		proof_of_rabies_vaccination: [],
		proof_of_sterilization: [],
		proof_of_sterilization_exemption: [],
		proof_of_need_of_service_animal: [],
		proof_of_damaged_medal: []
	}

	const initialAnimalRequiredFileAttributes: AnimalRequiredFileAttributes = {
		proof_of_microchipped: false,
		proof_of_microchipped_contract: false,
		proof_of_sterilization: false,
		proof_of_sterilization_exemption: false,
		proof_of_rabies_vaccination: false,
		proof_of_need_of_service_animal: false,
		proof_of_damaged_medal: false
	}

	const [currentAnimalIndex, setCurrentAnimalIndex] = useState<number>(0)

	const [renewPermit, setRenewPermit] = useState<boolean>(
		answers?.whatToDo == Number(radioListWhatToDo[1].value)
	)

	const [replacePermit, setReplacePermit] = useState<boolean>(
		answers?.whatToDo == Number(radioListWhatToDo[2]?.value)
	)

	const [reasonForRenewalList, setReasonForRenewalList] = useState<
		SelectList[]
	>([])

	const [animalInformationList, setAnimalInformationList] = useState<
		AnimalInformation[]
	>(
		answers?.animalInformationList
			? answers.animalInformationList
			: [initialAnimalInfo]
	)

	const [animalInformationFileList, setAnimalInformationFileList] = useState<
		AnimalInformationFile[]
	>(
		!!answers?.animalInformationFileList
			? answers.animalInformationFileList
			: [initialAnimalInfoFile]
	)

	const [animalRequiredFileAttributes, setAnimalRequiredFileAttributes] =
		useState<AnimalRequiredFileAttributes[]>(
			answers?.animalRequiredFileAttributes
				? answers.animalRequiredFileAttributes
				: [initialAnimalRequiredFileAttributes]
		)

	const [animalAttributes, setAnimalAttributes] = useState<string[][]>(
		answers?.animalAttributes
			? answers.animalAttributes
			: answers.whatToDo == Number(radioListWhatToDo[1].value)
			? [requiredAttrRenewPermit]
			: answers.whatToDo == Number(radioListWhatToDo[2].value)
			? [requiredAttrReplacePermit]
			: [requiredAttrNewPermit]
	)

	// a fake RequestInputs to put vehicleInformationList data in
	// value will be encoded as JSON
	const [animalInformationListInput, setAnimalInformationListInput] =
		useState<RequestInputs>({
			name: 'animalInformationList',
			label:
				pageAssets?.request_form_annualPermitAndTagForAnimals_65_years_older,
			labelKey: 'request_form_annualPermitAndTagForAnimals_65_years_older',
			value: JSON.stringify(animalInformationList),
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})
	// a fake RequestInputFiles to put vehicleInformationList data in
	// value will be encoded as JSON
	const [animalInformationFileListInput, setAnimalInformationFileListInput] =
		useState<RequestInputFiles<AnimalInformationFile[]>>({
			name: 'animalInformationFileList',
			label:
				pageAssets?.request_form_annualPermitAndTagForAnimals_identification_document,
			labelKey:
				'request_form_annualPermitAndTagForAnimals_identification_document',
			description: '',
			files: (filesInputs?.find(
				(el) => el.name == 'animalInformationFileList'
			) || [initialAnimalInfoFile]) as AnimalInformationFile[],
			required: false,
			// category: requestCategories.other
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		})

	const [
		animalRequiredFileAttributesInput,
		setAnimalRequiredFileAttributesInput
	] = useState<RequestInputs>({
		name: 'animalRequiredFileAttributes',
		label: '',
		labelKey: '',
		value: JSON.stringify(animalRequiredFileAttributes),
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [animalAttributesInput, setAnimalAttributesInput] =
		useState<RequestInputs>({
			name: 'animalAttributes',
			label: '',
			labelKey: '',
			value: JSON.stringify(animalAttributes),
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const optionalDogAttributes: string[] = [
		// 'is_service_dog',
	]

	const [openRemoveAnimalModal, setOpenRemoveAnimalModal] =
		useState<boolean>(false)
	const [openDetailModal, setOpenDetailModal] = useState<boolean>(false)
	const [modalAnimalInformation, setModalAnimalInformation] =
		useState<AnimalInformation>()

	//----------------------------------------------------------------
	//--------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	// ************* Renseignements sur le gardien ***********

	// Quelle est votre tranche d'âge?
	const [yearsOlderInput, setYearsOlderInput] = useState<RequestInputs>({
		name: 'yearsOlder',
		label: pageAssets?.request_form_annualPermitAndTagForAnimals_65_years_older,
		labelKey: 'request_form_annualPermitAndTagForAnimals_65_years_older',
		value: `${answers.yearsOlder}` || '',
		required: true,
		description:
			pageAssets?.request_form_annualPermitAndTagForAnimals_65_years_older_description,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListAgeGroup: RadioList[] = [
		{
			label: pageAssets?.request_form_65_years_and_over,
			value: REQUEST_CONFIGS_KEYS.yearsOlder.equal_and_over_65_years,
			key: 'request_form_65_years_and_over'
		},
		{
			label: pageAssets?.request_form_between_18_and_64_years_old,
			value: REQUEST_CONFIGS_KEYS.yearsOlder.between_18_and_64_years_old,
			key: 'request_form_between_18_and_64_years_old'
		},
		{
			label: pageAssets?.request_form_under_18,
			value: REQUEST_CONFIGS_KEYS.yearsOlder.under_18_years,
			key: 'request_form_under_18'
		}
	]
	// Nom
	const [otherGuardNameInput, setOtherGuardNameInput] = useState<RequestInputs>(
		{
			name: 'otherGuardName',
			label: pageAssets?.label_fullName,
			labelKey: 'label_fullName',
			value: answers.otherGuardName || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		}
	)
	// Prénom
	const [otherGuardFirstNameInput, setOtherGuardFirstNameInput] =
		useState<RequestInputs>({
			name: 'otherGuardFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.otherGuardFirstName || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})
	// Téléphone
	const [otherGuardPhoneNumberInput, setOtherGuardPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'otherGuardPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.otherGuardPhoneNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})
	// Autre téléphone
	const [otherGuardOtherPhoneNumberInput, setOtherGuardOtherPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'otherGuardOtherPhoneNumber',
			label: pageAssets?.label_other_telephone,
			labelKey: 'label_other_telephone',
			value: answers.otherGuardOtherPhoneNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})
	// Lien avec le gardien
	const [relationshipWithGuardInput, setRelationshipWithGuardInput] =
		useState<RequestInputs>({
			name: 'relationshipWithGuard',
			label:
				pageAssets?.request_form_annualPermitAndTagForAnimals_relationship_with_guard,
			labelKey:
				'request_form_annualPermitAndTagForAnimals_relationship_with_guard',
			value: answers.relationshipWithGuard || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	//----------------------------------------------------------------
	//-------------------- SubStep DETAILS_2 INPUTS ------------------
	//----------------------------------------------------------------

	// ************* Renseignements relatifs aux animaux ***********

	// Que souhaitez-vous faire?
	const [whatToDoInput, setWhatToDoInput] = useState<RequestInputs>({
		name: 'whatToDo',
		label:
			pageAssets?.request_form_annualPermitAndTagForAnimals_what_would_you_like_to_do,
		labelKey:
			'request_form_annualPermitAndTagForAnimals_what_would_you_like_to_do',
		value: `${answers.whatToDo}` || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	// Sexe de l'animal
	const radioListGender: RadioList[] = [
		{
			label: pageAssets?.label_male,
			value: REQUEST_CONFIGS_KEYS.gender.male,
			key: 'label_male'
		},
		{
			label: pageAssets?.label_female,
			value: REQUEST_CONFIGS_KEYS.gender.female,
			key: 'label_female'
		}
	]

	// Dog Race
	const selectListDogRace = pageUtils.createSearchableList(
		pageAssets?.request_form_annualPermitAndTagForAnimals_dog_race_list
	)
	// Cat Race
	const selectListCatRace = pageUtils.createSearchableList(
		pageAssets?.request_form_annualPermitAndTagForAnimals_cat_race_list
	)
	// Color
	const selectListColor = pageUtils.createSearchableList(
		pageAssets?.request_form_annualPermitAndTagForAnimals_animal_color
	)

	// Que souhaitez-vous faire?
	const [specialPermitInput, setSpecialPermitInput] = useState<RequestInputs>({
		name: 'specialPermit',
		label:
			pageAssets?.request_form_annualPermitAndTagForAnimals_special_permit_info,
		labelKey: 'request_form_annualPermitAndTagForAnimals_special_permit_info',
		value:
			whatToDoInput.value == radioListWhatToDo[1].value
				? answers.specialPermit?.toString() || ''
				: '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const radioListSpecialPermit: RadioList[] = [
		{
			label: pageAssets?.label_one_year_of_special_permit,
			value: '1',
			key: 'label_one_year_of_special_permit'
		},
		{
			label: pageAssets?.label_two_years_of_special_permit,
			value: '2',
			key: 'label_two_years_of_special_permit'
		},
		{
			label: pageAssets?.label_three_years_of_special_permit,
			value: '3',
			key: 'label_three_years_of_special_permit'
		},
		{
			label: pageAssets?.label_i_dont_have_special_permit_to_renew,
			value: '0',
			key: 'label_i_dont_have_special_permit_to_renew'
		}
	]

	const [coveredByExceptionInput, setCoveredByExceptionInput] =
		useState<RequestInputs>({
			name: 'coveredByException',
			label:
				pageAssets?.info_form_annualPermitAndTagForAnimals_covered_exception,
			labelKey: 'info_form_annualPermitAndTagForAnimals_covered_exception',
			value: answers.coveredByException?.toString() || 'false',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	//----------------------------------------------------------------
	//-------------------- SubStep DETAILS_2 INPUTS ------------------
	//----------------------------------------------------------------

	// ************* Je déclare... ***********

	const [declarationInput, setDeclarationInput] = useState<RequestInputs>({
		name: 'declaration',
		label: pageAssets?.request_form_i_declare,
		labelKey: 'request_form_i_declare',
		value: '000000, 11111, 22222, 333333, 44444',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [declarationList, setDeclarationList] = useState<CheckboxList[]>()

	const [eligibilityClause1Input, setEligibilityClause1Input] =
		useState<RequestInputs>({
			name: 'eligibilityClause1',
			label:
				pageAssets?.request_form_annualPermitAndTagForAnimals_not_having_been_convicted,
			labelKey:
				'request_form_annualPermitAndTagForAnimals_not_having_been_convicted',
			value: answers.eligibilityClause1?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})
	const [eligibilityClause2Input, setEligibilityClause2Input] =
		useState<RequestInputs>({
			name: 'eligibilityClause2',
			label:
				pageAssets?.request_form_annualPermitAndTagForAnimals_not_having_been_convicted_an_offence,
			labelKey:
				'request_form_annualPermitAndTagForAnimals_not_having_been_convicted_an_offence',
			value: answers.eligibilityClause2?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const [eligibilityClause3Input, setEligibilityClause3Input] =
		useState<RequestInputs>({
			name: 'eligibilityClause3',
			label:
				pageAssets?.request_form_annualPermitAndTagForAnimals_not_been_found_guilty,
			labelKey:
				'request_form_annualPermitAndTagForAnimals_not_been_found_guilty',
			value: answers.eligibilityClause3?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const [eligibilityClause4Input, setEligibilityClause4Input] =
		useState<RequestInputs>({
			name: 'eligibilityClause4',
			label:
				pageAssets?.request_form_annualPermitAndTagForAnimals_that_my_dog_is_not_trained,
			labelKey:
				'request_form_annualPermitAndTagForAnimals_that_my_dog_is_not_trained',
			value: answers.eligibilityClause4?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})
	const [eligibilityClause5Input, setEligibilityClause5Input] =
		useState<RequestInputs>({
			name: 'eligibilityClause5',
			label:
				pageAssets?.request_form_annualPermitAndTagForAnimals_not_having_had_in_my_care,
			labelKey:
				'request_form_annualPermitAndTagForAnimals_not_having_had_in_my_care',
			value: answers.eligibilityClause5?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// Pièce d'identité
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label:
			pageAssets?.request_form_annualPermitAndTagForAnimals_identification_document,
		labelKey:
			'request_form_annualPermitAndTagForAnimals_identification_document',
		description:
			pageAssets?.request_form_annualPermitAndTagForAnimals_identification_document_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		required: false,
		numberFilesRequired: 1,
		numberMaxFiles: 1,
		category: requestCategories.identityDocument,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// ------------------- DETAILS INPUTS SETTERS --------------------

	//------------------------------------------------------------
	const onSetYearsOlderInput = (value: string) => {
		setYearsOlderInput({ ...yearsOlderInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, yearsOlderInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, yearsOlderInput))
	}, [yearsOlderInput])

	//------------------------------------------------------------
	const onSetOtherGuardNameInput = (value: string) => {
		setOtherGuardNameInput({ ...otherGuardNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, otherGuardNameInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, otherGuardNameInput))
	}, [otherGuardNameInput])

	//------------------------------------------------------------
	const onSetOtherGuardFirstNameInput = (value: string) => {
		setOtherGuardFirstNameInput({ ...otherGuardFirstNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, otherGuardFirstNameInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, otherGuardFirstNameInput))
	}, [otherGuardFirstNameInput])

	//------------------------------------------------------------
	const onSetOtherGuardPhoneNumberInput = (value: string) => {
		setOtherGuardPhoneNumberInput({ ...otherGuardPhoneNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, otherGuardPhoneNumberInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, otherGuardPhoneNumberInput))
	}, [otherGuardPhoneNumberInput])

	//------------------------------------------------------------
	const onSetOtherGuardOtherPhoneNumberInput = (value: string) => {
		setOtherGuardOtherPhoneNumberInput({
			...otherGuardOtherPhoneNumberInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				otherGuardOtherPhoneNumberInput.name
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs!, otherGuardOtherPhoneNumberInput)
		)
	}, [otherGuardOtherPhoneNumberInput])

	//------------------------------------------------------------
	const onSetRelationshipWithGuardInput = (value: string) => {
		setRelationshipWithGuardInput({ ...relationshipWithGuardInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, relationshipWithGuardInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, relationshipWithGuardInput))
	}, [relationshipWithGuardInput])

	// ------------------- DETAILS INPUTS SETTERS STEP 2 --------------------
	//------------------------------------------------------------

	const updateAnimalAttribute = (list: string[][]) => {
		setAnimalAttributes(list)

		setAnimalAttributesInput({
			...animalAttributesInput,
			value: JSON.stringify(list)
		})

		setRequiredFieldAttributes && setRequiredFieldAttributes(list)
	}

	const onSetWhatToDoInput = (value: string) => {
		setWhatToDoInput({ ...whatToDoInput, value })

		for (let index = 0; index < animalInformationList.length; index++) {
			onSetAnimalInformationInput('', {
				row: index,
				attribute: 'type'
			})
		}

		onFixError(UpdateRequestInputsErrorsArray(errors!, whatToDoInput.name))
		if (value == radioListWhatToDo[0].value) {
			updateAnimalAttribute([requiredAttrNewPermit])
			updateRequiredFileAttributes([initialAnimalRequiredFileAttributes])

			setRenewPermit(false)
			setReplacePermit(false)
			setSpecialPermitInput({
				...specialPermitInput,
				required: false,
				value: ''
			})
			setSpecialPermitInput({ ...specialPermitInput, required: true })
		} else if (value == radioListWhatToDo[1].value) {
			// renew
			updateAnimalAttribute([requiredAttrRenewPermit])
			updateRequiredFileAttributes([initialAnimalRequiredFileAttributes])

			setRenewPermit(true)
			setReplacePermit(false)
			setSpecialPermitInput({ ...specialPermitInput, required: true })
		} else if (value == radioListWhatToDo[2]?.value) {
			// replace
			updateAnimalAttribute([requiredAttrReplacePermit])
			updateRequiredFileAttributes([initialAnimalRequiredFileAttributes])

			setRenewPermit(false)
			setReplacePermit(true)
			setSpecialPermitInput({
				...specialPermitInput,
				required: false,
				value: ''
			})
		} else {
			setRequiredFieldAttributes && setRequiredFieldAttributes([])

			setRenewPermit(false)
			setReplacePermit(false)
			setSpecialPermitInput({
				...specialPermitInput,
				required: false,
				value: ''
			})
		}

		setAnimalInformationList([initialAnimalInfo])
		setAnimalInformationFileList([initialAnimalInfoFile])
		onFixError([])
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, whatToDoInput))
	}, [whatToDoInput])

	const onSetSpecialPermitInput = (value: string) => {
		if (
			[radioListWhatToDo[0].value, radioListWhatToDo[1].value].includes(
				whatToDoInput.value
			)
		) {
			setSpecialPermitInput({ ...specialPermitInput, value })
		}

		onFixError(UpdateRequestInputsErrorsArray(errors!, specialPermitInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, specialPermitInput))
	}, [specialPermitInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, animalInformationListInput))
	}, [animalInformationListInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs!, animalRequiredFileAttributesInput)
		)
	}, [animalRequiredFileAttributesInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, animalAttributesInput))
	}, [animalAttributesInput])
	//------------------------------------------------------------
	// ------------------- DETAILS INPUTS SETTERS STEP 2 --------------------
	//------------------------------------------------------------
	const onChangeDeclarationInput = (value: string, key: string) => {
		let newValue
		if (value.trim() === '') {
			newValue = value?.trim()
		} else if (declarationInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(value, declarationInput.value)
		} else {
			newValue =
				declarationInput.value == ''
					? value
					: declarationInput.value + `, ${value}`
		}

		setDeclarationInput({
			...declarationInput,
			// value: newValue
			value: '000000, 11111, 22222, 333333, 44444'
		})
		onFixError(UpdateRequestInputsErrorsArray(errors, declarationInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, declarationInput))
	}, [declarationInput])

	//------------------------------------------------------------

	const onSelectCoveredByExceptionInput = (value) => {
		setCoveredByExceptionInput({
			...coveredByExceptionInput,
			value: value ? `${value}` : ''
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, coveredByExceptionInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, coveredByExceptionInput))
	}, [coveredByExceptionInput])
	//------------------------------------------------------------

	const onSelectEligibilityClause1Input = (value) => {
		setEligibilityClause1Input({
			...eligibilityClause1Input,
			value: value ? `${value}` : ''
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, eligibilityClause1Input.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, eligibilityClause1Input))
	}, [eligibilityClause1Input])
	//------------------------------------------------------------

	const onSelectEligibilityClause2Input = (value) => {
		setEligibilityClause2Input({
			...eligibilityClause2Input,
			value: value ? `${value}` : ''
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, eligibilityClause2Input.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, eligibilityClause2Input))
	}, [eligibilityClause2Input])
	//------------------------------------------------------------

	const onSelectEligibilityClause3Input = (value) => {
		setEligibilityClause3Input({
			...eligibilityClause3Input,
			value: value ? `${value}` : ''
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, eligibilityClause3Input.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, eligibilityClause3Input))
	}, [eligibilityClause3Input])
	//------------------------------------------------------------

	const onSelectEligibilityClause4Input = (value) => {
		setEligibilityClause4Input({
			...eligibilityClause4Input,
			value: value ? `${value}` : ''
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, eligibilityClause4Input.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, eligibilityClause4Input))
	}, [eligibilityClause4Input])
	//------------------------------------------------------------

	const onSelectEligibilityClause5Input = (value) => {
		setEligibilityClause5Input({
			...eligibilityClause5Input,
			value: value ? `${value}` : ''
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, eligibilityClause5Input.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, eligibilityClause5Input))
	}, [eligibilityClause5Input])
	//------------------------------------------------------------

	//------------------------------------------------------------

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// DOCUMENTS
	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		setFirstQuestion({
			...firstQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
	}

	// ======================= Register files =================

	useEffect(() => {
		onSetInputFiles<AnimalInformationFile[]>(
			UpdateRequestInputFilesArray<AnimalInformationFile[]>(
				inputFiles,
				animalInformationFileListInput
			)
		)
	}, [animalInformationFileListInput])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	// ==================== checkokbox =================
	const declarations = pageUtils.addLabelToList(
		pageUtils.declarationValues,
		pageAssets
	)

	const updateEligibilityClauses = (required: boolean) => {
		setEligibilityClause2Input({
			...eligibilityClause2Input,
			required: required
		})
		setEligibilityClause3Input({
			...eligibilityClause3Input,
			required: required
		})
		setEligibilityClause4Input({
			...eligibilityClause4Input,
			required: required
		})
		setEligibilityClause5Input({
			...eligibilityClause5Input,
			required: required
		})
	}

	useEffect(() => {
		if (!isAdmissibleClausesRequired(animalInformationList)) {
			updateEligibilityClauses(false)
			setEligibilityClause1Input({
				...eligibilityClause1Input,
				required: false
			})
			setDeclarationList([])

			if (subSteps?.details_3 == true) {
				onSetSubSteps({
					...subSteps!,
					details_3: false,
					steps: [
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					]
				})
			}
		} else {
			if (subSteps?.details_3 == false) {
				onSetSubSteps({
					...subSteps!,
					details_3: true,
					steps: [
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					]
				})
			}

			if (numberOfDogsSelected() < 1) {
				updateEligibilityClauses(false)
				setDeclarationList([declarations[0]])
			} else {
				updateEligibilityClauses(true)
				setDeclarationList(declarations)
			}
		}

		if (animalInformationList.length < 8) {
			setCoveredByExceptionInput({
				...coveredByExceptionInput,
				value: 'false'
			})
		}
	}, [language, animalInformationList])

	async function processFiles() {
		let animalFiles: AnimalInformationFile[] = []

		for (let i = 0; i <= 7; i++) {
			let animalFile = JSON.parse(JSON.stringify(initialAnimalInfoFile))

			if (!!answers[`animalInformation_proof_of_microchipped${i}Input`]) {
				const result = await getFilesFromStore(
					`animalInformation_proof_of_microchipped${i}Input`,
					answers
				)

				animalFile.proof_of_microchipped = [...result]
			}

			if (
				!!answers[`animalInformation_proof_of_microchipped_contract${i}Input`]
			) {
				const result = await getFilesFromStore(
					`animalInformation_proof_of_microchipped_contract${i}Input`,
					answers
				)

				animalFile.proof_of_microchipped_contract = [...result]
			}

			if (!!answers[`animalInformation_proof_of_sterilization${i}Input`]) {
				const result = await getFilesFromStore(
					`animalInformation_proof_of_sterilization${i}Input`,
					answers
				)

				animalFile.proof_of_sterilization = [...result]
			}

			if (
				!!answers[`animalInformation_proof_of_sterilization_exemption${i}Input`]
			) {
				const result = await getFilesFromStore(
					`animalInformation_proof_of_sterilization_exemption${i}Input`,
					answers
				)

				animalFile.proof_of_sterilization_exemption = [...result]
			}

			if (!!answers[`animalInformation_proof_of_rabies_vaccination${i}Input`]) {
				const result = await getFilesFromStore(
					`animalInformation_proof_of_rabies_vaccination${i}Input`,
					answers
				)

				animalFile.proof_of_rabies_vaccination = [...result]
			}

			if (
				!!answers[`animalInformation_proof_of_need_of_service_animal${i}Input`]
			) {
				const result = await getFilesFromStore(
					`animalInformation_proof_of_need_of_service_animal${i}Input`,
					answers
				)

				animalFile.proof_of_need_of_service_animal = [...result]
			}

			if (!!answers[`animalInformation_proof_of_damaged_medal${i}Input`]) {
				const result = await getFilesFromStore(
					`animalInformation_proof_of_damaged_medal${i}Input`,
					answers
				)

				animalFile.proof_of_damaged_medal = [...result]
			}

			// Ajoutez animalFile à animalFiles uniquement si au moins une condition est vraie
			if (
				animalInformationList[i] !== undefined
				// ||
				// animalFile.proof_of_microchipped.length > 0 ||
				// animalFile.proof_of_microchipped_contract.length > 0 ||
				// animalFile.proof_of_sterilization.length > 0 ||
				// animalFile.proof_of_sterilization_exemption.length > 0 ||
				// animalFile.proof_of_rabies_vaccination.length > 0 ||
				// animalFile.proof_of_need_of_service_animal.length > 0
			) {
				animalFiles.push(animalFile)
			}
		}

		if (animalFiles.length == 0) {
			animalFiles = [initialAnimalInfoFile]
		}

		setAnimalInformationFileList(animalFiles)
		setAnimalInformationFileListInput({
			...animalInformationFileListInput,
			files: animalFiles
		})
	}

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		if (!!answers.firstInput && firstQuestion.files.size == 0) {
			getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
				setFirstQuestion({
					...firstQuestion,
					files: result
				})
			})
		}

		processFiles()
	}, [])

	useEffect(() => {
		if (isSummaryStep) {
			processFiles()
		}
	}, [answers])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: !isAdmissibleClausesRequired(animalInformationList)
				? false
				: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: !isAdmissibleClausesRequired(animalInformationList)
				? [
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
				  ]
				: [
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
						REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
				  ]
		})
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getLabelFromList = (value?: string, list?: RadioList[]) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	useEffect(() => {
		if (yearsOlderInput.value === radioListAgeGroup[0].value) {
			setDisableNextButton && setDisableNextButton(true)
			setFirstQuestion({
				...firstQuestion,
				required: true
			})
		} else if (yearsOlderInput.value === radioListAgeGroup[2].value) {
			setDisableNextButton && setDisableNextButton(false)
			setFirstQuestion({
				...firstQuestion,
				required: false,
				files: new Set<File>([])
			})
			onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
		} else {
			setDisableNextButton && setDisableNextButton(true)
			setFirstQuestion({
				...firstQuestion,
				required: false,
				files: new Set<File>([])
			})
			onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
		}
	}, [yearsOlderInput.value])

	useEffect(() => {
		setReasonForRenewalList(
			pageUtils.addLabelToList(
				pageUtils.reasonForRenewalRawList,
				pageAssets
			) as SelectList[]
		)
	}, [language])

	// useEffect(() => {
	// 	// Disable next button if all declarations are not selected
	// 	if (
	// 		subSteps &&
	// 		subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	// 	) {
	// 		const declarationAnswerList = declarationInput.value?.split(', ')
	// 		if (declarationAnswerList.length != declarationList?.length) {
	// 			setDisableNextButton && setDisableNextButton(false)
	// 		} else {
	// 			setDisableNextButton && setDisableNextButton(true)
	// 		}
	// 	} else {
	// 		setDisableNextButton && setDisableNextButton(true)
	// 	}
	// }, [subSteps, declarationInput.value])

	const doesAnimalInformationHasErrors = (row: number): boolean => {
		const listErrors: string[] = []
		const animalInforList =
			row != undefined ? [animalInformationList[row]] : animalInformationList

		animalInforList?.forEach((element) => {
			animalAttributes[row]?.forEach((attr) => {
				if (element[`${attr}`] == '') {
					listErrors.push(`animalInformation_${attr}${row}`)
				}
			})
		})

		animalInformationFileListInput.files?.forEach((element, row) => {
			// animalRequiredFileAttributes?.forEach((attr) => {

			let attr = animalRequiredFileAttributes[row]

			!!attr &&
				Object.keys(attr).forEach((key) => {
					if (attr[key] == true) {
						if (
							element[`${key}`]?.length == 0 ||
							element[`${key}`]?.size == 0
						) {
							listErrors.push(`animalInformation_${key}${row}`)
						}
					}
				})
			// })
		})

		// Verify if it has errors and dispatch an action to set the various error messages
		if (listErrors.length > 0) {
			onFixError(listErrors)
		}

		return listErrors.length > 0
	}

	const addAnimalInformationRow = () => {
		if (doesAnimalInformationHasErrors(animalInformationList.length - 1)) return
		let itemList = JSON.parse(JSON.stringify(animalInformationList))

		let newItem: AnimalInformation = initialAnimalInfo

		itemList = itemList?.map((item: AnimalInformation) => {
			return {
				...item,
				display_information: false
			}
		})

		itemList.push(newItem)

		setAnimalInformationList(itemList)

		setAnimalInformationListInput({
			...animalInformationListInput,
			value: JSON.stringify(itemList)
		})

		// reset required attr
		updateAnimalAttribute([
			...animalAttributes,
			whatToDoInput.value == radioListWhatToDo[1].value
				? requiredAttrRenewPermit
				: whatToDoInput.value == radioListWhatToDo[2]?.value
				? requiredAttrReplacePermit
				: requiredAttrNewPermit
		])

		// add files
		// =================================================================
		let fileList: AnimalInformationFile[] = animalInformationFileList
		let newFileItem: AnimalInformationFile = initialAnimalInfoFile
		fileList.push(newFileItem)
		setAnimalInformationFileList(fileList)

		setAnimalInformationFileListInput({
			...animalInformationFileListInput,
			files: fileList
		})

		// update RequiredFileAttributes
		// let requiredFileAttributes = animalRequiredFileAttributes
		// requiredFileAttributes.push(initialAnimalRequiredFileAttributes)

		updateRequiredFileAttributes([
			...animalRequiredFileAttributes,
			initialAnimalRequiredFileAttributes
		])

		// =================================================================

		onFixError(updateAnimalInformationErrorsArray(itemList))
	}

	const removeAnimalInformationRow = (row: number) => {
		let itemList = [...animalInformationList]

		if (itemList.length <= 1) return
		itemList.splice(row, 1)

		setAnimalInformationList(itemList)

		setAnimalInformationListInput({
			...animalInformationListInput,
			value: JSON.stringify(itemList)
		})

		// Remove file
		// =================================================================
		let fileList = [...animalInformationFileList]
		fileList.splice(row, 1)
		setAnimalInformationFileList(fileList)

		setAnimalInformationFileListInput({
			...animalInformationFileListInput,
			files: fileList
		})
		// =================================================================

		// update RequiredFileAttributes
		let requiredFileAttributes = [...animalRequiredFileAttributes]

		requiredFileAttributes.splice(row, 1)

		updateRequiredFileAttributes(requiredFileAttributes)

		// =================================================================

		// update RequiredAttributes
		let requiredAttributes = [...animalAttributes]

		requiredAttributes.splice(row, 1)

		updateAnimalAttribute(requiredAttributes)

		// =================================================================

		let itemListLength = itemList.length

		onFixError(
			updateAnimalInformationRowErrorsArray([
				`animalInformation_name${row}`,
				`animalInformation_gender${row}`,
				`animalInformation_breed${row}`,
				`animalInformation_color${row}`,
				`animalInformation_birth_date${row}`,
				`animalInformation_birth_place${row}`,
				`animalInformation_is_micro_chipped${row}`,
				`animalInformation_micro_chipped_number${row}`,
				`animalInformation_is_sterilized${row}`,
				`animalInformation_sterilized_date${row}`,
				`animalInformation_is_rabies_vaccinated${row}`,
				`animalInformation_rabies_vaccinated_date${row}`,
				`animalInformation_service_animal${row}`,
				`animalInformation_permit_term${row}`,
				`animalInformation_renewal_duration${row}`,
				`animalInformation_tag_number${row}`,
				`animalInformation_non_renewal_reason${row}`,
				// files
				`proof_of_microchipped${row}`,
				`proof_of_microchipped_contract${row}`,
				`proof_of_sterilization${row}`,
				`proof_of_sterilization_exemption${row}`,
				`proof_of_rabies_vaccination${row}`,
				`proof_of_need_of_service_animal${row}`,
				`proof_of_damaged_medal${row}`
			])
		)
	}

	const updateAnimalInformationRowErrorsArray = (
		inputNameList: string[]
	): string[] => {
		return errors.filter((err) => !inputNameList.includes(err))
	}

	const onSelectFileInput = (
		files: Set<File>,
		currentInput: { row: number; attribute: string }
	) => {
		const { row, attribute } = currentInput
		let fileList: AnimalInformationFile[] = animalInformationFileList

		fileList[row][`${attribute}`] = files

		setAnimalInformationFileList(fileList)

		setAnimalInformationFileListInput({
			...animalInformationFileListInput,
			files: fileList
		})

		onFixError(updateAnimalInformationErrorsArray(fileList))
	}

	const updateRequiredFileAttributes = (attributes) => {
		setAnimalRequiredFileAttributes(attributes)

		setAnimalRequiredFileAttributesInput({
			...animalRequiredFileAttributesInput,
			value: JSON.stringify(attributes)
		})

		setRequiredFileAttributes && setRequiredFileAttributes(attributes)
	}

	const onSetAnimalInformationInput = (
		value: string | boolean,
		currentInput: { row: number; attribute: string }
	) => {
		const { row, attribute } = currentInput
		// Do not accept negative number for animal weight
		if (attribute === 'weight' && Number(value) < 0) {
			return
		}

		let itemList = JSON.parse(JSON.stringify(animalInformationList))
		itemList[row][`${attribute}`] = value

		let requiredFileAttributes = JSON.parse(
			JSON.stringify(animalRequiredFileAttributes)
		)

		let requiredAttributes = JSON.parse(JSON.stringify(animalAttributes))

		if (attribute == 'is_micro_chipped') {
			if (value == radioListYesNo[0].value) {
				requiredAttributes[row] = [
					...new Set([...requiredAttributes[row], 'micro_chipped_number'])
				]
				updateAnimalAttribute(requiredAttributes)

				// requiredFileAttributes[row]['proof_of_microchipped'] = true
				// requiredFileAttributes[row]['proof_of_microchipped_contract'] = false

				// updateRequiredFileAttributes(requiredFileAttributes)
			} else {
				fixAnimalAttribute(requiredAttributes, 'micro_chipped_number', row)

				// requiredFileAttributes[row]['proof_of_microchipped'] = false
				// requiredFileAttributes[row]['proof_of_microchipped_contract'] = true

				// updateRequiredFileAttributes(requiredFileAttributes)
			}
		} else if (attribute == 'is_sterilized') {
			if (value == radioListYesNo[0].value) {
				requiredAttributes[row] = [
					...new Set([...requiredAttributes[row], 'sterilized_date'])
				]
				updateAnimalAttribute(requiredAttributes)

				// requiredFileAttributes[row]['proof_of_sterilization'] = true
				// requiredFileAttributes[row]['proof_of_sterilization_exemption'] = false

				// updateRequiredFileAttributes(requiredFileAttributes)
			} else {
				fixAnimalAttribute(requiredAttributes, 'sterilized_date', row)

				// requiredFileAttributes[row]['proof_of_sterilization'] = false

				// if (
				// 	!!itemList[row].birth_date &&
				// 	!isWithinSixMonths(itemList[row].birth_date)
				// ) {
				// 	requiredFileAttributes[row]['proof_of_sterilization_exemption'] = true
				// } else {
				// 	requiredFileAttributes[row]['proof_of_sterilization_exemption'] =
				// 		false
				// }

				// updateRequiredFileAttributes(requiredFileAttributes)
			}
		} else if (attribute == 'birth_date') {
			// if (itemList[row]?.is_sterilized == radioListYesNo[0].value) {
			// 	requiredFileAttributes[row]['proof_of_sterilization_exemption'] = false
			// 	updateRequiredFileAttributes(requiredFileAttributes)
			// }
			// if (itemList[row]?.is_sterilized == radioListYesNo[1].value) {
			// 	if (!!value && !isWithinSixMonths(String(value))) {
			// 		requiredFileAttributes[row]['proof_of_sterilization_exemption'] = true
			// 	} else {
			// 		requiredFileAttributes[row]['proof_of_sterilization_exemption'] =
			// 			false
			// 	}
			// 	updateRequiredFileAttributes(requiredFileAttributes)
			// }
		} else if (attribute == 'is_rabies_vaccinated') {
			if (value == radioListYesNo[0].value) {
				requiredAttributes[row] = [
					...new Set([...requiredAttributes[row], 'rabies_vaccinated_date'])
				]
				updateAnimalAttribute(requiredAttributes)

				// requiredFileAttributes[row]['proof_of_rabies_vaccination'] = true

				// updateRequiredFileAttributes(requiredFileAttributes)
			} else {
				fixAnimalAttribute(requiredAttributes, 'rabies_vaccinated_date', row)

				// requiredFileAttributes[row]['proof_of_rabies_vaccination'] = false

				// updateRequiredFileAttributes(requiredFileAttributes)
			}
		} else if (attribute == 'type') {
			// if (value == AnimalType.Dog) {
			// 	// Remove cat optional attributes if any
			// 	updateAnimalAttribute([
			// 		...new Set([...animalAttributes, ...optionalDogAttributes])
			// 	])
			// } else {
			// 	// Remove dog optional attributes first
			// 	const uniqueElements = animalAttributes.filter(
			// 		(element) => !optionalDogAttributes.includes(element)
			// 	)
			// 	updateAnimalAttribute([...new Set([...uniqueElements])])
			// }

			// Reset the race
			itemList[row]['breed'] = ''
		} else if (attribute == 'service_animal') {
			if (whatToDoInput.value == radioListWhatToDo[0].value) {
				if (value == radioListYesNo[0].value) {
					requiredFileAttributes[row]['proof_of_need_of_service_animal'] = true

					updateRequiredFileAttributes(requiredFileAttributes)
				} else {
					requiredFileAttributes[row]['proof_of_need_of_service_animal'] = false

					updateRequiredFileAttributes(requiredFileAttributes)
				}
			}
		} else if (attribute == 'breed') {
			// Ensures that the value is always in french
			if (itemList[row][`type`] == AnimalType.Dog) {
				const animalIndex = selectListDogRace.findIndex(
					(animal) => animal.value.trim() == value
				)
				const list = pageUtils.createSearchableList(
					tempAllText![LanguageEnum.FR]['assets'][
						'request_form_annualPermitAndTagForAnimals_dog_race_list'
					]
				)

				itemList[row][`${attribute}`] = list[animalIndex]?.value ?? ''
			} else {
				const animalIndex = selectListCatRace.findIndex(
					(animal) => animal.value.trim() == value
				)
				const list = pageUtils.createSearchableList(
					tempAllText![LanguageEnum.FR]['assets'][
						'request_form_annualPermitAndTagForAnimals_cat_race_list'
					]
				)

				itemList[row][`${attribute}`] = list[animalIndex]?.value ?? ''
			}
		} else if (attribute == 'tag_number_unknown') {
			// reset the medal number
			itemList[row][`tag_number`] = ''
			if (value == true) {
				fixAnimalAttribute(requiredAttributes, 'tag_number', row)
			} else {
				requiredAttributes[row] = [
					...new Set([...requiredAttributes[row], 'tag_number'])
				]
				updateAnimalAttribute(requiredAttributes)
			}
		} else if (attribute == 'tag_number') {
			// reset Je ne connais pas le numéro de médaille
			// itemList[row][`tag_number_unknown`] = 'false'
		} else if (attribute == 'is_renewing') {
			// renew
			// if (whatToDoInput.value == radioListWhatToDo[1].value) {
			if (value == radioListRenewPermit[0].value) {
				requiredAttributes[row] = [
					...new Set([
						...requiredAttributes[row],
						'service_animal',
						'permit_term'
					])
				]
				updateAnimalAttribute(requiredAttributes)

				fixAnimalAttribute(requiredAttributes, 'non_renewal_reason', row)
			} else {
				requiredAttributes[row] = [
					...new Set([...requiredAttributes[row], 'non_renewal_reason'])
				]
				updateAnimalAttribute(requiredAttributes)

				fixAnimalAttribute(requiredAttributes, 'service_animal', row)
				fixAnimalAttribute(requiredAttributes, 'permit_term', row)
			}
			// }

			// // replace
			// if (whatToDoInput.value == radioListWhatToDo[2].value) {
			// 	if (value == radioListRenewPermit[1].value) {
			// 		itemList[row][`tag_number_unknown`] = 'false'
			// 		requiredAttributes[row] = [
			// 			...new Set([...requiredAttributes[row], 'tag_number'])
			// 		]
			// 		updateAnimalAttribute(requiredAttributes)

			// 		requiredFileAttributes[row]['proof_of_damaged_medal'] = true

			// 		updateRequiredFileAttributes(requiredFileAttributes)
			// 	} else {
			// 		requiredFileAttributes[row]['proof_of_damaged_medal'] = false

			// 		updateRequiredFileAttributes(requiredFileAttributes)
			// 	}
			// }
		} else if (attribute == 'is_replacement') {
			if (value == radioListReplacePermit[1].value) {
				itemList[row][`tag_number_unknown`] = 'false'
				requiredAttributes[row] = [
					...new Set([...requiredAttributes[row], 'tag_number'])
				]
				updateAnimalAttribute(requiredAttributes)

				requiredFileAttributes[row]['proof_of_damaged_medal'] = true

				updateRequiredFileAttributes(requiredFileAttributes)
			} else {
				requiredFileAttributes[row]['proof_of_damaged_medal'] = false

				updateRequiredFileAttributes(requiredFileAttributes)
			}
		}

		setAnimalInformationList(itemList)

		setAnimalInformationListInput({
			...animalInformationListInput,
			value: JSON.stringify(itemList)
		})

		onFixError(updateAnimalInformationErrorsArray(itemList))
	}

	const fixAnimalAttribute = (
		requiredAttributes,
		attribute: string,
		row: number
	) => {
		requiredAttributes[row] = requiredAttributes[row].filter(
			(element) => element != attribute
		)

		updateAnimalAttribute(requiredAttributes)

		onFixError(
			updateAnimalInformationRowErrorsArray([
				`animalInformation_${attribute}${row}`
			])
		)
	}

	const editAnimalInformation = (row: number, value: boolean) => {
		// changes the display_information value to `true or false` to collapse section or not

		let itemList = JSON.parse(JSON.stringify(animalInformationList))

		let fileList = [...animalInformationFileList]

		itemList?.forEach((item, index) => {
			if (index == row) {
				// return {
				// 	...item,
				// 	display_information: value
				// }
				itemList[row].display_information = value

				if (item.is_micro_chipped == 'true') {
					fileList[row].proof_of_microchipped_contract = []
				} else if (item.is_micro_chipped == 'false') {
					fileList[row].proof_of_microchipped = []
					itemList[row].micro_chipped_number = ''
				}

				if (item.is_sterilized == 'true') {
					fileList[row].proof_of_sterilization_exemption = []
				} else if (item.is_sterilized == 'false') {
					fileList[row].proof_of_sterilization = []
					itemList[row].sterilized_date = ''
				}

				if (item.is_rabies_vaccinated == 'false') {
					fileList[row].proof_of_rabies_vaccination = []
					itemList[row].rabies_vaccinated_date = ''
				}

				if (item.service_animal == 'false') {
					fileList[row].proof_of_need_of_service_animal = []
				}

				if (item.is_replacement == radioListReplacePermit[0].value) {
					fileList[row].proof_of_damaged_medal = []
				}
			}
			return item
		})

		setAnimalInformationList(itemList)

		setAnimalInformationListInput({
			...animalInformationListInput,
			value: JSON.stringify(itemList)
		})

		setAnimalInformationFileList(fileList)

		setAnimalInformationFileListInput({
			...animalInformationFileListInput,
			files: fileList
		})
	}

	const closeEditedSection = (row: number, value: boolean) => {
		// validates current section before closing
		if (doesAnimalInformationHasErrors(row)) return
		editAnimalInformation(row, value)
	}

	// @Todo Fct to refactor
	const updateAnimalInformationErrorsArray = (
		itemList: AnimalInformation[] | AnimalInformationFile[]
	): string[] => {
		let errorArray: string[] = []

		errors?.forEach((error) => {
			if (error.includes('animalInformation')) {
				itemList.forEach((animalInformation, row) => {
					if (
						animalInformation.name == '' &&
						error == `animalInformation_name${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.gender == '' &&
						error == `animalInformation_gender${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.breed == '' &&
						error == `animalInformation_breed${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.color == '' &&
						error == `animalInformation_color${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.birth_date == '' &&
						error == `animalInformation_birth_date${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.birth_place == '' &&
						error == `animalInformation_birth_place${row}`
					) {
						errorArray.push(error)
					}

					if (
						animalInformation.is_micro_chipped == '' &&
						error == `animalInformation_is_micro_chipped${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.is_micro_chipped == radioListYesNo[0].value &&
						animalInformation.micro_chipped_number == '' &&
						error == `animalInformation_micro_chipped_number${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.is_sterilized == '' &&
						error == `animalInformation_is_sterilized${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.is_sterilized == radioListYesNo[0].value &&
						animalInformation.sterilized_date == '' &&
						error == `animalInformation_sterilized_date${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.is_rabies_vaccinated == '' &&
						error == `animalInformation_is_rabies_vaccinated${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.is_rabies_vaccinated == radioListYesNo[0].value &&
						animalInformation.rabies_vaccinated_date == '' &&
						error == `animalInformation_rabies_vaccinated_date${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.service_animal == '' &&
						error == `animalInformation_service_animal${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.permit_term == '' &&
						error == `animalInformation_permit_term${row}`
					) {
						errorArray.push(error)
					}
					// Files
					if (
						animalInformation.proof_of_microchipped == '' &&
						error == `animalInformation_proof_of_microchipped${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.proof_of_microchipped_contract == '' &&
						error == `animalInformation_proof_of_microchipped_contract${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.proof_of_sterilization == '' &&
						error == `animalInformation_proof_of_sterilization${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.proof_of_sterilization_exemption == '' &&
						error == `animalInformation_proof_of_sterilization_exemption${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.proof_of_rabies_vaccination == '' &&
						error == `animalInformation_proof_of_rabies_vaccination${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.proof_of_need_of_service_animal == '' &&
						error == `animalInformation_proof_of_need_of_service_animal${row}`
					) {
						errorArray.push(error)
					}
					if (
						animalInformation.proof_of_damaged_medal == '' &&
						error == `animalInformation_proof_of_damaged_medal${row}`
					) {
						errorArray.push(error)
					}
				})
			} else {
				errorArray.push(error)
			}
		})

		return errorArray
	}
	const animalNumber = {
		0: pageAssets?.request_form_annualPermitAndTagForAnimals_first_animal,
		1: pageAssets?.request_form_annualPermitAndTagForAnimals_second_animal,
		2: pageAssets?.request_form_annualPermitAndTagForAnimals_third_animal,
		3: pageAssets?.request_form_annualPermitAndTagForAnimals_fouth_animal,
		4: pageAssets?.request_form_annualPermitAndTagForAnimals_fifth_animal,
		5: pageAssets?.request_form_annualPermitAndTagForAnimals_sixth_animal,
		6: pageAssets?.request_form_annualPermitAndTagForAnimals_seventh_animal,
		7: pageAssets?.request_form_annualPermitAndTagForAnimals_eighth_animal
	}

	const isMoreThanFourDogs = (): boolean => {
		const sum = animalInformationList.reduce((accumulator, animal) => {
			if (animal.type == AnimalType.Dog) {
				return accumulator + 1
			}
			return accumulator
		}, 0)
		return sum > 3
	}

	const isAdmissibleClausesRequired = (animalsList): boolean => {
		let status = false

		if (whatToDoInput.value == radioListWhatToDo[2]?.value) {
			return false
		}

		animalsList?.forEach((animal) => {
			if (animal.is_renewing !== radioListRenewPermit[1].value) {
				status = true
			}
		})

		return status
	}

	const numberOfDogsSelected = (): number => {
		const numberOfDogs = animalInformationList?.reduce(
			(accumulator, animal) => {
				if (
					animal.type == AnimalType.Dog &&
					animal.is_renewing !== radioListRenewPermit[1].value
				) {
					return accumulator + 1
				}
				return accumulator
			},
			0
		)

		return numberOfDogs
	}

	const raceList = {
		[AnimalType.Dog]: selectListDogRace,
		[AnimalType.Cat]: selectListCatRace
	}

	const getSelectLabel = (options, value) => {
		return options.find((option) => option.value === value)
	}

	const shouldAddAnimal = (): boolean => {
		const lastAnimal = animalInformationList[animalInformationList.length - 1]
		if (String(lastAnimal.type) != '') {
			return true
		}
		return false
	}

	const isWithinSixMonths = (dob: string): boolean => {
		const currentDate = moment()
		const birthDate = moment(dob)
		const diffInMonths = currentDate.diff(birthDate, 'months')

		return diffInMonths < 6
	}
	const getDeclarations = (answer: string) => {
		const list: string[] = []
		const declarations: string[] = answer?.split(',')
		declarationList?.map((declaration) => {
			declarations?.forEach((value) => {
				if (declaration.value == value.trim()) {
					list.push(declaration.key)
				}
			})
		})
		return list
	}

	const getAnimalSubTitle = (
		animalInformation: AnimalInformation,
		whatToDoValue: string,
		isRenewal: boolean,
		isDamagedMedal: boolean
	) => {
		if (whatToDoValue == radioListWhatToDo[0].value) {
			return (
				<>
					{
						getSelectLabel(
							raceList[animalInformation.type],
							animalInformation.breed
						)?.label
					}{' '}
					{animalInformation.color && (
						<>
							, &nbsp;
							{getSelectLabel(selectListColor, animalInformation.color)?.label}
						</>
					)}
				</>
			)
		} else if (whatToDoValue == radioListWhatToDo[1].value) {
			return (
				<>
					{isRenewal
						? pageAssets?.label_renew
								?.replace('le permis', '')
								.replace('permit', '')
						: pageAssets?.label_do_not_renew}{' '}
					- {pageAssets?.label_medal}:&nbsp;
					{animalInformation?.tag_number?.trim() != ''
						? animalInformation?.tag_number
						: pageAssets?.label_unknown}
				</>
			)
		} else if (whatToDoValue == radioListWhatToDo[2]?.value) {
			return (
				<>
					{isDamagedMedal
						? pageAssets?.label_damaged_medal
						: pageAssets?.label_lost_medal}{' '}
					- {pageAssets?.label_medal}:&nbsp;
					{animalInformation?.tag_number?.trim() != ''
						? animalInformation?.tag_number
						: pageAssets?.label_unknown}
				</>
			)
		}
	}

	const durationOfPermitList = (
		animalType: string,
		ageGroupValue: string,
		isAssistanceAnimal?: boolean
	) => {
		// set the price for an animal based on the age group
		let price = isAssistanceAnimal
			? 0
			: ageGroupValue == radioListAgeGroup[1].value
			? 10
			: 0
		// set multiplier by 1 for cat an 2 for dog
		let multipier = animalType == AnimalType.Cat ? 1 : 2

		const unitPrice = price * multipier

		const radioListDurationOfPermit: RadioList[] = [
			{
				label: pageAssets?.label_one_year_dog?.replace(
					'$',
					`${unitPrice * 1}$`
				),
				value: '1',
				key: 'label_one_year_dog'
			},
			{
				label: pageAssets?.label_two_years_dog?.replace(
					'$',
					`${unitPrice * 2}$`
				),
				value: '2',
				key: 'label_two_years_dog'
			},
			{
				label: pageAssets?.label_three_years_dog?.replace(
					'$',
					`${unitPrice * 3}$`
				),
				value: '3',
				key: 'label_three_years_dog'
			}
		]

		return radioListDurationOfPermit
	}

	const durationOfSpecialPermitList = (ageGroupValue: string) => {
		// set the price for an animal based on the age group
		let price = ageGroupValue == radioListAgeGroup[1].value ? 50 : 0

		const radioListDurationOfPermit: RadioList[] = [
			{
				label: pageAssets?.label_one_year_dog?.replace('$', `${price * 1}$`),
				value: '1',
				key: 'label_one_year_of_special_permit'
			},
			{
				label: pageAssets?.label_two_years_dog?.replace('$', `${price * 2}$`),
				value: '2',
				key: 'label_two_years_of_special_permit'
			},
			{
				label: pageAssets?.label_three_years_dog?.replace('$', `${price * 3}$`),
				value: '3',
				key: 'label_three_years_of_special_permit'
			},
			{
				label: pageAssets?.label_i_dont_have_special_permit_to_renew,
				value: '0',
				key: 'label_i_dont_have_special_permit_to_renew'
			}
		]

		return radioListDurationOfPermit
	}

	const durationOfPermitLabel = (
		animalType: string,
		ageGroupValue: string,
		isAssistanceAnimal?: boolean,
		durationValue?: string
	): string | undefined => {
		return durationOfPermitList(
			animalType,
			ageGroupValue,
			isAssistanceAnimal
		).find((permit) => permit.value === durationValue)?.label
	}

	const renderAnimalInformation = (
		animalInformation: AnimalInformation,
		row: number
	) => {
		const displayInforAboutWeight = (
			weigth?: string,
			unit?: string
		): boolean => {
			if (String(animalInformation.type) == AnimalType.Cat) return false

			if (!weigth || !unit) return false
			const weigthInNumber = parseInt(weigth)
			// weigth in kg
			if (weigthInNumber >= 20 && unit == radioListWeightUnit[1].value)
				return true
			// Weigth in ib
			else if (weigthInNumber >= 44 && unit == radioListWeightUnit[0].value)
				return true
			return false
		}
		// const shouldDisplayDogLogo = (): boolean => {
		// 	return true
		// }

		// Find the index of the last occurrence of a dog
		const getLastDogIndex = animalInformationList?.reduce(
			(acc, animal, index) => {
				if (animal.type == AnimalType.Dog) {
					return index
				}
				return acc
			},
			-1
		)

		const isDogSelected = animalInformation.type == AnimalType.Dog
		const isCatSelected = animalInformation.type == AnimalType.Cat
		const isRenewal =
			animalInformation.is_renewing == radioListRenewPermit[0].value
		const isDamagedMedal =
			animalInformation.is_replacement == radioListReplacePermit[1].value

		return (
			<div key={`animal-${row}`}>
				{/* Animal number title */}
				<div className={classes.headerContainer}>
					<h5 className={pageUtils.classes.h5}>
						{<Text content={animalNumber[row]} />}
					</h5>
					{row > 0 && animalInformation.display_information && (
						<a
							style={{ margin: '20px 0px 12px' }}
							onClick={() => removeAnimalInformationRow(row)}
							className={joinClasses([
								classes.button,
								classes.outlinedButton,
								classes.btnPadding
							])}
						>
							{pageAssets?.label_remove_this_animal}
						</a>
					)}
				</div>
				{/* Display notification if animal is more than 4 dogs */}
				<div
					style={{
						maxHeight:
							numberOfDogsSelected() == 4 &&
							row == getLastDogIndex + 1 &&
							!replacePermit
								? '400px'
								: '0'
					}}
					className={classes.collapseWrapper}
				>
					<Notification
						type="warning"
						text={pageAssets?.info_a_maximum_of_4_dogs}
					/>
				</div>

				{/* Animal grey Card */}
				{!animalInformation.display_information && (
					<div className={joinClasses([classes.card, classes.animalHover])}>
						<div className={classes.animalWrapper}>
							<img
								src={isDogSelected ? dogIcon : catIcon}
								alt="animal"
								className={classes.imageIcon}
							/>
							<div>
								<strong>{animalInformation.name}</strong>
								<p style={{ marginTop: '5px' }}>
									{getAnimalSubTitle(
										animalInformation,
										whatToDoInput.value,
										isRenewal,
										isDamagedMedal
									)}
								</p>
							</div>
						</div>

						<div className={classes.buttonWrapper}>
							<>
								<button
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline,
										classes.btnPadding
									])}
									onClick={() => {
										editAnimalInformation(row, true)
									}}
									type="button"
								>
									{formatStrapiText(pageAssets?.label_edit_informations)}
								</button>

								{animalInformationList.length > 1 && (
									<button
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											classes.btnPadding
										])}
										onClick={() => setOpenRemoveAnimalModal(true)}
										type="button"
									>
										{formatStrapiText(pageAssets?.label_remove)}
									</button>
								)}
							</>
						</div>
					</div>
				)}
				{/* Animal form fields + border + Animal card logos */}
				{animalInformation.display_information && (
					<div className={classes.blocContainer}>
						{/* Animal tiles */}
						<div className={classes.col6}>
							<div className={classes.animalGrid}>
								{(numberOfDogsSelected() < 4 ||
									isDogSelected ||
									replacePermit) && (
									<div
										className={joinClasses([
											classes.animal,
											classes.animalHover,
											isDogSelected ? classes.selectedAnimal : '',
											errors!.includes(`animalInformation_type${row}`)
												? classes.cardError
												: ''
										])}
										onClick={(e) =>
											onSetAnimalInformationInput(AnimalType.Dog, {
												row,
												attribute: 'type'
											})
										}
									>
										<Icon
											src={checkBlueIcon}
											className={joinClasses([
												classes.icon,
												!isDogSelected ? classes.iconVissiblity : ''
											])}
										/>

										<div>
											<img src={dogIcon} alt="dog" style={{ width: '56px' }} />
											<p>
												{replacePermit
													? pageAssets?.label_dog
													: pageAssets?.request_form_annualPermitAndTagForAnimals_dog}
											</p>
										</div>
									</div>
								)}

								<div
									className={joinClasses([
										classes.animal,
										classes.animalHover,
										isCatSelected ? classes.selectedAnimal : '',
										errors!.includes(`animalInformation_type${row}`)
											? classes.cardError
											: ''
									])}
									onClick={(e) =>
										onSetAnimalInformationInput(AnimalType.Cat, {
											row,
											attribute: 'type'
										})
									}
								>
									<Icon
										src={checkBlueIcon}
										className={joinClasses([
											classes.icon,
											!isCatSelected ? classes.iconVissiblity : ''
										])}
									/>
									<div>
										<img src={catIcon} alt="cat" style={{ width: '56px' }} />
										<p>
											{replacePermit
												? pageAssets?.label_cat
												: pageAssets?.request_form_annualPermitAndTagForAnimals_chat}
										</p>
									</div>
								</div>
							</div>
						</div>

						{errors!.includes(`animalInformation_type${row}`) && (
							<div style={{ marginTop: '1rem' }}>
								<Notification
									type="error"
									text={
										pageAssets?.request_form_annualPermitAndTagForAnimals_type_error
									}
								/>
							</div>
						)}

						<div
							style={{
								// display: !!animalInformation.type ? 'block' : 'none'
								maxHeight: !!animalInformation.type ? '3000px' : '0'
							}}
							className={classes.collapseWrapper}
						>
							<div style={{ marginTop: '40px' }}></div>

							{/* Renouveler / Ne pas renouveler  || Remplacer */}
							{renewPermit && (
								<div className={pageUtils.classes.fieldsContainer}>
									<RadioInput
										name={`animalInformation_is_renewing${row}`}
										value={animalInformation.is_renewing ?? ''}
										list={radioListRenewPermit}
										direction={Direction.horizontal}
										hasError={errors!.includes(
											`animalInformation_is_renewing${row}`
										)}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'is_renewing'
											})
										}
										spanContainer
									/>
								</div>
							)}

							{replacePermit && (
								<div className={pageUtils.classes.fieldsContainer}>
									<RadioInput
										name={`animalInformation_is_replacement${row}`}
										value={animalInformation.is_replacement ?? ''}
										list={radioListReplacePermit}
										direction={Direction.horizontal}
										hasError={errors!.includes(
											`animalInformation_is_replacement${row}`
										)}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'is_replacement'
											})
										}
										spanContainer
									/>
								</div>
							)}

							<div
								className={
									!renewPermit && !replacePermit
										? pageUtils.classes.fieldsContainer
										: ''
								}
								style={{ padding: '0px 1px' }}
							>
								<TextInput
									id={`animalInformation_name${row}`}
									label={pageAssets?.label_name_of_animal}
									value={animalInformation.name}
									onChange={(e) =>
										onSetAnimalInformationInput(e, {
											row,
											attribute: 'name'
										})
									}
									hasError={errors?.includes(`animalInformation_name${row}`)}
									required
									maxCharacters={30}
								/>
								{!renewPermit && !replacePermit && (
									<>
										<RadioInput
											name={`animalInformation_gender${row}`}
											label={pageAssets?.label_sex_of_animal}
											value={animalInformation.gender ?? ''}
											list={radioListGender}
											direction={Direction.horizontal}
											hasError={errors!.includes(
												`animalInformation_gender${row}`
											)}
											onChange={(e) =>
												onSetAnimalInformationInput(e, {
													row,
													attribute: 'gender'
												})
											}
											required
											hasBackgroundWrapper={true}
											spanContainer
										/>
										<div
											className={joinClasses([
												isDogSelected ? classes.gridDisplay : '',
												classes.selectDropdown
											])}
										>
											<SearchableSelectInput
												id={`animalInformation_breed${row}`}
												label={pageAssets?.label_race}
												value={animalInformation.breed || ''}
												required
												hasError={errors.includes(
													`animalInformation_breed${row}`
												)}
												onChange={(e) =>
													onSetAnimalInformationInput(e, {
														row,
														attribute: 'breed'
													})
												}
												options={raceList[animalInformation.type]}
											/>
											{isDogSelected && (
												<>
													<div
														className={joinClasses([
															classes.checkbox2Container,
															classes.reduceMargin
														])}
													>
														<span
															className={classes.checkboxWrapper}
															style={{
																marginBottom: 0,
																padding: '12.5px 12px'
															}}
														>
															<input
																onClick={(e: any) =>
																	onSetAnimalInformationInput(
																		e.target.checked,
																		{
																			row,
																			attribute: 'cross_breed'
																		}
																	)
																}
																value={`${animalInformation.cross_breed}`}
																className={classes.checkbox2}
																type="checkbox"
																name={`animalInformation_cross${row}`}
																id={`animalInformation_cross${row}`}
															/>
															<label
																className={classes.checkbox2Label}
																htmlFor={`animalInformation_cross${row}`}
															>
																{
																	pageAssets?.request_form_annualPermitAndTagForAnimals_crossed
																}
															</label>
														</span>
													</div>
												</>
											)}
										</div>
										<div className={classes.selectDropdown}>
											<SearchableSelectInput
												id={`animalInformation_color${row}`}
												label={
													pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationColor_label
												}
												value={animalInformation.color ?? ''}
												hasError={errors.includes(
													`animalInformation_color${row}`
												)}
												onChange={(e) =>
													onSetAnimalInformationInput(e, {
														row,
														attribute: 'color'
													})
												}
												options={selectListColor}
												required
											/>
										</div>
										<TextInput
											id={`animalInformation_distinctive_sign${row}`}
											label={
												pageAssets?.request_form_annualPermitAndTagForAnimals_distinctive_sign
											}
											description={
												pageAssets?.request_form_annualPermitAndTagForAnimals_distinctive_sign_description
											}
											value={animalInformation.distinctive_sign ?? ''}
											onChange={(e) =>
												onSetAnimalInformationInput(e, {
													row,
													attribute: 'distinctive_sign'
												})
											}
											hasError={errors?.includes(
												`animalInformation_distinctive_sign${row}`
											)}
											maxCharacters={100}
										/>
										<DateFilterInput
											id={`animalInformation_birth_date${row}`}
											label={pageAssets?.label_date_of_birth}
											value={animalInformation.birth_date ?? ''}
											onChange={(e) =>
												onSetAnimalInformationInput(e, {
													row,
													attribute: 'birth_date'
												})
											}
											description={
												pageAssets?.request_form_annualPermitAndTagForAnimals_dob_description
											}
											hasError={errors.includes(
												`animalInformation_birth_date${row}`
											)}
											classWrapper={classes.inputContainer}
											maxDate={new Date()}
											required
											dateFormat="dd/MM/yyyy"
											shouldDisplayCustomHeader
										/>
										<div className={classes.subContent}>
											<TextInput
												id={`animalInformation_weight${row}`}
												type="number"
												label={
													pageAssets?.request_form_annualPermitAndTagForAnimals_weight
												}
												value={animalInformation.weight ?? ''}
												onChange={(e) =>
													onSetAnimalInformationInput(e, {
														row,
														attribute: 'weight'
													})
												}
												hasError={errors?.includes(
													`animalInformation_weight${row}`
												)}
												customErrorText={
													animalInformation.weight !== ''
														? 'Veuillez entrer un nombre valide.'
														: undefined
												}
												onKeyPress={pageUtils.onKeyPressNumberInput}
											/>
											<RadioInput
												name={`animalInformation_weight_unit${row}`}
												label={''}
												value={animalInformation.weight_unit ?? ''}
												list={radioListWeightUnit}
												direction={Direction.horizontal}
												hasError={errors!.includes(
													`animalInformation_weight_unit${row}`
												)}
												onChange={(e) =>
													onSetAnimalInformationInput(e, {
														row,
														attribute: 'weight_unit'
													})
												}
												hasBackgroundWrapper={true}
											/>
										</div>
										{displayInforAboutWeight(
											animalInformation.weight,
											animalInformation.weight_unit
										) && (
											<div className={classes.notificationShowOnSmallScreen}>
												<Notification
													type="info"
													text={
														pageAssets?.request_form_annualPermitAndTagForAnimals_weight_description
													}
												/>
											</div>
										)}
										<TextInput
											id={`animalInformation_birth_place${row}`}
											label={
												pageAssets?.request_form_annualPermitAndTagForAnimals_place_of_adoption
											}
											value={animalInformation.birth_place ?? ''}
											onChange={(e) =>
												onSetAnimalInformationInput(e, {
													row,
													attribute: 'birth_place'
												})
											}
											hasError={errors?.includes(
												`animalInformation_birth_place${row}`
											)}
											// required
											maxCharacters={100}
										/>
									</>
								)}
							</div>

							{!renewPermit &&
								!replacePermit &&
								displayInforAboutWeight(
									animalInformation.weight,
									animalInformation.weight_unit
								) && (
									<div className={classes.notificationShowOnLargeScreen}>
										<Notification
											type="info"
											text={
												pageAssets?.request_form_annualPermitAndTagForAnimals_weight_description
											}
										/>
									</div>
								)}

							{!renewPermit && !replacePermit && (
								<>
									<RadioInput
										name={`animalInformation_is_micro_chipped${row}`}
										label={
											pageAssets?.request_form_annualPermitAndTagForAnimals_is_the_animal_microchipped
										}
										value={animalInformation.is_micro_chipped ?? ''}
										list={radioListYesNo}
										direction={Direction.vertical}
										hasError={errors!.includes(
											`animalInformation_is_micro_chipped${row}`
										)}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'is_micro_chipped'
											})
										}
										hasBackgroundWrapper={true}
										required
									/>
									{
										<div
											style={{
												maxHeight:
													animalInformation.is_micro_chipped ==
													radioListYesNo[0].value
														? '600px'
														: '0'
											}}
											className={classes.collapseWrapper}
										>
											<TextInput
												id={`animalInformation_micro_chipped_number${row}`}
												label={
													pageAssets?.request_form_annualPermitAndTagForAnimals_number_of_micropuce
												}
												value={animalInformation.micro_chipped_number ?? ''}
												description={
													pageAssets?.request_form_annualPermitAndTagForAnimals_number_of_micropuce_description
												}
												onChange={(e) =>
													onSetAnimalInformationInput(e, {
														row,
														attribute: 'micro_chipped_number'
													})
												}
												hasError={errors?.includes(
													`animalInformation_micro_chipped_number${row}`
												)}
												required
												maxCharacters={100}
											/>
											<RequestFiles
												subtitle={
													pageAssets?.request_form_annualPermitAndTagForAnimals_proof_of_microchipped
												}
												name={'proof_of_microchipped'}
												files={
													new Set<File>(
														animalInformationFileList[
															row
														]?.proof_of_microchipped
													)
												}
												onSetFiles={(e) =>
													onSelectFileInput(e, {
														row,
														attribute: 'proof_of_microchipped'
													})
												}
												hasError={errors.includes(
													`animalInformation_proof_of_microchipped${row}`
												)}
												onSetHasError={onSetErrorFromFileComponent}
												subSteps={subSteps}
												minFiles={1}
												maxFiles={1}
												removeSectionMarginTop
												removePadding
												isNewDesign
											>
												<></>
											</RequestFiles>
											<div style={{ marginBottom: '20px' }}></div>
										</div>
									}
									{
										<div
											style={{
												// display:
												// 	animalInformation.is_micro_chipped ==
												// 	radioListYesNo[1].value
												// 		? 'block'
												// 		: 'none',
												maxHeight:
													animalInformation.is_micro_chipped ==
													radioListYesNo[1].value
														? '500px'
														: '0'
											}}
											className={classes.collapseWrapper}
										>
											<RequestFiles
												subtitle={
													pageAssets?.request_form_annualPermitAndTagForAnimals_proof_of_microchipped_contract
												}
												name={'proof_of_microchipped_contract'}
												files={
													new Set<File>(
														animalInformationFileList[
															row
														]?.proof_of_microchipped_contract
													)
												}
												onSetFiles={(e) =>
													onSelectFileInput(e, {
														row,
														attribute: 'proof_of_microchipped_contract'
													})
												}
												hasError={errors.includes(
													`animalInformation_proof_of_microchipped_contract${row}`
												)}
												onSetHasError={onSetErrorFromFileComponent}
												subSteps={subSteps}
												minFiles={1}
												maxFiles={1}
												removeSectionMarginTop
												removePadding
												isNewDesign
											>
												<></>
											</RequestFiles>
											<div style={{ marginBottom: '20px' }}></div>
										</div>
									}

									<RadioInput
										name={`animalInformation_is_sterilized${row}`}
										label={
											pageAssets?.request_form_annualPermitAndTagForAnimals_is_the_animal_sterilized
										}
										value={animalInformation.is_sterilized ?? ''}
										list={radioListYesNo}
										direction={Direction.vertical}
										hasError={errors!.includes(
											`animalInformation_is_sterilized${row}`
										)}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'is_sterilized'
											})
										}
										hasBackgroundWrapper={true}
										required
									/>
									{
										<div
											style={{
												maxHeight:
													animalInformation.is_sterilized ==
													radioListYesNo[0].value
														? '600px'
														: '0'
											}}
											className={classes.collapseWrapper}
										>
											<div className={pageUtils.classes.fieldsContainer}>
												<DateFilterInput
													id={`animalInformation_sterilized_date${row}`}
													label={
														pageAssets?.request_form_annualPermitAndTagForAnimals_date_sterilized
													}
													value={animalInformation.sterilized_date ?? ''}
													onChange={(e) =>
														onSetAnimalInformationInput(e, {
															row,
															attribute: 'sterilized_date'
														})
													}
													description={
														pageAssets?.request_form_annualPermitAndTagForAnimals_is_the_animal_vaccinated_description
													}
													hasError={errors.includes(
														`animalInformation_sterilized_date${row}`
													)}
													classWrapper={classes.inputContainer}
													required
													dateFormat="dd/MM/yyyy"
													maxDate={new Date()}
													minDate={
														new Date(animalInformation.birth_date + 'T12:00:00')
													}
													shouldDisplayCustomHeader
												/>
											</div>
											<RequestFiles
												subtitle={
													pageAssets?.request_form_annualPermitAndTagForAnimals_proof_of_sterilization
												}
												name={'proof_of_sterilization'}
												files={
													new Set<File>(
														animalInformationFileList[
															row
														]?.proof_of_sterilization
													)
												}
												onSetFiles={(e) =>
													onSelectFileInput(e, {
														row,
														attribute: 'proof_of_sterilization'
													})
												}
												hasError={errors.includes(
													`animalInformation_proof_of_sterilization${row}`
												)}
												onSetHasError={onSetErrorFromFileComponent}
												subSteps={subSteps}
												minFiles={1}
												maxFiles={1}
												removeSectionMarginTop
												removePadding
												isNewDesign
											>
												<></>
											</RequestFiles>
											<div style={{ marginBottom: '20px' }}></div>
										</div>
									}
									{
										<div
											style={{
												maxHeight:
													animalInformation.is_sterilized ==
														radioListYesNo[1].value &&
													!!animalInformation.birth_date &&
													!isWithinSixMonths(animalInformation.birth_date)
														? '500px'
														: '0'
											}}
											className={classes.collapseWrapper}
										>
											<RequestFiles
												subtitle={
													pageAssets?.request_form_annualPermitAndTagForAnimals_proof_of_sterilization_exemption
												}
												name={'proof_of_sterilization_exemption'}
												files={
													new Set<File>(
														animalInformationFileList[
															row
														]?.proof_of_sterilization_exemption
													)
												}
												onSetFiles={(e) =>
													onSelectFileInput(e, {
														row,
														attribute: 'proof_of_sterilization_exemption'
													})
												}
												hasError={errors.includes(
													`animalInformation_proof_of_sterilization_exemption${row}`
												)}
												onSetHasError={onSetErrorFromFileComponent}
												subSteps={subSteps}
												minFiles={1}
												maxFiles={1}
												removeSectionMarginTop
												removePadding
												isNewDesign
											>
												<></>
											</RequestFiles>
											<div style={{ marginBottom: '20px' }}></div>
										</div>
									}
									<RadioInput
										name={`animalInformation_is_rabies_vaccinated${row}`}
										label={
											pageAssets?.request_form_annualPermitAndTagForAnimals_is_the_animal_vaccinated
										}
										value={animalInformation.is_rabies_vaccinated ?? ''}
										list={radioListYesNo}
										// description={
										// 	pageAssets?.request_form_annualPermitAndTagForAnimals_is_the_animal_vaccinated_description
										// }
										direction={Direction.vertical}
										hasError={errors!.includes(
											`animalInformation_is_rabies_vaccinated${row}`
										)}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'is_rabies_vaccinated'
											})
										}
										hasBackgroundWrapper={true}
										required
									/>
									{
										<div
											style={{
												maxHeight:
													animalInformation.is_rabies_vaccinated ==
													radioListYesNo[0].value
														? '600px'
														: '0'
											}}
											className={classes.collapseWrapper}
										>
											<div className={pageUtils.classes.fieldsContainer}>
												<DateFilterInput
													id={`animalInformation_rabies_vaccinated_date${row}`}
													label={
														pageAssets?.request_form_annualPermitAndTagForAnimals_date_of_vaccination
													}
													value={animalInformation.rabies_vaccinated_date ?? ''}
													onChange={(e) =>
														onSetAnimalInformationInput(e, {
															row,
															attribute: 'rabies_vaccinated_date'
														})
													}
													description={
														pageAssets?.request_form_annualPermitAndTagForAnimals_is_the_animal_vaccinated_description
													}
													hasError={errors.includes(
														`animalInformation_rabies_vaccinated_date${row}`
													)}
													classWrapper={classes.inputContainer}
													maxDate={new Date()}
													minDate={
														new Date(animalInformation.birth_date + 'T12:00:00')
													}
													required
													dateFormat="dd/MM/yyyy"
													shouldDisplayCustomHeader
												/>
											</div>
											<RequestFiles
												subtitle={
													pageAssets?.request_form_annualPermitAndTagForAnimals_proof_of_rabies_vaccination
												}
												name={'proof_of_rabies_vaccination'}
												files={
													new Set<File>(
														animalInformationFileList[
															row
														]?.proof_of_rabies_vaccination
													)
												}
												onSetFiles={(e) =>
													onSelectFileInput(e, {
														row,
														attribute: 'proof_of_rabies_vaccination'
													})
												}
												hasError={errors.includes(
													`animalInformation_proof_of_rabies_vaccination${row}`
												)}
												onSetHasError={onSetErrorFromFileComponent}
												subSteps={subSteps}
												minFiles={1}
												maxFiles={1}
												removeSectionMarginTop
												removePadding
												isNewDesign
											>
												<></>
											</RequestFiles>
											<div style={{ marginBottom: '20px' }}></div>
										</div>
									}
								</>
							)}
							{(renewPermit || replacePermit) && (
								<>
									<TextInput
										id={`animalInformation_tag_number${row}`}
										label={
											pageAssets?.request_form_annualPermitAndTagForAnimals_medal_number
										}
										value={animalInformation.tag_number ?? ''}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'tag_number'
											})
										}
										hasError={errors?.includes(
											`animalInformation_tag_number${row}`
										)}
										required={animalAttributes[row]?.includes(`tag_number`)}
										maxCharacters={20}
										disabled={!animalAttributes[row]?.includes(`tag_number`)}
									/>
								</>
							)}
							{((!renewPermit && !replacePermit) ||
								(renewPermit && isRenewal)) && (
								<>
									<RadioInput
										name={`animalInformation_service_animal${row}`}
										label={
											isDogSelected
												? pageAssets?.request_form_annualPermitAndTagForAnimals_is_service_dog
												: pageAssets?.request_form_annualPermitAndTagForAnimals_is_service_cat
										}
										value={animalInformation.service_animal ?? ''}
										list={radioListYesNo}
										direction={Direction.vertical}
										description={
											isDogSelected
												? pageAssets?.request_form_annualPermitAndTagForAnimals_is_service_dog_description
												: pageAssets?.request_form_annualPermitAndTagForAnimals_is_service_cat_description
										}
										hasError={errors!.includes(
											`animalInformation_service_animal${row}`
										)}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'service_animal'
											})
										}
										hasBackgroundWrapper={true}
										required={animalAttributes[row]?.includes(`service_animal`)}
									/>

									<div
										style={{
											maxHeight:
												animalInformation.service_animal ==
												radioListYesNo[0].value
													? '600px'
													: '0'
										}}
										className={classes.collapseWrapper}
									>
										<RequestFiles
											subtitle={
												pageAssets?.request_form_annualPermitAndTagForAnimals_proof_of_need_of_service_animal
											}
											name={'proof_of_need_of_service_animal'}
											files={
												new Set<File>(
													animalInformationFileList[
														row
													]?.proof_of_need_of_service_animal
												)
											}
											onSetFiles={(e) =>
												onSelectFileInput(e, {
													row,
													attribute: 'proof_of_need_of_service_animal'
												})
											}
											hasError={errors.includes(
												`animalInformation_proof_of_need_of_service_animal${row}`
											)}
											onSetHasError={onSetErrorFromFileComponent}
											subSteps={subSteps}
											minFiles={1}
											maxFiles={1}
											removeSectionMarginTop
											removePadding
											isNewDesign
											required={
												animalRequiredFileAttributes[row]
													.proof_of_need_of_service_animal
											}
										>
											<></>
										</RequestFiles>
										<div style={{ marginBottom: '20px' }}></div>
									</div>
								</>
							)}
							{renewPermit && isRenewal && (
								<>
									<RadioInput
										name={`animalInformation_permit_term${row}`}
										label={
											pageAssets?.request_form_annualPermitAndTagForAnimals_renewal_duration
										}
										value={animalInformation.permit_term ?? ''}
										list={durationOfPermitList(
											animalInformation.type,
											yearsOlderInput.value,
											animalInformation.service_animal ==
												radioListYesNo[0].value
										)}
										direction={Direction.vertical}
										hasError={errors!.includes(
											`animalInformation_permit_term${row}`
										)}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'permit_term'
											})
										}
										hasBackgroundWrapper={true}
										required
									/>
								</>
							)}
							{((renewPermit && !isRenewal) ||
								(replacePermit && !isDamagedMedal)) && (
								<>
									<div className={joinClasses([classes.checkbox2Container])}>
										<span
											className={classes.checkboxWrapper}
											style={{
												marginBottom: 0,
												padding: '12.5px 12px'
											}}
										>
											<input
												onClick={(e: any) =>
													onSetAnimalInformationInput(e.target.checked, {
														row,
														attribute: 'tag_number_unknown'
													})
												}
												value={`${animalInformation.tag_number_unknown}`}
												className={classes.checkbox2}
												type="checkbox"
												name={`animalInformation_tag_number_unknown${row}`}
												id={`animalInformation_tag_number_unknown${row}`}
											/>
											<label
												className={classes.checkbox2Label}
												htmlFor={`animalInformation_tag_number_unknown${row}`}
											>
												{
													pageAssets?.request_form_annualPermitAndTagForAnimals_I_dont_know_the_medal_number
												}
											</label>
										</span>
									</div>
									{renewPermit && (
										<SelectInput
											id={`animalInformation_non_renewal_reason${row}`}
											label={
												pageAssets?.request_form_annualPermitAndTagForAnimals_reason_for_non_renewal
											}
											value={animalInformation.non_renewal_reason ?? ''}
											list={reasonForRenewalList}
											onChange={(e) =>
												onSetAnimalInformationInput(e, {
													row,
													attribute: 'non_renewal_reason'
												})
											}
											hasError={errors.includes(
												`animalInformation_non_renewal_reason${row}`
											)}
											required
										/>
									)}
								</>
							)}
							{replacePermit && isDamagedMedal && (
								<div
									style={{
										maxHeight: '600px'
									}}
									className={classes.collapseWrapper}
								>
									<RequestFiles
										subtitle={
											pageAssets?.request_form_annualPermitAndTagForAnimals_proof_of_damaged_medal
										}
										name={'proof_of_damaged_medal'}
										files={
											new Set<File>(
												animalInformationFileList[row]?.proof_of_damaged_medal
											)
										}
										onSetFiles={(e) =>
											onSelectFileInput(e, {
												row,
												attribute: 'proof_of_damaged_medal'
											})
										}
										hasError={errors.includes(
											`animalInformation_proof_of_damaged_medal${row}`
										)}
										onSetHasError={onSetErrorFromFileComponent}
										subSteps={subSteps}
										minFiles={1}
										maxFiles={1}
										removeSectionMarginTop
										removePadding
										isNewDesign
										required={
											animalRequiredFileAttributes[row].proof_of_damaged_medal
										}
									>
										<></>
									</RequestFiles>
									<div style={{ marginBottom: '20px' }}></div>
								</div>
							)}
							{!renewPermit && !replacePermit && (
								<>
									<RadioInput
										name={`animalInformation_permit_term${row}`}
										label={
											pageAssets?.request_form_annualPermitAndTagForAnimals_duration_of_permit
										}
										value={animalInformation.permit_term ?? ''}
										list={durationOfPermitList(
											animalInformation.type,
											yearsOlderInput.value,
											animalInformation.service_animal ==
												radioListYesNo[0].value
										)}
										direction={Direction.vertical}
										hasError={errors!.includes(
											`animalInformation_permit_term${row}`
										)}
										onChange={(e) =>
											onSetAnimalInformationInput(e, {
												row,
												attribute: 'permit_term'
											})
										}
										hasBackgroundWrapper={true}
										required
									/>
								</>
							)}
							<a
								style={{ margin: '10px 0px 12px' }}
								onClick={() => closeEditedSection(row, false)}
								className={joinClasses([classes.button, classes.btnPadding])}
							>
								{pageAssets?.button_complete}
							</a>
						</div>
					</div>
				)}

				<div className={classes.modalWrapper}>
					<Modal
						cancelButtonText={pageAssets?.button_cancel}
						primaryButtonText={pageAssets?.label_remove}
						isOpen={openRemoveAnimalModal}
						onCancelBtn={() => {
							setOpenRemoveAnimalModal(false)
						}}
						onPrimaryButton={() => {
							removeAnimalInformationRow(row)
							setOpenRemoveAnimalModal(false)
						}}
						onSetIsOpen={() => {}}
						onCloseModal={() => setOpenRemoveAnimalModal(false)}
						alignBtn={'left'}
						title={pageAssets?.label_remove_animal}
						showExitBtn
					>
						<div>{pageAssets?.label_remove_animal_message}</div>
					</Modal>
				</div>
			</div>
		)
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_guardian_information}
							</h4>
						</div>

						<div>
							<strong>
								{
									pageAssets?.request_form_annualPermitAndTagForAnimals_65_years_older
								}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									String(answers?.yearsOlder),
									radioListAgeGroup
								)}
							</span>
						</div>

						<div>
							{!!firstQuestion.files.size && (
								<RequestFiles
									subtitle={firstQuestion.label}
									name={firstQuestion.name}
									required={firstQuestion.required}
									files={firstQuestion.files}
									hasError={errors.includes(firstQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFirstQuestion}
									maxFiles={firstQuestion.numberFilesRequired}
									isSummary
								>
									<></>
								</RequestFiles>
							)}
						</div>
						{(answers?.otherGuardFirstName ||
							answers?.otherGuardFirstName ||
							answers?.otherGuardPhoneNumber ||
							answers?.otherGuardOtherPhoneNumber ||
							answers?.relationshipWithGuard) && (
							<h5 className={pageUtils.classes.h5}>
								{
									pageAssets?.request_form_persons_to_contact_in_case_of_emergency
								}
							</h5>
						)}

						{answers?.otherGuardFirstName && (
							<div>
								<strong>{pageAssets[otherGuardNameInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.otherGuardName}
								</span>
							</div>
						)}
						{answers?.otherGuardFirstName && (
							<div>
								<strong>{pageAssets[otherGuardFirstNameInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.otherGuardFirstName}
								</span>
							</div>
						)}
						{answers?.otherGuardPhoneNumber && (
							<div>
								<strong>
									{pageAssets[otherGuardPhoneNumberInput.labelKey]}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.otherGuardPhoneNumber}
								</span>
							</div>
						)}
						{answers?.otherGuardOtherPhoneNumber && (
							<div>
								<strong>
									{pageAssets[otherGuardOtherPhoneNumberInput.labelKey]}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.otherGuardOtherPhoneNumber}
								</span>
							</div>
						)}

						{answers?.relationshipWithGuard && (
							<div>
								<strong>
									{pageAssets[relationshipWithGuardInput.labelKey]}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.relationshipWithGuard}
								</span>
							</div>
						)}
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.label_information_about_the_animals}
							</h4>
						</div>
						<div>
							<strong>{pageAssets[whatToDoInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(String(answers?.whatToDo), radioListWhatToDo)}
							</span>
						</div>

						{answers?.animalInformationList?.map((animalInformation, row) => (
							<>
								<div className={classes.headerContainer}>
									<h5 className={pageUtils.classes.h5}>
										{<Text content={animalNumber[row]} />}
									</h5>
								</div>
								<div className={classes.card}>
									<div className={classes.animalWrapper}>
										<img
											src={
												animalInformation.type == AnimalType.Dog
													? dogIcon
													: catIcon
											}
											alt="animal"
											className={classes.imageIcon}
										/>
										<div>
											<strong>{animalInformation.name}</strong>
											<p style={{ marginTop: '5px' }}>
												{getAnimalSubTitle(
													animalInformation,
													String(answers.whatToDo) ??
														radioListWhatToDo[0].value,
													animalInformation?.is_renewing?.toString() ==
														radioListRenewPermit[0].value,
													animalInformation?.is_replacement?.toString() ==
														radioListReplacePermit[1].value
												)}
											</p>
										</div>
									</div>

									<div className={classes.buttonWrapper}>
										<>
											<button
												className={joinClasses([
													pageUtils.classes.btn,
													pageUtils.classes.button,
													pageUtils.classes.buttonOutline,
													classes.btnPadding
												])}
												onClick={() => {
													setModalAnimalInformation(animalInformation)
													setOpenDetailModal(true)
													setCurrentAnimalIndex(row)
												}}
												type="button"
											>
												{formatStrapiText(pageAssets?.label_see_details)}
											</button>
										</>
									</div>
								</div>
							</>
						))}

						{answers.coveredByException?.toString() == 'true' &&
							answers?.animalInformationList?.length == 8 && (
								<div>
									<strong>
										{
											pageAssets?.info_form_annualPermitAndTagForAnimals_covered_exception_title
										}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{
											pageAssets?.info_form_annualPermitAndTagForAnimals_covered_exception
										}
									</span>
								</div>
							)}

						{answers.whatToDo == Number(radioListWhatToDo[1].value) && (
							<div>
								<strong>{pageAssets?.label_special_permit}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{getLabelFromList(
										answers?.specialPermit,
										durationOfSpecialPermitList(
											answers.yearsOlder?.toString() ||
												radioListAgeGroup[1].value
										)
									)}
								</span>
							</div>
						)}

						<AnimalInforamtionModal
							isModalOpen={openDetailModal}
							animalInformation={modalAnimalInformation}
							primaryButtonText={pageAssets?.button_complete}
							close={() => {
								setOpenDetailModal(false)
							}}
							onPrimaryButton={() => {
								setOpenDetailModal(false)
							}}
							dogRaceList={selectListDogRace}
							catRaceList={selectListCatRace}
							colorList={selectListColor}
							permitDurationLabel={durationOfPermitLabel(
								modalAnimalInformation?.type ?? '',
								String(answers?.yearsOlder) ?? '',
								modalAnimalInformation?.service_animal ==
									radioListYesNo[0].value,
								modalAnimalInformation?.permit_term ||
									modalAnimalInformation?.renewal_duration
							)}
							weigthUnitList={radioListWeightUnit}
							files={animalInformationFileList[currentAnimalIndex]}
							permitTypeList={radioListWhatToDo}
							typeOfPermit={`${answers.whatToDo}`}
							reasonForRenewalList={reasonForRenewalList}
						/>
					</>
				)}

				{subSteps?.details_3 &&
					isAdmissibleClausesRequired(answers?.animalInformationList) && (
						<>
							<div style={{ marginTop: '40px' }}>
								<a
									style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline
									])}
									onClick={() =>
										backToForm(
											steps?.form!,
											REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
										)
									}
								>
									{pageAssets?.label_modify}
								</a>
								<h4 className={pageUtils.classes.h4}>
									{pageAssets?.request_form_eligibility_clauses}
								</h4>
							</div>
							<h5 className={pageUtils.classes.h5}>
								{`${pageAssets?.request_form_i_declare}...`}
							</h5>
							{answers?.declaration && (
								<ul className={classes.declarationList}>
									{getDeclarations(answers?.declaration)?.map(
										(declaration, index) => (
											<li key={`${declaration}${index}`}>
												<Text content={pageAssets[declaration]} />
											</li>
										)
									)}
								</ul>
							)}
						</>
					)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_guardian_information}
						</h4>
						<RadioInput
							name={yearsOlderInput.name}
							label={yearsOlderInput.label}
							value={yearsOlderInput.value}
							description={yearsOlderInput.description}
							list={radioListAgeGroup}
							direction={Direction.vertical}
							hasError={errors!.includes(yearsOlderInput.name)}
							onChange={onSetYearsOlderInput}
							ref={yearsOlderInput.ref as RefObject<HTMLInputElement>}
							required={yearsOlderInput.required}
							hasBackgroundWrapper={true}
						/>

						<Collapse
							isOpened={yearsOlderInput.value === radioListAgeGroup[0].value}
						>
							<RequestFiles
								subtitle={firstQuestion.label}
								name={firstQuestion.name}
								required
								files={firstQuestion.files}
								hasError={errors.includes(firstQuestion.name)}
								removeSectionMarginTop
								removePadding
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileFirstQuestion}
								subSteps={subSteps}
								minFiles={firstQuestion.numberFilesRequired}
								maxFiles={firstQuestion.numberMaxFiles}
								isNewDesign
							>
								<div className={classes.description}>
									<Text content={firstQuestion.description} />
								</div>
							</RequestFiles>
						</Collapse>
						<Collapse
							isOpened={yearsOlderInput.value === radioListAgeGroup[2].value}
						>
							<Notification
								type="error"
								text={
									pageAssets?.request_form_annualPermitAndTagForAnimals_individuals_under_the_age
								}
							/>
						</Collapse>

						<h5
							className={joinClasses([
								pageUtils.classes.h5,
								classes.reducedH5Margin
							])}
						>
							{pageAssets?.request_form_persons_to_contact_in_case_of_emergency}
						</h5>

						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={otherGuardNameInput.name}
								label={otherGuardNameInput.label}
								value={otherGuardNameInput.value}
								onChange={onSetOtherGuardNameInput}
								hasError={errors?.includes(otherGuardNameInput.name)}
								required={otherGuardNameInput.required}
								ref={otherGuardNameInput.ref as RefObject<HTMLInputElement>}
								maxCharacters={100}
							/>
							<TextInput
								id={otherGuardFirstNameInput.name}
								label={otherGuardFirstNameInput.label}
								value={otherGuardFirstNameInput.value}
								onChange={onSetOtherGuardFirstNameInput}
								hasError={errors?.includes(otherGuardFirstNameInput.name)}
								required={otherGuardFirstNameInput.required}
								ref={
									otherGuardFirstNameInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={100}
							/>
							<MaskedTextInput
								id={otherGuardPhoneNumberInput.name}
								label={otherGuardPhoneNumberInput.label}
								value={otherGuardPhoneNumberInput.value}
								onChange={onSetOtherGuardPhoneNumberInput}
								hasError={errors?.includes(otherGuardPhoneNumberInput.name)}
								type="tel"
								min={10}
								max={14}
								mask={formUtils.MASKS.phoneNumber}
								placeholder={formUtils.MASKS.phoneNumber}
								errors={errors ? errors : []}
								setError={onFixError}
								schema={PHONE_NUMBER_REGEX}
								required={otherGuardPhoneNumberInput.required}
								ref={
									otherGuardPhoneNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>
							<MaskedTextInput
								id={otherGuardOtherPhoneNumberInput.name}
								label={otherGuardOtherPhoneNumberInput.label}
								value={otherGuardOtherPhoneNumberInput.value}
								onChange={onSetOtherGuardOtherPhoneNumberInput}
								hasError={errors?.includes(
									otherGuardOtherPhoneNumberInput.name
								)}
								type="tel"
								min={10}
								max={14}
								mask={formUtils.MASKS.phoneNumber}
								placeholder={formUtils.MASKS.phoneNumber}
								errors={errors ? errors : []}
								setError={onFixError}
								schema={PHONE_NUMBER_REGEX}
								required={otherGuardOtherPhoneNumberInput.required}
								ref={
									otherGuardOtherPhoneNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>
							<TextInput
								id={relationshipWithGuardInput.name}
								label={relationshipWithGuardInput.label}
								value={relationshipWithGuardInput.value}
								onChange={onSetRelationshipWithGuardInput}
								hasError={errors?.includes(relationshipWithGuardInput.name)}
								required={relationshipWithGuardInput.required}
								ref={
									relationshipWithGuardInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={100}
							/>
						</div>
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<div
					style={{
						display:
							subSteps !== undefined &&
							subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
								? 'block'
								: 'none'
					}}
					className={classes.collapseAutoHeight}
				>
					{/* <Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				> */}
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.label_information_about_the_animals}
						</h4>

						<RadioInput
							name={whatToDoInput.name}
							label={whatToDoInput.label}
							value={whatToDoInput.value}
							list={radioListWhatToDo}
							direction={Direction.vertical}
							hasError={errors!.includes(whatToDoInput.name)}
							onChange={onSetWhatToDoInput}
							ref={whatToDoInput.ref as RefObject<HTMLInputElement>}
							required={whatToDoInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* {whatToDoInput.value == radioListWhatToDo[1].value && (
							<Notification
								type="info"
								text={
									pageAssets?.request_form_annualPermitAndTagForAnimals_noReplacement_notification
								}
							/>
						)} */}

						{(whatToDoInput.value == radioListWhatToDo[0].value ||
							whatToDoInput.value == radioListWhatToDo[1].value ||
							whatToDoInput.value == radioListWhatToDo[2]?.value) && (
							<>
								{/* /////////////////  Animal Information List  //////////////// */}
								{animalInformationList?.map((animalInformation, index) => {
									return renderAnimalInformation(animalInformation, index)
								})}

								{/* /////////////////  covered exception //////////////// */}
								{animalInformationList?.length == 8 && (
									<div
										style={{
											maxHeight: '600px'
										}}
										className={classes.collapseWrapper}
									>
										<div style={{ marginTop: '20px' }}></div>
										<Notification
											type="warning"
											text={
												pageAssets?.info_form_annualPermitAndTagForAnimals_you_have_reach_max
											}
											hasHtml
										/>
										<>
											<div className={classes.checkbox2Container}>
												<span
													className={classes.checkboxWrapper}
													style={{
														marginBottom: 0,
														padding: '12.5px 12px'
													}}
												>
													<input
														onClick={(e: any) =>
															onSelectCoveredByExceptionInput(e.target.checked)
														}
														value={`${coveredByExceptionInput.value}`}
														className={classes.checkbox2}
														type="checkbox"
														name={`animalInformation_covered_exception`}
														id={`animalInformation_covered_exception`}
													/>
													<label
														className={classes.checkbox2Label}
														htmlFor={`animalInformation_covered_exception`}
													>
														{
															pageAssets?.info_form_annualPermitAndTagForAnimals_covered_exception
														}
													</label>
												</span>
											</div>
										</>
									</div>
								)}

								{animalInformationList?.length == 2 &&
									whatToDoInput.value == radioListWhatToDo[2].value &&
									shouldAddAnimal() && (
										<>
											<div style={{ marginTop: '30px' }}></div>
											<Notification
												type="warning"
												text={
													pageAssets?.info_form_annualPermitAndTagForAnimals_you_have_reach_max_replacement
												}
												hasHtml
											/>
										</>
									)}

								{/* /////////////////  Add Button  //////////////// */}
								{shouldAddAnimal() &&
								(([
									radioListWhatToDo[0].value,
									radioListWhatToDo[1].value
								].includes(whatToDoInput.value) &&
									animalInformationList?.length < 8) ||
									(whatToDoInput.value == radioListWhatToDo[2].value &&
										animalInformationList?.length < 2)) ? (
									<a
										style={{ margin: '20px 0px 12px' }}
										onClick={() => addAnimalInformationRow()}
										className={joinClasses([
											classes.button,
											classes.btnPadding
										])}
									>
										{pageAssets?.label_add_animal}
									</a>
								) : (
									''
								)}
							</>
						)}

						<div
							style={{
								maxHeight: [
									radioListWhatToDo[0].value,
									radioListWhatToDo[1].value
								].includes(whatToDoInput.value)
									? '600px'
									: '0'
							}}
							className={classes.collapseWrapper}
						>
							<h5 className={pageUtils.classes.h5}>
								{pageAssets?.label_special_permit}
							</h5>
							<RadioInput
								name={specialPermitInput.name}
								label={specialPermitInput.label}
								value={specialPermitInput.value}
								list={durationOfSpecialPermitList(yearsOlderInput.value)}
								direction={Direction.vertical}
								hasError={errors!.includes(specialPermitInput.name)}
								onChange={onSetSpecialPermitInput}
								ref={specialPermitInput.ref as RefObject<HTMLInputElement>}
								required={specialPermitInput.required}
								hasBackgroundWrapper={true}
							/>
						</div>
					</section>
					{/* </Collapse> */}
				</div>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_eligibility_clauses}
						</h4>
						<h5 className={pageUtils.classes.h5}>
							{`${pageAssets?.request_form_i_declare}...`}
						</h5>

						{/* <CheckboxInput
							name={declarationInput.name}
							label={''}
							value={`${declarationInput.value}`}
							list={declarationList}
							onChange={onChangeDeclarationInput}
							hasError={errors.includes(declarationInput.name)}
							ref={declarationInput.ref as RefObject<HTMLInputElement>}
							errors={errors}
							shouldNotDivide
							newCheckValue
							hasBackgroundWrapper
							onFixError={onFixError}
						/> */}
						<div className={classes.checkbox2Container}>
							<span
								className={joinClasses([
									classes.checkboxWrapper,
									'eligibility_clause'
								])}
							>
								<input
									onClick={(e: any) =>
										onSelectEligibilityClause1Input(e.target.checked)
									}
									value={eligibilityClause1Input.value ?? false}
									className={classes.checkbox2}
									type="checkbox"
									name={eligibilityClause1Input.name}
									id={eligibilityClause1Input.labelKey}
								/>
								<label
									className={joinClasses([
										classes.checkbox2Label,
										errors?.includes(eligibilityClause1Input.name)
											? 'error-check'
											: ''
									])}
									htmlFor={eligibilityClause1Input.labelKey}
								>
									<Text
										content={pageAssets[eligibilityClause1Input.labelKey]}
									/>
									<span className={classes.requiredIcon}>*</span>
								</label>
							</span>
						</div>
						{numberOfDogsSelected() >= 1 && (
							<>
								<div className={classes.checkbox2Container}>
									<span
										className={joinClasses([
											classes.checkboxWrapper,
											'eligibility_clause'
										])}
									>
										<input
											onClick={(e: any) =>
												onSelectEligibilityClause2Input(e.target.checked)
											}
											value={eligibilityClause2Input.value ?? false}
											className={classes.checkbox2}
											type="checkbox"
											name={eligibilityClause2Input.name}
											id={eligibilityClause2Input.labelKey}
										/>
										<label
											className={joinClasses([
												classes.checkbox2Label,
												errors?.includes(eligibilityClause2Input.name)
													? 'error-check'
													: ''
											])}
											htmlFor={eligibilityClause2Input.labelKey}
										>
											<Text
												content={pageAssets[eligibilityClause2Input.labelKey]}
											/>
											<span className={classes.requiredIcon}>*</span>

											<Text
												content={
													pageAssets?.request_form_annualPermitAndTagForAnimals_not_having_been_convicted_an_offence_subList
												}
											/>
										</label>
									</span>
								</div>
								<div className={classes.checkbox2Container}>
									<span
										className={joinClasses([
											classes.checkboxWrapper,
											'eligibility_clause'
										])}
									>
										<input
											onClick={(e: any) =>
												onSelectEligibilityClause3Input(e.target.checked)
											}
											value={eligibilityClause3Input.value ?? false}
											className={classes.checkbox2}
											type="checkbox"
											name={eligibilityClause3Input.name}
											id={eligibilityClause3Input.labelKey}
										/>
										<label
											className={joinClasses([
												classes.checkbox2Label,
												errors?.includes(eligibilityClause3Input.name)
													? 'error-check'
													: ''
											])}
											htmlFor={eligibilityClause3Input.labelKey}
										>
											<Text
												content={pageAssets[eligibilityClause3Input.labelKey]}
											/>
											<span className={classes.requiredIcon}>*</span>

											<Text
												content={
													pageAssets?.request_form_annualPermitAndTagForAnimals_not_been_found_guilty_subList
												}
											/>
										</label>
									</span>
								</div>
								<div className={classes.checkbox2Container}>
									<span
										className={joinClasses([
											classes.checkboxWrapper,
											'eligibility_clause'
										])}
									>
										<input
											onClick={(e: any) =>
												onSelectEligibilityClause4Input(e.target.checked)
											}
											value={eligibilityClause4Input.value ?? false}
											className={classes.checkbox2}
											type="checkbox"
											name={eligibilityClause4Input.name}
											id={eligibilityClause4Input.labelKey}
										/>
										<label
											className={joinClasses([
												classes.checkbox2Label,
												errors?.includes(eligibilityClause4Input.name)
													? 'error-check'
													: ''
											])}
											htmlFor={eligibilityClause4Input.labelKey}
										>
											<Text
												content={pageAssets[eligibilityClause4Input.labelKey]}
											/>
											<span className={classes.requiredIcon}>*</span>
										</label>
									</span>
								</div>
								<div className={classes.checkbox2Container}>
									<span
										className={joinClasses([
											classes.checkboxWrapper,
											'eligibility_clause'
										])}
									>
										<input
											onClick={(e: any) =>
												onSelectEligibilityClause5Input(e.target.checked)
											}
											value={eligibilityClause5Input.value ?? false}
											className={classes.checkbox2}
											type="checkbox"
											name={eligibilityClause5Input.name}
											id={eligibilityClause5Input.labelKey}
										/>
										<label
											className={joinClasses([
												classes.checkbox2Label,
												errors?.includes(eligibilityClause5Input.name)
													? 'error-check'
													: ''
											])}
											htmlFor={eligibilityClause5Input.labelKey}
										>
											<Text
												content={pageAssets[eligibilityClause5Input.labelKey]}
											/>
											<span className={classes.requiredIcon}>*</span>
										</label>
									</span>
								</div>
							</>
						)}
					</section>
				</Collapse>
			)}
		</>
	)
}

export default AnnualPermitAndTagForAnimals
