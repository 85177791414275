import React, { FC } from 'react'
import { RequestFormPDFProps } from '@utils/request'
import RainwaterBarrelForm from '@components/requestForms/rainwaterBarrel.form'
import ClothDiapersForm from '@components/requestForms/clothDiapers.form'
import ReplacementOilHeatingSystemForm from '@components/requestForms/replacementOilHeatingSystem.form'
import HeritageBuildingsRevitalizationProgram from './heritageBuildingsRevitalizationProgram.form'
import CrackedHousesForm from './crackedHouses.form'
import TreePlantingForm from './treePlanting.form'
import TemporaryPesticideApplicationPermitForIndividualsForm from './temporaryPesticideApplicationPermitForIndividuals.form'
import ClaimNoticeForm from './claimNotice.form'
import PublicAuctionsForm from './publicAuctions.form'
import RecruitmentAndCitizenInvolvementForm from './recruitmentAndCitizenInvolvement.form'
import BuildingTransactionRequestForm from './buildingTransactionRequest.form'
import OilHeatingDeclarationForm from './oilHeatingDeclaration.form'
import HistoricalArchiveForm from './historicalArchive.form'
import AnnualRegistrationCertificateForContractorsForm from './annualRegistrationCertificateForContractors.form'
import SelfReliefWaterCounterForm from './selfReliefWaterCounter.form'
import HydraulicAndPressureTestsForm from './hydraulicAndPressureTest.form'
import FireHydrantsTempAqueductNetworkForm from './fireHydrantsTempAqueductNetwork.form'
import FireHydrantsTankFillingForm from './fireHydrantsTankFilling.form'
import MunicipalEvaluationForm from './municipalEvaluation.form'
import AnnualPermitAndTagForAnimals from './annualPermitAndTagForAnimals'
import FireplaceDeclarationForm from './fireplaceDeclaration.form'
import ParkingPermitForm from './parkingPermit.form'
import PermitOccupationOfPublicHighway from './permitOccupationOfPublicHighway.form'
import SustainableHygieneProductsForm from './sustainableHygieneProducts.form'
import CarSharingForm from './carSharing.form'
import BikeSharingForm from './bikeSharing.form'
import FireplaceGrantForm from './fireplaceGrant.form'
import SwimmingPoolSpaPermitForm from './swimmingPoolSpaPermit.form'
import BuildingConstructionOrAdditionPermitForm from './buildingConstructionOrAdditionPermit.form'
import WateringPermitForm from './wateringPermit.form'
import BuildingEnlargementPermitForm from './buildingEnlargementPermit.form'
import BuildingDemolitionOrMovingPermitForm from './buildingDemolitionOrMovingPermit.form'
import ResidentialRenovationOrRepairPermitForm from './residentialRenovationOrRepairPermit.form'
import LandDevelopmentWorkPermitForm from './landDevelopmentWorkPermit.form'

type Props = RequestFormPDFProps & {
	name: string
	currentStep: number
}

export type RequestFormPDFComponent = {
	rainwaterBarrel: FC<RequestFormPDFProps>
	clothDiapers: FC<RequestFormPDFProps>
	replacementOilHeatingSystem: FC<RequestFormPDFProps>
	heritageBuildingsRevitalizationProgram: FC<RequestFormPDFProps>
	crackedHouses: FC<RequestFormPDFProps>
	treePlanting: FC<RequestFormPDFProps>
	temporaryPesticideApplicationPermitForIndividuals: FC<RequestFormPDFProps>
	claimNotice: FC<RequestFormPDFProps>
	publicAuctions: FC<RequestFormPDFProps>
	recruitmentAndCitizenInvolvement: FC<RequestFormPDFProps>
	buildingTransactionRequest: FC<RequestFormPDFProps>
	oilHeatingDeclaration: FC<RequestFormPDFProps>
	historicalArchive: FC<RequestFormPDFProps>
	annualRegistrationCertificateForContractors: FC<RequestFormPDFProps>
	selfReliefWaterCounter: FC<RequestFormPDFProps>
	hydraulicAndPressureTests: FC<RequestFormPDFProps>
	fireHydrantsTempAqueductNetwork: FC<RequestFormPDFProps>
	fireHydrantsTankFilling: FC<RequestFormPDFProps>
	municipalEvaluation: FC<RequestFormPDFProps>
	annualPermitAndTagForAnimals: FC<RequestFormPDFProps>
	fireplaceDeclaration: FC<RequestFormPDFProps>
	parkingPermit: FC<RequestFormPDFProps>
	permitOccupationOfPublicHighway: FC<RequestFormPDFProps>
	sustainableHygieneProducts: FC<RequestFormPDFProps>
	carSharing: FC<RequestFormPDFProps>
	bikeSharing: FC<RequestFormPDFProps>
	fireplaceGrant: FC<RequestFormPDFProps>
	swimmingPoolSpaPermit: FC<RequestFormPDFProps>
	buildingConstructionOrAdditionPermit: FC<RequestFormPDFProps>
	wateringPermit: FC<RequestFormPDFProps>
	buildingEnlargementPermit: FC<RequestFormPDFProps>
	buildingDemolitionOrMovingPermit: FC<RequestFormPDFProps>
	residentialRenovationOrRepairPermit: FC<RequestFormPDFProps>
	landDevelopmentWorkPermit: FC<RequestFormPDFProps>
}

const SelectionPDF: FC<Props> = ({
	name,
	inputs,
	files,
	hasFileError,
	hasFileRequiredError,
	subSteps,
	errors,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	currentStep,
	setCurrentStep,
	onSetCurrentSubStep,
	onSetInputFiles,
	onFixError,
	onSetFiles,
	onSetHasFileError,
	onSetHasFileRequiredError,
	onSetInputs,
	onSetSubSteps,
	onCustomError,
	setDisableNextButton,
	setRequiredFieldAttributes,
	setRequiredFileAttributes,
	setStepsList,
	setSteps
}) => {
	const formFactory: RequestFormPDFComponent = {
		rainwaterBarrel: RainwaterBarrelForm,
		clothDiapers: ClothDiapersForm,
		replacementOilHeatingSystem: ReplacementOilHeatingSystemForm,
		heritageBuildingsRevitalizationProgram:
			HeritageBuildingsRevitalizationProgram,
		crackedHouses: CrackedHousesForm,
		treePlanting: TreePlantingForm,
		temporaryPesticideApplicationPermitForIndividuals:
			TemporaryPesticideApplicationPermitForIndividualsForm,
		claimNotice: ClaimNoticeForm,
		recruitmentAndCitizenInvolvement: RecruitmentAndCitizenInvolvementForm,
		publicAuctions: PublicAuctionsForm,
		buildingTransactionRequest: BuildingTransactionRequestForm,
		oilHeatingDeclaration: OilHeatingDeclarationForm,
		historicalArchive: HistoricalArchiveForm,
		annualRegistrationCertificateForContractors:
			AnnualRegistrationCertificateForContractorsForm,
		selfReliefWaterCounter: SelfReliefWaterCounterForm,
		hydraulicAndPressureTests: HydraulicAndPressureTestsForm,
		fireHydrantsTempAqueductNetwork: FireHydrantsTempAqueductNetworkForm,
		fireHydrantsTankFilling: FireHydrantsTankFillingForm,
		municipalEvaluation: MunicipalEvaluationForm,
		annualPermitAndTagForAnimals: AnnualPermitAndTagForAnimals,
		fireplaceDeclaration: FireplaceDeclarationForm,
		parkingPermit: ParkingPermitForm,
		permitOccupationOfPublicHighway: PermitOccupationOfPublicHighway,
		sustainableHygieneProducts: SustainableHygieneProductsForm,
		carSharing: CarSharingForm,
		bikeSharing: BikeSharingForm,
		fireplaceGrant: FireplaceGrantForm,
		swimmingPoolSpaPermit: SwimmingPoolSpaPermitForm,
		buildingConstructionOrAdditionPermit:
			BuildingConstructionOrAdditionPermitForm,
		wateringPermit: WateringPermitForm,
		buildingEnlargementPermit: BuildingEnlargementPermitForm,
		buildingDemolitionOrMovingPermit: BuildingDemolitionOrMovingPermitForm,
		residentialRenovationOrRepairPermit: ResidentialRenovationOrRepairPermitForm,
		landDevelopmentWorkPermit: LandDevelopmentWorkPermitForm
	}

	const ComponentForm: FC<RequestFormPDFProps> = formFactory[name] || undefined

	if (!ComponentForm) {
		return <></>
	}

	return (
		<ComponentForm
			files={files}
			inputs={inputs}
			hasFileError={hasFileError}
			hasFileRequiredError={hasFileRequiredError}
			subSteps={subSteps}
			errors={errors}
			onSetFiles={onSetFiles}
			onFixError={onFixError}
			onSetHasFileError={onSetHasFileError}
			onSetHasFileRequiredError={onSetHasFileRequiredError}
			onSetInputs={onSetInputs}
			onSetSubSteps={onSetSubSteps}
			inputFiles={inputFiles}
			onSetInputFiles={onSetInputFiles}
			formPosition={currentStep}
			isSummaryStep={isSummaryStep}
			setCurrentStep={setCurrentStep}
			onSetCurrentSubStep={onSetCurrentSubStep}
			steps={steps}
			toPrint={toPrint}
			filesInputs={filesInputs}
			onCustomError={onCustomError}
			setDisableNextButton={setDisableNextButton}
			setRequiredFieldAttributes={setRequiredFieldAttributes}
			setRequiredFileAttributes={setRequiredFileAttributes}
			setStepsList={setStepsList}
			setSteps={setSteps}
		/>
	)
}

export default SelectionPDF
