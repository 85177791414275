import React, {
	FocusEventHandler,
	forwardRef,
	ForwardRefRenderFunction,
	ReactElement,
	useState
} from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors, Constants } from '@utils/css-variables'
import RequiredIcon from '@components/ui/requiredIcon'
import Error from '@components/requestForms/inputs/error.input'
import { IMaskInput } from 'react-imask'
import requestForm from '../request.form'

type Props = {
	id: string
	label: string
	value: string
	required?: boolean
	hasError?: boolean
	disabled?: boolean
	type?: 'text' | 'number' | 'date' | 'tel'
	max: number
	min: number
	onChange: (selected: string) => void
	classWrapper?: string
	placeholder?: string
	mask?: string
	schema?: RegExp
	errors: string[]
	schemaInvalid?: string
	setError: React.Dispatch<React.SetStateAction<string[]>>
}

type Classes = {
	root: string
	input: string
	error: string
	moneyInput: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '20px'
	},
	input: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',
		width: '100%',
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400,
		padding: '8px 12px',
		borderRadius: '0px',
		lineHeight: '26px',
		'&:focus': {
			outline: 'none'
		}
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		input: {
			borderColor: Colors.errorRed,
			'&:focus': {
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		}
	},
	moneyInput: {
		position: 'relative',
		'& span': {
			position: 'absolute',
			display: 'flex',
			width: '32px',
			height: '44px',
			background: Colors.lightGreyTransparent,
			justifyContent: 'center',
			alignItems: 'center'
		},
		'& input': {
			paddingLeft: '40px'
		},
		'& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
			display: 'none',
			WebkitUserModify: 'read-only !important',
			writingMode: 'inherit !important'
		},
		'& input[type=number]': {
			MozAppearance: 'text-field'
		},
		'& input::-webkit-textfield-decoration-container': {
			width: 'calc(100% - 28px)'
		}
	}
})

const TextInputForwardRef: ForwardRefRenderFunction<HTMLInputElement, Props> = (
	{
		id,
		label,
		required,
		hasError,
		disabled,
		value,
		min,
		type = 'text',
		max,
		classWrapper,
		placeholder,
		onChange,
		schema,
		setError,
		mask,
		errors
	},
	ref
) => {
	const { pageAssets } = requestForm()

	const [textTooShort, setTextTooShort] = useState<boolean>(false)

	const checkValue = (e: any) => {
		if (e.target?.value?.trim() == '') return
		let length = e.target?.value?.length

		if (type == 'tel') {
			length = e.target?.value?.replace(/[\(\)\-\s]/g, '').length
		}

		if (length < min) {
			setError([...errors, id])
			setTextTooShort(true)
		} else {
			setTextTooShort(false)
		}
	}

	return (
		<div
			className={joinClasses([
				classes.root,
				hasError ? classes.error : '',
				classWrapper || ''
			])}
		>
			<label htmlFor={id}>
				{label} {required && <RequiredIcon />}
				<Error
					hasError={hasError}
					errorText={
						textTooShort
							? !!value
								? pageAssets.label_tooShortErrorText
								: undefined
							: undefined
					}
				/>
			</label>
			<div>
				<IMaskInput
					mask={mask || schema}
					value={value}
					name={id}
					id={id}
					className={classes.input}
					minLength={min}
					maxLength={max}
					disabled={disabled}
					required={required}
					placeholder={placeholder}
					type={type}
					ref={ref}
					onBlur={(e: any) => checkValue(e)}
					onAccept={(e) => onChange(e as string)}
					// unmask={false}
					// lazy={false}
					// overwrite={true}
				/>
			</div>
		</div>
	)
}

const MaskedTextInput = forwardRef(TextInputForwardRef)

export default MaskedTextInput
