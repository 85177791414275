import { Colors, Constants } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { RequestInputs } from '@utils/request'
import { Coordinate } from '../map/mapForm.interfaces'
import Breakpoints from '@utils/breakpoints'
import axios from 'axios'
import {
	StaticApiAddressProvidersEnum,
	StaticApiSearchTypeEnum
} from '@services/constants/_index'
import { AxiosResponse, ICoodinate } from '@services/types'

export type Props = {
	errors: string[]
	inputs: RequestInputs[]
	onFixError: React.Dispatch<React.SetStateAction<string[]>>
	onSetInputs: (inputs: RequestInputs[]) => void
	hasError: boolean
	onAddressInputChange: React.Dispatch<React.SetStateAction<string | undefined>>
	onRetreiveCoordinates: (coordinates: Coordinate | undefined) => void
	onAddError: (address: string) => void
}

type Classes = {
	h3: string
	label: string
	formInput: string
	searchAddressDisabled: string
	map: string
	formLabel: string
	labelError: string
	required: string
	field: string
	fieldsContainer: string
	marginTop: string
	fieldWrapper: string
	h4: string
}

export const classes: Classes = makeClasses({
	h3: {
		color: Colors.primary,
		marginTop: 0,
		marginBottom: '25px'
	},
	label: {
		margin: '5px 0px',
		':first-child': {
			marginTop: '0px'
		},
		'& span': {
			display: 'block',
			margin: '10px 0px 20px',
			lineHeight: '24px'
		}
	},
	labelError: {
		color: Colors.errorRed,
		marginBottom: '12px'
	},
	formInput: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',
		width: 'calc(100% - 26px)',
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400 as any,
		padding: '12px',
		borderRadius: '0px',
		lineHeight: '26px',
		marginBottom: '10px',
		'&:focus': {
			outline: 'none'
		}
	},
	searchAddressDisabled: {
		background: Colors.lightGrey4
	},
	map: {
		':focus': {
			border: `1px solid ${Colors.primary}`
		}
	},
	formLabel: {
		margin: '0px',
		paddingBottom: 0,
		fontSize: '16px',
		fontWeight: 'bold' as any,
		display: 'block'
	},
	required: {
		':after': {
			content: '"*"',
			marginLeft: '5px',
			color: Colors.ligthPurple
		}
	},
	field: {
		'& > div': {
			padding: '0px'
		},
		'& .notification': {
			marginBottom: '20px !important',
			marginTop: '-24px'
		},
		'& .soleField input': {
			width: '100% !important',
			padding: 'none'
		}
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	fieldWrapper: {
		'& label.css-17qsju7': {
			// display: 'flex !important',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '10px 12px'
		}
	},
	h4: {
		fontSize: '22px',
		lineHeight: '26px',
		margin: '0 0 30px 0',
		color: Colors.darkBlue1,
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '20px'
		}
	}
})

type GeoJSONFeature = {
	type: 'Feature'
	bbox: [number, number, number, number]
	geometry: {
		type: 'Point'
		coordinates: [number, number]
	}
	properties: {
		Parish_lot: string
		address: string
		address_id: string
		cadastre: string
		civic: string
		dissemination_area_id: string
		dissemination_block_id: string
		electoral_district_id: string
		fire_id: string
		historical_cadastre: string
		matricule: string
		mtm8_projection: [number, number]
		odonym: string
		old_city_code: string
		old_city_name: string
		parish: string
		planning_id: string
		police_id: string
		postal_code: string
		street_id: string
	}
	id: string
	place_name: string
	place_sub_type: string[]
	place_type: string[]
}

type GeoJSONFeatureCollection = {
	type: 'FeatureCollection'
	features: GeoJSONFeature[]
	query: string
	elapsed_milliseconds: number
}
const removeLeadingDigits = (sentence: string): string => {
	const regex = /^[0-9]+\s*/
	const result = sentence.replace(regex, '')
	return result
}

export const getDefaultCadastralNumber = async (
	userLocation
): Promise<AxiosResponse<GeoJSONFeatureCollection>> => {
	const response = await axios.get(`/api/addresses`, {
		params: {
			serviceProvider: StaticApiAddressProvidersEnum.THINK_WHERE,
			search: removeLeadingDigits(userLocation),
			searchType: StaticApiSearchTypeEnum.address
		}
	})

	return response
}

export const getAddressByCoordinates = async (
	coordinates
): Promise<AxiosResponse<GeoJSONFeatureCollection>> => {
	const coordinate = {
		latitude: coordinates.latitude,
		longitude: coordinates.longitude
	}
	const response = await axios.get(`/api/addresses`, {
		params: {
			serviceProvider: StaticApiAddressProvidersEnum.THINK_WHERE,
			search:  JSON.stringify(coordinate),
			searchType: StaticApiSearchTypeEnum.coordinate
		}
	})

	return response
}

export const getVignetteByCoordinates = async (coordinates: ICoodinate) => {
	try {
		const coordinate = {
			latitude: coordinates?.latitude,
			longitude: coordinates?.longitude
		}
		const response = await axios.get(`/api/addresses`, {
			params: {
				serviceProvider: StaticApiAddressProvidersEnum.LOCATION,
				search:  JSON.stringify(coordinate),
				searchType: StaticApiSearchTypeEnum.coordinate
			}
		})
		return response.data?.features
	} catch (error) {
		console.error(error)
	}
}
