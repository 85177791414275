import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RadioList, RequestFormPDFProps, RequestInputs } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RadioInput from './inputs/radio.input'
import AddressInfoSection from '@components/ui/addressInfosSection'
import { formatStrapiText, getLabelFromList } from '@utils/methods'
import { AddressCoordinates, CustomDate } from '@services/models'
import Notification from '@components/ui/notification'
import DateFilterInput from './inputs/datefilter.input'
import moment from 'moment'

const WateringPermitForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	isSummaryStep,
	toPrint,
	steps,
	setDisableNextButton,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()
	type Classes = {
		adresseBlock: string
		fieldsContainer: string
		reducedInputMargin: string
	}
	const classes: Classes = makeClasses({
		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px'
		},
		fieldsContainer: {
			[Breakpoints.minWidth('md')]: {
				display: 'grid',
				columnGap: '20px',
				gridTemplateColumns: '1fr 1fr',
				alignItems: 'end'
			}
		},
		reducedInputMargin: {
			marginBottom: '25px'
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [showAdresseBlock, setShowAdresseBlock] = useState<boolean>(
		answers?.sameAddressAsApplicant?.toString() == 'false' ? true : false
	)

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	//----------------------------------------------------------------
	//--------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const radioListAddressOption: RadioList[] = [
		{
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		}
	]

	const radioListWateringPurpose: RadioList[] = [
		{
			label:
				pageAssets?.request_form_watering_permit_wateringPurpose_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.wateringPermitPurpose.firstOption,
			key: 'request_form_watering_permit_wateringPurpose_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_watering_permit_isWateringLimit_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.wateringPermitPurpose.secondOption,
			key: 'request_form_watering_permit_isWateringLimit_secondOption_label'
		},
		{
			label:
				pageAssets?.request_form_watering_permit_isWateringLimit_thirdOption_label,
			value: REQUEST_CONFIGS_KEYS.wateringPermitPurpose.thirdOption,
			key: 'request_form_watering_permit_isWateringLimit_thirdOption_label'
		},
		{
			label:
				pageAssets?.request_form_watering_permit_isWateringLimit_fourthOption_label,
			value: REQUEST_CONFIGS_KEYS.wateringPermitPurpose.fourthOption,
			key: 'request_form_watering_permit_isWateringLimit_fourthOption_label'
		}
	]

	const [isSameLocationInput, setIsSameLocationInput] = useState<RequestInputs>(
		{
			name: 'sameAddressAsApplicant',
			label: pageAssets?.request_form_wateringPermit_isSameLocation_label,
			labelKey: 'request_form_wateringPermit_isSameLocation_label',
			value: answers?.sameAddressAsApplicant?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		}
	)

	const [isOwnerInput, setIsOwnerInput] = useState<RequestInputs>({
		name: 'isOwner',
		label: pageAssets?.request_form_watering_permit_isOwner_label,
		labelKey: 'request_form_watering_permit_isOwner_label',
		value: answers.isOwner?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [isWateringLimitInput, setIsWateringLimitInput] =
		useState<RequestInputs>({
			name: 'isWateringLimit',
			label: pageAssets?.request_form_watering_permit_isWateringLimit_label,
			labelKey: 'request_form_watering_permit_isWateringLimit_label',
			value: answers.isWateringLimit?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [startDateInput, setStartDateInput] = useState<RequestInputs>({
		name: 'startDate',
		label:
			pageAssets?.request_form_fireHydrantsTempAqueductNetwork_startDate_label,
		labelKey: 'request_form_fireHydrantsTempAqueductNetwork_startDate_label',
		value: answers?.startDate || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
		extraParams: {
			type: 'date',
			min: CustomDate.currentDate(),
			customErrorText:
				pageAssets?.request_form_historicalArchive_coveredSearchPeriod_error_label
		}
	})

	const [endDateInput, setEndDateInput] = useState<RequestInputs>({
		name: 'endDate',
		label:
			pageAssets?.request_form_fireHydrantsTempAqueductNetwork_endDate_label,
		labelKey: 'request_form_fireHydrantsTempAqueductNetwork_endDate_label',
		value: answers?.endDate || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
		extraParams: {
			type: 'date',
			min: answers.startDate ?? '',
			max: answers.startDate
				? moment(answers.startDate).add(26, 'weeks').format('YYYY-MM-DD')
				: '',
			customErrorText:
				pageAssets?.request_form_historicalArchive_coveredSearchPeriod_error_label
		}
	})

	const [wateringPurposeInput, setWateringPurposeInput] =
		useState<RequestInputs>({
			name: 'wateringPurpose',
			label: pageAssets?.request_form_wateringPurpose_watering_label,
			labelKey: 'request_form_wateringPurpose_watering_label',
			value: answers.wateringPurpose?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	// ------------------- DETAILS INPUTS SETTERS --------------------

	const onSelectIsSameLocationInput = (value) => {
		setIsSameLocationInput({
			...isSameLocationInput,
			value: `${value}`
		})

		if (isWateringLimitInput.value !== radioListYesNo[0].value) {
			setDisableNextButton && setDisableNextButton(true)
		}

		if (value == radioListYesNo[0].value) {
			if (isAddressOutsideLaval) {
				setDisableNextButton && setDisableNextButton(false)
			}
			setTimeout(() => {
				setShowAdresseBlock(false)
			}, 100)
		} else {
			setTimeout(() => {
				setShowAdresseBlock(true)
			}, 100)
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, isSameLocationInput.name))
	}

	const onSelectIsOwnerInput = (value) => {
		setIsOwnerInput({ ...isOwnerInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, isOwnerInput.name))
	}

	const onSelectIsWateringLimitInput = (value) => {
		setIsWateringLimitInput({ ...isWateringLimitInput, value })

		if (
			isSameLocationInput.value !== radioListYesNo[0].value &&
			!isAddressOutsideLaval
		) {
			setDisableNextButton && setDisableNextButton(true)
		}

		if (value == radioListYesNo[0].value) {
			setDisableNextButton && setDisableNextButton(false)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isWateringLimitInput.name)
		)
	}

	const onSelectStartDateInput = (value: string) => {
		let delayDate = new Date(value)

		delayDate.setDate(delayDate.getDate() + 15)

		if (Number(value.split('-', 2)[0]).toString().length >= 5) {
			return
		}

		setStartDateInput({
			...startDateInput,
			value
		})

		setEndDateInput({
			...endDateInput,
			value: moment(delayDate).format('YYYY-MM-DD')
		})

		onFixError(
			errors.filter(
				(error) => ![startDateInput.name, endDateInput.name].includes(error)
			)
		)
	}

	const onSelectWateringPurposeInput = (value) => {
		setWateringPurposeInput({ ...wateringPurposeInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, wateringPurposeInput.name)
		)
	}

	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isSameLocationInput))

		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)
		if (coordinates.validate()) {
			setIsAddressOutsideLaval(false)
		} else {
			setIsAddressOutsideLaval(true)
		}
	}, [isSameLocationInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isOwnerInput))
	}, [isOwnerInput])

	useEffect(() => {
		if (
			!isAddressOutsideLaval &&
			isWateringLimitInput.value == radioListYesNo[1].value
		) {
			setDisableNextButton && setDisableNextButton(true)
		}

		onSetInputs(UpdateRequestInputsArray(inputs, isWateringLimitInput))
	}, [isWateringLimitInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, startDateInput))
	}, [startDateInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, endDateInput))
	}, [endDateInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, wateringPurposeInput))
	}, [wateringPurposeInput])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1]
		})
	}, [])

	useEffect(() => {
		if (
			subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 &&
			isAddressOutsideLaval &&
			isSameLocationInput.value == radioListYesNo[0].value
		) {
			setDisableNextButton && setDisableNextButton(false)
		}
	}, [subSteps?.position])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h4 className={pageUtils.classes.h4} style={{ fontSize: '25px' }}>
							{pageAssets?.request_form_watering_permit}
						</h4>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_location}
						</h5>

						{isSameLocationInput.value?.toString() ===
						radioListYesNo[1].value ? (
							<div>
								<strong>{pageAssets?.request_form_location}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.addressField}
									{answers?.addressAppartment && (
										<>
											<br />
											{answers?.addressAppartment}
										</>
									)}
								</span>
							</div>
						) : (
							<div>
								<strong>{isSameLocationInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListYesNo, answers?.isSameLocation)}
								</span>

								<span className={pageUtils.classes.answer}>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</span>
							</div>
						)}
						<strong>
							{pageAssets?.request_form_watering_permit_isOwner_label}
						</strong>
						<br />
						<span className={pageUtils.classes.answer}>
							{getLabelFromList(radioListYesNo, answers?.isOwner)}
						</span>

						<strong>
							{pageAssets?.request_form_watering_permit_isWateringLimit_label}
						</strong>
						<br />
						<span className={pageUtils.classes.answer}>
							{getLabelFromList(radioListYesNo, answers?.isWateringLimit)}
						</span>

						<div>
							<strong>
								{
									pageAssets?.request_form_fireHydrantsTempAqueductNetwork_startDate_label
								}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{pageUtils.getLocaleDate(answers?.startDate!)}
							</span>
						</div>

						<div>
							<strong>
								{
									pageAssets?.request_form_fireHydrantsTempAqueductNetwork_endDate_label
								}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{pageUtils.getLocaleDate(answers?.endDate!)}
							</span>
						</div>

						<strong>
							{pageAssets?.request_form_wateringPurpose_watering_label}
						</strong>
						<br />
						<span className={pageUtils.classes.answer}>
							{getLabelFromList(
								radioListWateringPurpose,
								answers?.wateringPurpose
							)}
						</span>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4} style={{ fontSize: '25px' }}>
							{pageAssets?.request_form_watering_permit}
						</h4>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_location}
						</h5>

						<div className={classes.adresseBlock}>
							<strong>{pageAssets?.label_address}</strong>
							<br />
							{authUser?.profile?.address.address}
							<br />
							{authUser?.profile?.address.city}
							{authUser?.profile?.address.state
								? ` (${authUser?.profile?.address.state}) `
								: ' '}
							{authUser?.profile?.address.postalCode}
							<br />
							{authUser?.profile?.address.apartment
								? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
										authUser?.profile?.address.apartment
								  }`
								: ''}
						</div>

						<RadioInput
							name={isSameLocationInput.name}
							label={isSameLocationInput.label}
							value={isSameLocationInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isSameLocationInput.name)}
							onChange={onSelectIsSameLocationInput}
							ref={isSameLocationInput.ref as RefObject<HTMLInputElement>}
							required={isSameLocationInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedInputMargin}
						/>

						{isSameLocationInput.value === radioListYesNo[0].value &&
							isAddressOutsideLaval && (
								<Notification
									type="warning"
									text={
										pageAssets?.request_form_watering_permit_address_outside_laval_label
									}
								/>
							)}

						{showAdresseBlock && (
							<div style={{ marginBottom: '30px' }}>
								<AddressInfoSection
									label={pageAssets?.request_form_location}
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										isSameLocationInput.value == radioListYesNo[1].value
									}
									showButtonUserLocation={false}
									displayAddressDetails={false}
									addressOptions={radioListAddressOption}
									requiredFields={[
										'addressOption',
										'addressField',
										'addressAppartment',
										'addressLotNumber'
									]}
									subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1}
									displayAptInput
								/>
							</div>
						)}

						{/**/}

						<RadioInput
							name={isOwnerInput.name}
							label={isOwnerInput.label}
							value={isOwnerInput.value ?? ''}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isOwnerInput.name)}
							onChange={onSelectIsOwnerInput}
							required={isOwnerInput.required}
							hasBackgroundWrapper={true}
						/>

						<RadioInput
							name={isWateringLimitInput.name}
							label={isWateringLimitInput.label}
							value={isWateringLimitInput.value ?? ''}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isWateringLimitInput.name)}
							onChange={onSelectIsWateringLimitInput}
							required={isWateringLimitInput.required}
							hasBackgroundWrapper={true}
						/>

						{isWateringLimitInput.value === radioListYesNo[0].value && (
							<Notification
								type="warning"
								text={
									pageAssets?.request_form_watering_permit_isWateringLimit_notification_label
								}
								hasHtml
							/>
						)}
						{isWateringLimitInput.value === radioListYesNo[1].value && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_watering_permit_wateringDate_title_label
									}
								</h5>
								<Notification
									type="info"
									text={
										pageAssets?.request_form_watering_permit_wateringDate_notification_label
									}
									hasHtml
								/>

								<div className={classes.fieldsContainer}>
									<DateFilterInput
										id={startDateInput.name}
										label={startDateInput.label}
										value={startDateInput.value}
										onChange={onSelectStartDateInput}
										hasError={errors.includes(startDateInput.name)}
										required={startDateInput.required}
										minDate={new Date()}
										dateFormat="dd/MM/yyyy"
										customErrorText={
											startDateInput.value !== ''
												? startDateInput.extraParams?.customErrorText
												: undefined
										}
									/>

									<DateFilterInput
										id={endDateInput.name}
										label={endDateInput.label}
										value={endDateInput.value}
										onChange={() => {}}
										hasError={errors.includes(endDateInput.name)}
										required={endDateInput.required}
										minDate={
											new Date(endDateInput.extraParams?.min + 'T12:00:00')
										}
										maxDate={
											new Date(endDateInput.extraParams?.max + 'T12:00:00')
										}
										dateFormat="dd/MM/yyyy"
										disabled
										customErrorText={
											endDateInput.value !== ''
												? endDateInput.extraParams?.customErrorText
												: undefined
										}
									/>
								</div>

								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingTransactionRequest_firstSubStep_title
									}
								</h5>
								<Notification
									type="info"
									text={
										pageAssets?.request_form_watering_permit_limitations_label
									}
								/>

								<RadioInput
									name={wateringPurposeInput.name}
									label={wateringPurposeInput.label}
									value={wateringPurposeInput.value ?? ''}
									list={radioListWateringPurpose}
									direction={Direction.vertical}
									hasError={errors!.includes(wateringPurposeInput.name)}
									onChange={onSelectWateringPurposeInput}
									required={wateringPurposeInput.required}
									hasBackgroundWrapper={true}
								/>
							</>
						)}
					</section>
				</Collapse>
			)}
		</>
	)
}

export default WateringPermitForm
