'use strict'

import React from 'react'

type Props = {
	pageSelectedHandler: (event: any) => void
	selected: boolean
	pageClassName: string
	pageLinkClassName: string
	activeClassName: string
	activeLinkClassName: string
	extraAriaContext: string
	href: string
	ariaLabel: string
	page: number
	getEventListener(event: any): void
	pageLabelBuilder(page: number): string | React.ReactNode
	rel: string
	hide: boolean
}
const PageView: React.FC<Props> = ({
	page,
	pageClassName,
	pageLabelBuilder,
	pageLinkClassName,
	pageSelectedHandler,
	selected,
	activeClassName,
	activeLinkClassName,
	extraAriaContext,
	href,
	ariaLabel,
	getEventListener,
	rel,
	hide
}) => {
	ariaLabel =
		ariaLabel ||
		'Page ' + page + (extraAriaContext ? ' ' + extraAriaContext : '')
	let ariaCurrent

	if (selected) {
		ariaCurrent = 'page'

		ariaLabel = ariaLabel || 'Page ' + page + ' is your current page'

		if (typeof pageClassName !== 'undefined') {
			pageClassName = pageClassName + ' ' + activeClassName
		} else {
			pageClassName = activeClassName
		}

		if (typeof pageLinkClassName !== 'undefined') {
			if (typeof activeLinkClassName !== 'undefined') {
				pageLinkClassName = pageLinkClassName + ' ' + activeLinkClassName
			}
		} else {
			pageLinkClassName = activeLinkClassName
		}
	}

	return (
		<li className={pageClassName} style={hide ? { display: 'none' } : {}}>
			<a
				rel={rel}
				role={!href ? 'button' : undefined}
				className={pageLinkClassName}
				href={href}
				tabIndex={selected ? -1 : 0}
				aria-label={ariaLabel}
				aria-current={ariaCurrent}
				onKeyPress={pageSelectedHandler}
				{...getEventListener(pageSelectedHandler)}
			>
				{pageLabelBuilder(page)}
			</a>
		</li>
	)
}

export default PageView
