import assert from 'assert'
import axios, { AxiosError } from 'axios'
import apiClient from '@services/api.client'
import Case from 'case'
import {
	IRequestConsentDTO,
	IRequestDTO,
	IRequestNumericTransactionDTO,
	RequestPartialsDTO,
	REQUEST_FIELDS_SET
} from '@services/dtos/requests.dto'
import {
	AxiosResponse,
	BackendPostResponseType,
	IRequestsRepository
} from '@services/types'
import { acquireAccessToken } from '@utils/authConfig'
import {
	ActivityLogRepository,
	BaseRepository
} from '@services/repositories/_miscellaneous.repository'
import { ATTACHMENT_HEADER, RequestPartialsEnum } from '@services/constants'
import { ICommentsAttachmentDTO } from '@services/dtos/comments.dto'
import { LoggerService } from '@services/logger'

export default class RequestsRepository
	extends BaseRepository
	implements IRequestsRepository
{
	private _partialsResources: Record<RequestPartialsEnum, string>
	private _activityLogRepository: ActivityLogRepository
	private _logger: LoggerService

	constructor() {
		super()
		this.resource = 'incidents'
		this._partialsResources = {
			emails: 'emails',
			comments: 'portal_comments',
			consents: 'consents',
			attachments: 'attachments',
			history: '/n-a',
			form: 'incident_forms',
			numericTransactions: 'numeric_transactions',
			phone_calls: "phone_calls",
			blobSas: "blob/sas"
		}

		this._activityLogRepository = new ActivityLogRepository()
		this._logger = new LoggerService()
	}

	public getRequestPartialsAsync = async (
		requestId: string,
		partial: RequestPartialsEnum
	): Promise<RequestPartialsDTO> => {
		try {
			if (partial === (RequestPartialsEnum.HISTORY as RequestPartialsEnum)) {
				return await this._activityLogRepository.searchActivitiesByResourceIdAsync(
					'incident_id',
					requestId
				)
			}

			const response: AxiosResponse<RequestPartialsDTO> = await apiClient.get(
				'',
				{
					params: {
						resource: this._partialsResources[partial],
						params: this.stringify({
							incident_id: requestId
						})
					},
					headers: {
						token: await acquireAccessToken()
					}
				}
			)

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'GET - Request Patials')
				throw new Error(
					`Error while fetching the request partials [${partial}] : ` +
						error.message
				)
			}

			throw error
		}
	}

	public getRequest = async (id: string): Promise<IRequestDTO> => {
		try {
			const response: AxiosResponse<IRequestDTO> = await apiClient.get('', {
				params: {
					resource: this.resource,
					resourceId: id
				},
				headers: {
					token: await acquireAccessToken()
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'GET - Request')
				throw new Error('Error while fetching the request: ' + error.message)
			}

			throw error
		}
	}

	public searchRequestsBy = async (
		field: keyof IRequestDTO | string,
		value: number | string,
		additional_informations: boolean
	): Promise<IRequestDTO[]> => {
		const fieldSnake: string = Case.snake(field)

		try {
			assert(
				REQUEST_FIELDS_SET.has(fieldSnake),
				`Can not search by a field (${fieldSnake}) that is not part of the DTO (IRequestDTO)`
			)

			const response: AxiosResponse<IRequestDTO[]> = await apiClient.get('', {
				params: {
					resource: this.resource,
					params: this.stringify({
						[fieldSnake]: value,
						additional_informations: `${additional_informations}`
					})
				},
				headers: {
					token: await acquireAccessToken()
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'GET - Search Request')
				throw new Error(
					`Error while searching requests with keysVals (${field}=${value}): ` +
						error.message
				)
			}

			throw error
		}
	}

	public patchRequest = async (
		requestDTO: IRequestDTO,
		cancelRequest?: boolean
	): Promise<string> => {
		assert(requestDTO.incident_id, 'Error: Can not update request without id.')

		try {
			const response: AxiosResponse<BackendPostResponseType> =
				await apiClient.patch(
					'',
					cancelRequest
						? {
								incident_id: requestDTO.incident_id,
								cancel_reason: requestDTO.cancel_reason
						  }
						: requestDTO,
					{
						params: {
							resource: this.resource,
							resourceId: requestDTO.incident_id
						},
						headers: {
							token: await acquireAccessToken()
						}
					}
				)

			// TODO: check for incident_id
			return response.data.resourceid
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'PATCH - Request')
				throw new Error('Error while updating the request: ' + error.message)
			}

			throw error
		}
	}

	public postRequest = async (requestDTO: IRequestDTO): Promise<string> => {
		try {
			const response: AxiosResponse<BackendPostResponseType> =
				await apiClient.post('', requestDTO, {
					params: {
						resource: this.resource
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data.resourceid
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'POST - Request')
				throw new Error('Error while creating the request: ' + error.message)
			}

			throw error
		}
	}

	public postTNConfigs = async (
		requestDTO: IRequestDTO,
		resource: string
	): Promise<string> => {
		try {
			const response: AxiosResponse<BackendPostResponseType> =
				await apiClient.post('', requestDTO, {
					params: {
						resource: resource
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data.resourceid
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'POST - Configs')
				throw new Error('Error while creating the request: ' + error.message)
			}

			throw error
		}
	}

	public postNumericTransactions = async (
		requestDTO: IRequestNumericTransactionDTO
	): Promise<string> => {
		try {
			const response: AxiosResponse<BackendPostResponseType> =
				await apiClient.post('', requestDTO, {
					params: {
						resource: this._partialsResources.numericTransactions
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data.resourceid
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'POST - Numeric Transactions')
				throw new Error('Error while creating the request: ' + error.message)
			}

			throw error
		}
	}

	public postAttachment = async (attachmentData: FormData): Promise<string> => {
		try {
			const response: AxiosResponse<BackendPostResponseType> =
				await apiClient.post('', attachmentData, {
					params: {
						resource: this._partialsResources.attachments
					},
					headers: {
						token: await acquireAccessToken(),
						ATTACHMENT_HEADER
					}
				})

			return response.data.resourceid
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'POST - Attachment')
				throw new Error(
					'Error while creating a consent for the request: ' + error.message
				)
			}

			throw error
		}
	}

	public getAttachmentsFiles = async (id) => {
		try {
			const response: AxiosResponse<ICommentsAttachmentDTO> =
				await apiClient.get('', {
					params: {
						resource: this._partialsResources.attachments,
						resourceId: id
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'GET - Attachment Files')
				throw new Error('Error while fetching the file: ' + error.message)
			}

			throw error
		}
	}

	public getAttachments = async (id): Promise<ICommentsAttachmentDTO[]> => {
		try {
			const response: AxiosResponse<ICommentsAttachmentDTO[]> =
				await apiClient.get('', {
					params: {
						resource: this._partialsResources.attachments,
						params: this.stringify({
							parent_object_id: id
						})
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'GET - Attachments')
				throw new Error(
					'Error while fetching the attachments: ' + error.message
				)
			}

			throw error
		}
	}

	public getNumericTransactions = async (
		id
	): Promise<IRequestNumericTransactionDTO[]> => {
		try {
			const response: AxiosResponse<IRequestNumericTransactionDTO[]> =
				await apiClient.get('', {
					params: {
						resource: this._partialsResources.numericTransactions,
						params: this.stringify({
							incident_id: id,
							include_attachments_metadata: 'true'
						})
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'GET - Numeric Transactions')
				throw new Error(
					'Error while fetching the numeric transactions: ' + error.message
				)
			}

			throw error
		}
	}

	public postConsent = async (dto: IRequestConsentDTO): Promise<string> => {
		try {
			const response: AxiosResponse<BackendPostResponseType> =
				await apiClient.post('', dto, {
					params: {
						resource: this._partialsResources.consents
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data.resourceid
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'POST - Consent')
				throw new Error(
					'Error while creating a consent for the request: ' + error.message
				)
			}

			throw error
		}
	}

	public deleteConsent = async (consentId: string): Promise<void> => {
		try {
			await apiClient.delete('', {
				params: {
					resource: this._partialsResources.consents,
					resourceId: consentId
				},
				headers: {
					token: await acquireAccessToken()
				}
			})
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'DELETE - Consent')
				throw new Error(
					'Error while deleting a consent for the request: ' + error.message
				)
			}

			throw error
		}
	}

	public getSASBlobStorage = async (object): Promise<string> => {
		try {
			const response: AxiosResponse<{ sasToken: string }> =
				await apiClient.get('', {
					params: {
						resource: this._partialsResources.blobSas
					},
					headers: {
						token: await acquireAccessToken(),
						'x-request-context-data': object.toString()
					}
				})

			return response.data.sasToken
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				this._logger.error(error.message, 'GET - Blob SAS')
				throw new Error(
					'Error while fetching the blob SAS: ' + error.message
				)
			}

			throw error
		}
	}
}
