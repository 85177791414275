import React, { FC, useContext } from 'react'
import Modal, { ModalTypeProp } from '../../modal'
import Text from '@components/ui/text'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { makeClasses } from '@utils/styles'
import { Icon } from '../../Icon'
import {
	AnimalInformation,
	AnimalInformationFile,
	AnimalType,
	RadioList,
	RequestInputFiles
} from '@utils/request'
import Breakpoints from '@utils/breakpoints'
import RequestFiles from '@components/ui/requestFiles'
import CheckboxInput from '@components/requestForms/inputs/checkbox.input'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import moment from 'moment'

type SelectListType = {
	label: string
	value: string
}

type propsTypes = {
	isModalOpen: boolean
	cancelButtonText?: string
	primaryButtonText?: string
	type?: ModalTypeProp
	swapButtonPositions?: boolean
	iconColor?: string
	close: () => void
	animalInformation?: AnimalInformation
	onPrimaryButton: () => void
	onCloseModal?: () => void
	dogRaceList?: SelectListType[]
	catRaceList?: SelectListType[]
	colorList?: SelectListType[]
	weigthUnitList?: RadioList[]
	files?: AnimalInformationFile
	permitTypeList: RadioList[]
	typeOfPermit?: string
	reasonForRenewalList?: RadioList[]
	permitDurationLabel?: string
}

type ClassType = {
	modalContent: string
	img: string
	answer: string
	fieldsContainer: string
	modalItem: string
	requestFilesMargin: string
}

const classes: ClassType = makeClasses({
	modalContent: {
		margin: '-20px 0 0'
		// textAlign: 'center'
	},
	img: {
		width: '40px',
		height: '40px',
		marginBottom: '20px'
	},
	answer: {
		display: 'block',
		margin: '0px 0px 15px',
		whiteSpace: 'pre-wrap'
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	modalItem: {
		margin: '-5px 0'
	},
	requestFilesMargin: {
		marginBottom: '12px',
		'& section div': {
			marginTop: '5px'
		}
	}
})

const AnimalInforamtionModal: FC<propsTypes> = ({
	isModalOpen,
	close,
	animalInformation,
	onPrimaryButton,
	cancelButtonText,
	primaryButtonText,
	dogRaceList,
	catRaceList,
	colorList,
	weigthUnitList,
	files,
	onCloseModal,
	permitTypeList,
	typeOfPermit,
	reasonForRenewalList,
	permitDurationLabel
}) => {
	const { pageData } = useContext(AppStateContext)
	const radioListYesNo: RadioList[] = [
		{
			label: pageData?.assets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageData?.assets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]
	const radioListGender: RadioList[] = [
		{
			label: pageData?.assets?.label_male,
			value: REQUEST_CONFIGS_KEYS.gender.male,
			key: 'label_male'
		},
		{
			label: pageData?.assets?.label_female,
			value: REQUEST_CONFIGS_KEYS.gender.female,
			key: 'label_female'
		}
	]
	const getLabelFromList = (value?: string, list?: RadioList[]) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}
	const getLocaleDate = (value: string) => {
		if (value) {
			return moment(value).utc().format('DD/MM/YYYY')
		}
		return null
	}
	const raceList = {
		[AnimalType.Dog]: dogRaceList,
		[AnimalType.Cat]: catRaceList
	}
	const isDog = animalInformation?.type == AnimalType.Dog
	const getFile = (name: string) => {
		if (files) return new Set(files[name])
	}

	return (
		<Modal
			cancelButtonText={cancelButtonText}
			primaryButtonText={primaryButtonText}
			isOpen={isModalOpen}
			onCancelBtn={() => {
				close()
			}}
			onPrimaryButton={() => {
				onPrimaryButton()
			}}
			onSetIsOpen={() => {}}
			onCloseModal={onCloseModal}
			alignBtn={'left'}
			title={`${animalInformation?.name} (${
				pageData?.assets[isDog ? 'label_dog' : 'label_cat']
			})`}
			showExitBtn
		>
			<div className={classes.modalContent}>
				<div style={{ marginTop: '20px' }}>
					{typeOfPermit == permitTypeList[0].value && (
						<>
							<div className={classes.fieldsContainer}>
								<div className={classes.modalItem}>
									<strong>{pageData?.assets.label_name_of_animal}</strong>
									<br />
									<span className={classes.answer}>
										{animalInformation?.name}
									</span>
								</div>
								<div className={classes.modalItem}>
									<strong>{pageData?.assets.label_sex_of_animal}</strong>
									<br />
									<span className={classes.answer}>
										{getLabelFromList(
											animalInformation?.gender,
											radioListGender
										)}
									</span>
								</div>
								<div className={classes.modalItem}>
									<strong>{pageData?.assets.label_race}</strong>
									<br />
									<span className={classes.answer}>
										{getLabelFromList(
											animalInformation?.breed,
											raceList[animalInformation?.type ?? AnimalType.Dog]
										)}
										{String(animalInformation?.cross_breed) == 'true' &&
											` (${pageData?.assets?.request_form_annualPermitAndTagForAnimals_crossed})`}
									</span>
								</div>
								{animalInformation?.color && (
									<div className={classes.modalItem}>
										<strong>
											{
												pageData?.assets
													.request_form_annualRegistrationCertificateForContractors_vehicleInformationColor_label
											}
										</strong>
										<br />
										<span className={classes.answer}>
											{getLabelFromList(
												animalInformation?.color,
												colorList as RadioList[]
											)}
										</span>
									</div>
								)}

								{animalInformation?.distinctive_sign && (
									<div className={classes.modalItem}>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_distinctive_sign
											}
										</strong>
										<br />
										<span className={classes.answer}>
											{animalInformation?.distinctive_sign}
										</span>
									</div>
								)}

								<div className={classes.modalItem}>
									<strong>{pageData?.assets.label_date_of_birth}</strong>
									<br />
									<span className={classes.answer}>
										{getLocaleDate(animalInformation?.birth_date ?? '')}
									</span>
								</div>
								{animalInformation?.weight && (
									<div className={classes.modalItem}>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_weight
											}
										</strong>
										<br />
										<span className={classes.answer}>
											{animalInformation?.weight}&nbsp;
											{getLabelFromList(
												animalInformation?.weight_unit,
												weigthUnitList
											)}
										</span>
									</div>
								)}
								{animalInformation?.birth_place && (
									<div className={classes.modalItem}>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_place_of_adoption
											}
										</strong>
										<br />
										<span className={classes.answer}>
											{animalInformation?.birth_place}
										</span>
									</div>
								)}
							</div>

							<div style={{ marginTop: '10px' }}></div>
							<div className={classes.modalItem}>
								<strong>
									{
										pageData?.assets
											.request_form_annualPermitAndTagForAnimals_is_the_animal_microchipped
									}
								</strong>
								<br />
								<span className={classes.answer}>
									{getLabelFromList(
										animalInformation?.is_micro_chipped,
										radioListYesNo
									)}
								</span>
							</div>

							{animalInformation?.is_micro_chipped?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div className={classes.modalItem}>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_number_of_micropuce
											}
										</strong>
										<br />
										<span className={classes.answer}>
											{animalInformation?.micro_chipped_number}
										</span>
									</div>
									{!!new Set(files?.proof_of_microchipped).size && (
										<div>
											<strong>
												{
													pageData?.assets
														.request_form_annualPermitAndTagForAnimals_proof_of_microchipped
												}
											</strong>
											<div className={classes.requestFilesMargin}>
												<RequestFiles
													required={false}
													files={new Set(files?.proof_of_microchipped)}
													hasError={false}
													removeSectionMarginTop
													removePadding
													onSetHasError={() => {}}
													onSetFiles={() => {}}
													isSummary
												>
													<></>
												</RequestFiles>
											</div>
										</div>
									)}
								</>
							)}
							<div>
								{animalInformation?.is_micro_chipped?.toString() ==
									radioListYesNo[1].value &&
									!!new Set(files?.proof_of_microchipped_contract).size && (
										<div>
											<strong>
												{
													pageData?.assets
														.request_form_annualPermitAndTagForAnimals_proof_of_microchipped_contract
												}
											</strong>
											<div className={classes.requestFilesMargin}>
												<RequestFiles
													required={false}
													files={new Set(files?.proof_of_microchipped_contract)}
													hasError={false}
													removeSectionMarginTop
													removePadding
													onSetHasError={() => {}}
													onSetFiles={() => {}}
													isSummary
												>
													<></>
												</RequestFiles>
											</div>
										</div>
									)}
							</div>
							<div className={classes.modalItem}>
								<strong>
									{
										pageData?.assets
											.request_form_annualPermitAndTagForAnimals_is_the_animal_sterilized
									}
								</strong>
								<br />
								<span className={classes.answer}>
									{getLabelFromList(
										animalInformation?.is_sterilized,
										radioListYesNo
									)}
								</span>
							</div>
							{animalInformation?.is_sterilized?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div className={classes.modalItem}>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_date_sterilized
											}
										</strong>
										<br />
										<span className={classes.answer}>
											{getLocaleDate(animalInformation?.sterilized_date ?? '')}
										</span>
									</div>
									{!!new Set(files?.proof_of_sterilization).size && (
										<div>
											<strong>
												{
													pageData?.assets
														.request_form_annualPermitAndTagForAnimals_proof_of_sterilization
												}
											</strong>
											<div className={classes.requestFilesMargin}>
												<RequestFiles
													required={false}
													files={new Set(files?.proof_of_sterilization)}
													hasError={false}
													removeSectionMarginTop
													removePadding
													onSetHasError={() => {}}
													onSetFiles={() => {}}
													isSummary
												>
													<></>
												</RequestFiles>
											</div>
										</div>
									)}
								</>
							)}
							{animalInformation?.is_sterilized?.toString() ==
								radioListYesNo[1].value && (
								<>
									{!!new Set(files?.proof_of_sterilization_exemption).size && (
										<div>
											<strong>
												{
													pageData?.assets
														.request_form_annualPermitAndTagForAnimals_proof_of_sterilization_exemption
												}
											</strong>
											<div className={classes.requestFilesMargin}>
												<RequestFiles
													required={false}
													files={
														new Set(files?.proof_of_sterilization_exemption)
													}
													hasError={false}
													removeSectionMarginTop
													removePadding
													onSetHasError={() => {}}
													onSetFiles={() => {}}
													isSummary
												>
													<></>
												</RequestFiles>
											</div>
										</div>
									)}
								</>
							)}

							<div className={classes.modalItem}>
								<strong>
									{
										pageData?.assets
											.request_form_annualPermitAndTagForAnimals_is_the_animal_vaccinated
									}
								</strong>
								<br />
								<span className={classes.answer}>
									{getLabelFromList(
										animalInformation?.is_rabies_vaccinated,
										radioListYesNo
									)}
								</span>
							</div>

							{animalInformation?.is_rabies_vaccinated?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div className={classes.modalItem}>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_date_of_vaccination
											}
										</strong>
										<br />
										<span className={classes.answer}>
											{getLocaleDate(
												animalInformation?.rabies_vaccinated_date ?? ''
											)}
										</span>
									</div>
									{!!new Set(files?.proof_of_rabies_vaccination).size && (
										<div>
											<strong>
												{
													pageData?.assets
														.request_form_annualPermitAndTagForAnimals_proof_of_rabies_vaccination
												}
											</strong>
											<div className={classes.requestFilesMargin}>
												<RequestFiles
													required={false}
													files={new Set(files?.proof_of_rabies_vaccination)}
													hasError={false}
													removeSectionMarginTop
													removePadding
													onSetHasError={() => {}}
													onSetFiles={() => {}}
													isSummary
												>
													<></>
												</RequestFiles>
											</div>
										</div>
									)}
								</>
							)}

							<>
								<div className={classes.modalItem}>
									<strong>
										{isDog
											? pageData?.assets
													.request_form_annualPermitAndTagForAnimals_is_service_dog
											: pageData?.assets
													.request_form_annualPermitAndTagForAnimals_is_service_cat}
									</strong>
									<br />
									<span className={classes.answer}>
										{getLabelFromList(
											animalInformation?.service_animal,
											radioListYesNo
										)}
									</span>
								</div>
								{!!new Set(files?.proof_of_need_of_service_animal).size && (
									<div>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_proof_of_need_of_service_animal
											}
										</strong>
										<div className={classes.requestFilesMargin}>
											<RequestFiles
												required={false}
												files={new Set(files?.proof_of_need_of_service_animal)}
												hasError={false}
												removeSectionMarginTop
												removePadding
												onSetHasError={() => {}}
												onSetFiles={() => {}}
												isSummary
											>
												<></>
											</RequestFiles>
										</div>
									</div>
								)}
							</>

							<div className={classes.modalItem}>
								<strong>
									{
										pageData?.assets
											.request_form_annualPermitAndTagForAnimals_duration_of_permit
									}
								</strong>
								<br />
								<span className={classes.answer}>{permitDurationLabel}</span>
							</div>
							{/* {animalInformation?.covered_exception && (
								<CheckboxInput
									name={``}
									label={
										pageData?.assets
											.info_form_annualPermitAndTagForAnimals_covered_exception
									}
									value={animalInformation?.covered_exception ?? ''}
									onChange={(e) => {}}
									hasError={false}
									errors={[]}
									onFixError={() => {}}
									hasBackgroundWrapper={true}
									UpdateRequestInputsErrorsArray={(
										errors: string[],
										inputName: string
									) => []}
									shouldNotDivide
								/>
							)} */}
						</>
					)}
					{typeOfPermit == permitTypeList[1].value && (
						<>
							<div className={classes.modalItem}>
								<strong>{pageData?.assets.label_name_of_animal}</strong>
								<br />
								<span className={classes.answer}>
									{animalInformation?.name}
								</span>
							</div>
							<div className={classes.modalItem}>
								<strong>
									{
										pageData?.assets
											.request_form_annualPermitAndTagForAnimals_medal_number
									}
								</strong>
								<br />
								<span className={classes.answer}>
									{animalInformation?.tag_number != '' &&
									animalInformation?.tag_number != null
										? animalInformation?.tag_number
										: pageData?.assets.label_unknown}
								</span>
							</div>
							{animalInformation?.non_renewal_reason && (
								<div>
									<strong>
										{
											pageData?.assets
												.request_form_annualPermitAndTagForAnimals_reason_for_non_renewal
										}
									</strong>
									<br />
									<span className={classes.answer}>
										{
											pageData?.assets[
												reasonForRenewalList?.find(
													(el) =>
														el.value ==
														animalInformation?.non_renewal_reason?.toString()
												)?.key!
											]
										}
									</span>
								</div>
							)}
							{animalInformation?.service_animal && (
								<>
									<div className={classes.modalItem}>
										<strong>
											{isDog
												? pageData?.assets
														.request_form_annualPermitAndTagForAnimals_is_service_dog
												: pageData?.assets
														.request_form_annualPermitAndTagForAnimals_is_service_cat}
										</strong>
										<br />
										<span className={classes.answer}>
											{getLabelFromList(
												animalInformation?.service_animal,
												radioListYesNo
											)}
										</span>
									</div>
									{!!new Set(files?.proof_of_need_of_service_animal).size && (
										<div>
											<strong>
												{
													pageData?.assets
														.request_form_annualPermitAndTagForAnimals_proof_of_need_of_service_animal
												}
											</strong>
											<div className={classes.requestFilesMargin}>
												<RequestFiles
													required={false}
													files={
														new Set(files?.proof_of_need_of_service_animal)
													}
													hasError={false}
													removeSectionMarginTop
													removePadding
													onSetHasError={() => {}}
													onSetFiles={() => {}}
													isSummary
												>
													<></>
												</RequestFiles>
											</div>
										</div>
									)}
								</>
							)}
							{animalInformation?.is_renewing?.toString() == 'true' &&
								animalInformation?.permit_term && (
									<div className={classes.modalItem}>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_renewal_duration
											}
										</strong>
										<br />
										<span className={classes.answer}>
											{permitDurationLabel}
										</span>
									</div>
								)}
						</>
					)}

					{typeOfPermit == permitTypeList[2]?.value && (
						<>
							<div className={classes.modalItem}>
								<strong>{pageData?.assets.label_name_of_animal}</strong>
								<br />
								<span className={classes.answer}>
									{animalInformation?.name}
								</span>
							</div>
							<div className={classes.modalItem}>
								<strong>
									{
										pageData?.assets
											.request_form_annualPermitAndTagForAnimals_medal_number
									}
								</strong>
								<br />
								<span className={classes.answer}>
									{animalInformation?.tag_number != '' &&
									animalInformation?.tag_number != null
										? animalInformation?.tag_number
										: pageData?.assets.label_unknown}
								</span>
							</div>

							{/* damaged_medal */}
							{/* {animalInformation?.is_renewing == 'false' && ( */}
							<>
								{!!new Set(files?.proof_of_damaged_medal).size && (
									<div>
										<strong>
											{
												pageData?.assets
													.request_form_annualPermitAndTagForAnimals_proof_of_damaged_medal
											}
										</strong>
										<div className={classes.requestFilesMargin}>
											<RequestFiles
												required={false}
												files={new Set(files?.proof_of_damaged_medal)}
												hasError={false}
												removeSectionMarginTop
												removePadding
												onSetHasError={() => {}}
												onSetFiles={() => {}}
												isSummary
											>
												<></>
											</RequestFiles>
										</div>
									</div>
								)}
							</>
							{/* )} */}
						</>
					)}
				</div>
			</div>
		</Modal>
	)
}

export default AnimalInforamtionModal
