import React, {
	Dispatch,
	FC,
	SetStateAction,
	useContext,
	useEffect,
	useState
} from 'react'
import { navigate } from '@components/ui/link'
import { formatStrapiText } from '@utils/methods'
import { Dictionary } from 'typings/shared'
import * as pageUtils from '@pages/auth/requests/__index.utils'
import { joinClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import {
	envelopeBlueIcon,
	notificationLightBlueIcon,
	paymentPurpleIcon
} from '@images/icons'
import { Icon } from '../Icon'
import { Request } from '@services/models'
import ButtonWithTooltip from '../buttonWithTooltip'
import StatusBadge from '../statusBadge'
import AddInformationModal from '../addInformationModal'
import CancelRequestModal from '@components/ui/cancelRequestModal'
import {
	CATEGORIES,
	IDENTIFIERS_OF_TN_REQUEST_TYPE,
	RequestPartialsEnum
} from '@services/constants'
import { AppStateContext } from '@components/layouts/DynamicLayout'

const ADD_REQUEST_CONSTANT = 'Ajout d’une information à votre demande'
const CANCELED_REQUEST_CONSTANT = 'Annulation de votre demande'

type RequestCardProps = {
	pageAssets: Dictionary
	request: Request
	requestTypes: any
	dataTestid?: string
	onConsentValidated: (consentId) => void
	setRequestCode: Dispatch<SetStateAction<string | undefined>>
}

export const getStatusBadgeLabel = (
	assets: Dictionary,
	status: pageUtils.IRequest['status']
): string => {
	if (!status) {
		return ''
	}

	return formatStrapiText(assets?.['badge_' + status])
}

const RequestCard: FC<RequestCardProps> = ({
	pageAssets,
	request,
	requestTypes,
	onConsentValidated,
	setRequestCode
}) => {
	const { authUser } = useContext(AppStateContext)

	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
	const getNewMessageLabel = (messageCount: number): string => {
		if (!messageCount) {
			return ''
		}
		return (
			messageCount +
			' ' +
			(messageCount > 1
				? formatStrapiText(pageAssets?.request_newMessages)
				: formatStrapiText(pageAssets?.request_newMessage))
		)
	}

	const openRequest = (e, link) => {
		navigate(`${link}`)
	}

	const emailConsentmentCount = () => {
		const emails = request.emails

		const consentCount = emails?.reduce((acc, email) => {
			if (
				email.category === CATEGORIES.CREATE_CONSENT ||
				email.category === CATEGORIES.REVOKE_CONSENT ||
				email.title.includes(ADD_REQUEST_CONSTANT) ||
				email.title.includes(CANCELED_REQUEST_CONSTANT)
			) {
				return acc + 1
			}
			return acc
		}, 0)

		return consentCount
	}

	const addInfo = (e) => {
		e.stopPropagation()
		setIsOpen(true)
	}

	const openCancelModal = (e) => {
		e.stopPropagation()
		setIsCancelModalOpen(true)
	}

	const isPublicAuctionForm = (request) => {
		return request.typeId == IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS
	}

	const getRequestTitle = (value: string) => {
		let title = requestTypes?.find(
			(request) => request.requestId == value
		)?.title

		if (!title) {
			title = requestTypes?.find(
				(request) => request.requestId == 'other-subject'
			)?.title
		}

		return title
	}

	// Fonction pour les use cases dont le payement n'est pas necessaire
	// actuelement juste pour ne pas afficher le bloc de payement et recp de payement
	// se trouve à deux emplacements: TNRequestDetails et requestCard
	const isParticularConditionsOfNoPaymentRequired = () => {
		if (
			[
				IDENTIFIERS_OF_TN_REQUEST_TYPE.FIRE_HYDRANTS_TANK_FILLING_FORM,
				IDENTIFIERS_OF_TN_REQUEST_TYPE.FIRE_HYDRANTS_TEMP_AQUEDUCT_NETWORK_FORM,
				IDENTIFIERS_OF_TN_REQUEST_TYPE.HYDRAULIC_AND_PRESSURE_TESTS
			].includes(request.typeId as IDENTIFIERS_OF_TN_REQUEST_TYPE) &&
			request?.incidentForm?.isContractWithCity == true
		) {
			return true
		}

		return false
	}

	const partialCount = async () => {
		const result = await authUser?.fetchPartials(request, [
			RequestPartialsEnum.EMAILS,
			RequestPartialsEnum.PHONE_CALL
		])

		setUnreadActivities(
			result?.additionalInformation?.unreadEmail! -
				emailConsentmentCount() +
				result?.additionalInformation?.unreadPhoneCall! +
				result?.additionalInformation?.unreadAppointment!
		)
	}

	const [unreadActivities, setUnreadActivities] = useState<number>(
		request?.additionalInformation?.unreadEmail! -
			emailConsentmentCount() +
			request?.additionalInformation?.unreadPhoneCall! +
			request?.additionalInformation?.unreadAppointment!
	)

	useEffect(() => {
		setUnreadActivities(0)
		partialCount().catch(console.error)
	}, [request])

	return (
		<>
			<AddInformationModal
				setModal={() => setIsOpen(false)}
				request={request!}
				isOpen={isOpen}
				onInformationAdded={onConsentValidated}
				setRequestCode={setRequestCode}
			/>
			<CancelRequestModal
				request={request!}
				isOpen={isCancelModalOpen}
				onCancel={() => {
					setIsCancelModalOpen(false)
				}}
				title={
					isPublicAuctionForm(request)
						? pageAssets?.request_form_cancel_registration
						: undefined
				}
				description={
					isPublicAuctionForm(request)
						? pageAssets?.request_form_publicAuctions_cancel_registration
						: undefined
				}
				isDefaultCancelReason={isPublicAuctionForm(request)}
			/>

			<div
				onClick={(e) => openRequest(e, request.link)}
				className={pageUtils.classes.a}
			>
				<div className={pageUtils.classes.card}>
					<div className={pageUtils.classes.col1}>
						<div className={pageUtils.classes.info}>
							<div className={pageUtils.classes.title}>
								{getRequestTitle(request.typeId)}
							</div>
							<div className={pageUtils.classes.code}>{request.code}</div>
							{request.dateUpdate && (
								<div className={pageUtils.classes.date}>
									<span className={pageUtils.classes.dateSpan}>
										{formatStrapiText(pageAssets?.label_lastModification)}:
									</span>{' '}
									{request.dateUpdate.localDateString}
								</div>
							)}
							<div className={pageUtils.classes.date}>
								<span className={pageUtils.classes.dateSpan}>
									{formatStrapiText(pageAssets?.label_creationDate)}:
								</span>{' '}
								{request.dateAdd?.localDateString}
							</div>
						</div>
						<div className={pageUtils.classes.info2}>
							<span className={pageUtils.classes.infoSection}>
								<div className={pageUtils.classes.unReadMessages}>
									{/*{(request?.additionalInformation?.unreadEmail! > 0 ||
										request?.additionalInformation?.unreadPhoneCall! > 0 ||
										request?.additionalInformation?.unreadAppointment! > 0) && (
										<>*/}
									{!!unreadActivities && (
										<span
											className={joinClasses([
												pageUtils.classes.iconWrapper,
												pageUtils.classes.notificationBackground
											])}
										>
											<Icon
												className={pageUtils.classes.icon}
												src={notificationLightBlueIcon}
											/>
										</span>
									)}
									<span style={{ color: Colors.darkGrey, fontWeight: 500 }}>
										{getNewMessageLabel(unreadActivities)}
									</span>
									{/*</>
									)}*/}
								</div>
								{/* {request?.invoice &&
									request?.invoice?.status ===
										INVOICE_STATUS.waitingForPayment &&
									// Test pour les use cases dont le payement n'est pas necessaire
									!isParticularConditionsOfNoPaymentRequired() && (
										<div className={pageUtils.classes.unReadMessages}>
											<>
												<span
													className={joinClasses([
														pageUtils.classes.iconWrapper,
														pageUtils.classes.paymentBackground
													])}
												>
													<Icon
														className={pageUtils.classes.icon}
														src={`${paymentPurpleIcon}`}
													/>
												</span>
												<span
													style={{ color: Colors.darkGrey, fontWeight: 500 }}
												>
													{pageAssets.request_waiting_for_payment}
												</span>
											</>
										</div>
									)} */}
							</span>
							{request.status !== 'closed' && (
								<span className={pageUtils.classes.badgeSection}>
									<StatusBadge request={request} pageAssets={pageAssets} />
								</span>
							)}
						</div>
					</div>
					<div
						className={joinClasses([
							pageUtils.classes.action2,
							pageUtils.classes.col2
						])}
					>
						<ButtonWithTooltip type="edit" onclick={addInfo} />
						{request.status !== 'closed' && !request.cancelReason && (
							<ButtonWithTooltip type="cancel" onclick={openCancelModal} />
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default RequestCard
