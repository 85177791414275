import React, { FC, useContext } from 'react'
import Modal, { ModalTypeProp } from '../../modal'
import Text from '@components/ui/text'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { makeClasses } from '@utils/styles'
import { Icon } from '../../Icon'
import {
	AnimalInformation,
	AnimalInformationFile,
	AnimalType,
	ParkingPermitFile,
	ParkingPermitInformation,
	RadioList,
	RequestInputFiles,
	SelectList
} from '@utils/request'
import Breakpoints from '@utils/breakpoints'
import RequestFiles from '@components/ui/requestFiles'
import { Colors } from '@utils/css-variables'
import { capitalize } from '@utils/methods'

type propsTypes = {
	isModalOpen: boolean
	cancelButtonText?: string
	primaryButtonText?: string
	type?: ModalTypeProp
	swapButtonPositions?: boolean
	iconColor?: string
	close: () => void
	parkingInformation?: ParkingPermitInformation
	onPrimaryButton: () => void
	onCloseModal?: () => void
	files?: ParkingPermitFile
	isConsultationPage?: boolean
}

type ClassType = {
	modalContent: string
	img: string
	answer: string
	fieldsContainer: string
	modalItem: string
	requestFilesMargin: string
	h6: string
}

const classes: ClassType = makeClasses({
	modalContent: {
		margin: '-20px 0 0'
		// textAlign: 'center'
	},
	img: {
		width: '40px',
		height: '40px',
		marginBottom: '20px'
	},
	answer: {
		display: 'block',
		margin: '0px 0px 15px',
		whiteSpace: 'pre-wrap'
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	modalItem: {
		margin: '-5px 0'
	},
	requestFilesMargin: {
		marginBottom: '12px',
		'& section div': {
			marginTop: '5px'
		}
	},
	h6: {
		color: Colors.secondary,
		margin: '30px 0 15px',
		fontSize: '1rem',
		fontWeight: 500,
		lineHeight: 1.2
	}
})

const ParkingPermitModal: FC<propsTypes> = ({
	isModalOpen,
	close,
	parkingInformation,
	onPrimaryButton,
	cancelButtonText,
	primaryButtonText,
	files,
	onCloseModal,
	isConsultationPage
}) => {
	const { pageData } = useContext(AppStateContext)
	const radioListYesNo: RadioList[] = [
		{
			label: pageData?.assets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageData?.assets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	return (
		<Modal
			cancelButtonText={cancelButtonText}
			primaryButtonText={primaryButtonText}
			isOpen={isModalOpen}
			onCancelBtn={() => {
				close()
			}}
			onPrimaryButton={() => {
				onPrimaryButton()
			}}
			onSetIsOpen={() => {}}
			onCloseModal={onCloseModal}
			alignBtn={'left'}
			title={`${capitalize(pageData?.assets?.label_vignette)} ${
				parkingInformation?.renewal_sticker_number
			}`}
			showExitBtn
		>
			<div className={classes.modalContent}>
				<div style={{ marginTop: '20px' }}>
					<div className={classes.fieldsContainer}>
						{parkingInformation?.reason_for_replacement && (
							<div>
								<strong>
									{pageData?.assets.label_what_is_the_reason_for_replacement}
								</strong>
								<br />
								<span className={classes.answer}>
									{/* {getLabel(
									reasonForReplacementList,
									parkingInformation?.reason_for_replacement
								)?.label}
									{ } */}
								</span>
							</div>
						)}

						{parkingInformation?.renewal_sticker_number && (
							<div>
								<strong>{pageData?.assets.label_vignette_number}</strong>
								<br />
								<span className={classes.answer}>
									{parkingInformation?.renewal_sticker_number}
								</span>
							</div>
						)}

						{parkingInformation?.renewal_sticker_owner_name && (
							<div>
								<strong>{pageData?.assets.label_name_of_vignette_owner}</strong>
								<br />
								<span className={classes.answer}>
									{parkingInformation?.renewal_sticker_owner_name}
								</span>
							</div>
						)}

						<div>
							<strong>{pageData?.assets.label_license_plate_number}</strong>
							<br />
							<span className={classes.answer}>
								{parkingInformation?.vehicle_plate_number}
							</span>
						</div>

						<div>
							<strong>{pageData?.assets.label_brand}</strong>
							<br />
							<span className={classes.answer}>
								{parkingInformation?.vehicle_brand}
							</span>
						</div>

						<div>
							<strong>{pageData?.assets.label_model}</strong>
							<br />
							<span className={classes.answer}>
								{parkingInformation?.vehicle_model}
							</span>
						</div>

						<div>
							<strong>{pageData?.assets.label_year}</strong>
							<br />
							<span className={classes.answer}>
								{parkingInformation?.vehicle_year}
							</span>
						</div>
					</div>

					{parkingInformation?.vehicle_owner?.toString() && (
						<div>
							<strong>
								{pageData?.assets.label_is_owner_of_vignette_owner_of_property}
							</strong>
							<br />
							<span className={classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									parkingInformation?.vehicle_owner?.toString() 
								)}
							</span>
						</div>
					)}

					{/* Documents */}

					{!isConsultationPage && (
						<>
							<h6 className={classes.h6}>
								{pageData?.assets.label_justification_document}
							</h6>
							<RequestFiles
								subtitle={
									pageData?.assets.request_form_parkingPermit_proof_of_recidence
								}
								name={`parkingPermitInfo_applicant_proof_of_recidence${0}`}
								files={new Set<File>(files?.applicant_proof_of_recidence)}
								hasError={false}
								removeSectionMarginTop
								onSetHasError={() => {}}
								onSetFiles={() => {}}
								maxFiles={10}
								description={''}
								isSummary
							></RequestFiles>
							<RequestFiles
								subtitle={pageData?.assets.request_form_vehicle_registration}
								name={`parkingPermitInfo_vehicle_registration${0}`}
								files={new Set<File>(files?.vehicle_registration)}
								hasError={false}
								removeSectionMarginTop
								onSetHasError={() => {}}
								onSetFiles={(e) => {}}
								maxFiles={10}
								description={''}
								isSummary
							></RequestFiles>

							{files?.proof_of_insurance &&
								files?.proof_of_insurance?.length > 0 && (
									<RequestFiles
										subtitle={
											pageData?.assets
												.request_form_parkingPermit_proof_of_insurance
										}
										name={`parkingPermitInfo_proof_of_insurance${0}`}
										files={new Set<File>(files?.proof_of_insurance)}
										hasError={false}
										removeSectionMarginTop
										onSetHasError={() => {}}
										onSetFiles={(e) => {}}
										maxFiles={10}
										description={''}
										isSummary
									></RequestFiles>
								)}
						</>
					)}
				</div>
			</div>
		</Modal>
	)
}

export default ParkingPermitModal
