import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { CheckboxList, RadioList, SelectList } from '@utils/request'
import Collapse from '@components/ui/collapse'
import Modal from '@components/ui/modal'
import * as formUtils from '@components/requestForms/__forms.utils'
import { joinClasses } from '@utils/styles'
import { formatNumberWithSpaces } from '@components/requestForms/__forms.utils'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'

const ResidentialRenovationOrRepairPermitFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}, //Entrepreneur or manager
		{
			label: pageAssets?.request_form_entrepreneur_or_manager,
			value: REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			key: 'request_form_entrepreneur_or_manager'
		}
	]

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const radioListWhatToDo: RadioList[] = [
		{
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_request_interiorAndExterior,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
					.request_interiorAndExterior,
			key: 'request_form_residentialRenovationOrRepairPermit_request_interiorAndExterior'
		},
		{
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_request_exterior,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
					.request_exterior,
			key: 'request_form_residentialRenovationOrRepairPermit_request_exterior'
		},
		{
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_request_interior,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
					.request_interior,
			key: 'request_form_residentialRenovationOrRepairPermit_request_interior'
		}
	]

	const radioListIsTheNewBuilding: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3'
		}
	]

	const radioListMainUseOfExistingBuilding: RadioList[] =
		formUtils.radioListMainUseOfExistingBuildingRenovation(pageAssets)

	const radioListWhoWillBeInChargeOfWork: RadioList[] =
		formUtils.radioListWhoWillBeInChargeOfWork(pageAssets)

	const radioListIsPropertyConnectedToMunicipalConduits: CheckboxList[] =
		formUtils.radioListIsPropertyConnectedToMunicipalConduits(pageAssets)

	const listPlannedWork: CheckboxList[] = formUtils.listPlannedWork(pageAssets)

	const questionAnswer = (question: string, answer?: string) => {
		if (answer != undefined) {
			return (
				<>
					<div className={pageUtils.classes.answerMargin}>
						<strong>{question}</strong>
						<br />
						<span>{answer}</span>
					</div>
				</>
			)
		}
	}

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	const { incidentForm } = request

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	const isRenovationInteriorAndExterior = () => {
		return (
			incidentForm?.whatToDo?.toString() ==
			REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
				.request_interiorAndExterior
		)
	}

	const isRenovationExterior = () => {
		return (
			incidentForm?.whatToDo?.toString() ==
			REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
				.request_exterior
		)
	}

	const isRenovationInterior = () => {
		return (
			incidentForm?.whatToDo?.toString() ==
			REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
				.request_interior
		)
	}

	const radioListTypesOfWorkRenovationExterior: CheckboxList[] =
		formUtils.radioListTypesOfWorkRenovationExterior(pageAssets)

	const radioListTypesOfWorkRenovationInterior: CheckboxList[] =
		formUtils.radioListTypesOfWorkRenovationInterior(pageAssets)

	const checkboxesListWallWhereAddOpeningsOrReplaceDoors: CheckboxList[] =
		formUtils.checkboxesListWallWhereAddOpeningsOrReplaceDoors(pageAssets)

	const isAucunChoix = () => {
		if (
			incidentForm?.existingMainBuildingIs?.toString() ==
			radioListIsTheNewBuilding[2].value
		) {
			return `(${radioListIsTheNewBuilding[0].label}, ${radioListIsTheNewBuilding[1].label})`
		}
		return ''
	}

	return (
		<>
			{/* Question 1 */}
			<div>
				<strong>{pageAssets.request_form_what_would_you_like_to_do}</strong>
				<br />
				<span>
					{getLabelFromList(radioListWhatToDo, incidentForm?.whatToDo)?.replace(
						/\*\*/g,
						''
					)}
				</span>
			</div>

			<p style={{ marginBottom: '20px' }}>
				<span
					onClick={() => {
						setIsModalOpen(true)
					}}
					className={pageUtils.classes.viewDetailsSpan}
				>
					<b>{pageAssets.request_form_show_details}</b>
				</span>
			</p>

			<Modal
				// cancelButtonText={pageAssets?.close_label}
				primaryButtonText={pageAssets?.close_label}
				isOpen={isModalOpen}
				onCancelBtn={() => {
					setIsModalOpen(false)
				}}
				onPrimaryButton={() => {
					setIsModalOpen(false)
				}}
				onSetIsOpen={() => {}}
				alignBtn={'left'}
				title={pageAssets?.request_form_request_details}
				showExitBtn
			>
				<div>
					<div
						style={{ marginTop: '-20px' }}
						className={joinClasses([
							pageUtils.classes.collapse,
							pageUtils.classes.summarySubTitles
						])}
					>
						<>
							<Collapse
								id={1}
								buttonText={pageAssets?.request_form_type_de_demandes}
								key={`request_form_type_de_demandes`}
								isOpen={false}
							>
								{/* Question 1 */}
								{questionAnswer(
									pageAssets?.request_form_what_would_you_like_to_do,
									getLabelFromList(
										radioListWhatToDo,
										incidentForm?.whatToDo
									)?.replace(/\*\*/g, '')
								)}

								{/* Question 2 */}
								{questionAnswer(
									pageAssets?.request_form_request_as,
									getLabelFromList(radioListRequestAs, incidentForm?.requestAs)
								)}

								{[REQUEST_CONFIGS_KEYS.requestAs.entreprise].includes(
									incidentForm?.requestAs?.toString() || ''
								) && (
									<>
										{questionAnswer(
											pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
											incidentForm?.companyName
										)}
										{questionAnswer(
											pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
											incidentForm?.representativeName
										)}
									</>
								)}

								{(isRenovationExterior() ||
									isRenovationInteriorAndExterior()) && (
									<>
										{/* Question 3: Is Subject to PIIA Approval */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.isSubjectToPiiaApproval
											)
										)}

										{/* Question 4: Has Been Subject to Approval */}
										{incidentForm?.isSubjectToPiiaApproval?.toString() ==
											radioListYesNo[0].value && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label,
													getLabelFromList(
														radioListYesNo,
														incidentForm?.hasBeenSubjectToApproval
													)
												)}
												{incidentForm?.hasBeenSubjectToApproval?.toString() ==
													radioListYesNo[0].value && (
													<>
														{questionAnswer(
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_iaNumber_label,
															incidentForm?.iaNumber
														)}

														{questionAnswer(
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label,
															incidentForm?.ecResolutionNumber
														)}
													</>
												)}
											</>
										)}
									</>
								)}

								<br />
							</Collapse>

							<Collapse
								id={2}
								buttonText={pageAssets?.request_form_request_location}
								key={`request_form_request_location`}
								isOpen={false}
							>
								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSameLocation,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.buildingAddressSameHasApplicant
									)
								)}

								{incidentForm?.buildingAddressSameHasApplicant?.toString() ==
									radioListYesNo[1].value && (
									<>
										{questionAnswer(
											pageAssets?.request_form_location,
											incidentForm?.addressField
										)}
										{questionAnswer(
											pageAssets?.label_cadastral_number,
											incidentForm?.addressLotNumber?.toString()
										)}

										{(incidentForm?.addressVoiePublic ||
											incidentForm?.addressCivicNumber) && (
											<div>
												<strong>
													{
														pageAssets?.request_form_addressVoiePublicSection_public_road
													}
												</strong>
												<br />
												<span>
													{incidentForm?.addressCivicNumber}
													{incidentForm?.addressCivicNumber &&
														incidentForm?.addressVoiePublic &&
														', '}
													{incidentForm?.addressVoiePublic}
												</span>
											</div>
										)}
										{(incidentForm?.addressVoiePublic2 ||
											incidentForm?.addressCivicNumber2) && (
											<div>
												<span>
													{incidentForm?.addressCivicNumber2}
													{incidentForm?.addressCivicNumber2 &&
														incidentForm?.addressVoiePublic2 &&
														', '}
													{incidentForm?.addressVoiePublic2}
												</span>
											</div>
										)}

										{(incidentForm?.addressVoiePublic3 ||
											incidentForm?.addressCivicNumber3) && (
											<div>
												<span>
													{incidentForm?.addressCivicNumber3}
													{incidentForm?.addressCivicNumber3 &&
														incidentForm?.addressVoiePublic3 &&
														', '}
													{incidentForm?.addressVoiePublic3}
												</span>
											</div>
										)}

										{incidentForm?.addressAdditionalInfos && (
											<div>
												<span>{incidentForm?.addressAdditionalInfos}</span>
											</div>
										)}
										<div className={pageUtils.classes.answerMargin}></div>
									</>
								)}

								{/* Question 9 */}
								{questionAnswer(
									pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.isLocationOwned
									)
								)}

								{/* ****** Owner inputs ****** */}
								{incidentForm?.isLocationOwned?.toString() ==
									radioListYesNo[1].value && (
									<>
										{questionAnswer(
											pageAssets?.label_firstName,
											incidentForm?.buildingOwnerInfo?.first_name
										)}
										{questionAnswer(
											pageAssets?.label_lastName,
											incidentForm?.buildingOwnerInfo?.last_name
										)}
										{questionAnswer(
											pageAssets?.label_email,
											incidentForm?.buildingOwnerInfo?.email
										)}
										{questionAnswer(
											pageAssets?.label_telephone,
											incidentForm?.buildingOwnerInfo?.telephone
										)}
									</>
								)}

								<br />
							</Collapse>

							<Collapse
								id={3}
								buttonText={pageAssets?.request_form_type_of_use}
								key={`request_form_type_of_use`}
								isOpen={false}
							>
								<>
									{/* Question 10 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding,
										getLabelFromList(
											radioListMainUseOfExistingBuilding,
											incidentForm?.mainUseOfExistingBuilding
										)
									)}

									{/* Question 12 */}
									{questionAnswer(
										pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty,
										getLabelFromList(
											radioListYesNo,
											incidentForm?.existingMainBuildingIsShared
										)
									)}
								</>

								<br />
							</Collapse>

							<Collapse
								id={4}
								buttonText={
									pageAssets?.request_form_information_on_renovations_and_repairs
								}
								key={`request_form_information_on_renovations_and_repairs`}
								isOpen={false}
							>
								{(isRenovationInteriorAndExterior() ||
									isRenovationExterior()) && (
									<>
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_information_on_exterior_renovations
											}
										</h5>

										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_residentialRenovationOrRepairPermit_typesOfWork
												}
											</strong>
											<br />
											<span className="no-margin">
												{radioListTypesOfWorkRenovationExterior.map(
													(item, index) =>
														incidentForm &&
														incidentForm[`constructionTypeExt${index + 1}`] && (
															<p key={index}>
																{parse(formatHtmlForStrapiText(item.label))}
															</p>
														)
												)}
											</span>
										</div>

										{(incidentForm?.numberOfCurrentHousing?.toString() ||
											incidentForm?.numberOfHousingAdded?.toString() ||
											incidentForm?.wallWhereAddOpenings1 ||
											incidentForm?.wallWhereAddOpenings2 ||
											incidentForm?.wallWhereAddOpenings3 ||
											incidentForm?.wallWhereAddOpenings4 ||
											incidentForm?.isThereOrWillThereBeOpenings?.toString() ||
											incidentForm?.detailsOnReplacingHeatingSystemWithGeothermal?.toString() ||
											incidentForm?.existingRoofingMaterial ||
											incidentForm?.projectedRoofingMaterial ||
											incidentForm?.existingExteriorCladdingMaterial ||
											incidentForm?.projectedExteriorCladdingMaterial ||
											incidentForm?.detailsOnRepairsToBeMadeToStructureOrFoundation ||
											incidentForm?.pleaseSpecifyOtherWork) && (
											<h6 className={formUtils.classes.h6}>
												{pageAssets?.request_form_additional_info}
											</h6>
										)}

										{(incidentForm?.constructionTypeExt1 ||
											incidentForm?.constructionTypeExt5 ||
											incidentForm?.constructionTypeExt6) && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_numberOfCurrentHousing,
													incidentForm?.numberOfCurrentHousing?.toString()
												)}

												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_numberOfHousingAdded,
													incidentForm?.numberOfHousingAdded?.toString()
												)}
											</>
										)}

										{(incidentForm?.constructionTypeExt3 ||
											incidentForm?.constructionTypeExt8) && (
											<>
												{(incidentForm?.wallWhereAddOpenings1 ||
													incidentForm?.wallWhereAddOpenings2 ||
													incidentForm?.wallWhereAddOpenings3 ||
													incidentForm?.wallWhereAddOpenings4) && (
													<div className={pageUtils.classes.answerMargin}>
														<strong>
															{
																pageAssets?.request_form_residentialRenovationOrRepairPermit_wallWhereAddOpeningsOrReplaceDoors
															}
														</strong>
														<br />
														<span className="no-margin">
															{checkboxesListWallWhereAddOpeningsOrReplaceDoors.map(
																(item, index) =>
																	incidentForm &&
																	incidentForm[
																		`wallWhereAddOpenings${index + 1}`
																	] && <p key={index}>{item.label}</p>
															)}
														</span>
													</div>
												)}

												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_isThereOrWillThereBeOpenings,
													getLabelFromList(
														radioListYesNo,
														incidentForm?.isThereOrWillThereBeOpenings
													)
												)}
											</>
										)}

										{incidentForm?.constructionTypeExt7 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_detailsOnReplacingHeatingSystemWithGeothermal,
													incidentForm?.detailsOnReplacingHeatingSystemWithGeothermal?.toString()
												)}
											</>
										)}

										{incidentForm?.constructionTypeExt9 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_existingRoofingMaterial,
													incidentForm?.existingRoofingMaterial
												)}

												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_projectedRoofingMaterial,
													incidentForm?.projectedRoofingMaterial
												)}
											</>
										)}

										{incidentForm?.constructionTypeExt10 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_existingExteriorCladdingMaterial,
													incidentForm?.existingExteriorCladdingMaterial
												)}

												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_projectedExteriorCladdingMaterial,
													incidentForm?.projectedExteriorCladdingMaterial
												)}
											</>
										)}

										{incidentForm?.constructionTypeExt13 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_detailsOnRepairsToBeMadeToStructureOrFoundation,
													incidentForm?.detailsOnRepairsToBeMadeToStructureOrFoundation
												)}
											</>
										)}

										{incidentForm?.constructionTypeExt14 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_pleaseSpecifyOtherWork,
													incidentForm?.pleaseSpecifyOtherWork
												)}
											</>
										)}
									</>
								)}

								{(isRenovationInteriorAndExterior() ||
									isRenovationInterior()) && (
									<>
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_information_on_interior_renovations
											}
										</h5>

										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_residentialRenovationOrRepairPermit_typesOfWork
												}
											</strong>
											<br />
											<span className="no-margin">
												{radioListTypesOfWorkRenovationInterior.map(
													(item, index) =>
														incidentForm &&
														incidentForm[`constructionTypeInt${index + 1}`] && (
															<p key={index}>
																{parse(formatHtmlForStrapiText(item.label))}
															</p>
														)
												)}
											</span>
										</div>

										{(incidentForm?.numberOfCurrentHousingInt?.toString() ||
											incidentForm?.numberOfHousingAddedInt?.toString() ||
											incidentForm?.detailsInt ||
											incidentForm?.bathroomLocation ||
											incidentForm?.housingLocation ||
											incidentForm?.pleaseSpecifyOtherWorkInt) && (
											<h6 className={formUtils.classes.h6}>
												{pageAssets?.request_form_additional_info}
											</h6>
										)}

										{incidentForm?.constructionTypeInt3 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_numberOfCurrentHousing,
													incidentForm?.numberOfCurrentHousingInt?.toString()
												)}
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_numberOfHousingAdded,
													incidentForm?.numberOfHousingAddedInt?.toString()
												)}
											</>
										)}

										{incidentForm?.constructionTypeInt5 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_detailsInt,
													incidentForm?.detailsInt
												)}
											</>
										)}

										{incidentForm?.constructionTypeInt2 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_bathroomLocation,
													incidentForm?.bathroomLocation
												)}
											</>
										)}

										{incidentForm?.constructionTypeInt3 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_housingLocation,
													incidentForm?.housingLocation
												)}
											</>
										)}

										{incidentForm?.constructionTypeInt7 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_residentialRenovationOrRepairPermit_pleaseSpecifyOtherWork,
													incidentForm?.pleaseSpecifyOtherWorkInt
												)}
											</>
										)}
									</>
								)}
								<br />
							</Collapse>

							<Collapse
								id={5}
								buttonText={pageAssets?.request_form_information_on_the_work}
								key={`request_form_information_on_the_work`}
								isOpen={false}
							>
								<>
									{/* Question 24 */}
									<div className={pageUtils.classes.answerMargin}>
										<strong>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster
											}
										</strong>
										<br />
										<span>
											{getLabelFromList(
												radioListYesNo,
												incidentForm?.isWorkDueToDisaster
											)}
										</span>
									</div>

									{incidentForm?.isWorkDueToDisaster?.toString() ==
										radioListYesNo[0].value && (
										<>
											<div className={pageUtils.classes.answerMargin}>
												<strong>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision
													}
												</strong>
												<br />
												<span>{incidentForm?.workDueToDisasterPrecision}</span>
											</div>
										</>
									)}

									{/* Question 25 */}
									<div className={pageUtils.classes.answerMargin}>
										<strong>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork
											}
										</strong>
										<br />
										<span>
											{formatNumberWithSpaces(
												incidentForm?.approximateCostOfWork?.toString()
											)}{' '}
											$
										</span>
									</div>

									{/* Question 27 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired,
										getLabelFromList(
											radioListYesNo,
											incidentForm?.isTreeCuttingRequired
										)
									)}

									{/* Question 28 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28,
										incidentForm?.additionalInfoQ28
									)}

									{/* Dates des travaux */}
									{(incidentForm?.workStartDate ||
										incidentForm?.workEndDate) && (
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
											}
										</h5>
									)}

									{incidentForm?.workStartDate && (
										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_startDate
												}
											</strong>
											<br />
											<span>
												{pageUtils.getLocaleDate(incidentForm?.workStartDate!)}
											</span>
										</div>
									)}

									{incidentForm?.workEndDate && (
										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_endDate
												}
											</strong>
											<br />
											<span>
												{pageUtils.getLocaleDate(incidentForm?.workEndDate!)}
											</span>
										</div>
									)}

									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
										}
									</h5>
									{/* Question 31 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork,
										getLabelFromList(
											radioListWhoWillBeInChargeOfWork,
											incidentForm?.whoWillBeInChargeOfWork
										)
									)}

									{[
										radioListWhoWillBeInChargeOfWork[1].value,
										radioListWhoWillBeInChargeOfWork[2].value
									].includes(
										incidentForm?.whoWillBeInChargeOfWork?.toString()!
									) &&
										questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.entrepreneurUnknown1?.toString() ||
													String(false)
											)
										)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
										incidentForm?.companyNameExecutingWork
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNEQNumber,
										incidentForm?.companyNEQNumber
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
										incidentForm?.companyRBQNumber
									)}

									{(incidentForm?.representativeInfo?.organization_name ||
										incidentForm?.representativeInfo?.telephone ||
										incidentForm?.representativeInfo?.email) && (
										<h6 className={formUtils.classes.h6}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
											}
										</h6>
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
										incidentForm?.representativeInfo?.organization_name
									)}

									{questionAnswer(
										pageAssets?.label_telephone,
										incidentForm?.representativeInfo?.telephone
									)}

									{questionAnswer(
										pageAssets?.label_email,
										incidentForm?.representativeInfo?.email
									)}

									{(incidentForm?.contactDetailsInfo?.address ||
										incidentForm?.contactDetailsInfo?.city ||
										(incidentForm?.contactDetailsInfo?.postal_code &&
											incidentForm?.contactDetailsInfo?.postal_code !==
												'n/d')) && (
										<h6 className={formUtils.classes.h6}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
											}
										</h6>
									)}

									{questionAnswer(
										pageAssets?.label_address,
										incidentForm?.contactDetailsInfo?.address
									)}

									{questionAnswer(
										pageAssets?.label_city,
										incidentForm?.contactDetailsInfo?.city
									)}

									{incidentForm?.contactDetailsInfo?.postal_code !== 'n/d' &&
										questionAnswer(
											pageAssets?.label_postalCode,
											incidentForm?.contactDetailsInfo?.postal_code
										)}
								</>
								<br />
							</Collapse>

							<Collapse
								id={6}
								buttonText={pageAssets?.request_form_branching_aqueduc_egouts}
								key={`request_form_branching_aqueduc_egouts`}
								isOpen={false}
							>
								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.isWaterAndSewerConnectionRequired?.toString() ||
											String(false)
									)
								)}

								{incidentForm?.isWaterAndSewerConnectionRequired?.toString() ==
									'true' && (
									<>
										{(incidentForm?.connectedToAqueduct ||
											incidentForm?.connectedToSanitarySewer ||
											incidentForm?.connectedToRainSewer ||
											incidentForm?.connectedToCombinedSewer ||
											incidentForm?.connectedToDNA) && (
											<div className={pageUtils.classes.answerMargin}>
												<strong>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits
													}
												</strong>
												<br />
												<span className="no-margin">
													<>
														{/* connectedToAqueduct */}
														{incidentForm?.connectedToAqueduct && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[0]
																		.label
																}
															</p>
														)}
														{/* connectedToSanitarySewer */}
														{incidentForm?.connectedToSanitarySewer && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[1]
																		.label
																}
															</p>
														)}
														{/* connectedToRainSewer */}
														{incidentForm?.connectedToRainSewer && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[2]
																		.label
																}
															</p>
														)}
														{/* connectedToCombinedSewer */}
														{incidentForm?.connectedToCombinedSewer && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[3]
																		.label
																}
															</p>
														)}
														{/* connectedToDNA */}
														{incidentForm?.connectedToDNA && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[4]
																		.label
																}
															</p>
														)}
													</>
												</span>
											</div>
										)}

										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42,
											incidentForm?.additionalInfoQ42
										)}

										{(incidentForm?.constructionType1 ||
											incidentForm?.constructionType2 ||
											incidentForm?.constructionType3 ||
											incidentForm?.constructionType4 ||
											incidentForm?.constructionType5 ||
											incidentForm?.constructionType6 ||
											incidentForm?.constructionType7) && (
											<div className={pageUtils.classes.answerMargin}>
												<strong>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork
													}
												</strong>
												<br />
												<span className="no-margin">
													<>
														{listPlannedWork.map(
															(item, index) =>
																incidentForm?.[
																	`constructionType${index + 1}`
																] && <p key={index}>{item.label}</p>
														)}
													</>
												</span>
											</div>
										)}

										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44,
											incidentForm?.additionalInfoQ44
										)}

										{(incidentForm?.constructionType1 ||
											incidentForm?.constructionType2 ||
											incidentForm?.constructionType3 ||
											incidentForm?.constructionType6 ||
											incidentForm?.constructionType7) && (
											<>
												<h5 className={pageUtils.classes.h5}>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_branchement_aqueduc_egouts_ou_ingenieur_civil
													}
												</h5>

												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
													getLabelFromList(
														radioListYesNo,
														incidentForm?.entrepreneurUnknown?.toString() ||
															String(false)
													)
												)}

												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
													incidentForm?.entrepreneurName
												)}

												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
													incidentForm?.entrepreneurRBQNumber
												)}

												{(incidentForm?.representativeInfo2
													?.organization_name ||
													incidentForm?.representativeInfo2?.telephone ||
													incidentForm?.representativeInfo2?.email) && (
													<h6 className={formUtils.classes.h6}>
														{
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
														}
													</h6>
												)}

												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
													incidentForm?.representativeInfo2?.organization_name
												)}

												{questionAnswer(
													pageAssets?.label_telephone,
													incidentForm?.representativeInfo2?.telephone
												)}

												{questionAnswer(
													pageAssets?.label_email,
													incidentForm?.representativeInfo2?.email
												)}

												{(incidentForm?.contactDetailsInfo2?.address ||
													incidentForm?.contactDetailsInfo2?.city ||
													(incidentForm?.contactDetailsInfo2?.postal_code &&
														incidentForm?.contactDetailsInfo2?.postal_code !==
															'n/d')) && (
													<h6 className={formUtils.classes.h6}>
														{
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
														}
													</h6>
												)}

												{questionAnswer(
													pageAssets?.label_address,
													incidentForm?.contactDetailsInfo2?.address
												)}

												{questionAnswer(
													pageAssets?.label_city,
													incidentForm?.contactDetailsInfo2?.city
												)}

												{incidentForm?.contactDetailsInfo2?.postal_code !==
													'n/d' &&
													questionAnswer(
														pageAssets?.label_postalCode,
														incidentForm?.contactDetailsInfo2?.postal_code
													)}
											</>
										)}
									</>
								)}

								<br />
							</Collapse>
						</>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default ResidentialRenovationOrRepairPermitFormDetails
