import React, { createContext, FC, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate
} from '@azure/msal-react'
import '@components/layouts/Fonts.css'
import '@components/layouts/global.scss'
import 'reflect-metadata'

import LayoutWithProvider from '@components/layouts/LayoutWithProvider'
import LayoutWithSidebarWithProvider from '@components/layouts/LayoutWithSidebarWithProvider'
import LayoutRequestWithProvider from '@components/layouts/LayoutRequestWithProvider'
import LayoutWithSideContentWithProvider from '@components/layouts/LayoutWithSideContentWithProvider'
import RedirectComponent from '@components/auth/RedirectComponent'
import config from '@utils/config'
import store from '@services/store'
import {
	pathToPageName,
	sortRequestThemes,
	transformStrapiData
} from '@utils/methods'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { PageDataType, TempAllTextType } from '../../typings/shared'
import { User } from '@services/models'
import { LanguageEnum } from '@services/constants'
import { Request } from '@services/models'
import { IUserDTO } from '@services/dtos/users.dto'
import {
	ElectorialDistrict,
	TeamMember
} from '@services/models/_miscellaneous.model'
import { PageProps } from 'gatsby'
import { Article } from '@services/models/articles.model'
import { Helmet } from 'react-helmet'
import SEO from '@components/configs/SEO'
import * as layoutUtils from './__layout.utils'
import moment from 'moment'

export type AppContextProps = {
	pathname: string
	urlParams: URLSearchParams
	language: LanguageEnum | undefined
	setLanguage: (language: LanguageEnum) => void
	setAuthUser: (user: User) => void
	pageData: PageDataType
	electedTeamMembers?: TeamMember[]
	setElectedTeamMembers?: (members: TeamMember[]) => void
	electorialDistricts?: ElectorialDistrict[]
	setElectorialDistricts?: (districts: ElectorialDistrict[]) => void
	authUser?: User | null
	userRequest?: Request
	setUserRequest?: (request: Request) => void
	currentArticle?: Article
	setCurrentArticle: (article: Article | undefined) => void
	tempAllText?: TempAllTextType
}

export const AppStateContext = createContext<AppContextProps>({
	pathname: '',
	urlParams: new URLSearchParams(),
	language: LanguageEnum.FR,
	setLanguage: () => null,
	setAuthUser: () => null,
	setUserRequest: () => null,
	setCurrentArticle: () => null,
	setElectedTeamMembers: () => null,
	setElectorialDistricts: () => null,
	pageData: {} as PageDataType,
	electedTeamMembers: [] as TeamMember[],
	electorialDistricts: [] as ElectorialDistrict[],
	authUser: {} as User,
	tempAllText: {} as TempAllTextType
})

type UserLastLoginData = {
	email: string
	date: string
}

const DynamicLayout: FC<PageProps> = ({ location, children })=> {
	const [language, setLocalLanguage] = useState<LanguageEnum>()
	const [authUser, setLocalAuthUser] = useState<User | undefined | null>()
	const [userRequest, setUserRequest] = useState<Request>()
	const [currentArticle, setCurrentArticle] = useState<Article>()
	const [electedTeamMembers, setElectedTeamMembers] = useState<TeamMember[]>([])
	const [electorialDistricts, setElectorialDistricts] = useState<
		ElectorialDistrict[]
	>([])

	const getLanguage = (): LanguageEnum => {
		const lang: LanguageEnum = (localStorage.getItem(
			config.localStorage.languageKey
		) || LanguageEnum.FR) as LanguageEnum

		return lang
	}

	const setAuthUser = (user: User | null) => {
		if (user) {
			localStorage.setItem(
				config.localStorage.authUserKey,
				JSON.stringify(User.serialize(user))
			)
		} else {
			localStorage.removeItem(config.localStorage.authUserKey)
		}

		setLocalAuthUser(user)
	}

	const setLanguage = (language: LanguageEnum | null) => {
		if (language) {
			localStorage.setItem(config.localStorage.languageKey, language)
			setLocalLanguage(language)
		} else {
			localStorage.removeItem(config.localStorage.languageKey)
			setLocalLanguage(LanguageEnum.FR)
		}
	}

	const getAuthUser = async (): Promise<User | null> => {
		const localValue: string | null = localStorage.getItem(
			config.localStorage.authUserKey
		)

		if (localValue) {
			const localUser = JSON.parse(localValue) as IUserDTO

			if (localUser?.identity?.homeAccountId) {
				const user = await User.parse(localUser)

				return user
			}
		}

		return null
	}

	const isArray = (value: string): boolean => {
		try {
			const parsedValue = JSON.parse(value)
			return Array.isArray(parsedValue)
		} catch (error) {
			return false
		}
	}

	const getLastLoginDate = () => {
		const userData = localStorage.getItem(config.localStorage.lastLoginDateKey)

		if (!userData || !isArray(userData)) return null

		const userInfo: UserLastLoginData[] = JSON.parse(userData)
		const userExist = userInfo?.find(
			(user) => user.email == authUser?.profile?.email
		)
		if (userExist) return userExist?.date
		return null
	}

	const saveUser = (userInfo?: UserLastLoginData[]) => {
		const lastLoginDateKey = config.localStorage.lastLoginDateKey

		const newUserLastLoginData: UserLastLoginData = {
			email: authUser?.profile?.email!,
			date: moment().format('YYYY-MM-DD HH:mm:ss')
		}

		const userLastLoginDataArray: UserLastLoginData[] = userInfo
			? [...userInfo, newUserLastLoginData]
			: [newUserLastLoginData]

		localStorage.setItem(
			lastLoginDateKey,
			JSON.stringify(userLastLoginDataArray)
		)
	}

	const addIfNotExist = () => {
		const userData = localStorage.getItem(config.localStorage.lastLoginDateKey)
		// first time login in
		if (!userData) {
			saveUser()
		} else {
			try {
				// verify if the current user has a last login date an add if not
				const userInfo: UserLastLoginData[] = JSON.parse(userData)
				const userExist = userInfo?.find(
					(user) => user.email == authUser?.profile?.email
				)
				if (!userExist) {
					saveUser(userInfo)
				}
			} catch (err) {
				localStorage.removeItem(config.localStorage.lastLoginDateKey)
				saveUser()
			}
		}
	}

	const setLastLoginDate = (date: string | null) => {
		const accountExists = localStorage.getItem('tnsApp')

		if (date === null && accountExists !== null) {
			addIfNotExist()
			localStorage.setItem('updateProfilePopup', 'true')
		} else {
			addIfNotExist()
		}
	}

	useEffect(() => {
		const fetchUser = async () => {
			const user = await getAuthUser()

			const authRedirect: string = config.localStorage.authRedirect
			const link: string | null = localStorage.getItem(authRedirect)

			if (!user && location.pathname !== '/' && location.pathname !== '/auth') {
				if (link == null && location.pathname !== '/auth/') {
					localStorage.setItem(authRedirect, location.pathname)
				}

				navigate('/auth')
			}
			setAuthUser(user)
		}

		fetchUser()
	}, [])

	useEffect(() => {
		const fetchElectedTeamMembers = async () => {
			setElectedTeamMembers(await TeamMember.getElectedTeamMembersAsync())
		}

		const fetchElectorialDistricts = async () => {
			setElectorialDistricts(
				await ElectorialDistrict.getElectorialDistrictsAsync()
			)
		}

		if (authUser) {
			fetchElectedTeamMembers()
			fetchElectorialDistricts()
		}
	}, [authUser])

	useEffect(() => {
		if (authUser) setLastLoginDate(getLastLoginDate())
		setLanguage(getLanguage())
	}, [authUser])

	const pathname: string = location?.pathname

	const urlParams: URLSearchParams = new URLSearchParams(location?.search)

	const strapiData = useStaticQuery(graphql`
		query AllStrapiContentQuery {
			allStrapiPtcPage {
				edges {
					node {
						id
						title
						uri
						description
						ptc_assets {
							key
							value {
								data {
									value
								}
							}
						}
						locale
						name
					}
				}
			}
			allStrapiPtcAsset {
				edges {
					node {
						key
						value {
							data {
								value
							}
						}
						locale
					}
				}
			}
			allStrapiPtcRequest {
				edges {
					node {
						description {
							data {
								description
							}
						}
						locale
						name
						request_id
						template_type
						title
						ptc_collections {
							group
							id
							name
							title
							order
							description {
								data {
									description
								}
							}
						}
						ptc_tags {
							value
						}
					}
				}
			}
			allStrapiPtcForm {
				edges {
					node {
						cacher
						description {
							data {
								description
							}
						}
						form {
							url
							ext
						}
						locale
						name
						order
						title
						request_id
						ptc_collections {
							group
							id
							name
							title
							order
							description {
								data {
									description
								}
							}
						}
					}
				}
			}
		}
	`)

	const pageName = pathToPageName(pathname) || 'home'

	const tempAllText = transformStrapiData(strapiData)

	const allText =
		tempAllText[language || LanguageEnum.FR] ||
		({ pages: {}, collections: {} } as PageDataType)

	const pageData = allText[pageName] || {}

	pageData.pages = allText?.pages
	pageData.assets = { ...allText?.assets, ...allText[pageName]?.assets }
	pageData.allAssets = {}
	Object.keys(tempAllText).forEach(
		(key) => (pageData.allAssets[key] = tempAllText[key].assets)
	)

	pageData.collections = allText?.collections

	pageData.collections.requestThemes = sortRequestThemes(
		allText?.collections?.requestThemes as []
	)

	pageData.requests = allText.requests

	pageData.formRequests = allText.formRequests

	const isAuthPage = (pathname: string): boolean => {
		return !!pathname?.match(/auth/)?.length
	}

	const isAuthRedirectPage = (pathname: string): boolean => {
		return !!pathname?.match(/auth-redirect/)?.length
	}

	const isAuthUserInfoPage = (pathname: string): boolean => {
		return !!pathname?.match(/auth(\/?)$/)?.length
	}

	const isCreateNewRequest = (pathname: string): boolean => {
		return !!pathname?.match(/.*\/requests\/create\/[^\/]+/)?.length
	}

	const isCreateRequestPage = (pathname: string): boolean => {
		return !!pathname?.match(/.*\/requests\/create$|.*\/requests\/create\/$/)
			?.length
	}

	const isPaymentPage = (pathname: string): boolean => {
		return !!pathname?.match(/auth\/payment$/)?.length
	}

	// this is a workaround as Gatsby is not mature enougth to support our use case:
	// multiple layouts providers with shared state and azure auth component wrapper :(
	if (isAuthPage(pathname) && !isCreateRequestPage(pathname)) {
		return (
			<Provider store={store}>
				<AppStateContext.Provider
					value={{
						pathname,
						urlParams,
						language,
						setLanguage,
						pageData,
						setAuthUser,
						authUser,
						userRequest,
						setUserRequest,
						currentArticle,
						setCurrentArticle,
						electedTeamMembers,
						electorialDistricts,
						tempAllText
					}}
				>
					<Helmet htmlAttributes={{ lang: language || LanguageEnum.FR }} />
					<SEO />
					<LayoutWithProvider>
						<AuthenticatedTemplate>
							{(isCreateNewRequest(pathname) ||
								isAuthRedirectPage(pathname) ||
								isPaymentPage(pathname)) && (
								<LayoutRequestWithProvider
									wrapperClass={
										isPaymentPage(pathname)
											? layoutUtils.classes.paymentContainer
											: ''
									}
								>
									{children}
								</LayoutRequestWithProvider>
							)}
							{isAuthUserInfoPage(pathname) && !isPaymentPage(pathname) && (
								<LayoutWithSideContentWithProvider>
									{children}
								</LayoutWithSideContentWithProvider>
							)}
							{!isCreateNewRequest(pathname) &&
								!isAuthRedirectPage(pathname) &&
								!isAuthUserInfoPage(pathname) &&
								!isPaymentPage(pathname) && (
									<LayoutWithSidebarWithProvider>
										{children}
									</LayoutWithSidebarWithProvider>
								)}
						</AuthenticatedTemplate>
						<UnauthenticatedTemplate>
							<RedirectComponent />
						</UnauthenticatedTemplate>
					</LayoutWithProvider>
				</AppStateContext.Provider>
			</Provider>
		)
	}

	return (
		<Provider store={store}>
			<AppStateContext.Provider
				value={{
					pathname,
					urlParams,
					language,
					setLanguage,
					pageData,
					setAuthUser,
					authUser,
					userRequest,
					setUserRequest,
					currentArticle,
					setCurrentArticle,
					electedTeamMembers,
					electorialDistricts,
					tempAllText
				}}
			>
				<Helmet htmlAttributes={{ lang: language || LanguageEnum.FR }} />
				<SEO />

				<LayoutWithProvider>
					<LayoutWithSidebarWithProvider>
						{children}
					</LayoutWithSidebarWithProvider>
				</LayoutWithProvider>
			</AppStateContext.Provider>
		</Provider>
	)
}

export default DynamicLayout
