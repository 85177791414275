export const formatBytes = (kilobytes, decimals = 2) => {
	if (!+kilobytes) return '0 Kb'

	return `${(kilobytes / 1000).toFixed(decimals)}Mb`
}

export const formatFileSize = (bytes: number, decimals: number = 2) => {
	if (!+bytes) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'Kb', 'Mb', 'Gb']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
