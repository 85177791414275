import React, { FC, useContext } from 'react'
import { ButtonWithTooltipProps } from '@utils/request'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import {
	cancelIcon,
	cancelWhiteIcon,
	editIcon,
	editWhiteIcon
} from '@images/icons'
import { formatStrapiText } from '@utils/methods'
import { AppStateContext } from '@components/layouts/DynamicLayout'

type Classes = {
	edit: string
	cancel: string
	tooltip: string
	tooltiptext: string
}

const classes: Classes = makeClasses({
	edit: {
		background: `url('${editIcon}') ${Colors.darkWhite}`,
		':hover': {
			background: `url('${editWhiteIcon}') ${Colors.darkBlue1}`,
			backgroundPosition: 'center center'
		}
	},
	cancel: {
		background: `url('${cancelIcon}') ${Colors.darkWhite}`,
		':hover': {
			background: `url('${cancelWhiteIcon}') ${Colors.darkBlue1}`,
			backgroundPosition: 'center center'
		}
	},
	tooltip: {
		backgroundPosition: 'center',
		borderRadius: '50%',
		backgroundSize: '18px',
		backgroundRepeat: 'no-repeat',
		width: '40px',
		height: '40px',
		transition: '0.3s',
		margin: '0 3px',
		display: 'inline-block',
		position: 'relative',
		cursor: 'pointer',
		':hover': {
			borderRadius: '50%',
			backgroundSize: '18px',
			backgroundRepeat: 'no-repeat',
			transition: '0.3s',
			[Breakpoints.minWidth('sm')]: {
				'& > span': {
					display: 'inline-block'
				}
			}
		},
		[Breakpoints.minWidth('sm')]: {
			'::before': {
				content: '". "',
				color: 'transparent'
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			':last-child': {
				'& > span': {
					left: 0,
					'::after': {
						left: '63%'
					}
				}
			}
		}
	},
	tooltiptext: {
		display: 'none',
		width: '160px',
		backgroundColor: 'black',
		color: Colors.white,
		textAlign: 'center',
		borderRadius: '6px',
		padding: '6px',
		position: 'absolute',
		zIndex: '10',
		bottom: '115%',
		left: '50%',
		marginLeft: '-81.5px',
		fontSize: '12px',
		'::after': {
			content: '""',
			position: 'absolute',
			bottom: '-35%',
			left: '50%',
			marginLeft: '-5px',
			borderWidth: '5px',
			borderStyle: 'solid',
			borderColor: 'transparent transparent black transparent',
			transform: 'rotate(180deg)',
			zIndex: '100'
		}
	}
})

const ButtonWithTooltip: FC<ButtonWithTooltipProps> = ({ type, onclick }) => {
	const { pageData } = useContext(AppStateContext)
	return (
		<>
			<div
				className={joinClasses([
					type === 'edit' ? classes.edit : '',
					type === 'cancel' ? classes.cancel : '',
					classes.tooltip
				])}
				onClick={(e) => onclick(e)}
			>
				<span className={classes.tooltiptext}>
					{type === 'edit' &&
						formatStrapiText(pageData?.assets?.badge_addInformation)}
					{type === 'cancel' &&
						formatStrapiText(pageData?.assets?.badge_cancelRequest)}
				</span>
			</div>
		</>
	)
}

export default ButtonWithTooltip
