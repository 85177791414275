import React, { useContext } from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import {
	RadioList,
	RequestInputFiles,
	RequestInputs,
	SelectList
} from '@utils/request'
import Notification from '@components/ui/notification'
import { populateArticlesRequestId } from '@services/store/request'
import { useAppDispatch } from '@services/store'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

const requestForm = () => {
	const {
		pageData: page,
		language,
		authUser
	} = useContext<AppContextProps>(AppStateContext)
	const dispatch = useAppDispatch()

	const onChangeRadioInput = (
		value: string,
		valueKey: string,
		setRadioInput: (value: React.SetStateAction<RequestInputs>) => void,
		callback: () => void
	) => {
		setRadioInput((oldInput) => ({ ...oldInput, value, valueKey }))

		callback()
	}

	const radioSelectionYesNo: RadioList[] = [
		{
			label: page?.assets?.request_form_select_yes,
			value: page?.assets?.request_form_select_yes,
			key: 'request_form_select_yes'
		},
		{
			label: page?.assets?.request_form_select_no,
			value: page?.assets?.request_form_select_no,
			key: 'request_form_select_no'
		}
	]

	const radioSelectionYesNoNotApplicable: RadioList[] = [
		...radioSelectionYesNo,
		{
			label: page?.assets?.request_form_select_not_applicable,
			value: page?.assets?.request_form_select_not_applicable,
			key: 'request_form_select_not_applicable'
		}
	]

	const radioListYesNo: RadioList[] = [
		{
			label: page?.assets?.request_form_select_yes,
			value: REQUEST_CONFIGS_KEYS.yes,
			key: 'request_form_select_yes'
		},
		{
			label: page?.assets?.request_form_select_no,
			value: REQUEST_CONFIGS_KEYS.no,
			key: 'request_form_select_no'
		}
	]

	const radioListPropertyType: RadioList[] = [
		{
			label: page?.assets?.request_form_select_yes,
			value: REQUEST_CONFIGS_KEYS.yes,
			key: 'request_form_select_yes'
		},
		{
			label: page?.assets?.request_form_select_no,
			value: REQUEST_CONFIGS_KEYS.no,
			key: 'request_form_select_no'
		}
	]

	const radioSelectionYesNoDoNotKnow: RadioList[] = [
		...radioSelectionYesNo,
		{
			label: page?.assets?.request_form_select_dont_know,
			value: page?.assets?.request_form_select_dont_know,
			key: 'request_form_select_dont_know'
		}
	]

	const UpdateRequestInputsArray = (
		inputsArr: RequestInputs[],
		currentInput: RequestInputs
	): RequestInputs[] => {
		const newInputsArr: RequestInputs[] = inputsArr
		const index: number = newInputsArr.findIndex((input) =>
			!!input.temporaryName
				? input.temporaryName === currentInput.temporaryName
				: input.name === currentInput.name
		)

		if (index < 0) {
			newInputsArr.push(currentInput)

			return newInputsArr
		}

		if (!!newInputsArr[index].temporaryName) {
			newInputsArr[index].name = currentInput.name
		}

		newInputsArr[index].value = currentInput.value
		newInputsArr[index].required = currentInput.required
		newInputsArr[index].extraParams = currentInput.extraParams

		if (currentInput.valueKey) {
			newInputsArr[index].valueKey = currentInput.valueKey
		}

		if (currentInput.subStep) {
			newInputsArr[index].subStep = currentInput.subStep
		}

		return newInputsArr
	}
	
	const UpdateRequestInputFilesArray = <T=Set<File>>(
		inputsArr: RequestInputFiles[],
		currentInput: RequestInputFiles<T>
	): RequestInputFiles<T>[] => {
		const newInputsArr: RequestInputFiles[] =
			inputsArr instanceof Array ? inputsArr : []
		const index: number = newInputsArr.findIndex(
			(input) => input.name === currentInput.name
		)

		if (index < 0) {
			newInputsArr.push(currentInput as RequestInputFiles)
			return newInputsArr as RequestInputFiles<T>[]
		}

		newInputsArr[index].files = currentInput.files as Set<File>
		newInputsArr[index].required = currentInput.required
		newInputsArr[index].separateFiles = currentInput.separateFiles

		if (currentInput.numberFilesRequired) {
			newInputsArr[index].numberFilesRequired = currentInput.numberFilesRequired
		}
		
		if(currentInput.uploadedFilesMap){
			newInputsArr[index].uploadedFilesMap = currentInput.uploadedFilesMap
		}

		return newInputsArr as RequestInputFiles<T>[]
	}

	const UpdateRequestInputsErrorsArray = (
		errors: string[],
		inputName: string
	): string[] => errors.filter((error) => error !== inputName)

	const displayWarningSection = (label: string) => (
		<Notification text={label} type="info" hasHtml />
	)

	const filterMultipleRequests = (searchIncludeName: string): SelectList[] => {
		const requestIdsArticle: string[] = []
		const list: SelectList[] = page.requests
			.map((request) => {
				if (request.name.includes(searchIncludeName)) {
					requestIdsArticle.push(request.requestId)
					return {
						value: request.requestId,
						label: request.title,
						key: request.name.replace(searchIncludeName, '')
					} as SelectList
				}
			})
			.filter((r) => r) as SelectList[]

		list.unshift({ value: '', label: '', key: '' })
		dispatchPopulateArticlesRequestId(requestIdsArticle)
		return list
	}

	const dispatchPopulateArticlesRequestId = (listRequestIds: string[]) =>
		dispatch(populateArticlesRequestId(listRequestIds))

	return {
		pageAssets: page?.assets,
		requests: page?.requests,
		language,
		authUser,
		radioSelectionYesNo,
		radioSelectionYesNoDoNotKnow,
		radioListYesNo,
		radioListPropertyType,
		radioSelectionYesNoNotApplicable,
		onChangeRadioInput,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection,
		filterMultipleRequests,
		dispatchPopulateArticlesRequestId
	}
}

export default requestForm
