import {
	cancelIcon,
	cancelWhiteIcon,
	chevronBlueIcon,
	chevronDoubleBlueIcon,
	chevronDownBlueIcon,
	editIcon,
	editWhiteIcon
} from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

export interface IRequest {
	title: string
	code: string
	dateAdd: string
	dateUpdate?: string
	status: 'open' | 'closed' | 'processing'
	unReadMessages?: string[] | null | undefined
	link: string
}

// Dont delete dummyRequests, it is use in the test component as response to the axios request.

export const dummyRequests: IRequest[] = [
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'open',
		unReadMessages: null,
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		dateUpdate: '2022-02-02',
		status: 'closed',
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'processing',
		unReadMessages: ['test', 'read'],
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'open',
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		dateUpdate: '2022-02-02',
		status: 'closed',
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'processing',
		unReadMessages: ['test', 'read'],
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'open',
		unReadMessages: null,
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		dateUpdate: '2022-02-02',
		status: 'closed',
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'processing',
		unReadMessages: ['test', 'read'],
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'open',
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		dateUpdate: '2022-02-02',
		status: 'closed',
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'processing',
		unReadMessages: ['test', 'read'],
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'open',
		link: '#'
	},
	{
		title: 'Nid de poule dans une rue',
		code: 'RC-1234',
		dateAdd: '2021-01-01',
		status: 'open',
		link: '#'
	}
]

export type ClassType = {
	section: string
	row: string
	col4: string
	h3: string
	h4: string
	img: string
	ul: string
	li: string
	link: string
	rowTitle: string
	liImg: string
	card: string
	info: string
	col1: string
	info2: string
	infoSection: string
	badgeSection: string
	code: string
	date: string
	title: string
	unReadMessages: string
	status: string
	tag: string
	open: string
	closed: string
	current: string
	action2: string
	col2: string
	edit: string
	cancel: string
	tooltip: string
	tooltiptext: string
	a: string
	dateSpan: string
	list: string
	filters: string
	filtersSection: string
	inline: string
	select: string
	simple: string
	double: string
	currentPage: string
	icoArrow: string
	pager: string
	pagination: string
	previous: string
	image: string
	hidde: string
	container: string
	loader: string
	inputDescLabel: string
	commentsSeparator: string
	iconWrapper: string
	paymentBackground: string
	notificationBackground: string
	icon: string
	tnMargin: string
	filterTab: string
}

export const classes: ClassType = makeClasses({
	section: {
		margin: '50px',
		display: 'grid',
		[Breakpoints.maxWidth('md')]: {
			margin: '20px'
		}
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap' as any,
		width: '100%'
	},
	img: { width: '40px' },
	col4: {
		width: '100%',
		maxWidth: `${100 / 3}%`,
		marginBottom: '40px',
		[Breakpoints.maxWidth('md')]: {
			width: '100%',
			maxWidth: '50%'
		},
		[Breakpoints.maxWidth('md')]: {
			width: '100%',
			maxWidth: '100%'
		}
	},
	col1: {
		minWidth: '82%',
		maxWidth: '82%',
		display: 'flex',
		alignItems: 'center',
		[Breakpoints.maxWidth('lg')]: {
			display: 'block',
			minWidth: '100%'
		}
	},
	h3: {
		margin: '0 0 0 20px',
		fontWeight: 500,
		color: Colors.primary
	},
	h4: {
		margin: '30px 10px 20px 0',
		fontWeight: 500,
		color: Colors.darkBlue
	},
	li: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: '10px',
		paddingLeft: 0
	},
	ul: {
		listStyle: 'none',
		paddingLeft: '0',
		marginRight: '20px',
		lineHeight: '22px'
	},
	link: {
		textDecoration: 'none',
		color: Colors.secondary,
		':hover': {
			textDecoration: 'underline'
		}
	},
	rowTitle: {
		display: 'flex',
		alignItems: 'center',
		width: '100%'
	},
	liImg: {
		width: '20px',
		marginTop: '1px',
		marginRight: '5px'
	},
	card: {
		background: Colors.white,
		padding: '30px',
		marginBottom: '20px',
		display: 'flex',
		justifyContent: 'space-between',
		cursor: 'pointer',
		alignItems: 'center',
		transition: '0.3s',
		[Breakpoints.maxWidth('lg')]: {
			alignItems: 'flex-start',
			padding: '20px'
		},
		':hover': {
			background: Colors.white,
			boxShadow: '0 0 50px rgb(0 0 0 / 15%)',
			transition: '0.3s'
		}
	},
	info: {
		minWidth: '50%',
		maxWidth: '50%',
		marginRight: '1%',
		[Breakpoints.maxWidth('lg')]: {
			minWidth: '100%',
			maxWidth: '100%',
			textAlign: 'left'
		}
	},
	title: {
		fontSize: '20px',
		fontWeight: '500',
		color: Colors.primary,
		[Breakpoints.maxWidth('md')]: {
			paddingRight: '100px'
		}
	},
	code: {
		fontWeight: '500',
		color: Colors.brown,
		marginTop: '5px',
		marginBottom: '15px'
	},
	date: {
		color: Colors.darkGrey,
		fontSize: '15px',
		fontWeight: '600',
		padding: '3px 0',
		[Breakpoints.maxWidth('md')]: {
			display: 'block !important'
		}
	},
	dateSpan: {
		fontWeight: 400
	},
	info2: {
		minWidth: '54%',
		marginLeft: '10px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column-reverse !important',
			alignItems: 'start!important'
		},
		[Breakpoints.maxWidth('lg')]: {
			justifyContent: 'space-between',
			margin: '20px 0 10px',
			alignItems: 'center',
			flexDirection: 'row-reverse'
		}
	},
	infoSection: {
		flex: 1,
		display: 'flex',
		justifyContent: 'start',
		flexDirection: 'column',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column!important',
			alignItems: 'start!important'
		},
		[Breakpoints.maxWidth('lg')]: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'end'
		}
	},
	badgeSection: {
		[Breakpoints.maxWidth('md')]: {
			marginLeft: '-30px'
		}
	},
	image: {
		width: '18px',
		verticalAlign: 'middle',
		marginRight: '7px'
	},
	unReadMessages: {
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		'&:not(:first-child)': {
			marginTop: '0.25rem'
		},
		[Breakpoints.maxWidth('md')]: {
			marginTop: '10px',
			'&:not(:first-child)': {
				marginLeft: '0px!important'
			}
		},
		[Breakpoints.maxWidth('lg')]: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			textAlign: 'right',
			'&:not(:first-child)': {
				marginLeft: '15px'
			}
		}
	},
	status: {
		minWidth: '50%',
		maxWidth: '50%',
		textAlign: 'center',
		[Breakpoints.maxWidth('md')]: {
			textAlign: 'left',
			paddingLeft: '30px'
		},
		[Breakpoints.maxWidth('xxl')]: {
			minWidth: '25%',
			maxWidth: '25%'
		}
	},
	tag: {
		width: 'fit-content',
		padding: '10px 23px',
		// borderRadius: '23px',
		fontWeight: '500',
		whiteSpace: 'nowrap',
		fontSize: '14px'
	},
	open: {
		background: 'rgba(0,107,53,0.1)',
		color: Colors.veryDarkCyan
	},
	processing: {
		background: 'rgba(246,139,31,0.1)',
		color: Colors.vividOrange
	},
	waitingForPayment: {
		background: 'rgb(246,232,243)',
		color: '#C035A0'
	},
	closed: {
		background: 'rgba(0,84,166,0.1)',
		color: Colors.secondary
	},
	action2: {
		'& > div:last-child': {
			marginRight: '0'
		}
	},
	col2: {
		minWidth: '18%',
		maxWidth: '18%',
		textAlign: 'right',
		[Breakpoints.maxWidth('lg')]: {
			position: 'absolute',
			right: '70px',
			minWidth: 'inherit',
			maxWidth: 'inherit'
		},
		[Breakpoints.maxWidth('md')]: {
			right: '40px !important'
		}
	},
	edit: {
		background: `url('${editIcon}') ${Colors.darkWhite}`,
		':hover': {
			background: `url('${editWhiteIcon}') ${Colors.darkBlue1}`,
			backgroundPosition: 'center center'
		}
	},
	cancel: {
		background: `url('${cancelIcon}') ${Colors.darkWhite}`,
		':hover': {
			background: `url('${cancelWhiteIcon}') ${Colors.darkBlue1}`,
			backgroundPosition: 'center center'
		}
	},
	tooltip: {
		backgroundPosition: 'center',
		borderRadius: '50%',
		backgroundSize: '18px',
		backgroundRepeat: 'no-repeat',
		width: '40px',
		height: '40px',
		transition: '0.3s',
		margin: '0 3px',
		display: 'inline-block',
		position: 'relative',
		cursor: 'pointer',
		':hover': {
			borderRadius: '50%',
			backgroundSize: '18px',
			backgroundRepeat: 'no-repeat',
			transition: '0.3s',
			'& > span': {
				display: 'inline-block'
			}
		},
		'::before': {
			content: '". "',
			color: 'transparent'
		},
		[Breakpoints.maxWidth('sm')]: {
			':last-child': {
				'& > span': {
					left: 0,
					'::after': {
						left: '63%'
					}
				}
			}
		}
	},
	tooltiptext: {
		display: 'none',
		width: '160px',
		backgroundColor: 'black',
		color: Colors.white,
		textAlign: 'center',
		borderRadius: '6px',
		padding: '6px',
		position: 'absolute',
		zIndex: '10',
		bottom: '115%',
		left: '50%',
		marginLeft: '-81.5px',
		fontSize: '12px',
		'::after': {
			content: '""',
			position: 'absolute',
			bottom: '-35%',
			left: '50%',
			marginLeft: '-5px',
			borderWidth: '5px',
			borderStyle: 'solid',
			borderColor: 'transparent transparent black transparent',
			transform: 'rotate(180deg)',
			zIndex: '100'
		}
	},
	a: {
		textDecoration: 'none',
		color: Colors.secondary,
		':link': {
			textDecoration: 'none',
			color: Colors.secondary
		},
		':visited': {
			textDecoration: 'none',
			color: Colors.secondary
		}
	},
	filtersSection: {
		display: 'flex',
		alignItems: 'center',
		margin: '0 0 40px 0',
		[Breakpoints.maxWidth('md')]: {
			display: 'block',
			margin: '0 0 20px 0'
		}
	},
	filters: {
		display: 'flex',
		alignItems: 'center',
		[Breakpoints.maxWidth('md')]: {
			marginTop: '5px',
			'& > label': {
				width: '20%'
			}
		}
	},

	inline: {
		width: 'auto',
		paddingRight: '40px',
		border: '1px solid ' + Colors.greyIron
	},
	select: {
		appearance: 'none',
		backgroundImage: `url(${chevronDownBlueIcon})`,
		backgroundColor: Colors.white,
		backgroundRepeat: 'no-repeat,repeat',
		backgroundPosition: 'right 0.7em top 50%,0 0',
		backgroundSize: '24px',
		display: 'block',
		paddingLeft: '12px',
		height: '40px',
		fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
		lineHeight: '25px',
		webkitFontSmoothing: 'antialiased !important',
		fontWeight: 500,
		fontSize: '15px',
		color: Colors.lightBlack,
		marginLeft: '15px',
		':focus': {
			outline: 'none'
		}
	},
	currentPage: {
		color: Colors.secondary + ' !important',
		backgroundColor: Colors.greyIron
	},
	pager: {
		cursor: 'pointer',
		display: 'inline-block',
		width: '24px',
		height: '24px',
		lineHeight: '24px',
		textAlign: 'center',
		fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
		fontWeight: 700,
		verticalAlign: 'middle',
		textDecoration: 'none',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		borderRadius: '50%',
		':visited': {
			color: Colors.lightBlack
		},
		':link': {
			color: Colors.lightBlack
		},
		':hover': {
			backgroundColor: Colors.darkWhite
		}
	},
	icoArrow: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: '22px !important',
		backgroundPosition: 'center'
	},
	simple: {
		backgroundImage: `url(${chevronBlueIcon})`
	},
	double: {
		backgroundImage: `url(${chevronDoubleBlueIcon}) !important`
	},
	pagination: {
		paddingLeft: '0px'
	},
	previous: {
		transform: 'scaleX(-1)'
	},
	hidde: {
		display: 'none'
	},
	loader: {
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '26px',
		height: '26px',
		margin: '15px',
		animation: 'spin 2s linear infinite'
	},
	container: {
		display: 'flex',
		flexDirection: 'column' as any,
		width: '100%',
		maxWidth: '700px',
		padding: '40px 70px',
		margin: 'auto',
		color: '#0a0a0a',
		[Breakpoints.maxWidth('lg')]: {
			padding: '50px 20px 20px 20px'
		}
	},
	inputDescLabel: {
		display: 'block',
		margin: '5px 0 0',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2
	},
	commentsSeparator: {
		':not(:last-child)': {
			borderBottom: '1px solid rgba(0,0,0,0.1)'
		}
	},
	iconWrapper: {
		flex: 'none',
		marginRight: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		lineHeight: '26px',
		textAlign: 'center',
		borderRadius: '50%',
		width: '28px',
		height: '28px'
	},
	paymentBackground: {
		background: Colors.lightPurple
	},
	notificationBackground: {
		background: Colors.lightBlueTransparent20
	},
	icon: {
		width: '20px',
		height: '20px',
		marginRight: 0
	},
	tnMargin: {
		[Breakpoints.maxWidth('md')]: {
			margin: '20px 15px'
		}
	},
	filterTab: {
		display: 'flex',
		background: Colors.white,
		padding: '0px 40px',
		[Breakpoints.maxWidth('sm')]: {
			padding: '0px 20px'
		},
		'& > div': {
			cursor: 'pointer',
			padding: '20px 4px',
			fontSize: '18px',
			fontWeight: 400,
			color: Colors.darkGrey,
			marginRight: '20px',
			'&:hover': {
				boxShadow: `inset 0 -4px ${Colors.lightGrey6}`,
				transition: '0.3s'
			},
			[Breakpoints.maxWidth('sm')]: {
				fontSize: '16px',
				padding: '16px 4px'
			}
		},
		'& > .active': {
			boxShadow: `inset 0 -4px ${Colors.secondary} !important`,
			fontWeight: '700',
			color: Colors.secondary
		}
	}
})
