import { makeClasses } from '@utils/styles'

type classes = {
	container: string
}

export const classes: classes = makeClasses({
	container: {
		fontSize: '18px',
		background: 'rgba(255,255,255,0.7)',
		position: 'fixed',
		top: '0',
		left: '0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'Center',
		width: '100vw',
		height: '100vh',
		zIndex: '9999'
	}
})
