import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Error from '@components/requestForms/inputs/error.input'
import { calendar } from '@images/icons'
import { Calendar, Day as CalendarDay } from '@hassanmojab/react-modern-calendar-datepicker'
import { myCustomLocale } from './_inputs.utils'
import { LanguageEnum } from '@services/constants'
import moment from 'moment'
// import { colors } from 'react-select/dist/declarations/src/theme'
import Breakpoints from '@utils/breakpoints'

type Props = {
	id: string
	label?: string
	subLable?: string
	value: string
	required?: boolean
	hasError?: boolean
	onChange: (value: string) => void
	filterDate?: (value: Date) => boolean
	minDate?: Date
	maxDate?: Date
	description?: string
	disabled?: boolean
	customErrorText?: string
	disableErrorText?: boolean
	titleAndDescError?: boolean
	excludeDates?: Date[]
	hideRequiredIcon?: boolean
	local?: LanguageEnum
}

type Classes = {
	root: string
	// select: string
	// selectDisable: string
	error: string
	required: string
	contentRoot: string
	noWrap: string
	description: string
	calendar: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '20px',
		'.react-datepicker-wrapper': {
			position: 'relative',
			width: '100%',

			'& input': {
				background: 'transparent !important',
				cursor: 'pointer',
				'&:disabled': {
					background: `${Colors.lightGrey4} !important`
				}
			},
			'& div': {
				zIndex: 2
			},
			'&::after': {
				background: `url(${calendar}) no-repeat center center`,
				backgroundSize: 'contain',
				width: '20px',
				height: '20px',
				content: '""',
				fontFamily: 'Arial',
				position: 'absolute',
				top: '50%',
				right: '12px',
				transform: 'translateY(-50%)',
				cursor: 'pointer',
				zIndex: 1
			}
		},
		'.react-datepicker-popper': {
			zIndex: 3
		},
		'& .Calendar': {
			paddingTop: '0px !important'
		}
	},
	// select: {
	// 	width: '100%',
	// 	height: '43.6px',
	// 	maxWidth: '100%',
	// 	padding: '8px 12px',
	// 	fontSize: '16px',
	// 	fontWeight: '500',
	// 	color: Colors.lightBlack,
	// 	background: Colors.white,
	// 	border: `1px solid ${Colors.grey}`,
	// 	'&:focus': {
	// 		outline: 'none'
	// 	}
	// },
	// selectDisable: {
	// 	background: Colors.lightGrey4
	// },
	error: {
		label: {
			color: Colors.errorRed
		},
		span: {
			color: Colors.errorRed
		},
		select: {
			borderColor: Colors.errorRed,
			'&:focus': {
				outline: 'none',
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		},
		input: {
			borderColor: Colors.errorRed,
			'&:focus': {
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		}
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0px 3px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		fontWeight: '400'
	},
	calendar: {
		width: '100% !important',
		'& .Calendar__header': {
			backgroundColor: Colors.lightGrey2,
			color: Colors.white,
			'& .Calendar__monthText, & .Calendar__yearText': {
				color: Colors.white,
				background: Colors.lightGrey2,
				'&:hover': {
					background: `${Colors.lightGray8} !important`
				}
			}
		},
		'& .Calendar__weekDay': {
			color: Colors.black,
			fontWeight: '700'
			// width: 'calc(100% / 6) !important',
		},
		'& .Calendar__weekDays': {
			paddingTop: '10px',
			// [Breakpoints.widthBetween('xl', 'sidebar')]:{
			// 	paddingRight: '2rem !important',
			// },
			[Breakpoints.minWidth('laptop')]: {
				paddingRight: '3.5rem'
			},
			[Breakpoints.maxWidth('laptop')]: {
				paddingRight: '2.4rem'
			}
		},
		'& .Calendar__day': {
			marginRight: '0.3em !important',
			fontWeight: '500',
			width: 'calc(100% / 8) !important',
			'&:hover': {
				borderRadius: '0px !important'
			}
		},
		'.-ltr': {
			borderRadius: '0px !important'
		},
		'.-weekend': {
			borderRadius: '0px !important'
		},

		'& .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)':
			{
				color: Colors.black
			},
		'& .Calendar__day.-selected': {
			borderRadius: '0 !important'
		},
		'& .Calendar__monthArrowWrapper': {
			fontWeight: 700,
			fontSize: '1.2em'
		}
	}
})

const CalendarInputForwardRef: ForwardRefRenderFunction<
	HTMLSelectElement,
	Props
> = (
	{
		id,
		label,
		subLable,
		hasError,
		required,
		value,
		onChange,
		minDate,
		maxDate,
		description,
		disabled,
		customErrorText,
		disableErrorText = false,
		titleAndDescError,
		excludeDates,
		hideRequiredIcon,
		local
	},
	ref
) => {
	const getLabel = () => {
		const hasQuestionMark = label?.endsWith('?')
		if (hasQuestionMark) {
			return (
				<div className={classes.contentRoot}>
					{label?.replace('?', '')}
					<span className={required && !hideRequiredIcon ? classes.noWrap : ''}>
						?
					</span>
				</div>
			)
		} else {
			return (
				<span className={required && !hideRequiredIcon ? classes.required : ''}>
					{label}
				</span>
			)
		}
	}

	const getDay = (value) => {
		const date = new Date(value)
		return {
			day: date.getDate(),
			month: date.getMonth() + 1,
			year: date.getFullYear()
		}
	}

	const formatDate = (date: CalendarDay) => {
		return moment(`${date.year}/${date.month}/${date.day}`).format('YYYY/MM/DD')
	}

	const handleDateChanges = (dates?: CalendarDay[]) => {
		const formatedDate: string[] = dates?.map((date) => formatDate(date)) || []
		if (dates && dates?.length > 0) {
			onChange(JSON.stringify(formatedDate))
		} else {
			onChange(JSON.stringify([]))
		}
	}

	const disabledDates = (dates?: Date[]) => {
		return dates?.map((date) => getDay(date))
	}
	const getCalendarValue = (value) => {
		let dates: string[] = []
		if (value) dates = JSON.parse(value)

		const dayArray: CalendarDay[] = dates?.map((date) => getDay(date))
		return dayArray
	}
	const getLocal = () => {
		if (local == LanguageEnum.FR) return myCustomLocale
		return 'en'
	}

	return (
		<div className={joinClasses([classes.root, hasError ? classes.error : ''])}>
			<label htmlFor={id}>
				<span className={titleAndDescError ? classes.error : ''}>
					{getLabel()}
					<span>{subLable}</span>
					{description && (
						<span className={classes.description}>{description}</span>
					)}
				</span>
				{!disableErrorText && (
					<Error
						hasError={hasError}
						errorText={!!customErrorText ? customErrorText : undefined}
					/>
				)}
			</label>

			<Calendar
				value={getCalendarValue(value)}
				onChange={handleDateChanges}
				minimumDate={getDay(minDate)}
				maximumDate={getDay(maxDate)}
				disabledDays={disabledDates(excludeDates)}
				shouldHighlightWeekends
				colorPrimary={Colors.secondary}
				calendarClassName={classes.calendar}
				locale={getLocal()}
			/>
		</div>
	)
}

const CalendarInput = forwardRef(CalendarInputForwardRef)

export default CalendarInput
