import React, { FC } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from '@utils/authConfig'

const msalInstance = new PublicClientApplication(msalConfig)

const LayoutWithProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
	return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

export default LayoutWithProvider