import React, { FC, useContext, useEffect, useState } from 'react'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import * as pageUtils from './__index.utils'
import Collapse from '@components/ui/collapse'
import { joinClasses } from '@utils/styles'
import { accueilDarkBlueV2 } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import Text from '@components/ui/text'
import Link from '@components/ui/link'
import Loader from '@components/ui/loader'
import { Request } from '@services/models'
import { navigate } from '@components/ui/link'
import config from '@utils/config'
import { getAddressByCoordinates } from '@components/ui/addressForm/__index.utils'
import {
	numberMaxOfRequest,
	strapiDataRes,
	REQUEST_HIDDEN
} from '@pages/auth/requests/create/__index.utils'
import { IDENTIFIERS_OF_TN_REQUEST_TYPE } from '@services/constants'
import Modal from '@components/ui/modal'
import { eauLightGreyIcon, ecoResponsibilityIcon } from '@images/icons'
import axios from 'axios'
import { setRequestListStore } from '@services/store/requestList'
import { useAppDispatch } from '@services/store'
import RequestListCard from '@components/ui/requestSearch/RequestListCard'
import {
	removeApartmentNumber,
	removePostalCode
} from '@components/ui/incidentFormDetails/__details.utils'

type PropertyProps = {}

const MyProperty: FC<PropertyProps> = () => {
	const {
		pageData,
		authUser,
		language,
		electedTeamMembers,
		electorialDistricts
	} = useContext(AppStateContext)
	const userInfo = authUser?.profile?.address
	const requestThemes = pageData?.collections?.requestThemes
	const isCityOfLaval =
		userInfo?.city === config.geolocation.MUNICIPALITY_ADDRESS.city &&
		userInfo.inMunicipality

	const [loading, setLoading] = useState<boolean>(false)
	const [loadingStrapi, setLoadingStrapi] = useState<boolean>(false)
	const [requestDeclarations, setRequestDeclarations] =
		useState<pageUtils.RequestDeclaration[]>()
	const [buildingRegistrationNumber, setBuildingRegistrationNumber] =
		useState<string>()
	const [buildingDistrict, setBuildingDistrict] = useState<string>()
	const [buildingElectedTeam, setBuildingElectedTeam] = useState<string>()
	const [requests, setRequests] = useState<Request[]>([])
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const fetchRequests = async () => {
		if (authUser) {
			try {
				setLoading(true)
				const requestsList = await authUser.fetchRequests(false)
				setRequests(requestsList)
				dispatch(
					setRequestListStore({
						fetching: false,
						fetched: true,
						requests: requestsList
					})
				)
			} catch (error: any) {
				console.error(error?.message)
			} finally {
				setTimeout(() => setLoading(false), 200)
			}
		}
	}

	const getRequestTitle = (value: string) => {
		let title = pageData.requests?.find(
			(request) => request.requestId == value
		)?.title

		if (!title) {
			title = pageData.requests?.find(
				(request) => request.requestId == 'other-subject'
			)?.title
		}

		return title
	}
	const getRequestDescripion = (value: string) => {
		let description = pageData.requests?.find(
			(request) => request.requestId == value
		)?.description

		if (!description) {
			description = pageData.requests?.find(
				(request) => request.requestId == 'other-subject'
			)?.description
		}

		return description
	}

	const sortRequestByTitle = (requestList: IDENTIFIERS_OF_TN_REQUEST_TYPE[] ): IDENTIFIERS_OF_TN_REQUEST_TYPE[] => {
		const copy = [...requestList]
		copy.sort(function (a, b) {
			let aTitle = getRequestTitle(a)!
			let bTitle = getRequestTitle(b)!

			// Normalize the titles to remove accents and special characters
			const normalizedA = aTitle?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			const normalizedB = bTitle?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

			if (normalizedA < normalizedB) {
				return -1
			}
			if (normalizedA > normalizedB) {
				return 1
			}
			return 0
		})

		return copy;
	}


	const [statementRedirectOptionsList, setStatementRedirectOptionList] =
		useState(sortRequestByTitle([
			IDENTIFIERS_OF_TN_REQUEST_TYPE.OIL_HEATING_DECLARATION_FORM,
			IDENTIFIERS_OF_TN_REQUEST_TYPE.SELF_RELIEF_WATER_COUNTER_FORM,
			IDENTIFIERS_OF_TN_REQUEST_TYPE.FIREPLACE_DECLARATION
		]))

	const onNavigateToRequest = (id: string) => (e) => {
		e.preventDefault()
		const link: string = `${config.request.create.baseURL}/${id}`
		navigate(link)
	}

	useEffect(() => {
		setLoadingStrapi(true)
		axios
			.get(
				`${process.env.STRAPI_API}/api/ptc-requests?pagination%5BpageSize%5D=${numberMaxOfRequest}`
			)
			.then(async (res) => {
				const data = res.data as strapiDataRes
				const hiddenRequestList = data.data.map((request) => {
					if (REQUEST_HIDDEN[request.attributes.Cacher])
						return request.attributes.request_id
				})

				setStatementRedirectOptionList(sortRequestByTitle(
					statementRedirectOptionsList.filter(
						(requestId) => !hiddenRequestList.includes(requestId)
					))
				)
			})
			.catch((e) => {
				// setStatementRedirectOptionList([])
				console.error(e)
			})
			.finally(() => setLoadingStrapi(false))
	}, [])

	const getRequestIcon = (request) => {
		switch (request) {
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.OIL_HEATING_DECLARATION_FORM:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.FIREPLACE_DECLARATION:
				return ecoResponsibilityIcon
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.SELF_RELIEF_WATER_COUNTER_FORM:
				return eauLightGreyIcon

			default:
				return ecoResponsibilityIcon
		}
	}

	const displayStatementList = () => {
		return statementRedirectOptionsList.map((element) => (
			<div
				key={element}
				className={pageUtils.classes?.typeSelect}
				onClick={onNavigateToRequest(element)}
			>
				
				<Icon
					src={getRequestIcon(element)}
					className={pageUtils.classes?.iconStyle}
				/>
				<div>
					<span>{getRequestTitle(element)}</span>
					<p>{getRequestDescripion(element)}</p>
				</div>
			</div>
		))
	}

	useEffect(() => {
		fetchRequests().catch(console.error)
	}, [authUser])

	useEffect(() => {
		const requestDeclarationsGroup = ['declarations']
		const pageRequests = requestThemes
			?.map((theme) => {
				if (requestDeclarationsGroup.includes(theme.name)) {
					return theme.requests
				}
			})
			.filter((value) => value !== undefined)

		const requestData: pageUtils.RequestData[] =
			pageRequests?.flat() as pageUtils.RequestData[]

		const data = pageUtils.requestDeclarations(requestData, requests)
		setRequestDeclarations(data)
	}, [requests])

	const getDistrict = (id: string) => {
		const district = electorialDistricts?.find((district) => district.id == id)
		if (id.length < 2) {
			id = '0' + id
		}
		return `${id} - ${district?.place_name}`
	}

	const getElectedTeam = (id: string) => {
		if (id.length < 2) {
			id = '0' + id
		}

		const teamMember = electedTeamMembers?.find((electedTeam) =>
			electedTeam.name.startsWith(`District ${id}`)
		)

		if (teamMember) return teamMember.name.split('-')[1].trim()
	}

	useEffect(() => {
		if (isCityOfLaval) {
			getAddressByCoordinates(userInfo?.coordinates)
				.then(async (res) => {
					setBuildingRegistrationNumber(
						res?.data?.features?.[0].properties.matricule
					)
					const electedDistrictId =
						res?.data?.features?.[0].properties.electoral_district_id

					const electedTeam = getElectedTeam(electedDistrictId)
					const district = getDistrict(electedDistrictId)
					setBuildingDistrict(district)
					setBuildingElectedTeam(electedTeam)
				})
				.catch(console.error)
		}
	}, [authUser, electorialDistricts])

	useEffect(() => {
		const sortedRequestList = sortRequestByTitle(statementRedirectOptionsList)
		setStatementRedirectOptionList(sortedRequestList)
	}, [language])

	const getRequestTheme = () => {
		return pageData.requests?.find(
			(request) =>
				request.requestId ==
				IDENTIFIERS_OF_TN_REQUEST_TYPE.MUNICIPAL_EVALUATION_FORM
		)
	}

	const renderDeclarations = () => {
		return (
			<section className={pageUtils.classes.section2}>
				<h3 className={pageUtils.classes.h3}>
					<Text content={pageData.assets?.page_myPropery_my_statements} />
				</h3>

				{loading ? (
					<Loader text={pageData?.assets?.loading} />
				) : (
					<>
						<div className={pageUtils.classes.collapse}>
							{requestDeclarations && !!requestDeclarations?.length ? (
								requestDeclarations.map((declaration) => (
									<Collapse
										id={1}
										buttonText={getRequestTitle(declaration.requestId) ?? ''}
										key={declaration.requestId}
										isOpen={false}
									>
										<div>
											<div className={pageUtils.classes.tableContainer}>
												<table className={pageUtils.classes.table}>
													<tbody>
														{declaration.requests.map((request) => (
															<tr className={pageUtils.classes.tableRow}>
																<td>
																	<b className={pageUtils.classes.rowHeader}>
																		{
																			pageData.assets
																				?.page_myPropery_declaration_date
																		}
																	</b>{' '}
																	<br />
																	{request.dateAdd?.localDateString}
																</td>

																<td>
																	<b className={pageUtils.classes.rowHeader}>
																		{
																			pageData.assets
																				?.request_form_declaration_adresse
																		}
																	</b>{' '}
																	<br />
																	{removePostalCode(
																		removeApartmentNumber(
																			request.incidentForm?.declareAddress,
																			request.incidentForm?.declareAppartment
																		)
																	)}
																</td>
																<td>
																	<b className={pageUtils.classes.rowHeader}>
																		{
																			pageData.assets
																				?.page_myPropery_request_number
																		}
																	</b>
																	<br />
																	<Link
																		to={request.link}
																		className={pageUtils.classes.link}
																	>
																		{request.code}
																	</Link>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</Collapse>
								))
							) : (
								<>
									<span>{pageData.assets?.label_no_declarations}</span>
								</>
							)}
						</div>
						<div>
							<div
								className={pageUtils.classes.button}
								onClick={() => setIsOpenModal(true)}
							>
								{pageData.assets?.page_myPropery_add_a_statement}
							</div>
						</div>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			<div className={pageUtils.classes.row}>
				<div className={joinClasses([pageUtils.classes.small])}>
					<div className={pageUtils.classes.homeInfo}>
						<div className={pageUtils.classes.homeAddress}>
							<div className={pageUtils.classes.homeIcon}>
								<Icon
									src={accueilDarkBlueV2}
									className={pageUtils.classes.homeImg}
								/>
							</div>

							<div style={{ marginTop: '.5rem' }}>
								<div className={pageUtils.classes.address}>
									{userInfo?.address}
									<br />
									{userInfo?.city} ({userInfo?.state}) {userInfo?.postalCode}
								</div>
								{isCityOfLaval && !userInfo.hasAddressEnteredManually && (
									<div style={{ marginTop: '.5rem' }}>
										<div className={pageUtils.classes.matriclue}>
											{pageData.assets?.label_matricule}:&nbsp;
											<b>{buildingRegistrationNumber}</b>
										</div>
										<div className={pageUtils.classes.matriclue}>
											{pageData.assets?.label_district}:&nbsp;
											<b>{buildingDistrict ?? ''}</b>
										</div>
										<div className={pageUtils.classes.matriclue}>
											{pageData.assets?.label_elected}:&nbsp;
											<b>{buildingElectedTeam ?? ''}</b>
										</div>
									</div>
								)}
							</div>

							<Link
								to="/auth/profile"
								className={pageUtils.classes.link}
								onClick={() => {
									localStorage.setItem(config.localStorage.activeTab, '0')
								}}
							>
								{formatStrapiText(
									pageData.assets?.label_myProperty_update_my_address
								)}
							</Link>
						</div>
					</div>
				</div>

				<div className={joinClasses([pageUtils.classes.large])}>
					<section
						className={joinClasses([
							pageUtils.classes.section2,
							pageUtils.classes.sectionWrapper
						])}
					>
						<h3
							className={pageUtils.classes.h3}
							style={{ marginBottom: '0px' }}
						>
							<Text
								content={
									pageData.assets?.page_myPropery_property_assessment_and_taxes
								}
							/>
						</h3>
						{getRequestTheme() && (
							<RequestListCard
								id={1}
								request={getRequestTheme()!}
								index={1}
								requestThemes={pageData?.collections?.requestThemes}
								hasParentGroup={false}
								isCardOpen={false}
							/>
						)}
					</section>

					{renderDeclarations()}
				</div>

				<Modal
					isOpen={isOpenModal !== false}
					showExitBtn
					title={pageData.assets?.page_myPropery_add_a_statement}
					onSetIsOpen={() => setIsOpenModal(false)}
					type={'default'}
				>
					<div className={pageUtils.classes?.modalContent}>
						{displayStatementList()}
					</div>
				</Modal>
			</div>
		</>
	)
}

export default MyProperty
