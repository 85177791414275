import React, { FC } from 'react'
import { formatStrapiText } from '@utils/methods'
import { Dictionary } from 'typings/shared'
import { Request } from '@services/models'
import { INVOICE_STATUS, RequestStatusEnum } from '@services/constants'
import * as pageUtils from '@pages/auth/requests/__index.utils'

type statusBadgeProps = {
	request: Request
	pageAssets: Dictionary
}

const StatusBadge: FC<statusBadgeProps> = ({ request, pageAssets }) => {
	const getStatusBadgeLabel = (
		assets: Dictionary,
		status: pageUtils.IRequest['status']
	): string => {
		if (!status) {
			return ''
		}

		return formatStrapiText(assets?.['badge_' + status])
	}

	const getRequestStatus = (request) => {
		if (request?.cancelReason) {
			return RequestStatusEnum.CLOSED
		}

		if (
			request?.invoice &&
			request?.invoice?.status === INVOICE_STATUS.waitingForPayment
		) {
			return RequestStatusEnum.WAITING_FOR_PAYMENT
		}

		return request?.status ?? RequestStatusEnum.PROCESSING
	}

	return (
		<div className={pageUtils.classes.status}>
			<div
				className={`${pageUtils.classes.tag} ${
					pageUtils.classes[getRequestStatus(request)]
				}`}
			>
				{getStatusBadgeLabel(pageAssets, getRequestStatus(request))}
			</div>
		</div>
	)
}

export default StatusBadge
