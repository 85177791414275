import { IRequestCreatedState } from '@services/store/request/type'
import Breakpoints from '@utils/breakpoints'
import { Colors, CreateRequestStyle } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { Request } from 'typings/shared'
import { Request as RequestObj } from '@services/models/requests.model'

export type Props = {
	requestCreated: IRequestCreatedState
	useClassWrapper?: boolean
	request?: Request
	closeLink?: string
	hasPayment?: boolean
	createdRequestWithInvoice?: RequestObj
}

type Classes = {
	container: string
	section: string
	h3: string
	confirmationContent: string
	confirmationRequestsLink: string
	confirmationRequestsLinkCode: string
	confirmationButton: string
}

export const classes: Classes = makeClasses({
	...CreateRequestStyle,
	confirmationContent: {
		display: 'flex',
		marginBottom: '15px',
		strong: {
			marginLeft: '5px'
		}
	},
	confirmationRequestsLink: {
		marginLeft: '5px',
		textDecoration: 'none',
		color: Colors.secondary,
		cursor: 'pointer',
		'&:active': {
			color: Colors.secondary
		},
		'&:visited': {
			color: Colors.secondary
		},
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	confirmationRequestsLinkCode: {
		fontWeight: 'bold'
	},
	confirmationButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		whiteSpace: 'nowrap',
		margin: '25px 0 0',
		button: {
			margin: 0
		},
		'& a': {
			lineHeight: '1em',
			background: Colors.secondary,
			color: '#fff !important',
			WebkitTextDecoration: 'none',
			textDecoration: 'none',
			padding: '15px 30px',
			borderRadius: '23px',
			border: '1px solid #0054a6',
			fontWeight: 500,
			fontSize: 16,
			margin: '0 5px',
			display: 'block',
			cursor: 'pointer',
			transition: '3s background',
			[Breakpoints.maxWidth('sm')]: {
				marginLeft: '0px'
			},
			':hover': {
				textDecoration: 'none',
				background: Colors.primary
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			flexDirection: 'column',
			alignItems: 'start',
			gap: '10px'
		}
	}
})
