import { Request, TeamMember } from '@services/models'
import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { TNRequestNames } from '@utils/request'
import { makeClasses } from '@utils/styles'

export type ClassType = {
	container: string
	section: string
	bloc: string
	collapse: string
	row: string
	col: string
	small: string
	large: string
	homeInfo: string
	homeImg: string
	homeIcon: string
	homeAddress: string
	address: string
	matriclue: string
	link: string
	section2: string
	h3: string
	tableContainer: string
	table: string
	tableRow: string
	rowHeader: string
	button: string
	modalContent: string
	typeSelect: string
	iconStyle: string
	sectionWrapper: string
}

export const classes: ClassType = makeClasses({
	container: {
		margin: ' 40px 30px',
		lineHeight: '1.62em',
		marginBottom: '50px',
		maxWidth: '100%',
		[Breakpoints.maxWidth('md')]: {
			margin: ' 20px 5px'
		}
	},
	row: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
		[Breakpoints.maxWidth('lg')]: {
			flexDirection: 'column'
		}
	},
	small: {
		flex: '0 0 33.333333%',
		maxWidth: '33.333333%',
		position: 'relative',
		width: '100%',
		paddingRight: '15px',
		[Breakpoints.maxWidth('lg')]: {
			flex: '100%',
			maxWidth: '100%',
			marginBottom: '30px'
		}
	},
	large: {
		flex: '0 0 66.666666%',
		maxWidth: '66.666666%',
		position: 'relative',
		width: '100%',
		paddingRight: '15px',
		[Breakpoints.maxWidth('lg')]: {
			flex: '100%',
			maxWidth: '100%'
		}
	},
	section: {
		position: 'relative',
		width: '100%',
		[Breakpoints.minWidth('lg')]: {
			paddingRight: '15px',
			paddingLeft: '15px'
		}
	},
	bloc: {
		padding: '30px',
		marginBottom: '30px'
	},
	collapse: {
		'& > div[id^="collapse-"i]': {
			borderBottom: `1px solid ${Colors.grey}`,
			':first-of-type': {
				borderTop: `1px solid ${Colors.grey}`
			}
		}
	},
	homeInfo: {
		display: 'flex',
		background: Colors.white,
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px 30px'
	},
	homeImg: {
		width: '75px',
		marginBottom: '15px'
	},
	homeIcon: {
		textAlign: 'center',
		background: Colors.backgroundConnected,
		borderRadius: '50%',
		padding: '15px 20px 5px'
	},
	homeAddress: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '20px',
		textAlign: 'center',
		marginBottom: '20px',
		[Breakpoints.widthBetween('md', 'lg')]: {
			flexDirection: 'row',
			gap: '30px'
		}
	},
	address: {
		fontSize: '24px',
		lineHeight: '1.4',
		marginBottom: '0.5rem',
		[Breakpoints.widthBetween('md', 'lg')]: {
			textAlign: 'start'
		}
	},
	matriclue: {
		fontSize: '14px',
		lineHeight: '1.4',
		whiteSpace: 'nowrap',
		[Breakpoints.widthBetween('md', 'lg')]: {
			textAlign: 'start'
		}
	},
	link: {
		color: Colors.secondary,
		textAlign: 'center',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
			cursor: 'pointer'
		},
		[Breakpoints.widthBetween('md', 'lg')]: {
			textAlign: 'start'
		}
	},
	section2: {
		background: Colors.white,
		p: {
			margin: 0
		},
		'& > div': {
			padding: '0 40px 40px',
			[Breakpoints.maxWidth('md')]: {
				padding: '30px 20px'
			}
		}
	},
	h3: {
		color: Colors.white,
		marginTop: 0,
		marginBottom: '25px',
		backgroundColor: Colors.secondary,
		padding: '15px 30px',
		fontWeight: 500,
		fontSize: '22px',
		[Breakpoints.maxWidth('md')]: {
			padding: '15px 20px'
		}
	},
	tableContainer: {
		lineHeight: '1.3',
		display: 'block',
		padding: '10px 0 30px 0px',
		width: '100%'
	},
	table: {
		width: '100%',
		border: 'none',
		borderCollapse: 'collapse',
		display: 'table',
		textIndent: 'initial',
		borderSpacing: '2px',
		'& td': {
			fontSize: '16px',
			padding: '12px 20px',
			border: 'none',
			borderTop: `1px solid ${Colors.grey}`,
			background: Colors.darkWhite,
			[Breakpoints.maxWidth('md')]: {
				display: 'block',
				width: '100%'
			}
		},
		'& tr:first-child td': {
			borderTop: 'none'
		},
		[Breakpoints.maxWidth('md')]: {
			'& tr td:last-child': {
				borderTop: 'none',
				paddingTop: '5px'
			}
		}
	},
	tableRow: {
		display: 'table-row',
		verticalAlign: 'inherit'
	},
	rowHeader: {
		fontSize: '14px'
	},
	button: {
		padding: '10px 20px',
		textDecoration: 'none',
		fontSize: '13px',
		display: 'inline-block',
		fontFamily: ' "Roboto", Arial, Helvetica, sans-serif',
		fontWeight: 500,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		background: Colors.secondary,
		color: Colors.white,
		cursor: 'pointer',
		border: `1px solid ${Colors.secondary}`,
		'&:hover': {
			background: Colors.primary,
			borderColor: Colors.primary
		}
	},
	modalContent: {
		color: Colors.black2,
		fontSize: '16px',
		marginBottom: '40px',
		lineHeight: '26px',
		textAlign: 'left',
		'& #viewDiv': {
			height: '490px'
		}
	},
	typeSelect: {
		background: Colors.darkWhite,
		marginBottom: '10px',
		padding: '15px',
		position: 'relative',
		overflow: 'hidden',
		maxHeight: '65px',
		display: 'flex',
		alignItems: 'start',
		justifyContent: 'start',
		transition: 'max-height 0.6s, background 0.5s ease-out',
		[Breakpoints.maxWidth('md')]:{
			maxHeight: '85px',
		},
		'& span': {
			verticalAlign: 'super',
			marginLeft: '10px',
			fontSize: '16px',
			fontWeight: '500'
		},
		'& p': {
			visibility: 'hidden',
			opacity: '0',
			position: 'relative',
			'z-index': 'auto',
			transition: 'visibility 0s, opacity 0.5s'
		},
		'&:hover': {
			background: Colors.secondary,
			color: Colors.white,
			maxHeight: '500px',
			transition: 'max-height 1s, background 0.3s ease-in',
			cursor: 'pointer',
			'& p': {
				visibility: 'visible',
				position: ' relative',
				opacity: '1',
				marginLeft: '10px',
				paddingRight: '15px',
				fontSize: '14px',
				fontWeight: '400',
				transition: 'visibility 0.5s, opacity 0.5s ease-in'
			}
		}
	},
	iconStyle: {
		width: '30px',
		height: '30px'
	},
	sectionWrapper: {
		'& .css-1p1l2wj': {
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'& .css-eoaksl':{
			marginTop: '20px !important',
		},
		'& .css-1e8g5d0 > div':{
			padding: '0 !important',
		},
		'& .css-137abwg':{
			paddingBottom: '0 !important',
		}
	}
})

export type strapiRes = {
	id: number
	attributes: {
		request_id: string
		Cacher: number
		title: string
		description: string
		name: string
	}
}

export type strapiDataRes = {
	data: strapiRes[]
	meta: {
		pagination: {
			pageCount: number
		}
	}
}
export type RequestData = {
	name: string
	title: string
	description: string
	requestId: string
	templateType: string
	subThemes: string[]
}

export type RequestDeclaration = {
	title: string
	requestId: string
	requests: Request[]
}

export const numberMaxOfRequest = 10000

export const REQUEST_HIDDEN = {
	Oui: true,
	Non: false
}

export const requestDeclarations = (
	tnRequests?: RequestData[],
	requests?: Request[]
) => {
	if (!tnRequests || !requests) {
		return
	}

	const results = requests
		.map((request) => {
			const exist = tnRequests.find(
				(tnRequest) =>
					request.typeId === tnRequest.requestId 
			)
			if (exist) return request
		})
		.filter((value) => value !== undefined) as Request[]

	let declarationGroup: RequestDeclaration[] = []

	results.forEach((request) => {
		const index = declarationGroup.findIndex(
			(declaration) => declaration?.requestId === request?.typeId
		)

		if (index >= 0) {
			declarationGroup[index].requests.push(request)
		} else {
			declarationGroup.push({
				title: request.title,
				requestId: request.typeId,
				requests: [request]
			})
		}
	})
	const sortRequestDeclarationGroup: RequestDeclaration[] =
		declarationGroup.map((declaration) => {
			return {
				...declaration,
				requests: declaration.requests.sort((a, b) => {
					return (
						new Date(b.dateAdd?.ISODateString!).getTime() -
						new Date(a.dateAdd?.ISODateString!).getTime()
					)
				})
			}
		})

	return sortRequestDeclarationGroup
}
