import React, { FC, useEffect, useState } from 'react'
import DisplayInforModal from '../modals/displayInfoModal'
import { navigate } from 'gatsby'
import requestForm from '@components/requestForms/request.form'

export type Props = {
	requestHasError: boolean
	setRequestHasError: React.Dispatch<React.SetStateAction<boolean>>
	onResetRequestFailed: () => void
	closeLink: string
	setDoSentPost: (doSentPost: boolean) => void
}

const RequestFailedModal: FC<Props> = ({
	requestHasError,
	setRequestHasError,
	onResetRequestFailed,
	closeLink,
	setDoSentPost
}) => {
	const { pageAssets } = requestForm()
	const [isModalOpen, setIsModalOpen] = useState<boolean>(requestHasError)

	const onCloseInforModal = () => {
		setRequestHasError(false)
		setIsModalOpen(false)
		onResetRequestFailed()
	}

	const submitRequest = () => {
		setDoSentPost(true)
	}

	const onQuitForm = () => {
		setRequestHasError(false)
		onResetRequestFailed()
		if (closeLink) {
			navigate(closeLink)
		}
	}

	useEffect(() => {
		setIsModalOpen(requestHasError)
	}, [requestHasError])

	return (
		<DisplayInforModal
			isModalOpen={isModalOpen}
			message={
				pageAssets?.modal_info_an_error_occurred_while_sending_your_request
			}
			close={onCloseInforModal}
			onPrimaryButton={onQuitForm}
			primaryButtonText={pageAssets?.label_cancel_and_exit}
			type="warning"
			iconColor="yellow"
			// cancelButtonText={pageAssets?.label_try_again}
			// swapButtonPositions
			// onCloseModal={()=>submitRequest()}
		/>
	)
}

export default RequestFailedModal
