import React, { useContext, useEffect, useState } from 'react'
import Browser from '@components/browser'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import {
	Dictionary,
	Request,
	RequestSubTheme,
	RequestTheme
} from 'typings/shared'
import {
	OthersRequestsId,
	RequestInputFiles,
	RequestInputs,
	Steps,
	StepsUrbanism,
	StepType,
	TNRequestNames
} from '@utils/request'
import config from '@utils/config'
import { Coordinate } from '@components/ui/map/mapForm.interfaces'
import { formatStrapiText } from '@utils/methods'
import { Address, AddressCoordinates, User } from '@services/models'
import { useAppDispatch, useAppSelector } from '@services/store'
import {
	clearRequestState,
	createRequest,
	createTNRequest,
	populateRequestModelState,
	resetRequestFailedState
} from '@services/store/request'
import {
	getRequestCreatedState,
	getRequestIdForArticles,
	getRequestStateModel
} from '@services/store/request/selector'
import { Article } from '@services/models/articles.model'
import {
	REQUESTS_IDS,
	identifierOfRequestTypeDic,
	REQUEST_TYPE,
	RequestTypeDic,
	INVOICE_STATUS
} from '@services/constants'
import {
	IRequestCreatedState,
	IRequestModelState
} from '@services/store/request/type'
import RequestsRepository from '@services/repositories/requests.repository'
import { Request as RequestObj } from '@services/models/requests.model'

export type RequestFormType = {
	files: Set<File>
	pageAssets: Dictionary
	title: string
	currentStep: number
	steps: StepType
	inputs: RequestInputs[]
	loading: boolean
	browser: string
	doSentPost: boolean
	MAX_CHAR_DESC: number
	listArticles: Article[]
	listSteps: string[]
	request: Request | undefined
	coordinates: Coordinate | undefined
	requestCreated: IRequestCreatedState
	closeLink: string
	isArticlesLoading: boolean
	isOtherRequestPage: boolean
	isAuthUserInLaval: boolean
	authUser: User | null | undefined
	address: string | undefined
	cadastralAddress: string | undefined
	city: string | undefined
	postalCode: string | undefined
	apartment: string | undefined
	errors: string[]
	requestType: REQUEST_TYPE
	filesInputs: RequestInputFiles[]
	useDefaultUserLocation: boolean
	requestHasError: boolean
	createdRequestWithInvoice: RequestObj | undefined
	onSubmit: (processingData: () => void, hasError: boolean) => (e) => void
	onAddErrorFromAddress: (address: string) => void
	onResetRequestFailed: () => void
	setCoordinates: (coordinates: Coordinate | undefined) => void
	setFiles: React.Dispatch<React.SetStateAction<Set<File>>>
	setDoSentPost: (doSentPost: boolean) => void
	setInputs: (inputs: RequestInputs[]) => void
	buildLocation: (
		address: string,
		apartment: string,
		city: string,
		postalCode: string,
		coordinates?: Coordinate,
		cadastralAddress?: string
	) => Address
	setCurrentStep: React.Dispatch<React.SetStateAction<number>>
	setAddress: React.Dispatch<React.SetStateAction<string | undefined>>
	setCity: React.Dispatch<React.SetStateAction<string>>
	setPostalCode: React.Dispatch<React.SetStateAction<string | undefined>>
	setApartment: React.Dispatch<React.SetStateAction<string | undefined>>
	setErrors: React.Dispatch<React.SetStateAction<string[]>>
	setRequestType: React.Dispatch<React.SetStateAction<REQUEST_TYPE>>
	setFilesInputs: React.Dispatch<React.SetStateAction<RequestInputFiles[]>>
	setUseDefaultUserLocation: React.Dispatch<React.SetStateAction<boolean>>
	setCadastralAddress: React.Dispatch<React.SetStateAction<string | undefined>>
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
	setRequestHasError: React.Dispatch<React.SetStateAction<boolean>>
	isDeclarationRequest: (requestName: string) => boolean
	isRequestHasPayementOnDepot: () => boolean
}

const CreateRequestCore = (requestId: string): RequestFormType => {
	const MAX_CHAR_DESC: number = config.request.input.textareaMaxChar
	const dispatch = useAppDispatch()
	const requestCreatedState: IRequestCreatedState = useAppSelector(
		getRequestCreatedState
	)
	// const requestsIdsForArticles: string[] = useAppSelector(
	// 	getRequestIdForArticles
	// )

	const requestsIdsForArticles = config.articles.relatedRequests
		.filter((request) => request.relatedRequestId == requestId)
		.map((request) => request.requestId)

	const requestModelState: IRequestModelState =
		useAppSelector(getRequestStateModel)

	const customLocationState = useAppSelector((state) => state.request.location)
	const hasRequestFailed = useAppSelector(
		(state) => state.request.requestFailed
	)

	const { pageData, authUser } = useContext<AppContextProps>(AppStateContext)
	const { browser } = Browser()

	const [closeLink, setCloseLink] = useState<string>('')
	const [inputs, setInputs] = useState<RequestInputs[]>([])
	const [files, setFiles] = useState<Set<File>>(new Set<File>())
	const [listArticles, setListArticles] = useState<Article[]>([])
	const [request, setRequest] = useState<Request>()
	const [listSteps, setListSteps] = useState<string[]>([])
	const [currentStep, setCurrentStep] = useState<number>(Steps.form)

	const [loading, setLoading] = useState<boolean>(false)
	const [doSentPost, setDoSentPost] = useState<boolean>(false)
	const [isArticlesLoading, setIsArticlesLoading] = useState<boolean>(true)
	const [requestCreated, setRequestCreated] =
		useState<IRequestCreatedState>(requestCreatedState)
	const [isOtherRequestPage, setIsOtherRequestPage] = useState<boolean>(true)
	const [isAuthUserInLaval, setIsAuthUserInLaval] = useState<boolean>(false)
	const [errors, setErrors] = useState<string[]>([])

	const [filesInputs, setFilesInputs] = useState<RequestInputFiles[]>([])
	const [requestType, setRequestType] = useState<REQUEST_TYPE>(
		REQUEST_TYPE.PLAIN
	)
	const [useDefaultUserLocation, setUseDefaultUserLocation] = useState<boolean>(
		customLocationState.useDefaultUserLocation
	)
	const [address, setAddress] = useState<string>()
	const [apartment, setApartment] = useState<string>()
	const [city, setCity] = useState<string>(
		authUser?.profile?.address.city || ''
	)
	const [postalCode, setPostalCode] = useState<string>()
	const [coordinates, setCoordinates] = useState<Coordinate>()
	const [cadastralAddress, setCadastralAddress] = useState<string>()
	const [requestHasError, setRequestHasError] =
		useState<boolean>(hasRequestFailed)
	const [createdRequestWithInvoice, setCreatedRequestWithInvoice] =
		useState<RequestObj>()

	const onSubmit = (processingData: () => void, hasError: boolean) => (e) => {
		e.preventDefault()

		if (loading || hasError) {
			return false
		}

		processingData()
	}

	const isRequestHasPayementOnDepot = () => {
		const requestTypes = [
			TNRequestNames.annualPermitAndTagForAnimals,
			TNRequestNames.municipalEvaluation,
			TNRequestNames.parkingPermit,
			TNRequestNames.wateringPermit
		]
		return requestTypes.includes(request?.name ?? '')
	}

	const hasPayementToBeCalculated = () => {
		const requestTypes = [TNRequestNames.permitOccupationOfPublicHighway]
		return requestTypes.includes(request?.name ?? '')
	}

	const hasPriceAboveZeroCheck = () => {
		const requestTypes = [TNRequestNames.wateringPermit]
		return requestTypes.includes(request?.name ?? '')
	}

	const hasAssociatedRequestStep = () => {
		return request?.name == TNRequestNames.buildingConstructionOrAdditionPermit
	}

	const submitPost = async () => {
		if (authUser) {
			setLoading(true)
			onResetRequestFailed()

			if (isRequestHasPayementOnDepot()) {
				// Scroll to top
				window.scroll(0, 0)
				document.body.style.overflow = 'hidden'
			}

			switch (requestType) {
				case REQUEST_TYPE.TN:
				case REQUEST_TYPE.TN_UR:
					await dispatch(createTNRequest({ authUser, filesInputs }))
					break
				default:
					await dispatch(createRequest(authUser))
					break
			}
		}
	}

	const createCloseLink = (): string => {
		const requestThemes: RequestTheme[] =
			pageData.collections?.requestThemes || []
		let baseLink: string = config.request.create.baseURLWidthParam
		let subThemesContainCurrentRequest: boolean = false

		requestThemes.forEach((theme: RequestTheme) => {
			if (!subThemesContainCurrentRequest) {
				theme?.requestSubThemes?.forEach((subTheme: RequestSubTheme) => {
					const requestFound: Request | undefined = subTheme?.requests.find(
						(request: Request) => request?.requestId === requestId
					)
					if (isDeclarationRequest(requestFound?.name)) {
						baseLink = config.request.create.profileURL
						localStorage.setItem(config.localStorage.activeTab, '2')
					} else if (requestFound && !baseLink.includes(theme.name)) {
						baseLink += theme.name
						subThemesContainCurrentRequest = true
					}
				})
			}
		})

		return baseLink
	}

	const onAddErrorFromAddress = (address: string) => {
		errors.push(address)
		setErrors(errors)
	}

	const onConfirmLeavingTheForm = (e) => {
		return (e.returnValue = '')
	}
	const onResetRequestFailed = () => {
		dispatch(resetRequestFailedState(false))
	}

	const buildLocation = (
		address: string,
		apartment: string,
		city: string,
		postalCode: string,
		coordinates?: Coordinate,
		cadastralAddress?: string
	): Address => {
		let location: Address = new Address()
		let locationCoordinate: AddressCoordinates = new AddressCoordinates()

		locationCoordinate.update({
			latitude: coordinates?.latitude,
			longitude: coordinates?.longitude
		})

		location.update({
			city,
			address,
			apartment,
			postalCode,
			cadastralAddress,
			coordinates: locationCoordinate
		})

		return location
	}

	const fetchArticlesForMultipleRequestIds = async () => {
		let listArticlesTMP: Article[] = []

		for (const id of requestsIdsForArticles) {
			try {
				const articlesFound: Article[] = await Article.searchArticlesByRequest(
					id
				)

				listArticlesTMP.push(...articlesFound)
			} catch (e: any) {
				setListArticles([])
				setIsArticlesLoading(false)
				console.error(`[ERROR] key [${id}]: ${e?.message}`)
			}
		}

		listArticlesTMP = listArticlesTMP.filter(
			(article, key, list) =>
				list.findIndex((article2) => article2.id === article.id) === key
		)

		setListArticles(listArticlesTMP)

		setTimeout(() => {
			setIsArticlesLoading(false)
		}, 50)
	}

	const fetchArticlesByRequestId = async () => {
		try {
			const { covid, otherSubject, multipleRequests } =
				config.request.searchArticles

			if (multipleRequests.includes(requestId)) {
				setListArticles([])
				setIsArticlesLoading(false)

				return
			}

			setIsOtherRequestPage(otherSubject.requestId === requestId)

			if (otherSubject.requestId === requestId) {
				setListArticles([])
				setIsArticlesLoading(false)

				return
			}

			if (requestId === REQUESTS_IDS.covid) {
				const articlesFound: Article[] = await Article.searchArticlesByKeyword(
					covid.keyword
				)

				setListArticles(articlesFound)

				setTimeout(() => {
					setIsArticlesLoading(false)
				}, 50)

				return
			}

			const isRequestFound = config.articles.relatedRequests.some(
				(request) => request.relatedRequestId == requestId
			)

			if (!isRequestFound) {
				const articlesFound: Article[] = await Article.searchArticlesByRequest(
					requestId
				)

				setListArticles(articlesFound)
			}

			setTimeout(() => {
				setIsArticlesLoading(false)
			}, 50)
		} catch (e: any) {
			setListArticles([])
			setIsArticlesLoading(false)
			console.error(`[ERROR]: ${e?.message}`)
		}
	}

	const onWaitingForAuth = () => {
		if (authUser && authUser.profile) {
			setIsAuthUserInLaval(authUser.profile.address.inMunicipality)

			dispatch(
				populateRequestModelState({
					customerId: `${authUser.profile.id}`
				})
			)
		}
	}

	const shouldSkipStartStep = (name: string): boolean => {
		const requestTypes = [
			TNRequestNames.oilHeatingDeclaration,
			TNRequestNames.selfReliefWaterCounter,
			TNRequestNames.fireplaceDeclaration
		]
		return !!requestTypes.includes(name ?? '')
	}

	useEffect(() => {
		if (doSentPost) {
			submitPost()
		}
	}, [doSentPost])

	useEffect(() => {
		if (request) {
			let type: string = ''
			const requestTypeTemp = RequestTypeDic[request?.templateType!]
			setRequestType(requestTypeTemp)

			if (requestTypeTemp == REQUEST_TYPE.PLAIN) {
				type =
					requestId === OthersRequestsId.name ? OthersRequestsId.key : requestId
			}

			if (
				requestTypeTemp == REQUEST_TYPE.TN ||
				requestTypeTemp == REQUEST_TYPE.TN_UR
			) {
				type = identifierOfRequestTypeDic[request?.name!]
			}

			// Clear the request state from the store if it exists && the type is different from the current request
			// otherwise keep data
			if (requestModelState?.typeId !== type) {
				dispatch(clearRequestState())
			}

			dispatch(
				populateRequestModelState({
					title: request.title,
					typeId: type,
					name: request.name
				})
			)
		}
	}, [request])

	useEffect(() => {
		onWaitingForAuth()
	}, [authUser])

	useEffect(() => {
		const fetchData = async (requestId) => {
			try {
				const repository = new RequestsRepository()
				// Appel de la fonction getRequest pour obtenir les données de la requête créé avec l'ID spécifié
				const result = await repository.getRequest(requestId)

				// Vérifie si la requête reçue accepte des prix de 0$ et rafraîchit le fetch si nécessaire.
				if (hasPriceAboveZeroCheck() && result.invoice?.grand_total === 0) {
					setTimeout(() => {
						fetchData(requestId)
					}, 2000)
				}

				// Vérifier si le résultat contient un id dans le champ "invoice" et son status
				else if (
					result &&
					result.invoice?.id &&
					result.invoice?.status &&
					(result.invoice?.status == INVOICE_STATUS.waitingForPayment ||
						result.invoice?.status == INVOICE_STATUS.notRequired ||
						(hasPayementToBeCalculated() &&
							result.invoice?.status == INVOICE_STATUS.calculationOfTheAmount))
				) {
					// les données de la requête récupérées
					let request = new RequestObj(authUser!, result)
					setCreatedRequestWithInvoice(request)

					// Mettre à jour les données de la requete dans l'entité authUser
					authUser?.setRequest(request)

					document.body.style.overflow = 'auto'
					setLoading(false)
					setDoSentPost(false)
					setCurrentStep(
						// hasAssociatedRequestStep()
						// 	? StepsUrbanism.confirmation
						// 	: Steps.confirmation
						Steps.confirmation
					)
				} else {
					// Sinon appeler de nouveau la fonction fetchData (récursion)
					setTimeout(() => {
						fetchData(requestId)
					}, 2000)
				}
			} catch (error) {
				doIfRequestHasFailed()
				console.error(
					'Erreur lors de la récupération des données de la requête:',
					error
				)
			}
		}

		if (requestCreatedState.code !== '' && requestCreatedState.id !== '') {
			setRequestCreated(requestCreatedState)

			if (isRequestHasPayementOnDepot() || hasPayementToBeCalculated()) {
				// Appel de la fonction pour récupérer les données de la requête
				fetchData(requestCreatedState.id)
			} else {
				setCurrentStep(
					// hasAssociatedRequestStep()
					// 	? StepsUrbanism.confirmation
					// 	: Steps.confirmation

					Steps.confirmation
				)
				setLoading(false)
				setDoSentPost(false)
			}

			if (
				requestType !== REQUEST_TYPE.TN &&
				requestType !== REQUEST_TYPE.TN_UR
			) {
				dispatch(clearRequestState())
			}
		}
	}, [requestCreatedState])

	const doIfRequestHasFailed = () => {
		setRequestHasError(true)
		setLoading(false)
		setDoSentPost(false)
		document.body.style.overflow = 'auto'
	}

	useEffect(() => {
		if (hasRequestFailed) {
			doIfRequestHasFailed()
		}
	}, [hasRequestFailed])

	useEffect(() => {
		if (requestsIdsForArticles.length > 0) {
			fetchArticlesForMultipleRequestIds()
		}
	}, [])

	useEffect(() => {
		fetchArticlesByRequestId()

		const plainStepsTmp: string[] = []
		let PDFSStepsTmp: string[] = []

		// @TODO: REMOVE STRAPI KEY : page_requestInfo_info_step NEXT RELEASE MAIN BUILD TO PROD
		const totalOfPDFSteps: number = config.request.create.nbPDFSteps

		if (pageData?.assets) {
			for (let i = 1; i <= totalOfPDFSteps; i++) {
				PDFSStepsTmp.push(
					formatStrapiText(pageData?.assets['page_requestInfo_step' + i])
				)
			}

			plainStepsTmp.push(
				formatStrapiText(pageData?.assets?.page_requestInfo_step3)
			)
			plainStepsTmp.push(
				formatStrapiText(pageData?.assets?.page_requestInfo_step5)
			)
		}

		const currenRequest = pageData?.requests?.filter(
			(r: Request) => r.requestId === requestId
		)[0]

		setRequest(currenRequest)

		if (isDeclarationRequest(currenRequest?.name)) {
			PDFSStepsTmp[2] = formatStrapiText(
				pageData?.assets?.page_requestInfo_step3_declaration
			)
		}

		let stepTmp =
			currenRequest?.templateType === config.request.create.templateType.PLAIN
				? Steps.form
				: Steps.start

		if (shouldSkipStartStep(currenRequest?.name)) {
			PDFSStepsTmp.shift()
			stepTmp = Steps.applicant
		}

		setListSteps(
			currenRequest?.templateType === config.request.create.templateType.PLAIN
				? plainStepsTmp
				: PDFSStepsTmp
		)

		setCurrentStep(stepTmp)

		setCloseLink(createCloseLink())
		window.addEventListener('beforeunload', onConfirmLeavingTheForm)

		return () => {
			setRequest(undefined)
			setFiles(new Set<File>())
			setFilesInputs([])
			setDoSentPost(false)
			setCurrentStep(stepTmp)
			// commenté pour eviter de vider les données de la requete
			// @TODO: a supprimer apres tests et validation
			// dispatch(clearRequestState())
			window.removeEventListener('beforeunload', onConfirmLeavingTheForm)
		}
	}, [])

	// commenté pour eviter de vider les données de la requete
	// @TODO: a supprimer apres tests et validation
	// useEffect(() => {
	// 	dispatch(clearRequestState())
	// }, [])

	const getPageTitle = () => {
		if (isDeclarationRequest(request?.name)) {
			return pageData?.assets.page_create_new_declaration
		}

		return pageData?.title
	}

	const isDeclarationRequest = (requestName): boolean => {
		const requestTypes = [
			TNRequestNames.oilHeatingDeclaration,
			TNRequestNames.selfReliefWaterCounter,
			TNRequestNames.fireplaceDeclaration
		]
		return !!requestTypes.includes(requestName ?? '')
	}

	return {
		pageAssets: pageData?.assets,
		title: getPageTitle(),
		files,
		inputs,
		listArticles,
		request,
		currentStep,
		steps: Steps,
		listSteps,
		loading,
		coordinates,
		cadastralAddress,
		doSentPost,
		browser,
		requestCreated,
		MAX_CHAR_DESC,
		closeLink,
		isArticlesLoading,
		isOtherRequestPage,
		isAuthUserInLaval,
		authUser,
		address,
		city,
		postalCode,
		apartment,
		errors,
		requestType,
		filesInputs,
		useDefaultUserLocation,
		requestHasError,
		createdRequestWithInvoice,
		onAddErrorFromAddress,
		setApartment,
		setErrors,
		onSubmit,
		setAddress,
		setCoordinates,
		setFiles,
		setDoSentPost,
		setInputs,
		buildLocation,
		setCurrentStep,
		setRequestType,
		setFilesInputs,
		setCity,
		setPostalCode,
		setUseDefaultUserLocation,
		setCadastralAddress,
		setLoading,
		setRequestHasError,
		onResetRequestFailed,
		isDeclarationRequest,
		isRequestHasPayementOnDepot
	}
}

export default CreateRequestCore
