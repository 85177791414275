import React, { FC, useEffect } from 'react'
import * as pageUtils from './__index.utils'
import Text from '../../text'

interface redirectProps {
	text: string
	shouldRedirect: boolean
	onRedirect: () => void
}

const RedirectModal: FC<redirectProps> = ({
	text,
	shouldRedirect,
	onRedirect
}) => {
	useEffect(() => {
		if (shouldRedirect) {
			setTimeout(onRedirect, 3000)
		}
	}, [shouldRedirect])

	return (
		<div className={pageUtils.classes.container}>
			<Text content={text} />
		</div>
	)
}

export default RedirectModal
