import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	AdministrativeRiviewInfo,
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import {
	Direction,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	RequestStatusEnum,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors, Constants } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RadioInput from '@components/requestForms/inputs/radio.input'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import Notification from '@components/ui/notification'
import CheckboxInput from './inputs/checkbox.input'
import TextAreaInput from './inputs/textarea.inputs'
import RequestFiles from '@components/ui/requestFiles'
import {
	closeIcon,
	permitOccupationImage,
	roadStation,
	obstaclePosition
} from '@images/icons'
import DateFilterInput from './inputs/datefilter.input'
import CalendarInput from './inputs/calendar.input'
import { FORMS_ID } from '@templates/requests/__create.utils'
import SearchableSelectInput from './inputs/SearchableSelect.inputs'
import moment from 'moment'
import SelectInput from './inputs/select.inputs'
import { Icon } from '@components/ui/Icon'
import Text from '@components/ui/text'
import Modal from '@components/ui/modal'
import MapForm from '@components/ui/map/MapForm'
import { Collapse } from 'react-collapse'
import CollapseComponent from '@components/ui/collapse'
// import { colors } from 'react-select/dist/declarations/src/theme'

type Classes = {
	rowSection: string
	adresseBlock: string
	checkbox2: string
	checkbox2Label: string
	checkbox2Container: string
	checkboxWrapper: string
	fieldsContainer: string
	rowRemoveContainer: string
	closeIcon: string
	button: string
	description: string
	fileHelperSection: string
	fileContainer: string
	grayBackground: string
	textDescription: string
	noPadding: string
	alignItemStart: string
	timeWrapper: string
	datesWrapper: string
	dateContainer: string
	datesAnswerWrapper: string
	tableSummary: string
	rowNumber: string
	underlineOnHover: string
	linkButton: string
	formInput: string
	map: string
	textError: string
}

const classes: Classes = makeClasses({
	rowSection: {
		display: 'grid',
		alignItems: 'end',
		gridTemplateColumns: '96%3%',
		columnGap: '1%',
		[Breakpoints.maxWidth('sm')]: {
			marginBottom: '15px'
		},
		'& > div': {
			marginBottom: '8px'
		}
	},
	adresseBlock: {
		border: `1px solid ${Colors.greyAlto}`,
		padding: '20px 25px',
		marginBottom: '10px'
	},
	checkbox2: {
		width: 'fit-content',
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	checkbox2Label: {
		fontSize: '16px',
		padding: '0',
		color: Colors.lightBlack,
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: `1px solid ${Colors.lightGrey3}`,
			background: Colors.white,
			outline: 'none !important'
		},
		':after': {
			content: "''",
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: Colors.secondary,
			outline: 'none',
			opacity: '0',
			transition: 'all .15s ease-out'
		}
	},
	checkbox2Container: {
		'& input[value=true] ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxWrapper: {
		display: 'block',
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '14px 12px'
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	rowRemoveContainer: {
		display: 'flex',
		'& > span': {
			marginBottom: '20px'
		}
	},
	closeIcon: {
		display: 'inline-flex',
		height: '30px',
		marginLeft: '-3px',
		cursor: 'pointer',
		alignSelf: 'flex-start',
		marginBottom: '7px',
		img: {
			height: '100%'
		},

		'& span': {
			display: 'none'
		},

		[Breakpoints.maxWidth('sm')]: {
			marginTop: '5px',
			height: '27px',
			'& span': {
				display: 'inline',
				color: '#0054a6',
				textDecoration: 'underline',
				lineHeight: '27px'
			}
		}
	},
	button: {
		display: 'inline-block',
		padding: '10px 20px',
		fontSize: '13px',
		fontWeight: '500',
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: Colors.secondary,
		border: 'none',
		cursor: 'pointer',
		'&:hover': {
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2,
			color: Colors.white
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		marginBottom: '10px!important'
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	},
	grayBackground: {
		background: Colors.darkWhite,
		padding: '20px'
	},
	textDescription: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2
	},
	noPadding: {
		'& section': {
			padding: '0px'
		}
	},
	alignItemStart: {
		alignItems: 'start'
	},
	timeWrapper: {
		display: 'grid',
		columnGap: '2%',
		gridTemplateColumns: '23%23%15%',
		alignItems: 'end',
		[Breakpoints.maxWidth('lg')]: {
			gridTemplateColumns: '35%35%28%'
		},
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr',
			dispplayDirection: 'column'
		}
	},
	datesWrapper: {
		display: 'grid',
		columnGap: '2%',
		gridTemplateColumns: '23%23%23%18%7%',
		alignItems: 'end',
		[Breakpoints.maxWidth('lg')]: {
			gridTemplateColumns: '23%23%23%18%7%'
		},
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr',
			dispplayDirection: 'column'
		}
	},
	dateContainer: {
		background: ' #ddd',
		border: '1px solid #cccccc',
		padding: '14px 12px',
		width: '100%',
		fontWeight: 500,
		fontSize: '15px',
		marginBottom: '20px'
	},
	datesAnswerWrapper: {
		display: 'grid',
		columnGap: '2%',
		gridTemplateColumns: '24%24%24%22%',
		alignItems: 'center',
		[Breakpoints.maxWidth('lg')]: {
			gridTemplateColumns: '24%24%24%22%'
		},
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr',
			dispplayDirection: 'column'
		}
	},
	tableSummary: {
		borderCollapse: 'collapse',
		width: '100%',
		'& td, & th': {
			fontSize: '16px',
			border: '1px solid #ccc',
			padding: '7px 15px',
			lineHeight: '28px'
		},
		'& tr:nth-child(even)': {
			backgroundColor: Colors.lightGrey4
		}
	},
	rowNumber: {
		fontWeight: '500',
		color: Colors.white,
		borderRadius: '50%',
		fontSize: '14px',
		width: '26px',
		height: '26px',
		lineHeight: '26px',
		background: Colors.lightBlue,
		textAlign: 'center',
		display: 'block'
	},
	underlineOnHover: {
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	linkButton: {
		marginLeft: '0px',
		padding: '0px',
		backgroundColor: 'transparent',
		fontSize: '14px',
		border: `none`,
		color: `${Colors.secondary}`,
		'&:hover': {
			backgroundColor: 'transparent !important',
			border: `none !important`,
			color: `${Colors.secondary} !important`
		}
	},
	formInput: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',

		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400 as any,
		padding: '12px',
		borderRadius: '0px',
		lineHeight: '26px',
		marginBottom: '10px',
		'&:focus': {
			outline: 'none'
		},
		height: '46px'
	},
	map: {
		':focus': {
			border: `1px solid ${Colors.primary}`
		}
	},
	textError: {
		color: Colors.errorRed
	}
})

const PermitOccupationOfPublicHighway: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	isSummaryStep,
	steps,
	toPrint,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	filesInputs,
	onSetInputFiles,
	inputFiles,
	formPosition,
	setDisableNextButton
}) => {
	const {
		authUser,
		language,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		UpdateRequestInputFilesArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const subStepData = {
		newRequest: {
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			details_4: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
			]
		},
		modificationRequest: {
			map: false,
			details_1: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1]
		}
	}

	//----------------------------------------------------------------
	//----------------------- INPUTS ---------------------------------
	//----------------------------------------------------------------
	const radioListIsNewRequest: RadioList[] = [
		{
			label: pageAssets?.label_new_request,
			value: REQUEST_CONFIGS_KEYS.requestType.new_request,
			key: 'label_new_request'
		},
		{
			label: pageAssets?.label_request_to_modify,
			value: REQUEST_CONFIGS_KEYS.requestType.modification,
			key: 'label_request_to_modify'
		}
	]
	const radioListCompanyOrOrganisation: RadioList[] = [
		{
			label: pageAssets?.request_form_company_or_organisation,
			value: '102720000',
			key: 'request_form_company_or_organisation'
		},
		{
			label: pageAssets?.request_form_individual,
			value: '102720001',
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_non_profit_organization,
			value: '102720002',
			key: 'request_form_non_profit_organization'
		}
	]

	const radioListQuestion15: RadioList[] = [
		{
			label: pageAssets?.label_complete,
			value: 'true',
			key: 'label_complete'
		},
		{
			label: pageAssets?.label_partial,
			value: 'false',
			key: 'label_partial'
		}
	]
	const radioListQuestion21: RadioList[] = [
		{
			label: pageAssets?.label_yes_the_company_installs_it,
			value: '102720000',
			key: 'label_yes_the_company_installs_it'
		},
		{
			label: pageAssets?.label_no_the_work_is_subcontracted,
			value: '102720001',
			key: 'label_no_the_work_is_subcontracted'
		}
	]
	const radioListQuestion27: RadioList[] = [
		{
			label: pageAssets?.label_continuous_occupation,
			value: '102720000',
			key: 'label_continuous_occupation'
		},
		{
			label: pageAssets?.label_occasional_occupation,
			value: '102720001',
			key: 'label_occasional_occupation'
		}
	]
	const question15_4ListType: RadioList[] = [
		{
			label: pageAssets?.request_form_3_meters_or_less,
			key: 'request_form_3_meters_or_less',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters3
		},
		{
			key: 'request_form_3_to_6_meters',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters3To6,
			label: pageAssets?.request_form_3_to_6_meters
		},
		{
			key: 'request_form_6_to_9_meters',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters6To9,
			label: pageAssets?.request_form_6_to_9_meters
		},
		{
			key: 'request_form_9_to_12_meters',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters9To12,
			label: pageAssets?.request_form_9_to_12_meters
		},
		{
			key: 'request_form_more_than_12_meters',
			value: REQUEST_CONFIGS_KEYS.approximateWidthOfObstruction.meters12,
			label: pageAssets?.request_form_more_than_12_meters
		}
	]

	const [openDetailModal, setOpenDetailModal] = useState<boolean>(false)

	const [selectedDaysList, setSelectedDaysList] = useState<SelectList[]>([])

	const [questionOneInput, setQuestionOneInput] = useState<RequestInputs>({
		name: 'isNewRequest',
		label: pageAssets?.request_form_is_this_a_new_request,
		labelKey: 'request_form_is_this_a_new_request',
		value: answers?.isNewRequest?.toString() || '',
		required: true,
		description: pageAssets?.request_form_is_this_a_new_request_description,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [question2Input, setQuestion2Input] = useState<RequestInputs>({
		name: 'makingRequestAs',
		label: pageAssets?.request_form_making_request_as_a,
		labelKey: 'request_form_making_request_as_a',
		value: answers?.makingRequestAs?.toString() || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [question3Input, setQuestion3Input] = useState<RequestInputs>({
		name: 'haveContractedCompany',
		label: pageAssets?.request_form_have_you_contracted_a_company,
		labelKey: 'request_form_have_you_contracted_a_company',
		value: answers?.haveContractedCompany?.toString() || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [question4Input, setQuestion4Input] = useState<RequestInputs>({
		name: 'haveAgreementWithCity',
		label: pageAssets?.request_form_is_there_an_agreement_with_the_city,
		labelKey: 'request_form_is_there_an_agreement_with_the_city',
		value: answers?.haveAgreementWithCity?.toString() || '',
		required: false,
		description:
			pageAssets?.request_form_is_there_an_agreement_with_the_city_description,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question5Input, setQuestion5Input] = useState<RequestInputs>({
		name: 'workFollowInsurance',
		label: pageAssets?.request_form_are_the_works_to_be_carried,
		labelKey: 'request_form_are_the_works_to_be_carried',
		value: answers?.workFollowInsurance?.toString() || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [question5_1Input, setQuestion5_1Input] = useState<RequestInputs>({
		name: 'connectionPermitNumber',
		label: pageAssets?.request_form_connection_permit_number,
		labelKey: 'request_form_connection_permit_number',
		value: answers?.connectionPermitNumber || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question5_2Input, setQuestion5_2Input] = useState<RequestInputs>({
		name: 'nameOfLavalCity',
		label: pageAssets?.request_form_name_of_the_city_of_laval,
		labelKey: 'request_form_name_of_the_city_of_laval',
		value: answers?.nameOfLavalCity || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [question6Input, setQuestion6Input] = useState<RequestInputs>({
		name: 'companyName',
		label:
			pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
		labelKey:
			'request_form_annualRegistrationCertificateForContractors_companyName_label',
		value: answers?.companyName || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [question7Input, setQuestion7Input] = useState<RequestInputs>({
		name: 'companyAddress',
		label: pageAssets?.request_form_company_address,
		labelKey: 'request_form_company_address',
		value: answers?.companyAddress || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [question8Input, setQuestion8Input] = useState<RequestInputs>({
		name: 'personInchargeOfProject',
		label: pageAssets?.request_form_person_incharge_of_project,
		labelKey: 'request_form_person_incharge_of_project',
		value: answers?.personInchargeOfProject?.toString() || '',
		description:
			pageAssets?.request_form_person_incharge_of_project_description,
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question8_1Input, setQuestion8_1Input] = useState<RequestInputs>({
		name: 'personInchargeFirstName',
		label: pageAssets?.label_firstName,
		labelKey: 'label_firstName',
		value: answers?.personInchargeFirstName || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question8_2Input, setQuestion8_2Input] = useState<RequestInputs>({
		name: 'personInchargeLastName',
		label: pageAssets?.label_lastName,
		labelKey: 'label_lastName',
		value: answers?.personInchargeLastName || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question8_3Input, setQuestion8_3Input] = useState<RequestInputs>({
		name: 'personInchargeEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers?.personInchargeEmail || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question8_4Input, setQuestion8_4Input] = useState<RequestInputs>({
		name: 'personInchargePhone',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers?.personInchargePhone || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	/*const [question9Input, setQuestion9Input] = useState<RequestInputs>({
		name: 'isSupervisor',
		label: pageAssets?.request_form_are_you_the_supervisor,
		labelKey: 'request_form_are_you_the_supervisor',
		value: answers?.isSupervisor?.toString() || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question9_1Input, setQuestion9_1Input] = useState<RequestInputs>({
		name: 'supervisorFirstName',
		label: pageAssets?.label_firstName,
		labelKey: 'label_firstName',
		value: answers?.supervisorFirstName || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question9_2Input, setQuestion9_2Input] = useState<RequestInputs>({
		name: 'supervisorLastName',
		label: pageAssets?.label_lastName,
		labelKey: 'label_lastName',
		value: answers?.supervisorLastName || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question9_3Input, setQuestion9_3Input] = useState<RequestInputs>({
		name: 'supervisorEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers?.supervisorEmail || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [question9_4Input, setQuestion9_4Input] = useState<RequestInputs>({
		name: 'supervisorPhone',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers?.supervisorPhone || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})*/

	const [question10ListType, setQuestion10ListType] = useState<CheckboxList[]>()
	const [question15_3ListType, setQuestion15_3ListType] =
		useState<CheckboxList[]>()

	// Sub step 2
	const [question10Input, setQuestion10Input] = useState<RequestInputs>({
		name: 'natureOfIntervention',
		value: answers.natureOfIntervention?.toString() ?? '',
		label: pageAssets?.request_form_nature_of_the_intervention,
		labelKey: 'request_form_nature_of_the_intervention',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		ref: useRef<HTMLInputElement>(null)
	})
	const [question10OtherInput, setQuestion10OtherInput] =
		useState<RequestInputs>({
			name: 'natureOfInterventionOther',
			label:
				pageAssets?.request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label,
			labelKey:
				'request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label',
			value: answers.natureOfInterventionOther ?? '',
			required: false,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})

	const [question11Input, setQuestion11Input] = useState<RequestInputs>({
		name: 'additionalInformation',
		value: answers.additionalInformation ?? '',
		description: pageAssets?.request_form_additional_information_description,
		label: pageAssets?.request_form_additional_information,
		labelKey: 'request_form_additional_information',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		ref: useRef<HTMLInputElement>(null)
	})

	// Sub step 3
	const [question12Input, setQuestion12Input] = useState<RequestInputs>({
		name: 'mainAddressOfWork',
		value: answers?.mainAddressOfWork ?? '',
		label: pageAssets?.request_form_main_address_of_work,
		labelKey: 'request_form_main_address_of_work',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question13Input, setQuestion13Input] = useState<RequestInputs>({
		name: 'startOfTheDisruption',
		value: answers.startOfTheDisruption ?? '',
		label: pageAssets?.request_form_start_of_the_disruption,
		labelKey: 'request_form_start_of_the_disruption',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question14Input, setQuestion14Input] = useState<RequestInputs>({
		name: 'endOfTheDisruption',
		value: answers.endOfTheDisruption ?? '',
		label: pageAssets?.request_form_end_of_the_disruption,
		labelKey: 'request_form_end_of_the_disruption',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question15Input, setQuestion15Input] = useState<RequestInputs>({
		name: 'isFullOrPatialClosure',
		value: answers.isFullOrPatialClosure?.toString() ?? '',
		label: pageAssets?.request_form_is_full_or_partial_closure,
		description:
			pageAssets?.request_form_is_full_or_partial_closure_description,
		labelKey: 'request_form_is_full_or_partial_closure',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question15_1Input, setQuestion15_1Input] = useState<RequestInputFiles>(
		{
			name: 'temporaryTrafficControl',
			label: pageAssets?.request_form_please_attach_a_temporary_traffic_control,
			labelKey: 'request_form_please_attach_a_temporary_traffic_control',
			description:
				pageAssets?.request_form_please_attach_a_temporary_traffic_control_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'temporaryTrafficControl')
					?.files || []
			),
			required: false,
			numberFilesRequired: 1,
			numberMaxFiles: 10,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			category: requestCategories.other,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		}
	)

	const [question15_2Input, setQuestion15_2Input] = useState<RequestInputs>({
		name: 'willRoadBeLimited',
		value: answers.willRoadBeLimited?.toString() ?? '',
		label: pageAssets?.request_form_will_the_road_occupancy_be_limited,
		description:
			pageAssets?.request_form_will_the_road_occupancy_be_limited_description,
		labelKey: 'request_form_will_the_road_occupancy_be_limited',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question15_3Input, setQuestion15_3Input] = useState<RequestInputs>({
		name: 'impactsOnTraffic',
		value: answers.impactsOnTraffic?.toString() ?? '',
		label: pageAssets?.request_form_what_are_the_other_impacts,
		labelKey: 'request_form_what_are_the_other_impacts',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question15_4Input, setQuestion15_4Input] = useState<RequestInputs>({
		name: 'approximateWidthOfObstruction',
		value: answers.approximateWidthOfObstruction?.toString() ?? '',
		label: pageAssets?.request_form_what_is_the_approximate_width,
		labelKey: 'request_form_what_is_the_approximate_width',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})
	const [question15_5Input, setQuestion15_5Input] = useState<RequestInputs>({
		name: 'approximateLengthOfObstruction',
		value: answers.approximateLengthOfObstruction?.toString() ?? '',
		label: pageAssets?.request_form_what_is_the_approximate_length,
		labelKey: 'request_form_what_is_the_approximate_length',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})
	const [question15_6Input, setQuestion15_6Input] = useState<RequestInputs>({
		name: 'useStandardizedBoard',
		value: answers.useStandardizedBoard?.toString() ?? '',
		label: pageAssets?.request_form_are_you_planning_to_use_a_standardized,
		labelKey: 'request_form_are_you_planning_to_use_a_standardized',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question15_6_1Input, setQuestion15_6_1Input] = useState<RequestInputs>(
		{
			name: 'numberOfStandardizedDrawing',
			value: answers.numberOfStandardizedDrawing ?? '',
			label: pageAssets?.request_form_number_of_the_standardized_drawing,
			labelKey: 'request_form_number_of_the_standardized_drawing',
			required: false,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
			ref: useRef<HTMLInputElement>(null)
		}
	)
	const [question15_6_2Input, setQuestion15_6_2Input] =
		useState<RequestInputFiles>({
			name: 'temporaryTrafficControlPlan',
			label:
				pageAssets?.request_form_please_attach_a_temporary_traffic_control_plan,
			labelKey: 'request_form_please_attach_a_temporary_traffic_control_plan',
			description:
				pageAssets?.request_form_please_attach_a_temporary_traffic_control_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'temporaryTrafficControlPlan')
					?.files || []
			),
			required: false,
			numberFilesRequired: 1,
			numberMaxFiles: 10,
			// subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			category: requestCategories.other
		})
	const [question16Input, setQuestion16Input] = useState<RequestInputs>({
		name: 'isLocatedNearIntersection',
		value: answers.isLocatedNearIntersection?.toString() ?? '',
		label: pageAssets?.request_form_is_the_obstruction_area_located_near,
		description:
			pageAssets?.request_form_is_the_obstruction_area_located_near_description,
		labelKey: 'request_form_is_the_obstruction_area_located_near',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})
	const [question17Input, setQuestion17Input] = useState<RequestInputs>({
		name: 'willAllowPassage',
		value: answers.willAllowPassage?.toString() ?? '',
		label: pageAssets?.request_form_will_the_obstruction_area_allow,
		labelKey: 'request_form_will_the_obstruction_area_allow',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})
	const [question18Input, setQuestion18Input] = useState<RequestInputs>({
		name: 'willMakeAnSTL',
		value: answers.willMakeAnSTL?.toString() ?? '',
		label: pageAssets?.request_form_will_the_obstruction_area_make_an_STL,
		labelKey: 'request_form_will_the_obstruction_area_make_an_STL',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})
	const [question19Input, setQuestion19Input] = useState<RequestInputs>({
		name: 'isATemporaryReduction',
		value: answers.isATemporaryReduction?.toString() ?? '',
		label: pageAssets?.request_form_is_a_temporary_reduction,
		labelKey: 'request_form_is_a_temporary_reduction',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})
	const [question19_2Input, setQuestion19_2Input] = useState<RequestInputFiles>(
		{
			name: 'temporarySpeedLimitReduction',
			label: pageAssets?.request_form_please_attach_a_temporary_speed_limit,
			labelKey: 'request_form_please_attach_a_temporary_speed_limit',
			description:
				pageAssets?.request_form_please_attach_a_temporary_speed_limit_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'temporarySpeedLimitReduction')
					?.files || []
			),
			required: false,
			numberFilesRequired: 1,
			numberMaxFiles: 10,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			category: requestCategories.other
		}
	)

	const [question20Input, setQuestion20Input] = useState<RequestInputs>({
		name: 'anyAdditionalInformation',
		value: answers.anyAdditionalInformation ?? '',
		label: pageAssets?.request_form_any_additional_information,
		description:
			pageAssets?.request_form_any_additional_information_description,
		labelKey: 'request_form_any_additional_information',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question21Input, setQuestion21Input] = useState<RequestInputs>({
		name: 'companyResponsibleForSignage',
		value: answers.companyResponsibleForSignage?.toString() ?? '',
		label: pageAssets?.request_form_is_company_responsible_for_signage,
		labelKey: 'request_form_is_company_responsible_for_signage',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [question22Input, setQuestion22Input] = useState<RequestInputs>({
		name: 'organisationName',
		label: pageAssets?.request_form_historicalArchive_organizationName_label,
		labelKey: 'request_form_historicalArchive_organizationName_label',
		value: answers?.organisationName || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})
	const [question23Input, setQuestion23Input] = useState<RequestInputs>({
		name: 'nameOfPersonResponsible',
		label: pageAssets?.label_name_of_the_responsible_person,
		labelKey: 'label_name_of_the_responsible_person',
		value: answers?.nameOfPersonResponsible || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})
	const [question24Input, setQuestion24Input] = useState<RequestInputs>({
		name: 'personResponsiblePhone',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers?.personResponsiblePhone || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})
	const [question25Input, setQuestion25Input] = useState<RequestInputs>({
		name: 'personResponsibleEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers?.personResponsibleEmail || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [question26Input, setQuestion26Input] = useState<RequestInputFiles>({
		name: 'proofOfLiabilityInsurance',
		label: pageAssets?.request_form_valid_proof_of_liability_insurance,
		labelKey: 'request_form_valid_proof_of_liability_insurance',
		description:
			pageAssets?.request_form_valid_proof_of_liability_insurance_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'proofOfLiabilityInsurance')
				?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		numberMaxFiles: 10,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		category: requestCategories.other
	})
	// Sub step 4

	const [question27Input, setQuestion27Input] = useState<RequestInputs>({
		name: 'occupationDate',
		label: pageAssets?.request_form_occupation_date,
		labelKey: 'request_form_occupation_date',
		value: answers?.occupationDate?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep:
			questionOneInput.value == radioListIsNewRequest[1].value
				? REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [question28Input, setQuestion28Input] = useState<RequestInputs>({
		name: 'occupationStartDate',
		label:
			pageAssets?.request_form_fireHydrantsTempAqueductNetwork_startDate_label,
		labelKey: 'request_form_fireHydrantsTempAqueductNetwork_startDate_label',
		value: answers?.occupationStartDate || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep:
			questionOneInput.value == radioListIsNewRequest[1].value
				? REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})
	const [question29Input, setQuestion29Input] = useState<RequestInputs>({
		name: 'occupationEndDate',
		label:
			pageAssets?.request_form_fireHydrantsTempAqueductNetwork_endDate_label,
		labelKey: 'request_form_fireHydrantsTempAqueductNetwork_endDate_label',
		value: answers?.occupationEndDate || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep:
			questionOneInput.value == radioListIsNewRequest[1].value
				? REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [question30Input, setQuestion30Input] = useState<RequestInputs>({
		name: 'calendarDate',
		label: pageAssets?.request_form_please_select_the_date_of_occupancy,
		labelKey: 'request_form_please_select_the_date_of_occupancy',
		value: answers?.calendarDate || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep:
			questionOneInput.value == radioListIsNewRequest[1].value
				? REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const initialDateTime: AdministrativeRiviewInfo = {
		date: '',
		occpation_value: '',
		start_time: '',
		end_time: '',
		is_24_7: false,
		is_weekend: false
	}

	let dataList = recursiveParse(answers?.dateTimeList)
	if (answers?.dateTimeList?.at(0)?.is_from_config) {
		dataList = pageUtils.transformData(answers?.dateTimeList, true)
	}

	const [dateTimeList, setDateTimeList] = useState<AdministrativeRiviewInfo[]>(
		answers?.dateTimeList ? recursiveParse(dataList) : [initialDateTime]
	)

	const [dateTimeListInput, setDateTimeListInput] = useState<RequestInputs>({
		name: 'dateTimeList',
		label: '',
		labelKey: '',
		value: JSON.stringify(dateTimeList),
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep:
			questionOneInput.value == radioListIsNewRequest[1].value
				? REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [question32Input, setQuestion32Input] = useState<RequestInputs>({
		name: 'startHour',
		label: pageAssets?.label_start_hour,
		labelKey: 'label_start_hour',
		value: answers?.startHour || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})
	const [question33Input, setQuestion33Input] = useState<RequestInputs>({
		name: 'endHour',
		label: pageAssets?.label_end_hour,
		labelKey: 'label_end_hour',
		value: answers?.endHour || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [question34Input, setQuestion34Input] = useState<RequestInputs>({
		name: 'is24On24',
		label: pageAssets?.label_24_on_24,
		labelKey: 'label_24_on_24',
		value: answers?.is24On24 || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [question37Input, setQuestion37Input] = useState<RequestInputs>({
		name: 'additionalOccupationInformation',
		label: pageAssets?.request_form_any_additional_information,
		labelKey: 'request_form_any_additional_information',
		value: answers?.additionalOccupationInformation || '',
		description:
			pageAssets?.request_form_any_additional_information_on_occupation,
		required: false,
		ref: useRef<HTMLSelectElement>(null)
		// subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [question39Input, setQuestion39Input] = useState<RequestInputs>({
		name: 'additionalDisruptionInformation',
		label: pageAssets?.request_form_any_additional_information,
		labelKey: 'request_form_any_additional_information',
		description:
			pageAssets?.request_form_any_additional_information_location_description,
		value: answers?.additionalDisruptionInformation || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [question38Input, setQuestion38Input] = useState<RequestInputs>({
		name: 'specifiedDatesAndTime',
		label: '',
		labelKey: '',
		value: answers?.specifiedDatesAndTime || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [question35Input, setQuestion35Input] = useState<RequestInputs>({
		name: 'permitNumber',
		label: pageAssets?.request_form_permit_number,
		labelKey: 'request_form_permit_number',
		value: answers?.permitNumber || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null)
		// subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [question36Input, setQuestion36Input] = useState<RequestInputs>({
		name: 'requestNumber',
		label: pageAssets?.request_form_request_number,
		labelKey: 'request_form_request_number',
		value: answers?.requestNumber || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null)
		// subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [requestNumberInput, setRequestNumberInput] = useState<RequestInputs>({
		name: 'requestNumberOther',
		label: pageAssets?.request_form_request_number,
		labelKey: 'request_form_request_number',
		value: answers?.requestNumberOther || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null)
		// subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})
	const [occupationComplyInput, setOccupationComplyInput] =
		useState<RequestInputs>({
			name: 'occupancyRespectingExigences',
			label:
				pageAssets?.request_form_does_the_occupation_comply_with_the_requirements,
			labelKey: 'request_form_request_number',
			value: answers?.occupancyRespectingExigences || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	//----------------------------------------------------------------
	//------------------- INPUTS SETTERS -----------------------------
	//----------------------------------------------------------------

	const updateNextButton = (value: string) => {
		if (value == radioListYesNo[0].value) {
			setDisableNextButton && setDisableNextButton(false)
		} else {
			setDisableNextButton && setDisableNextButton(true)
		}
	}

	const changeRequiredAttributes = async (
		required: boolean,
		setQuestionInputList:
			| Array<(value: React.SetStateAction<RequestInputs>) => void>
			| Array<(value: React.SetStateAction<RequestInputFiles>) => void>,
		errorArray?: string[]
	) => {
		const inputNames: string[] = []
		await Promise.all(
			setQuestionInputList.map(
				async (
					setQuestionInput:
						| ((value: React.SetStateAction<RequestInputs>) => void)
						| ((value: React.SetStateAction<RequestInputFiles>) => void)
				) => {
					await new Promise<void>((resolve) => {
						setQuestionInput((questionInput) => {
							if (!required) {
								inputNames.push(questionInput.name)
							}

							if ('files' in questionInput) {
								return {
									...questionInput,
									required: required,
									value: required ? questionInput.files : []
								}
							}
							return {
								...questionInput,
								required: required,
								value: required ? questionInput.value : ''
							}
						})

						resolve()
					})
				}
			)
		)
		// fix errors if any
		if (errorArray) {
			onFixError(errorArray.filter((err) => !inputNames.includes(err)))
		} else {
			onFixError(errors.filter((err) => !inputNames.includes(err)))
		}
	}

	const updateSubSection4Inputs = (
		has4SubSections: boolean,
		setQuestionInputList: Array<
			(value: React.SetStateAction<RequestInputs>) => void
		>
	) => {
		setQuestionInputList?.map((setQuestionInput) => {
			setQuestionInput((questionInput) => {
				const newQuestionInput = {
					...questionInput,
					subStep: has4SubSections
						? REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
						: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
				return newQuestionInput
			})
		})
	}

	const onSelectQuestionOneInput = (value: string) => {
		setQuestionOneInput({ ...questionOneInput, value })

		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			questionOneInput.name
		)

		const falesyQuestions = [
			setQuestion2Input,
			setQuestion3Input,
			setQuestion4Input,
			setQuestion5Input,
			setQuestion5_1Input,
			setQuestion6Input,
			setQuestion7Input,
			setQuestion8_1Input,
			setQuestion8_2Input,
			setQuestion8_3Input,
			setQuestion8_4Input
			/*setQuestion9Input,
			setQuestion9_1Input,
			setQuestion9_2Input,
			setQuestion9_3Input,
			setQuestion9_4Input*/
		]
		const truthyQuestions = [setQuestion2Input]
		const subSectionInputs = [
			setQuestion27Input,
			setQuestion28Input,
			setQuestion29Input,
			setQuestion30Input,
			setQuestion37Input,
			setDateTimeListInput
		]

		if (value == radioListIsNewRequest[0].value) {
			changeRequiredAttributes(true, truthyQuestions, errorArray)
			// update sub section
			updateSubSection4Inputs(true, subSectionInputs)
			changeRequiredAttributes(false, [setQuestion27Input], errorArray)
			onSetSubSteps(subStepData.newRequest)
		} else {
			changeRequiredAttributes(false, falesyQuestions, errorArray)
			// update sub section
			updateSubSection4Inputs(false, subSectionInputs)
			changeRequiredAttributes(true, [setQuestion27Input], errorArray)
			onSetSubSteps(subStepData.modificationRequest)
		}
	}

	const onSelectQuestion2Input = (value: string) => {
		setQuestion2Input({ ...question2Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question2Input.name
		)
		const questions = [
			setQuestion4Input,
			setQuestion5Input,
			setQuestion6Input,
			setQuestion7Input,
			setQuestion8_1Input,
			setQuestion8_2Input,
			setQuestion8_3Input,
			setQuestion8_4Input
			//setQuestion9Input,
		]
		const organisationQuestions = [
			setQuestion21Input,
			setQuestion22Input,
			setQuestion23Input,
			setQuestion24Input,
			setQuestion25Input
		]
		if (value == radioListCompanyOrOrganisation[1].value) {
			changeRequiredAttributes(
				false,
				[...questions, ...organisationQuestions]
				// errorArray
			)
			changeRequiredAttributes(
				true,
				[setQuestion3Input]
				// errorArray
			)
			// changeRequiredAttributes(false, organisationQuestions, errorArray)
			changeRequiredAttributes(false, [setQuestion26Input], errorArray)
		} else {
			changeRequiredAttributes(false, [setQuestion3Input], errorArray)
			changeRequiredAttributes(
				true,
				[...questions, setQuestion21Input],
				errorArray
			)
			// changeRequiredAttributes(true, organisationQuestions, errorArray)
			changeRequiredAttributes(true, [setQuestion26Input], errorArray)
			setDisableNextButton && setDisableNextButton(true)
		}

		if (question4Input.value == radioListYesNo[0].value) {
			setDisableNextButton && setDisableNextButton(false)
		} else {
			setDisableNextButton && setDisableNextButton(true)
		}
		// Reset question 15
		changeRequiredAttributes(false, [setQuestion15Input], errorArray)
		changeRequiredAttributes(true, [setQuestion15Input], errorArray)
	}

	const onSelectQuestion3Input = (value: string) => {
		setQuestion3Input({ ...question3Input, value })

		updateNextButton(value)

		onFixError(UpdateRequestInputsErrorsArray(errors!, question3Input.name))
	}

	const onSelectQuestion4Input = (value: string) => {
		setQuestion4Input({ ...question4Input, value })

		updateNextButton(value)

		onFixError(UpdateRequestInputsErrorsArray(errors!, question4Input.name))
	}

	const onSelectQuestion5Input = (value: string) => {
		setQuestion5Input({ ...question5Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question5Input.name
		)

		const questions = [setQuestion5_1Input]
		if (value == radioListYesNo[0].value) {
			changeRequiredAttributes(true, questions, errorArray)
		} else {
			changeRequiredAttributes(false, questions, errorArray)
		}
	}

	const onSelectQuestion5_1Input = (value: string) => {
		setQuestion5_1Input({ ...question5_1Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question5_1Input.name))
	}

	const onSelectQuestion5_2Input = (value: string) => {
		setQuestion5_2Input({ ...question5_2Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question5_2Input.name))
	}

	const onSelectQuestion6Input = (value: string) => {
		setQuestion6Input({ ...question6Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question6Input.name))
	}
	const onSelectQuestion7Input = (value: string) => {
		setQuestion7Input({ ...question7Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question7Input.name))
	}

	const onSelectQuestion8Input = (value: string) => {
		setQuestion8Input({ ...question8Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question8Input.name
		)
		const questions = [
			setQuestion8_1Input,
			setQuestion8_2Input,
			setQuestion8_3Input,
			setQuestion8_4Input
		]
		if (value == '') {
			changeRequiredAttributes(true, questions, errorArray)
		} else {
			changeRequiredAttributes(false, questions, errorArray)
		}
	}
	const onSelectQuestion8_1Input = (value: string) => {
		setQuestion8_1Input({ ...question8_1Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question8_1Input.name))
	}
	const onSelectQuestion8_2Input = (value: string) => {
		setQuestion8_2Input({ ...question8_2Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question8_2Input.name))
	}
	const onSelectQuestion8_3Input = (value: string) => {
		setQuestion8_3Input({ ...question8_3Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question8_3Input.name))
	}
	const onSelectQuestion8_4Input = (value: string) => {
		setQuestion8_4Input({ ...question8_4Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question8_4Input.name))
	}

	/*const onSelectQuestion9Input = (value: string) => {
		setQuestion9Input({ ...question9Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question9Input.name
		)
		const questions = [
			setQuestion9_1Input,
			setQuestion9_2Input,
			setQuestion9_3Input,
			setQuestion9_4Input
		]
		if (value == radioListYesNo[1].value) {
			changeRequiredAttributes(true, questions, errorArray)
		} else {
			changeRequiredAttributes(false, questions, errorArray)
		}
	}

	const onSelectQuestion9_1Input = (value: string) => {
		setQuestion9_1Input({ ...question9_1Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question9_1Input.name))
	}
	const onSelectQuestion9_2Input = (value: string) => {
		setQuestion9_2Input({ ...question9_2Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question9_2Input.name))
	}
	const onSelectQuestion9_3Input = (value: string) => {
		setQuestion9_3Input({ ...question9_3Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question9_3Input.name))
	}
	const onSelectQuestion9_4Input = (value: string) => {
		setQuestion9_4Input({ ...question9_4Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question9_4Input.name))
	}*/

	const onSelectQuestion10Input = (value: string) => {
		pageUtils.handleCheckboxWithOthers(
			value,
			question10Input,
			setQuestion10Input,
			question10OtherInput,
			setQuestion10OtherInput,
			question10ListType!
		)

		onFixError(
			errors.filter(
				(error) =>
					![question10Input.name, question10OtherInput.name].includes(error)
			)
		)
	}

	const onSelectQuestion11Input = (value: string) => {
		setQuestion11Input({ ...question11Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question11Input.name))
	}

	const onSelectQuestion12Input = (value: string) => {
		setQuestion12Input({ ...question12Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question12Input.name))
	}

	const onSelectQuestion13Input = (value: string) => {
		setQuestion13Input({ ...question13Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question13Input.name))
	}
	const onSelectQuestion14Input = (value: string) => {
		setQuestion14Input({ ...question14Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question14Input.name))
	}
	const onSelectQuestion15Input = (value: string) => {
		setQuestion15Input({ ...question15Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question15Input.name
		)

		if (value == radioListQuestion15[0].value) {
			changeRequiredAttributes(
				true,
				[setQuestion15_1Input, setQuestion26Input],
				errorArray
			)
			changeRequiredAttributes(true, [setQuestion17Input], errorArray)
			changeRequiredAttributes(
				false,
				[
					setQuestion15_2Input,
					setQuestion15_3Input,
					setQuestion15_4Input,
					setQuestion15_5Input,
					setQuestion15_6Input,
					setQuestion15_6_1Input
				],
				errorArray
			)

			if (question2Input.value == radioListCompanyOrOrganisation[1].value) {
				changeRequiredAttributes(
					true,
					[
						setQuestion22Input,
						setQuestion23Input,
						setQuestion24Input,
						setQuestion25Input
					],
					errorArray
				)
			} else {
				changeRequiredAttributes(
					false,
					[
						setQuestion22Input,
						setQuestion23Input,
						setQuestion24Input,
						setQuestion25Input
					],
					errorArray
				)
			}

			changeRequiredAttributes(false, [setQuestion15_6_2Input], errorArray)
		} else {
			changeRequiredAttributes(false, [setQuestion15_1Input], errorArray)
			if (question2Input.value != radioListCompanyOrOrganisation[1].value) {
				changeRequiredAttributes(true, [setQuestion26Input], errorArray)
				// changeRequiredAttributes(true, [setQuestion21Input], errorArray)
			} else {
				changeRequiredAttributes(false, [setQuestion26Input], errorArray)
				// changeRequiredAttributes(false, [setQuestion21Input], errorArray)
			}
			changeRequiredAttributes(false, [setQuestion17Input], errorArray)
			changeRequiredAttributes(true, [setQuestion15_2Input], errorArray)
		}
	}

	const onSelectQuestion15_1Input = (files: Set<File>) => {
		setQuestion15_1Input({
			...question15_1Input,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, question15_1Input.name))
	}

	const onSelectQuestion15_2Input = (value: string) => {
		setQuestion15_2Input({ ...question15_2Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question15_2Input.name
		)
		// setQuestion15_4Input
		// setQuestion15_6Input
		if (value == radioListYesNo[0].value) {
			changeRequiredAttributes(
				true,
				[setQuestion15_5Input, setOccupationComplyInput],
				errorArray
			)
			if (question2Input.value != radioListCompanyOrOrganisation[1].value) {
				changeRequiredAttributes(true, [setQuestion26Input], errorArray)
				// changeRequiredAttributes(true, [setQuestion21Input], errorArray)
			} else {
				changeRequiredAttributes(false, [setQuestion26Input], errorArray)
				// changeRequiredAttributes(false, [setQuestion21Input], errorArray)
			}

			// changeRequiredAttributes(false, [setQuestion26Input], errorArray)
			changeRequiredAttributes(
				false,
				[
					setQuestion15_3Input,
					setQuestion15_4Input,
					setQuestion15_6Input,
					setQuestion22Input,
					setQuestion23Input,
					setQuestion24Input,
					setQuestion25Input
				],
				errorArray
			)
		} else {
			changeRequiredAttributes(
				true,
				[
					setQuestion15_3Input,
					// setQuestion21Input,
					setQuestion15_4Input,
					setQuestion15_6Input
				],
				errorArray
			)
			changeRequiredAttributes(true, [setQuestion26Input], errorArray)
			if (question2Input.value == radioListCompanyOrOrganisation[1].value) {
				changeRequiredAttributes(
					true,
					[
						setQuestion22Input,
						setQuestion23Input,
						setQuestion24Input,
						setQuestion25Input
					],
					errorArray
				)
			}
			// else{
			// 	changeRequiredAttributes(false, [
			// 		setQuestion22Input,
			// 		setQuestion23Input,
			// 		setQuestion24Input,
			// 		setQuestion25Input,
			// 	], errorArray)
			// }
			changeRequiredAttributes(
				false,
				[setOccupationComplyInput, setQuestion15_5Input],
				errorArray
			)
		}
	}
	const onSelectQuestion15_3Input = (value: string) => {
		let newValue
		if (question15_3Input.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(value, question15_3Input.value)
		} else {
			newValue =
				question15_3Input.value == ''
					? value
					: question15_3Input.value + `, ${value}`
		}

		setQuestion15_3Input({
			...question15_3Input,
			value: newValue
		})

		onFixError(
			errors.filter((error) => ![question15_3Input.name].includes(error))
		)
	}
	const onSelectQuestion15_4Input = (value: string) => {
		setQuestion15_4Input({
			...question15_4Input,
			value
		})
		onFixError(UpdateRequestInputsErrorsArray(errors, question15_4Input.name))
	}

	const onSelectQuestion15_5Input = (value: string) => {
		setQuestion15_5Input({ ...question15_5Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question15_5Input.name))
	}

	const onSelectQuestion15_6Input = (value: string) => {
		setQuestion15_6Input({ ...question15_6Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question15_6Input.name
		)

		if (value === radioListYesNo[1].value) {
			changeRequiredAttributes(true, [setQuestion15_6_2Input], errorArray)
			changeRequiredAttributes(false, [setQuestion15_6_1Input], errorArray)
		} else {
			changeRequiredAttributes(false, [setQuestion15_6_2Input], errorArray)
			changeRequiredAttributes(true, [setQuestion15_6_1Input], errorArray)
		}
	}
	const onSelectQuestion15_6_1Input = (value: string) => {
		setQuestion15_6_1Input({ ...question15_6_1Input, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, question15_6_1Input.name)
		)
	}
	const onSelectQuestion15_6_2Input = (files: Set<File>) => {
		setQuestion15_6_2Input({
			...question15_6_2Input,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, question15_6_2Input.name))
	}
	const onSelectQuestion16Input = (value: string) => {
		setQuestion16Input({ ...question16Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question16Input.name))
	}
	const onSelectQuestion17Input = (value: string) => {
		setQuestion17Input({ ...question17Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question17Input.name))
	}
	const onSelectQuestion18Input = (value: string) => {
		setQuestion18Input({ ...question18Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question18Input.name))
	}
	const onSelectQuestion19Input = (value: string) => {
		setQuestion19Input({ ...question19Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question19Input.name
		)

		if (value === radioListYesNo[0].value) {
			changeRequiredAttributes(true, [setQuestion19_2Input], errorArray)
		} else {
			changeRequiredAttributes(false, [setQuestion19_2Input], errorArray)
		}
	}
	const onSelectQuestion19_2Input = (files: Set<File>) => {
		setQuestion19_2Input({
			...question19_2Input,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, question19_2Input.name))
	}
	const onSelectQuestion20Input = (value: string) => {
		setQuestion20Input({ ...question20Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question20Input.name))
	}
	const onSelectQuestion21Input = (value: string) => {
		setQuestion21Input({ ...question21Input, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question21Input.name
		)

		if (value === radioListQuestion21[1].value) {
			changeRequiredAttributes(
				true,
				[
					setQuestion22Input,
					setQuestion23Input,
					setQuestion24Input,
					setQuestion25Input
				],
				errorArray
			)
			// changeRequiredAttributes(true, [setQuestion26Input])
		} else {
			changeRequiredAttributes(
				false,
				[
					setQuestion22Input,
					setQuestion23Input,
					setQuestion24Input,
					setQuestion25Input
				],
				errorArray
			)
			// changeRequiredAttributes(false, [setQuestion26Input])
		}
	}
	const onSelectQuestion22Input = (value: string) => {
		setQuestion22Input({ ...question22Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question22Input.name))
	}
	const onSelectQuestion23Input = (value: string) => {
		setQuestion23Input({ ...question23Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question23Input.name))
	}
	const onSelectQuestion24Input = (value: string) => {
		setQuestion24Input({ ...question24Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question24Input.name))
	}
	const onSelectQuestion25Input = (value: string) => {
		setQuestion25Input({ ...question25Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question25Input.name))
	}

	const onSelectQuestion26Input = (files: Set<File>) => {
		setQuestion26Input({
			...question26Input,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, question26Input.name))
	}

	const UpdateRequestInputsErrorsArrayList = (
		errors: string[],
		inputName: string
	): string[] => {
		return errors.filter((error) => !error.includes('dateTime'))
	}

	const onSelectQuestion27Input = (value: string) => {
		setQuestion27Input({ ...question27Input, value })
		let errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			question27Input.name
		)

		if (value == radioListQuestion27[0].value) {
			errorArray = UpdateRequestInputsErrorsArrayList(
				errorArray,
				dateTimeListInput.name
			)
			changeRequiredAttributes(
				true,
				[setQuestion28Input, setQuestion29Input],
				errorArray
			)
			setDateTimeListInput({
				...dateTimeListInput,
				required: false,
				value: JSON.stringify([initialDateTime])
			})
			changeRequiredAttributes(false, [setQuestion30Input], errorArray)
		} else {
			errorArray = UpdateRequestInputsErrorsArray(
				errorArray!,
				question28Input.name
			)
			errorArray = UpdateRequestInputsErrorsArray(
				errorArray!,
				question29Input.name
			)

			changeRequiredAttributes(
				false,
				[setQuestion28Input, setQuestion29Input],
				errorArray
			)
			setDateTimeListInput({
				...dateTimeListInput,
				required: true
			})

			changeRequiredAttributes(true, [setQuestion30Input], errorArray)
		}
	}

	const onSelectQuestion28Input = (value: string) => {
		setQuestion28Input({ ...question28Input, value })
		if (
			question29Input.value &&
			moment(value).isSameOrAfter(question29Input.value)
		) {
			setQuestion29Input({ ...question29Input, value: '' })
		}

		onFixError(UpdateRequestInputsErrorsArray(errors!, question28Input.name))
	}
	const onSelectQuestion29Input = (value: string) => {
		setQuestion29Input({ ...question29Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question29Input.name))
	}
	const onSelectQuestion30Input = (value: string) => {
		// filter date values
		const dates: string[] = recursiveParse(value)
		dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

		setQuestion30Input({
			...question30Input,
			value: dates?.length > 0 ? JSON.stringify(dates) : ''
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, question30Input.name))
	}

	const onSelectQuestion37Input = (value: string) => {
		setQuestion37Input({ ...question37Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question37Input.name))
	}

	const onSelectQuestion39Input = (value: string) => {
		setQuestion39Input({ ...question39Input, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, question39Input.name))
	}

	const onSelectQuestion35Input = (value: string) => {
		setQuestion35Input({ ...question35Input, value })

		setQuestion36Input({ ...question36Input, required: value === '' })

		onFixError(
			errors.filter(
				(error) => ![question35Input.name, question36Input.name].includes(error)
			)
		)
	}

	const onSelectQuestion36Input = (value: string) => {
		setQuestion36Input({ ...question36Input, value })

		setQuestion35Input({ ...question35Input, required: value === '' })

		onFixError(
			errors.filter(
				(error) => ![question35Input.name, question36Input.name].includes(error)
			)
		)
	}

	const onSelectRequestNumberInput = (value: string) => {
		setRequestNumberInput({ ...requestNumberInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, requestNumberInput.name))
	}

	const onSelectOccupationComplyInput = (value: string) => {
		setOccupationComplyInput({ ...occupationComplyInput, value })
		const errorArray = UpdateRequestInputsErrorsArray(
			errors!,
			occupationComplyInput.name
		)

		if (value == radioListYesNo[0].value) {
			changeRequiredAttributes(false, [setQuestion15_6Input], errorArray)
		} else {
			changeRequiredAttributes(true, [setQuestion15_6Input], errorArray)
		}

		// onFixError(UpdateRequestInputsErrorsArray(errors!, occupationComplyInput.name))
	}

	const getDateDifference = () => {
		return (
			moment(question29Input.value).diff(
				moment(question28Input.value),
				'days'
			) + 1
		)
	}

	//----------------------------------------------------------------
	//------------------- USE EFFECTS --------------------------------
	//----------------------------------------------------------------

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, questionOneInput))
	}, [questionOneInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question2Input))
	}, [question2Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question3Input))
	}, [question3Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question4Input))
	}, [question4Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question5Input))
	}, [question5Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question5_1Input))
	}, [question5_1Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question5_2Input))
	}, [question5_2Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question6Input))
	}, [question6Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question7Input))
	}, [question7Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question8Input))
	}, [question8Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question8_1Input))
	}, [question8_1Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question8_2Input))
	}, [question8_2Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question8_3Input))
	}, [question8_3Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question8_4Input))
	}, [question8_4Input])

	/*useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question9Input))
	}, [question9Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question9_1Input))
	}, [question9_1Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question9_2Input))
	}, [question9_2Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question9_3Input))
	}, [question9_3Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question9_4Input))
	}, [question9_4Input])*/

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question10Input))
	}, [question10Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question10OtherInput))
	}, [question10OtherInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question11Input))
	}, [question11Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question12Input))
	}, [question12Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question13Input))
	}, [question13Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question14Input))
	}, [question14Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question15Input))
	}, [question15Input])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, question15_1Input))
	}, [question15_1Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question15_2Input))
	}, [question15_2Input])

	useEffect(() => {
		if (
			question15Input.value == radioListQuestion15[1].value &&
			question15_2Input.value == radioListYesNo[1].value
		) {
			setQuestion15_6Input({
				...question15_6Input,
				required: !impactsOnTrafficPedestrianRule()
			})

			setQuestion15_6_1Input({
				...question15_6_1Input,
				required: !impactsOnTrafficPedestrianRule()
			})

			setQuestion15_6_2Input({
				...question15_6_2Input,
				required: impactsOnTrafficPedestrianRule()
			})
		} else {
			setQuestion15_6Input({
				...question15_6Input,
				required: false
			})

			setQuestion15_6_1Input({
				...question15_6_1Input,
				required: false
			})

			setQuestion15_6_2Input({
				...question15_6_2Input,
				required: false
			})
		}

		onSetInputs(UpdateRequestInputsArray(inputs, question15_3Input))
	}, [question15_3Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question15_4Input))
	}, [question15_4Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question15_5Input))
	}, [question15_5Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question15_6Input))
	}, [question15_6Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question15_6_1Input))
	}, [question15_6_1Input])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, question15_6_2Input)
		)
	}, [question15_6_2Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question16Input))
	}, [question16Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question17Input))
	}, [question17Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question18Input))
	}, [question18Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question19Input))
	}, [question19Input])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, question19_2Input))
	}, [question19_2Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question20Input))
	}, [question20Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question21Input))
	}, [question21Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question22Input))
	}, [question22Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question23Input))
	}, [question23Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question24Input))
	}, [question24Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question25Input))
	}, [question25Input])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, question26Input))
	}, [question26Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question27Input))
	}, [question27Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question28Input))
	}, [question28Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question29Input))
	}, [question29Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question30Input))
	}, [question30Input])

	// useEffect(() => {
	// 	onSetInputs(UpdateRequestInputsArray(inputs, question31Input))
	// }, [question31Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question32Input))
	}, [question32Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question33Input))
	}, [question33Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question34Input))
	}, [question34Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question37Input))
	}, [question37Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question38Input))
	}, [question38Input])
	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question39Input))
	}, [question39Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question35Input))
	}, [question35Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, question36Input))
	}, [question36Input])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, requestNumberInput))
	}, [requestNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, dateTimeListInput))
	}, [dateTimeListInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, occupationComplyInput))
	}, [occupationComplyInput])

	useEffect(() => {
		if (answers?.isNewRequest == radioListIsNewRequest[1].value) {
			onSetSubSteps(subStepData.modificationRequest)
		} else {
			onSetSubSteps(subStepData.newRequest)
		}
	}, [])

	useEffect(() => {
		if (
			!!answers.temporaryTrafficControl &&
			question15_1Input.files.size == 0
		) {
			getFilesFromStore('temporaryTrafficControl', answers).then(
				(result: Set<File>) => {
					setQuestion15_1Input({
						...question15_1Input,
						files: result
					})
				}
			)
		}
		if (
			!!answers.temporaryTrafficControlPlan &&
			question15_6_2Input.files.size == 0
		) {
			getFilesFromStore('temporaryTrafficControlPlan', answers).then(
				(result: Set<File>) => {
					setQuestion15_6_2Input({
						...question15_6_2Input,
						files: result
					})
				}
			)
		}
		if (
			!!answers.temporarySpeedLimitReduction &&
			question19_2Input.files.size == 0
		) {
			getFilesFromStore('temporarySpeedLimitReduction', answers).then(
				(result: Set<File>) => {
					setQuestion19_2Input({
						...question19_2Input,
						files: result
					})
				}
			)
		}
		if (
			!!answers.proofOfLiabilityInsurance &&
			question26Input.files.size == 0
		) {
			getFilesFromStore('proofOfLiabilityInsurance', answers).then(
				(result: Set<File>) => {
					setQuestion26Input({
						...question26Input,
						files: result
					})
				}
			)
		}
	}, [])

	const natureOfInventionList = pageUtils.addLabelToList(
		pageUtils.natureOfInventionListTypeAndValues,
		pageAssets
	)
	const impactsOnTrafficList = pageUtils.addLabelToList(
		pageUtils.impactsOnTrafficListTypeAndValues,
		pageAssets
	)

	const impactsOnTrafficPedestrianRule = (): boolean => {
		return question15_3Input.value
			.split(', ')
			.some((r) =>
				[
					REQUEST_CONFIGS_KEYS.impactsOnTraffic.sidewalk,
					REQUEST_CONFIGS_KEYS.impactsOnTraffic.bicycleLane
				].includes(r)
			)
	}

	const selectedDays = pageUtils.addLabelToList(
		pageUtils.selectedDaysListTypeAndValues,
		pageAssets
	) as SelectList[]

	useEffect(() => {
		setQuestion10ListType(natureOfInventionList)
		setQuestion15_3ListType(impactsOnTrafficList)
		setSelectedDaysList(selectedDays)
	}, [language])

	//----------------------------------------------------------------
	//---------------- CONSTANTS AND FUNCTIONS -----------------------
	//----------------------------------------------------------------

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const getSelectList = (): SelectList[] | [] => {
		const selectList: SelectList[] = [
			{
				label: '',
				value: '',
				key: ''
			}
		]
		authUser?.requests
			?.filter(
				(request) =>
					request.typeId === FORMS_ID.PERMIT_OCCUPATION_OF_PUBLIC_HIGHWAY &&
					request.status != RequestStatusEnum.CLOSED
			)
			?.sort(
				(a, b) =>
					new Date(b.dateAdd?.localDateAndTimeString!).getTime() -
					new Date(a.dateAdd?.localDateAndTimeString!).getTime()
			)
			?.map((request) => {
				selectList.push({
					label: `${request.code} (${request.dateAdd?.localDateString})`,
					value: request.code,
					key: `${request.code} (${request.dateAdd?.localDateString})`
				})
			})
		return selectList
	}

	const renderQuestion35And36 = () => {
		return (
			<>
				<TextInput
					id={question35Input.name}
					label={question35Input.label}
					value={question35Input.value}
					// hasError={}
					required={question35Input.required}
					onChange={onSelectQuestion35Input}
					ref={question35Input.ref as RefObject<HTMLInputElement>}
					toolTip
					tooltipMedia={permitOccupationImage}
					tooltipIsModal
				/>
				<SearchableSelectInput
					id={question36Input.name}
					label={question36Input.label}
					value={question36Input.value}
					// hasError={}
					required={question36Input.required}
					onChange={onSelectQuestion36Input}
					ref={question36Input.ref as RefObject<HTMLSelectElement>}
					options={getSelectList()}
					isWithFilterOptions={true}
				/>
			</>
		)
	}

	const renderPersonalQuestion = (
		question1Input: RequestInputs,
		question2Input: RequestInputs,
		question3Input: RequestInputs,
		question4Input: RequestInputs,
		onSetQuestion1Input: (value: string) => void,
		onSetQuestion2Input: (value: string) => void,
		onSetQuestion3Input: (value: string) => void,
		onSetQuestion4Input: (value: string) => void
	) => {
		return (
			<div className={pageUtils.classes.fieldsContainer}>
				<TextInput
					id={question1Input.name}
					label={question1Input.label}
					value={question1Input.value}
					onChange={onSetQuestion1Input}
					hasError={errors?.includes(question1Input.name)}
					required={question1Input.required}
					ref={question1Input.ref as RefObject<HTMLInputElement>}
				/>
				<TextInput
					id={question2Input.name}
					label={question2Input.label}
					value={question2Input.value}
					onChange={onSetQuestion2Input}
					hasError={errors?.includes(question2Input.name)}
					required={question2Input.required}
					ref={question2Input.ref as RefObject<HTMLInputElement>}
				/>
				<TextInput
					id={question3Input.name}
					label={question3Input.label}
					value={question3Input.value}
					onChange={onSetQuestion3Input}
					hasError={errors?.includes(question3Input.name)}
					required={question3Input.required}
					ref={question3Input.ref as RefObject<HTMLInputElement>}
				/>
				<TextInput
					id={question4Input.name}
					label={question4Input.label}
					value={question4Input.value}
					onChange={onSetQuestion4Input}
					hasError={errors?.includes(question4Input.name)}
					required={question4Input.required}
					ref={question4Input.ref as RefObject<HTMLInputElement>}
				/>
			</div>
		)
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const getDateValues = (value: string) => {
		if (value) {
			const dates: string[] = recursiveParse(value)
			return dates
		}
	}
	const getLabelsFromString = (
		list: pageUtils.CheckboxType[],
		answer?: string
	): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.key)
	}

	const questionAnswer = (question: string, answer?: string) => {
		if (answer) {
			return (
				<div>
					<strong>{question}</strong>
					<br />
					<span className={pageUtils.classes.answer}>{answer}</span>
				</div>
			)
		}
	}

	const onSetDateTimeInput = (
		value: string | boolean,
		currentInput: { row: number; attribute: string }
	) => {
		const { row, attribute } = currentInput
		let itemList = recursiveParse(JSON.stringify(dateTimeList))
		itemList[row][`${attribute}`] = value

		if (
			attribute == 'occpation_value' &&
			row == 0 &&
			value == REQUEST_CONFIGS_KEYS.selectedDays.all_dates
		) {
			itemList = [itemList[0]]
		}

		if (attribute == 'is_24_7' && value == true) {
			itemList[row]['start_time'] = ''
			itemList[row]['end_time'] = ''
		}

		setDateTimeList(itemList)

		setDateTimeListInput({
			...dateTimeListInput,
			value: JSON.stringify(itemList)
		})

		if (attribute == 'is_24_7') {
			onFixError(
				updateDateTimeErrorsArray([
					`dateTime_start_time${row}`,
					`dateTime_end_time${row}`
				])
			)
		} else {
			onFixError(updateDateTimeErrorsArray([`dateTime_${attribute}${row}`]))
		}
	}

	const doesDateTimeHasErrors = (row: number): boolean => {
		const listErrors: string[] = []
		const attributes = ['occpation_value', 'start_time', 'end_time']

		dateTimeList?.forEach((element) => {
			attributes?.forEach((attr) => {
				if (element[`${attr}`] == '' && element['is_24_7'] == false) {
					listErrors.push(`dateTime_${attr}${row}`)
				}
			})
		})

		// Verify if it has errors and dispatch an action to set the various error messages
		if (listErrors.length > 0) {
			onFixError(listErrors)
		}

		return listErrors.length > 0
	}

	const addDateTimeRow = () => {
		if (doesDateTimeHasErrors(dateTimeList.length - 1)) return
		let itemList = recursiveParse(JSON.stringify(dateTimeList))

		itemList.push(initialDateTime)

		setDateTimeList(itemList)

		setDateTimeListInput({
			...dateTimeListInput,
			value: JSON.stringify(itemList)
		})

		// onFixError(updateDateTimeErrorsArray(itemList))
	}

	const updateDateTimeErrorsArray = (itemList: string[]): string[] => {
		return errors.filter((error) => !itemList.includes(error))
	}

	const removeDateTimeRow = (row: number) => {
		let itemList = [...dateTimeList]

		itemList.splice(row, 1)

		setDateTimeList(itemList)

		setDateTimeListInput({
			...dateTimeListInput,
			value: JSON.stringify(itemList)
		})

		onFixError(
			updateDateTimeErrorsArray([
				`dateTime_occpation_value${row}`,
				`dateTime_start_time${row}`,
				`dateTime_end_time${row}`
			])
		)
	}

	const getSelectListItems = (row: number) => {
		const dateTimeLength = dateTimeList.length
		const previousElement =
			dateTimeLength > 1 ? dateTimeList[dateTimeLength - 2] : dateTimeList[0]
		const isNotAllDays =
			dateTimeList[0].occpation_value !=
			REQUEST_CONFIGS_KEYS.selectedDays.all_dates
		if (row == 0) {
			return selectedDaysList
		} else if (row == 1 && isNotAllDays) {
			return selectedDaysList
				.filter((element, index) => index != 1)
				.filter((element) => element.value != previousElement.occpation_value)
		} else if (row == 2) {
			return selectedDaysList
				.filter((item, index) => index != 1)
				.filter((element) => dateTimeList[1].occpation_value != element.value)
		}
		return []
	}

	const renderDateAndTimeRows = (
		dateTime: AdministrativeRiviewInfo,
		row: number
	) => {
		return (
			<>
				<div className={classes.datesWrapper} key={`dateTime-${row}`}>
					<SelectInput
						id={`dateTime_date${row}`}
						label={pageAssets?.label_date + 's'}
						value={dateTime.occpation_value}
						list={getSelectListItems(row)}
						onChange={(e) =>
							onSetDateTimeInput(e, {
								row,
								attribute: 'occpation_value'
							})
						}
						hasError={errors.includes(`dateTime_occpation_value${row}`)}
						// required
					/>
					<TextInput
						id={`dateTime_start_time${row}`}
						label={question32Input.label}
						value={dateTime.start_time}
						disabled={dateTime.is_24_7}
						onChange={(e) =>
							onSetDateTimeInput(e, {
								row,
								attribute: 'start_time'
							})
						}
						hasError={errors.includes(`dateTime_start_time${row}`)}
						required={question38Input.required}
						ref={question38Input.ref as RefObject<HTMLInputElement>}
						type="time"
					/>
					<TextInput
						id={`dateTime_end_time${row}`}
						label={question33Input.label}
						value={dateTime.end_time}
						disabled={dateTime.is_24_7 || !dateTime.start_time}
						onChange={(e) =>
							onSetDateTimeInput(e, {
								row,
								attribute: 'end_time'
							})
						}
						hasError={errors.includes(`dateTime_end_time${row}`)}
						required={question38Input.required}
						ref={question38Input.ref as RefObject<HTMLInputElement>}
						type="time"
						min={dateTime.start_time}
					/>

					<div className={classes.checkbox2Container}>
						<span
							className={classes.checkboxWrapper}
							style={{ marginBottom: '20px' }}
						>
							<input
								onClick={(e: any) => {
									onSetDateTimeInput(e.target.checked, {
										row,
										attribute: 'is_24_7'
									})
								}}
								value={String(dateTime.is_24_7)}
								className={classes.checkbox2}
								type="checkbox"
								name={`dateTime_is_true${row}`}
								id={`dateTime_is_true${row}`}
							/>
							<label
								className={classes.checkbox2Label}
								htmlFor={`dateTime_is_true${row}`}
							>
								{question34Input.label}
							</label>
						</span>
					</div>

					<div className={classes.rowRemoveContainer}>
						{dateTimeList?.length > 1 && (
							<span
								className={classes.closeIcon}
								onClick={() => removeDateTimeRow(row)}
							>
								<Icon src={closeIcon} />
								<span>
									{
										pageAssets?.request_form_hydraulicAndPressureTest_remove_localisation
									}
								</span>
							</span>
						)}
					</div>
				</div>
			</>
		)
	}

	const isDateMoreThan10WorkingDays = (date?: string): boolean => {
		if (date == '' || date == undefined) return true
		const today = moment().startOf('day')
		const targetDate = moment(date).startOf('day')

		let workingDaysCount = 0
		let currentDate = moment(today)

		while (workingDaysCount < 10) {
			if (currentDate.day() !== 0 && currentDate.day() !== 6) {
				workingDaysCount++
			}
			currentDate.add(1, 'day')
		}

		return targetDate.isAfter(currentDate)
	}

	const renderSubSection4 = () => {
		return (
			<>
				<h4 className={pageUtils.classes.h4}>
					{pageAssets?.request_form_occupation_period}
				</h4>
				<h5 className={pageUtils.classes.h5}>
					{pageAssets?.request_form_date_of_the_occupation}
				</h5>

				<RadioInput
					name={question27Input.name}
					label={question27Input.label}
					value={question27Input.value}
					description={question27Input.description}
					list={radioListQuestion27}
					direction={Direction.vertical}
					hasError={errors?.includes(question27Input.name)}
					onChange={onSelectQuestion27Input}
					ref={question27Input.ref as RefObject<HTMLInputElement>}
					required={question27Input.required}
					hasBackgroundWrapper={true}
				/>
				<Collapse
					isOpened={
						!isDateMoreThan10WorkingDays(question28Input.value) ||
						!isDateMoreThan10WorkingDays(
							getDateValues(question30Input.value)?.at(0)
						)
					}
				>
					<Notification
						type="warning"
						text={
							pageAssets?.request_form_warning_the_start_date_is_less_than_the
						}
					/>
				</Collapse>

				{question27Input.value == radioListQuestion27[0].value && (
					<>
						<div
							className={joinClasses([
								pageUtils.classes.fieldsContainer,
								pageUtils.classes.textFieldContainer
							])}
						>
							<DateFilterInput
								id={question28Input.name}
								label={question28Input.label}
								value={question28Input.value}
								onChange={onSelectQuestion28Input}
								hasError={errors.includes(question28Input.name)}
								required={question28Input.required}
								classWrapper={pageUtils?.classes.textFieldContainer}
								minDate={new Date(moment().add(1, 'day').format('YYYY-MM-DD'))}
								dateFormat="dd/MM/yyyy"
							/>
							<DateFilterInput
								id={question29Input.name}
								label={question29Input.label}
								value={question29Input.value}
								onChange={onSelectQuestion29Input}
								hasError={errors.includes(question29Input.name)}
								required={question29Input.required}
								classWrapper={pageUtils?.classes.textFieldContainer}
								minDate={
									new Date(
										moment(question28Input.value)
											.add(2, 'day')
											.format('YYYY-MM-DD')
									)
								}
								maxDate={
									new Date(
										moment(question28Input.value)
											.add(3, 'month')
											.format('YYYY-MM-DD')
									)
								}
								dateFormat="dd/MM/yyyy"
								disabled={question28Input.value == ''}
							/>
						</div>
						{!isNaN(getDateDifference()) && getDateDifference() > 1 && (
							<>
								<b>
									{
										pageAssets?.request_occupationHighway_number_of_occupation_days
									}
								</b>
								<Text
									content={
										getDateDifference().toString() +
										` ` +
										pageAssets?.label_days
									}
								/>
							</>
						)}
					</>
				)}

				{question27Input.value == radioListQuestion27[1].value && (
					<>
						<div
							className={joinClasses([
								pageUtils.classes.fieldsContainer,
								classes.alignItemStart
							])}
						>
							<CalendarInput
								id={question30Input.name}
								// subLable={question30Input.label}
								value={question30Input.value}
								onChange={onSelectQuestion30Input}
								hasError={errors.includes(question30Input.name)}
								required={question30Input.required}
								hideRequiredIcon
								minDate={new Date(moment.now())}
								maxDate={
									new Date(
										moment(getDateValues(question30Input.value)?.at(0))
											.add(3, 'months')
											.format('YYYY/MM/DD')
									)
								}
								local={language}
							/>
							<div>
								<h4 style={{ marginTop: '0px' }}>
									{pageAssets?.request_form_please_select_the_date_of_occupancy}
								</h4>
								{!!getDateValues(question30Input.value)?.length ? (
									<div className={pageUtils.classes.collapse}>
										<CollapseComponent
											id={2}
											buttonText={
												<>
													{getDateValues(question30Input.value)?.length}{' '}
													{pageAssets?.label_selected_dates}
												</>
											}
											key={question30Input.labelKey}
										>
											{getDateValues(question30Input.value)?.map(
												(date, index) => {
													return (
														<p
															key={`${date}-${index}`}
															style={{ marginBottom: '5px' }}
														>
															{date}
														</p>
													)
												}
											)}
										</CollapseComponent>
									</div>
								) : (
									<p className={classes.description}>
										{pageAssets?.label_no_selected_date}
									</p>
								)}
							</div>
						</div>
						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_occupation_hours}
						</h5>

						<p style={{ marginBottom: '20px' }}>
							{pageAssets?.request_form_the_city_of_laval_reserves_the_right}
						</p>

						{/* /////////////////  date time List  //////////////// */}
						{dateTimeList?.map((dateTime, index) => {
							return renderDateAndTimeRows(dateTime, index)
						})}

						{/* /////////////////  Add Button  //////////////// */}
						{dateTimeList.length < 2 &&
							dateTimeList[0].occpation_value !=
								REQUEST_CONFIGS_KEYS.selectedDays.all_dates && (
								<a
									style={{ marginBottom: '12px' }}
									onClick={() => addDateTimeRow()}
									className={classes.button}
								>
									{pageAssets?.label_add_a_time_row}
								</a>
							)}
					</>
				)}
				<div style={{ marginTop: '30px' }}>
					<TextAreaInput
						id={question37Input.name}
						label={question37Input.label}
						value={question37Input.value}
						hasDescriptionLabel={question37Input.description}
						onChange={onSelectQuestion37Input}
						descriptionAlignment="left"
						displayDescriptionTop
						hasError={errors.includes(question37Input.name)}
						required={question37Input.required}
						ref={question37Input.ref as RefObject<HTMLTextAreaElement>}
						maxCharacters={500}
					/>
				</div>
			</>
		)
	}

	function recursiveParse(data: any): any {
		if (typeof data === 'string') {
			try {
				const parsedData = JSON.parse(data)
				if (typeof parsedData === 'string') {
					return recursiveParse(parsedData)
				} else {
					return parsedData
				}
			} catch (error) {
				return data
			}
		} else {
			return data
		}
	}

	const renderSubSection4Summary = () => {
		let dataList = recursiveParse(answers?.dateTimeList)
		if (answers?.dateTimeList?.at(0)?.is_from_config) {
			dataList = pageUtils.transformData(answers?.dateTimeList, true)
		}
		return (
			<>
				<h5 className={pageUtils.classes.h5}>
					{pageAssets?.request_form_date_of_the_occupation}
				</h5>

				{questionAnswer(
					question27Input.label,
					getLabelFromList(radioListQuestion27, answers.occupationDate)
				)}

				{answers?.occupationDate?.toString() ==
					radioListQuestion27[0].value && (
					<>
						{questionAnswer(question28Input.label, answers.occupationStartDate)}
						{questionAnswer(question29Input.label, answers.occupationEndDate)}
						{questionAnswer(
							pageAssets?.request_occupationHighway_number_of_occupation_days,
							getDateDifference().toString()
						)}
					</>
				)}

				{answers?.occupationDate?.toString() ==
					radioListQuestion27[1].value && (
					<>
						<div>
							<strong>{pageAssets?.label_selected_dates}</strong>
							<br />
							<>
								<button
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										classes.linkButton,
										classes.underlineOnHover
									])}
									onClick={() => {
										setOpenDetailModal(true)
									}}
									type="button"
								>
									{formatStrapiText(pageAssets?.label_see_details)}
								</button>
							</>

							<Modal
								title={pageAssets?.label_date + '(s)'}
								isOpen={openDetailModal}
								onCloseModal={() => setOpenDetailModal(false)}
								onSetIsOpen={setOpenDetailModal}
								showExitBtn
							>
								{getDateValues(answers?.calendarDate || '') ? (
									<>
										{getDateValues(answers?.calendarDate || '')?.map(
											(date, index) => {
												return (
													<span
														className={pageUtils.classes.answer}
														key={`${date}-${index}`}
													>
														{date}
													</span>
												)
											}
										)}
									</>
								) : (
									<p className={classes.description}>
										{pageAssets?.label_no_selected_date}
									</p>
								)}
							</Modal>
						</div>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_occupation_hours}
						</h5>

						<div style={{ overflowX: 'auto', margin: '40px 0px' }}>
							<table className={classes.tableSummary}>
								<thead>
									<tr>
										<th></th>
										<th>
											<strong>{pageAssets?.label_date + 's'}</strong>
										</th>
										<th>
											<strong>{pageAssets?.label_start_hour}</strong>
										</th>
										<th>
											<strong>{pageAssets?.label_end_hour}</strong>
										</th>
										<th>
											<strong>{pageAssets?.label_24_on_24}</strong>
										</th>
									</tr>
								</thead>
								<tbody>
									{dataList?.map((dateTime, index) => {
										return (
											<tr key={`dateTime${index}`}>
												<td>
													<span className={classes.rowNumber}>{index + 1}</span>
												</td>
												<td>
													{getLabelFromList(
														selectedDaysList,
														dateTime.occpation_value
													)}
												</td>
												<td>{dateTime.start_time}</td>
												<td>{dateTime.end_time}</td>
												<td>
													{getLabelFromList(
														radioListYesNo,
														dateTime.is_24_7?.toString()
													)}
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</>
				)}
				<br />
				{questionAnswer(
					question37Input.label,
					answers.additionalOccupationInformation
				)}
			</>
		)
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_identification_du_demandeur}
							</h4>
							<h5 className={pageUtils.classes.h5}>
								{pageAssets?.request_form_type_de_demandes}
							</h5>
						</div>

						<div>
							<strong>{questionOneInput.label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								<Text
									content={getLabelFromList(
										radioListIsNewRequest,
										answers.isNewRequest?.toString()
									)}
								/>
							</span>
						</div>

						{answers?.isNewRequest?.toString() ==
							radioListIsNewRequest[1].value && (
							<>
								{questionAnswer(question35Input.label, answers.permitNumber)}
								{
									// answers.requestNumber
									// 	?
									questionAnswer(question36Input.label, answers.requestNumber)
									// : questionAnswer(
									// 		question36Input.label,
									// 		answers.requestNumberOther
									//   )
								}

								{answers?.occupationDate && (
									<>
										<h4 className={pageUtils.classes.h4}>
											{pageAssets?.request_form_occupation_period}
										</h4>
										{renderSubSection4Summary()}
									</>
								)}
							</>
						)}

						{answers?.isNewRequest?.toString() ==
							radioListIsNewRequest[0].value && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{pageAssets?.label_applicant}
								</h5>
								{questionAnswer(
									question2Input.label,
									getLabelFromList(
										radioListCompanyOrOrganisation,
										answers.makingRequestAs
									)
								)}
								{answers?.makingRequestAs?.toString() ==
									radioListCompanyOrOrganisation[1].value && (
									<>
										{questionAnswer(
											question3Input.label,
											getLabelFromList(
												radioListYesNo,
												answers.haveContractedCompany
											)
										)}
									</>
								)}
								{answers?.makingRequestAs?.toString() !=
									radioListCompanyOrOrganisation[1].value && (
									<>
										{questionAnswer(
											question4Input.label,
											getLabelFromList(
												radioListYesNo,
												answers.haveAgreementWithCity
											)
										)}
										{questionAnswer(
											question5Input.label,
											getLabelFromList(
												radioListYesNo,
												answers.workFollowInsurance
											)
										)}

										{answers?.workFollowInsurance?.toString() ==
											radioListYesNo[0].value && (
											<>
												{questionAnswer(
													question5_1Input.label,
													answers?.connectionPermitNumber
												)}
												{questionAnswer(
													question5_2Input.label,
													answers?.nameOfLavalCity
												)}
											</>
										)}

										<h5 className={pageUtils.classes.h5}>
											{pageAssets?.request_form_information_about_company}
										</h5>

										{questionAnswer(question6Input.label, answers?.companyName)}
										{questionAnswer(
											question7Input.label,
											answers?.companyAddress
										)}
										{answers?.personInchargeOfProject &&
											questionAnswer(
												question8Input.label,
												pageAssets?.request_form_i_am_the_person_incharge_of_project
											)}

										{question8Input.value == '' ? (
											<>
												{questionAnswer(
													question8_1Input.label,
													answers?.personInchargeFirstName
												)}
												{questionAnswer(
													question8_2Input.label,
													answers?.personInchargeLastName
												)}
												{questionAnswer(
													question8_3Input.label,
													answers?.personInchargeEmail
												)}
												{questionAnswer(
													question8_4Input.label,
													answers?.personInchargePhone
												)}
											</>
										) : (
											<>
												{questionAnswer(
													question8_1Input.label,
													authUser?.profile?.lastName
												)}
												{questionAnswer(
													question8_2Input.label,
													authUser?.profile?.firstName
												)}
												{questionAnswer(
													question8_3Input.label,
													authUser?.profile?.email
												)}
												{questionAnswer(
													question8_4Input.label,
													authUser?.profile?.phoneNumber
												)}
											</>
										)}

										{/*questionAnswer(
											question9Input.label,
											getLabelFromList(radioListYesNo, answers.isSupervisor)
										)}

										{question9Input.value == radioListYesNo[1].value && (
											<>
												{questionAnswer(
													question9_1Input.label,
													answers?.supervisorFirstName
												)}
												{questionAnswer(
													question9_2Input.label,
													answers?.supervisorLastName
												)}
												{questionAnswer(
													question9_3Input.label,
													answers?.supervisorEmail
												)}
												{questionAnswer(
													question9_4Input.label,
													answers?.supervisorPhone
												)}
											</>
												)*/}
									</>
								)}
							</>
						)}
					</>
				)}
				{answers?.isNewRequest?.toString() !=
					radioListIsNewRequest[1].value && (
					<>
						{subSteps?.details_2 && (
							<>
								<div>
									<a
										style={{
											display: toPrint ? 'none' : 'flex',
											float: 'right'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_reason_for_occupation}
									</h4>
								</div>
								<div style={{ marginBottom: '20px' }}>
									<strong>{question10Input.label}</strong>
									<br />

									{getLabelsFromString(
										pageUtils.natureOfInventionListTypeAndValues,
										answers?.natureOfIntervention?.toString()
									).map((answerLabel, index) => {
										return (
											<p
												className={pageUtils.classes.answer}
												key={`${answerLabel}-${index}-natureOfInterventionType`}
											>
												{pageAssets[answerLabel]}
											</p>
										)
									})}
									{answers?.natureOfInterventionOther
										?.split(', ')
										.map((answer, index) => {
											return (
												<p
													className={pageUtils.classes.answer}
													key={`${answer}-${index}-natureOfIntervention`}
												>
													{answer}
												</p>
											)
										})}
								</div>

								{questionAnswer(
									question11Input.label,
									answers.additionalInformation
								)}
							</>
						)}

						{subSteps?.details_3 && (
							<>
								<div>
									<a
										style={{
											display: toPrint ? 'none' : 'flex',
											float: 'right'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_the_location_of_the_occupation}
									</h4>
								</div>

								<h5 className={pageUtils.classes.h5}>
									{pageAssets?.page_requestInfo_location}
								</h5>

								{questionAnswer(
									question12Input.label,
									answers.mainAddressOfWork
								)}

								{questionAnswer(
									question13Input.label,
									answers.startOfTheDisruption
								)}
								{questionAnswer(
									question14Input.label,
									answers.endOfTheDisruption
								)}

								{questionAnswer(
									question39Input.label,
									answers.additionalDisruptionInformation
								)}

								<h5 className={pageUtils.classes.h5}>
									{pageAssets?.request_form_maitaining_circulation}
								</h5>
								{questionAnswer(
									question15Input.label,
									getLabelFromList(
										radioListQuestion15,
										answers?.isFullOrPatialClosure
									)
								)}

								{answers?.isFullOrPatialClosure?.toString() ==
									radioListQuestion15[0].value && (
									<div
										className={classes.noPadding}
										style={{ marginBottom: '20px' }}
									>
										<RequestFiles
											subtitle={question15_1Input.label}
											name={question15_1Input.name}
											required={question15_1Input.required}
											files={question15_1Input.files}
											hasError={errors.includes(question15_1Input.name)}
											removeSectionMarginTop
											onSetHasError={onSetErrorFromFileComponent}
											onSetFiles={onSelectQuestion15_1Input}
											subSteps={subSteps}
											maxFiles={question15_1Input.numberMaxFiles}
											description={question15_1Input.description}
											isSummary
										></RequestFiles>
									</div>
								)}
								{question15Input.value == radioListQuestion15[1].value && (
									<>
										{questionAnswer(
											question15_2Input.label,
											getLabelFromList(
												radioListYesNo,
												answers?.willRoadBeLimited
											)
										)}
									</>
								)}

								{/* 15.3 */}
								{answers?.willRoadBeLimited?.toString() ==
									radioListYesNo[1].value && (
									<>
										<strong>{question15_3Input.label}</strong>
										{getLabelsFromString(
											pageUtils.impactsOnTrafficListTypeAndValues,
											answers?.impactsOnTraffic?.toString()
										).map((answerLabel, index) => {
											return (
												<p
													className={pageUtils.classes.answer}
													key={`${answerLabel}-${index}-natureOfInterventionType`}
												>
													{pageAssets[answerLabel]}
												</p>
											)
										})}
									</>
								)}

								{/* 15.4 */}

								<>
									<br />
									{questionAnswer(
										question15_4Input.label,
										getLabelFromList(
											question15_4ListType,
											answers?.approximateWidthOfObstruction
										)
									)}
								</>

								{/* 15.5 */}
								{answers?.willRoadBeLimited?.toString() ==
									radioListYesNo[0].value && (
									<>
										{questionAnswer(
											question15_5Input.label,
											answers?.approximateLengthOfObstruction + 'm'
										)}

										{questionAnswer(
											occupationComplyInput.label,
											getLabelFromList(
												radioListYesNo,
												answers?.occupancyRespectingExigences
											)
										)}
									</>
								)}
								{/* 15.6 */}
								{!impactsOnTrafficPedestrianRule() && (
									<>
										{questionAnswer(
											question15_6Input.label,
											getLabelFromList(
												radioListYesNo,
												answers?.useStandardizedBoard
											)
										)}
										{/* 15.6.1 if yes */}
										{answers?.useStandardizedBoard?.toString() ===
											radioListYesNo[0].value && (
											<>
												{questionAnswer(
													question15_6_1Input.label,
													answers?.numberOfStandardizedDrawing
												)}
											</>
										)}
									</>
								)}

								{/* 15.6.2 if no */}
								{impactsOnTrafficPedestrianRule() ||
									(answers.useStandardizedBoard === radioListYesNo[1].value && (
										<div
											className={classes.noPadding}
											style={{ marginBottom: '20px' }}
										>
											<RequestFiles
												subtitle={question15_6_2Input.label}
												name={question15_6_2Input.name}
												required={question15_6_2Input.required}
												files={question15_6_2Input.files}
												hasError={errors.includes(question15_6_2Input.name)}
												removeSectionMarginTop
												onSetHasError={onSetErrorFromFileComponent}
												onSetFiles={onSelectQuestion15_6_2Input}
												subSteps={subSteps}
												maxFiles={question15_6_2Input.numberMaxFiles}
												description={question15_6_2Input.description}
												isSummary
											></RequestFiles>
										</div>
									))}

								{/* 16 */}
								{questionAnswer(
									question16Input.label,
									getLabelFromList(
										radioListYesNo,
										answers?.isLocatedNearIntersection
									)
								)}
								{/* 17 */}
								{questionAnswer(
									question17Input.label,
									getLabelFromList(radioListYesNo, answers?.willAllowPassage)
								)}
								{/* 18 */}
								{questionAnswer(
									question18Input.label,
									getLabelFromList(radioListYesNo, answers?.willMakeAnSTL)
								)}
								{/* 19 */}
								{questionAnswer(
									question19Input.label,
									getLabelFromList(
										radioListYesNo,
										answers?.isATemporaryReduction
									)
								)}
								{answers?.isATemporaryReduction?.toString() ===
									radioListYesNo[0].value && (
									<div
										className={classes.noPadding}
										style={{ marginBottom: '20px' }}
									>
										<RequestFiles
											subtitle={question19_2Input.label}
											name={question19_2Input.name}
											required={question19_2Input.required}
											files={question19_2Input.files}
											hasError={errors.includes(question19_2Input.name)}
											removeSectionMarginTop
											onSetHasError={onSetErrorFromFileComponent}
											onSetFiles={onSelectQuestion19_2Input}
											subSteps={subSteps}
											maxFiles={question19_2Input.numberMaxFiles}
											description={question19_2Input.description}
											isSummary
										></RequestFiles>
									</div>
								)}
								{questionAnswer(
									question20Input.label,
									answers?.anyAdditionalInformation
								)}

								{(question2Input.value !=
									radioListCompanyOrOrganisation[1].value ||
									(question2Input.value ==
										radioListCompanyOrOrganisation[1].value &&
										question15Input.value == radioListQuestion15[0].value) ||
									(question2Input.value ==
										radioListCompanyOrOrganisation[1].value &&
										question15_2Input.value == radioListYesNo[1].value)) && (
									<>
										<h5 className={pageUtils.classes.h5}>
											{pageAssets?.request_form_signage}
										</h5>
										{questionAnswer(
											question21Input.label,
											getLabelFromList(
												radioListQuestion21,
												answers?.companyResponsibleForSignage?.toString()
											)
										)}
									</>
								)}

								{/* 21 */}

								{/* {answers?.companyResponsibleForSignage ==
									radioListQuestion21[0].value && (
									<>
										{questionAnswer(
											question25Input.label,
											answers?.personResponsibleEmail
										)}
									</>
								)} */}
								{((question2Input.value !=
									radioListCompanyOrOrganisation[1].value &&
									question21Input.value == radioListQuestion21[1].value) ||
									(question15Input.value == radioListQuestion15[0].value &&
										question2Input.value ==
											radioListCompanyOrOrganisation[1].value) ||
									(question15Input.value == radioListQuestion15[1].value &&
										question15_2Input.value == radioListYesNo[1].value &&
										question2Input.value ==
											radioListCompanyOrOrganisation[1].value)) && (
									<>
										{questionAnswer(
											question22Input.label,
											answers?.organisationName
										)}
										{questionAnswer(
											question23Input.label,
											answers?.nameOfPersonResponsible
										)}
										{questionAnswer(
											question25Input.label,
											answers?.personResponsibleEmail
										)}
										{questionAnswer(
											question24Input.label,
											answers?.personResponsiblePhone
										)}
									</>
								)}
								<div
									className={classes.noPadding}
									style={{ marginBottom: '20px' }}
								>
									<RequestFiles
										subtitle={question26Input.label}
										name={question26Input.name}
										required={question26Input.required}
										files={question26Input.files}
										hasError={errors.includes(question26Input.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectQuestion26Input}
										subSteps={subSteps}
										maxFiles={question26Input.numberMaxFiles}
										description={question26Input.description}
										isSummary
									></RequestFiles>
								</div>
							</>
						)}

						{subSteps?.details_4 && (
							<>
								<div>
									<a
										style={{
											display: toPrint ? 'none' : 'flex',
											float: 'right'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_occupation_period}
									</h4>
								</div>
								{renderSubSection4Summary()}
							</>
						)}
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_identification_du_demandeur}
						</h4>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_type_de_demandes}
						</h5>
						<RadioInput
							name={questionOneInput.name}
							label={questionOneInput.label}
							value={questionOneInput.value}
							description={questionOneInput.description}
							list={radioListIsNewRequest}
							direction={Direction.vertical}
							hasError={errors?.includes(questionOneInput.name)}
							onChange={onSelectQuestionOneInput}
							ref={questionOneInput.ref as RefObject<HTMLInputElement>}
							required={questionOneInput.required}
							hasBackgroundWrapper={true}
						/>

						{questionOneInput.value == radioListIsNewRequest[1].value && (
							<>
								<p
									className={
										errors?.includes(question35Input.name) &&
										errors?.includes(question36Input.name)
											? classes.textError
											: ''
									}
									style={{ marginBottom: '20px' }}
								>
									{
										pageAssets?.request_form_provide_permit_number_or_request_number
									}
								</p>
								<div
									className={pageUtils.classes.fieldsContainer}
									style={{ alignItems: 'start' }}
								>
									{renderQuestion35And36()}
								</div>
								<>
									<div style={{ marginBottom: '20px' }}>
										{renderSubSection4()}
									</div>
								</>
							</>
						)}

						{questionOneInput.value == radioListIsNewRequest[0].value && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{pageAssets?.label_applicant}
								</h5>
								<RadioInput
									name={question2Input.name}
									label={question2Input.label}
									value={question2Input.value}
									list={radioListCompanyOrOrganisation}
									direction={Direction.vertical}
									hasError={errors?.includes(question2Input.name)}
									onChange={onSelectQuestion2Input}
									ref={question2Input.ref as RefObject<HTMLInputElement>}
									required={question2Input.required}
									hasBackgroundWrapper={true}
								/>

								{question2Input.value &&
									question2Input.value ==
										radioListCompanyOrOrganisation[1].value && (
										<>
											<RadioInput
												name={question3Input.name}
												label={question3Input.label}
												value={question3Input.value}
												list={radioListYesNo}
												direction={Direction.vertical}
												hasError={errors?.includes(question3Input.name)}
												onChange={onSelectQuestion3Input}
												ref={question3Input.ref as RefObject<HTMLInputElement>}
												required={question3Input.required}
												hasBackgroundWrapper={true}
											/>
											{question3Input.value == radioListYesNo[0].value && (
												<Notification
													type="error"
													text={
														pageAssets.label_obtaining_the_permit_for_occupation
													}
												/>
											)}
										</>
									)}
								{question2Input.value &&
									question2Input.value !=
										radioListCompanyOrOrganisation[1].value && (
										<>
											<RadioInput
												name={question4Input.name}
												label={question4Input.label}
												value={question4Input.value}
												description={question4Input.description}
												list={radioListYesNo}
												direction={Direction.vertical}
												hasError={errors?.includes(question4Input.name)}
												onChange={onSelectQuestion4Input}
												ref={question4Input.ref as RefObject<HTMLInputElement>}
												required={question4Input.required}
												hasBackgroundWrapper={true}
											/>
											{question4Input.value == radioListYesNo[0].value && (
												<Notification
													type="error"
													text={
														pageAssets?.error_label_the_permit_of_occupation
													}
												/>
											)}

											{question4Input.value !== radioListYesNo[0].value && (
												<>
													<RadioInput
														name={question5Input.name}
														label={question5Input.label}
														value={question5Input.value}
														list={radioListYesNo}
														direction={Direction.vertical}
														hasError={errors?.includes(question5Input.name)}
														onChange={onSelectQuestion5Input}
														ref={
															question5Input.ref as RefObject<HTMLInputElement>
														}
														required={question5Input.required}
														hasBackgroundWrapper={true}
													/>

													{question5Input.value == radioListYesNo[0].value && (
														<>
															<TextInput
																id={question5_1Input.name}
																label={question5_1Input.label}
																value={question5_1Input.value}
																onChange={onSelectQuestion5_1Input}
																hasError={errors.includes(
																	question5_1Input.name
																)}
																required={question5_1Input.required}
																ref={
																	question5_1Input.ref as RefObject<HTMLInputElement>
																}
															/>

															<TextInput
																id={question5_2Input.name}
																label={question5_2Input.label}
																value={question5_2Input.value}
																onChange={onSelectQuestion5_2Input}
																hasError={errors.includes(
																	question5_2Input.name
																)}
																required={question5_2Input.required}
																ref={
																	question5_2Input.ref as RefObject<HTMLInputElement>
																}
															/>
														</>
													)}

													<h5 className={pageUtils.classes.h5}>
														{pageAssets?.request_form_information_about_company}
													</h5>
													<div
														className={classes.grayBackground}
														style={{ marginBottom: '20px' }}
													>
														<div className={pageUtils.classes.fieldsContainer}>
															<TextInput
																id={question6Input.name}
																label={question6Input.label}
																value={question6Input.value}
																onChange={onSelectQuestion6Input}
																hasError={errors?.includes(question6Input.name)}
																required={question6Input.required}
																ref={
																	question6Input.ref as RefObject<HTMLInputElement>
																}
															/>
															<TextInput
																id={question7Input.name}
																label={question7Input.label}
																value={question7Input.value}
																onChange={onSelectQuestion7Input}
																hasError={errors?.includes(question7Input.name)}
																required={question7Input.required}
																ref={
																	question7Input.ref as RefObject<HTMLInputElement>
																}
															/>
														</div>

														<div className={classes.checkbox2Container}>
															<strong>{question8Input.label}</strong>
															<span
																className={classes.checkboxWrapper}
																style={{ marginTop: '10px' }}
															>
																<input
																	onClick={(e: any) =>
																		onSelectQuestion8Input(e.target.checked)
																	}
																	value={
																		question8Input.value?.toString() ||
																		String(false)
																	}
																	className={classes.checkbox2}
																	type="checkbox"
																	name={question8Input.name}
																	id={question8Input.labelKey}
																/>
																<label
																	className={classes.checkbox2Label}
																	htmlFor={question8Input.labelKey}
																>
																	{question8Input.description}
																</label>
															</span>
														</div>
														<div style={{ marginTop: '15px' }}>
															{question8Input.value == '' ? (
																renderPersonalQuestion(
																	question8_1Input,
																	question8_2Input,
																	question8_3Input,
																	question8_4Input,
																	onSelectQuestion8_1Input,
																	onSelectQuestion8_2Input,
																	onSelectQuestion8_3Input,
																	onSelectQuestion8_4Input
																)
															) : (
																<>
																	<div style={{ margin: '7.5px 0' }}>
																		<strong>
																			{pageAssets?.label_firstName}
																		</strong>
																		<p>{authUser?.profile?.lastName}</p>
																	</div>
																	<div style={{ margin: '7.5px 0' }}>
																		<strong>
																			{pageAssets?.label_lastName}
																		</strong>
																		<p>{authUser?.profile?.firstName}</p>
																	</div>
																	<div style={{ margin: '7.5px 0' }}>
																		<strong>{pageAssets?.label_email}</strong>
																		<p>{authUser?.profile?.email}</p>
																	</div>
																	<div style={{ margin: '7.5px 0' }}>
																		<strong>
																			{pageAssets?.label_telephone}
																		</strong>
																		<p>{authUser?.profile?.phoneNumber}</p>
																	</div>
																</>
															)}
														</div>
													</div>

													{/*<RadioInput
														name={question9Input.name}
														label={question9Input.label}
														value={question9Input.value}
														list={radioListYesNo}
														direction={Direction.vertical}
														hasError={errors?.includes(question9Input.name)}
														onChange={onSelectQuestion9Input}
														ref={
															question9Input.ref as RefObject<HTMLInputElement>
														}
														required={question9Input.required}
														hasBackgroundWrapper={true}
													/>
													{/*question9Input.value == radioListYesNo[1].value && (
														<>
															<Notification
																type="info"
																text={
																	pageAssets?.info_label_please_provide_the_information
																}
															/>
															<div className={classes.grayBackground}>
																{renderPersonalQuestion(
																	question9_1Input,
																	question9_2Input,
																	question9_3Input,
																	question9_4Input,
																	onSelectQuestion9_1Input,
																	onSelectQuestion9_2Input,
																	onSelectQuestion9_3Input,
																	onSelectQuestion9_4Input
																)}
															</div>
														</>
																)*/}
												</>
											)}
										</>
									)}
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
					checkTimeout={40}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_reason_for_occupation}
						</h4>

						<CheckboxInput
							name={question10Input.name}
							label={question10Input.label}
							value={`${question10Input.value}`}
							list={question10ListType}
							onChange={onSelectQuestion10Input}
							required={question10Input.required}
							hasError={errors.includes(question10Input.name)}
							ref={question10Input.ref as RefObject<HTMLInputElement>}
							errors={errors}
							shouldNotDivide
							hasBackgroundWrapper
							onFixError={onFixError}
							newCheckValue
							others={
								pageUtils.natureOfInventionListTypeAndValues[
									pageUtils.natureOfInventionListTypeAndValues.length - 1
								]
							}
							otherInput={question10OtherInput}
							setOtherInput={setQuestion10OtherInput}
							otherInputPlaceholder=""
							UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
						/>

						<TextAreaInput
							id={question11Input.name}
							label={question11Input.label}
							value={question11Input.value}
							hasDescriptionLabel={question11Input.description}
							onChange={onSelectQuestion11Input}
							descriptionAlignment="left"
							displayDescriptionTop
							hasError={errors.includes(question11Input.name)}
							required={question11Input.required}
							ref={question11Input.ref as RefObject<HTMLTextAreaElement>}
							maxCharacters={500}
						/>
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
					checkTimeout={40}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_the_location_of_the_occupation}
						</h4>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.page_requestInfo_location}
						</h5>

						<span className={classes.textDescription}>
							{
								pageAssets?.request_form_permitOccupationOfPublicHighway_text_description
							}
						</span>

						<MapForm
							value={answers.mainAddressOfWork}
							addressRequired={question12Input.required}
							classNames={{
								address: joinClasses([classes.formInput, classes.map])
							}}
							hasError={errors?.includes(question12Input.name)}
							addressLabel={question12Input.label}
							showButtonUserLocation
							onAddressInputChange={onSelectQuestion12Input}
							displayAddressInput
							// onAddressChange={onAddressChange}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={question13Input.name}
								label={question13Input.label}
								value={question13Input.value}
								onChange={onSelectQuestion13Input}
								hasError={errors.includes(question13Input.name)}
								required={question13Input.required}
								ref={question13Input.ref as RefObject<HTMLInputElement>}
							/>
							<TextInput
								id={question14Input.name}
								label={question14Input.label}
								value={question14Input.value}
								onChange={onSelectQuestion14Input}
								hasError={errors.includes(question14Input.name)}
								required={question14Input.required}
								ref={question14Input.ref as RefObject<HTMLInputElement>}
							/>
						</div>

						<TextAreaInput
							id={question39Input.name}
							label={question39Input.label}
							value={question39Input.value}
							hasDescriptionLabel={question39Input.description}
							onChange={onSelectQuestion39Input}
							descriptionAlignment="left"
							displayDescriptionTop
							hasError={errors.includes(question39Input.name)}
							required={question39Input.required}
							ref={question39Input.ref as RefObject<HTMLTextAreaElement>}
							maxCharacters={500}
						/>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_maitaining_circulation}
						</h5>

						<RadioInput
							name={question15Input.name}
							label={question15Input.label}
							value={question15Input.value}
							description={question15Input.description}
							list={radioListQuestion15}
							direction={Direction.vertical}
							hasError={errors?.includes(question15Input.name)}
							onChange={onSelectQuestion15Input}
							ref={question15Input.ref as RefObject<HTMLInputElement>}
							required={question15Input.required}
							hasBackgroundWrapper={true}
						/>

						{question15Input.value == radioListQuestion15[0].value && (
							<div
								className={classes.noPadding}
								style={{ marginBottom: '20px' }}
							>
								<RequestFiles
									subtitle={question15_1Input.label}
									name={question15_1Input.name}
									required={question15_1Input.required}
									files={question15_1Input.files}
									hasError={errors.includes(question15_1Input.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectQuestion15_1Input}
									subSteps={subSteps}
									maxFiles={question15_1Input.numberMaxFiles}
									description={question15_1Input.description}
								></RequestFiles>
							</div>
						)}
						{question15Input.value == radioListQuestion15[1].value && (
							<>
								<RadioInput
									name={question15_2Input.name}
									label={question15_2Input.label}
									value={question15_2Input.value}
									description={question15_2Input.description}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors?.includes(question15_2Input.name)}
									onChange={onSelectQuestion15_2Input}
									ref={question15_2Input.ref as RefObject<HTMLInputElement>}
									required={question15_2Input.required}
									hasBackgroundWrapper={true}
									showInfo
									toolTipMedia={roadStation}
									tooltipIsModal
								/>
							</>
						)}

						{/* 15.3 */}
						{question15_2Input.value == radioListYesNo[1].value && (
							<CheckboxInput
								name={question15_3Input.name}
								label={question15_3Input.label}
								value={`${question15_3Input.value}`}
								list={question15_3ListType}
								onChange={onSelectQuestion15_3Input}
								required={question15_3Input.required}
								hasError={errors.includes(question15_3Input.name)}
								ref={question15_3Input.ref as RefObject<HTMLInputElement>}
								errors={errors}
								shouldNotDivide
								hasBackgroundWrapper
								onFixError={onFixError}
								newCheckValue
							/>
						)}

						{/* 15.4 */}
						{question15_2Input.value == radioListYesNo[1].value && (
							<RadioInput
								name={question15_4Input.name}
								label={question15_4Input.label}
								value={`${question15_4Input.value}`}
								list={question15_4ListType}
								onChange={onSelectQuestion15_4Input}
								required={question15_4Input.required}
								hasError={errors.includes(question15_4Input.name)}
								ref={question15_4Input.ref as RefObject<HTMLInputElement>}
								hasBackgroundWrapper
								direction={Direction.vertical}
							/>
						)}
						{/* 15.5 */}
						{question15_2Input.value == radioListYesNo[0].value && (
							<>
								{/* TODO remove this and add the new field  */}
								<TextInput
									id={question15_5Input.name}
									label={question15_5Input.label}
									value={question15_5Input.value}
									onChange={onSelectQuestion15_5Input}
									hasError={errors.includes(question15_5Input.name)}
									type="number"
									required={question15_5Input.required}
									suffixText="m"
									ref={question15_5Input.ref as RefObject<HTMLInputElement>}
									min="0"
									acceptPositiveNumbers
								/>
								<RadioInput
									name={occupationComplyInput.name}
									label={occupationComplyInput.label}
									value={`${occupationComplyInput.value}`}
									list={radioListYesNo}
									onChange={onSelectOccupationComplyInput}
									required={occupationComplyInput.required}
									hasError={errors.includes(occupationComplyInput.name)}
									ref={occupationComplyInput.ref as RefObject<HTMLInputElement>}
									hasBackgroundWrapper
									showInfo
									toolTipMedia={obstaclePosition}
									tooltipIsModal
									direction={Direction.vertical}
								/>
							</>
						)}
						{/* 15.6 */}
						{(occupationComplyInput.value == radioListYesNo[1].value ||
							question15_2Input.value == radioListYesNo[1].value) &&
							!impactsOnTrafficPedestrianRule() && (
								<RadioInput
									name={question15_6Input.name}
									label={question15_6Input.label}
									value={question15_6Input.value}
									description={question15_6Input.description}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors?.includes(question15_6Input.name)}
									onChange={onSelectQuestion15_6Input}
									ref={question15_6Input.ref as RefObject<HTMLInputElement>}
									required={question15_6Input.required}
									hasBackgroundWrapper={true}
								/>
							)}
						{/* 15.6.1 if yes */}
						{question15_6Input.value === radioListYesNo[0].value &&
							!impactsOnTrafficPedestrianRule() && (
								<TextInput
									id={question15_6_1Input.name}
									label={question15_6_1Input.label}
									value={question15_6_1Input.value}
									onChange={onSelectQuestion15_6_1Input}
									hasError={errors.includes(question15_6_1Input.name)}
									required={question15_6_1Input.required}
									ref={question15_6_1Input.ref as RefObject<HTMLInputElement>}
								/>
							)}
						{/* 15.6.2 if no */}
						{(question15_6Input.value === radioListYesNo[1].value ||
							impactsOnTrafficPedestrianRule()) && (
							<div
								className={classes.noPadding}
								style={{ marginBottom: '20px' }}
							>
								<RequestFiles
									subtitle={question15_6_2Input.label}
									name={question15_6_2Input.name}
									required={question15_6_2Input.required}
									files={question15_6_2Input.files}
									hasError={errors.includes(question15_6_2Input.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectQuestion15_6_2Input}
									subSteps={subSteps}
									maxFiles={question15_6_2Input.numberMaxFiles}
									description={question15_6_2Input.description}
								></RequestFiles>
							</div>
						)}

						{/* 16 */}
						<RadioInput
							name={question16Input.name}
							label={question16Input.label}
							value={question16Input.value}
							description={question16Input.description}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors?.includes(question16Input.name)}
							onChange={onSelectQuestion16Input}
							ref={question16Input.ref as RefObject<HTMLInputElement>}
							required={question16Input.required}
							hasBackgroundWrapper={true}
						/>
						{/* 17 */}
						{question15Input.value == radioListQuestion15[0].value && (
							<RadioInput
								name={question17Input.name}
								label={question17Input.label}
								value={question17Input.value}
								description={question17Input.description}
								list={radioListYesNo}
								direction={Direction.vertical}
								hasError={errors?.includes(question17Input.name)}
								onChange={onSelectQuestion17Input}
								ref={question17Input.ref as RefObject<HTMLInputElement>}
								required={question17Input.required}
								hasBackgroundWrapper={true}
							/>
						)}
						{/* 18 */}
						<RadioInput
							name={question18Input.name}
							label={question18Input.label}
							value={question18Input.value}
							description={question18Input.description}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors?.includes(question18Input.name)}
							onChange={onSelectQuestion18Input}
							ref={question18Input.ref as RefObject<HTMLInputElement>}
							required={question18Input.required}
							hasBackgroundWrapper={true}
						/>
						{/* 19 */}
						<RadioInput
							name={question19Input.name}
							label={question19Input.label}
							value={question19Input.value}
							description={question19Input.description}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors?.includes(question19Input.name)}
							onChange={onSelectQuestion19Input}
							ref={question19Input.ref as RefObject<HTMLInputElement>}
							required={question19Input.required}
							hasBackgroundWrapper={true}
						/>
						{question19Input.value === radioListYesNo[0].value && (
							<div
								className={classes.noPadding}
								style={{ marginBottom: '20px' }}
							>
								<RequestFiles
									subtitle={question19_2Input.label}
									name={question19_2Input.name}
									required={question19_2Input.required}
									files={question19_2Input.files}
									hasError={errors.includes(question19_2Input.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectQuestion19_2Input}
									subSteps={subSteps}
									maxFiles={question19_2Input.numberMaxFiles}
									description={question19_2Input.description}
								></RequestFiles>
							</div>
						)}
						<TextAreaInput
							id={question20Input.name}
							label={question20Input.label}
							value={question20Input.value}
							hasDescriptionLabel={question20Input.description}
							onChange={onSelectQuestion20Input}
							descriptionAlignment="left"
							displayDescriptionTop
							hasError={errors.includes(question20Input.name)}
							required={question20Input.required}
							ref={question20Input.ref as RefObject<HTMLTextAreaElement>}
							maxCharacters={500}
						/>
						{(question2Input.value != radioListCompanyOrOrganisation[1].value ||
							(question2Input.value ==
								radioListCompanyOrOrganisation[1].value &&
								question15Input.value == radioListQuestion15[0].value) ||
							(question2Input.value ==
								radioListCompanyOrOrganisation[1].value &&
								question15_2Input.value == radioListYesNo[1].value)) && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{pageAssets?.request_form_signage}
								</h5>

								{/* 21 */}
								{question2Input.value !=
									radioListCompanyOrOrganisation[1].value && (
									<RadioInput
										name={question21Input.name}
										label={question21Input.label}
										value={question21Input.value}
										description={question21Input.description}
										list={radioListQuestion21}
										direction={Direction.vertical}
										hasError={errors?.includes(question21Input.name)}
										onChange={onSelectQuestion21Input}
										ref={question21Input.ref as RefObject<HTMLInputElement>}
										required={question21Input.required}
										hasBackgroundWrapper={true}
									/>
								)}

								{/* {question21Input.value == radioListQuestion21[0].value && (
								<div className={pageUtils.classes.fieldsContainer}>
									<TextInput
										id={question25Input.name}
										label={question25Input.label}
										value={question25Input.value}
										onChange={onSelectQuestion25Input}
										hasError={errors.includes(question25Input.name)}
										required={question25Input.required}
										ref={question25Input.ref as RefObject<HTMLInputElement>}
									/>
								</div>
							)} */}

								{((question2Input.value !=
									radioListCompanyOrOrganisation[1].value &&
									question21Input.value == radioListQuestion21[1].value) ||
									(question15Input.value == radioListQuestion15[0].value &&
										question2Input.value ==
											radioListCompanyOrOrganisation[1].value) ||
									(question15Input.value == radioListQuestion15[1].value &&
										question15_2Input.value == radioListYesNo[1].value &&
										question2Input.value ==
											radioListCompanyOrOrganisation[1].value)) && (
									<>
										<Notification
											type="info"
											text={
												pageAssets?.info_label_please_provide_the_following_information
											}
										/>

										<div
											className={classes.grayBackground}
											style={{ marginBottom: '20px' }}
										>
											<div style={{ marginTop: '15px' }}>
												{renderPersonalQuestion(
													question22Input,
													question23Input,
													question25Input,
													question24Input,
													onSelectQuestion22Input,
													onSelectQuestion23Input,
													onSelectQuestion25Input,
													onSelectQuestion24Input
												)}
											</div>
										</div>
									</>
								)}
								<div
									className={classes.noPadding}
									style={{ marginBottom: '20px' }}
								>
									<RequestFiles
										subtitle={question26Input.label}
										name={question26Input.name}
										required={question26Input.required}
										files={question26Input.files}
										hasError={errors.includes(question26Input.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectQuestion26Input}
										subSteps={subSteps}
										maxFiles={question26Input.numberMaxFiles}
										description={question26Input.description}
									></RequestFiles>
								</div>
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
					}
					checkTimeout={40}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						{renderSubSection4()}
					</section>
				</Collapse>
			)}
		</>
	)
}

export default PermitOccupationOfPublicHighway
