import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import RequiredIcon from '@components/ui/requiredIcon'
import Text from '@components/ui/text'
import Error from '@components/requestForms/inputs/error.input'
import { SelectList } from '@utils/request'

type Props = {
	id: string
	label?: string
	list: SelectList[]
	value: string
	description?: string
	required?: boolean
	hasError?: boolean
	disabled?: boolean
	filledForm?: boolean
	onChange: (value: string, currentObject?: any) => void
	classWrapper?: string
	onFocus?: React.FocusEventHandler<HTMLSelectElement>
	disableErrorText?: boolean
	titleAndDescError?: boolean
}

type Classes = {
	root: string
	select: string
	description: string
	selectDisable: string
	error: string
	required: string
	contentRoot: string
	noWrap: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '20px'
	},
	select: {
		width: '100%',
		height: '43.6px',
		maxWidth: '100%',
		padding: '8px 12px',
		fontSize: '16px',
		fontWeight: '500',
		color: Colors.lightBlack,
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		'&:focus': {
			outline: 'none'
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0px 3px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		fontWeight: '400',
		'& a': {
			color: '#0054a6'
		}
	},
	selectDisable: {
		background: Colors.lightGrey4
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		span: {
			color: Colors.errorRed
		},
		select: {
			borderColor: Colors.errorRed,
			'&:focus': {
				outline: 'none',
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		}
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	}
})

const SelectInputForwardRef: ForwardRefRenderFunction<
	HTMLSelectElement,
	Props
> = (
	{
		id,
		label,
		list,
		description,
		hasError,
		required,
		disabled,
		value,
		onChange,
		classWrapper,
		onFocus,
		disableErrorText = false,
		titleAndDescError
	},
	ref
) => {
	const getLabel = () => {
		const hasQuestionMark = label?.endsWith('?')
		if (hasQuestionMark) {
			return (
				<div className={classes.contentRoot}>
					{label?.replace('?', '')}
					<span className={required ? classes.noWrap : ''}>?</span>
				</div>
			)
		} else {
			return <span className={required ? classes.required : ''}>{label}</span>
		}
	}

	return (
		<div
			className={joinClasses([
				classes.root,
				hasError ? classes.error : '',
				classWrapper || ''
			])}
		>
			<label htmlFor={id}>
				<span className={titleAndDescError ? classes.error : ''}>
					{getLabel()}
					{description && (
						<span className={classes.description}>
							<Text content={description} />
						</span>
					)}
				</span>
				{!disableErrorText && <Error hasError={hasError} />}
			</label>
			<select
				className={joinClasses([
					classes.select,
					disabled ? classes.selectDisable : ''
				])}
				id={id}
				disabled={disabled}
				required={required}
				onChange={(e) => {
					const input: EventTarget & HTMLSelectElement = e.target

					onChange(
						input.value,
						`${input[input.options.selectedIndex].getAttribute(
							'data-value-key'
						)}`
					)
				}}
				value={value}
				ref={ref}
				onFocus={onFocus}
			>
				{list.map((item: SelectList, index) => (
					<option key={index} value={item?.value} data-value-key={item.key}>
						{item?.label}
					</option>
				))}
			</select>
		</div>
	)
}

const SelectInput = forwardRef(SelectInputForwardRef)

export default SelectInput
