import { ReactElement } from 'react'
import {
	ButtonStyle,
	Colors,
	CreateRequestStyle,
	CSSTransitionStyles
} from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { thirdStepSubSteps } from '@templates/requests/create_tn'

export type Props = {
	hasError: boolean
	files: Set<File>
	children?: ReactElement
	name?: string
	title?: string
	subtitle?: string
	required?: boolean
	removeSectionMarginTop?: boolean
	removeSectionPaddingTop?: boolean
	maxFiles?: number
	minFiles?: number
	namingValidation?: string
	formPosition?: number
	subSteps?: thirdStepSubSteps
	description?: string
	onSetHasError: (hasError: boolean, inputName: string) => void
	onSetFiles: (newFiles: Set<File>) => void
	isSummary?: boolean
	removePadding?: boolean
	isNewDesign?: boolean
	equivalentMargin?: boolean
	isDuplicate?: boolean
}

type Classes = {
	container: string
	section: string
	h3: string
	h4: string
	inputFileBtn: string
	btn: string
	inputFileIcon: string
	fileList: string
	fileItem: string
	fileItemCloseIcon: string
	enter: string
	enterActive: string
	exit: string
	exitActive: string
	img: string
	imgCloseIcon: string
	fileItemDetails: string
	notificationContainer: string
	error: string
	errorBorder: string
	marginTop0: string
	paddingTop0: string
	btnDisabled: string
	description: string
	padding0: string
	dragAndDropArea: string
	dragOver: string
	disabled: string
	fileHelperSection: string
	addMarginBottom: string
	equivalentMargin: string
}

export const classes: Classes = makeClasses({
	...CreateRequestStyle,
	inputFileBtn: {
		padding: '9px 20px 9px 30px',
		fontSize: '14px'
	},
	btn: {
		...ButtonStyle,
		background: Colors.secondary,
		borderColor: Colors.secondary,
		color: `${Colors.white} !important`,
		'&:hover': {
			background: Colors.primary
		}
	},
	inputFileIcon: {
		position: 'absolute' as 'absolute',
		left: '6px',
		width: '25px'
	},
	fileList: {
		marginTop: '10px',
		'& > div': {
			borderBottom: `1px solid ${Colors.greyIron}`,
			'&:last-child': {
				borderBottom: 0
			}
		}
	},
	fileItem: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '15px',
		background: Colors.ligthGrey2
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: '0',
		fontSize: '14px',
		lineHeight: '1.25em',
		color: Colors.lightGrey2
	},
	fileItemDetails: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	fileItemCloseIcon: {
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	img: {
		width: '100%',
		maxWidth: '32px',
		height: 'auto',
		maxHeight: '22px',
		marginRight: '15px'
	},
	imgCloseIcon: {
		width: '25px'
	},
	h4: {
		margin: '20px 0 5px',
		padding: 0,
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '22px',
		color: Colors.lightBlack
	},
	notificationContainer: {
		marginTop: '20px'
	},
	error: {
		color: `${Colors.errorRed}!important`
	},
	errorBorder: {
		borderColor: `${Colors.errorRed}!important`
	},
	marginTop0: {
		marginTop: '0!important'
	},
	paddingTop0: {
		paddingTop: '0!important'
	},
	padding0: {
		padding: '0!important'
	},
	equivalentMargin: {
		marginBottom: '10px!important',
		marginTop: '10px!important'
	},
	btnDisabled: {
		opacity: 0.8,
		'&:hover': {
			background: Colors.secondary,
			cursor: 'not-allowed'
		}
	},
	...CSSTransitionStyles,
	dragAndDropArea: {
		background: '#eee',
		border: ' 2px dashed #ddd',
		padding: '30px',
		textAlign: 'center',
		color: '#666',
		width: '100%',
		cursor: 'pointer',
		'& .css-1t571az': {
			paddingLeft: '20px !important'
		}
	},
	dragOver: {
		background: Colors.secondaryBackground,
		borderColor: `${Colors.secondary}!important`
	},
	disabled: {
		opacity: '0.6'
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px',
		width: '100%'
	}
})
