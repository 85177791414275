import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import Link from '@components/ui/link'
import config from '@utils/config'
import RequiredIcon from '@components/ui/requiredIcon'
import Notification from '@components/ui/notification'
import Error from '@components/requestForms/inputs/error.input'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { closeIcon, downloadIcon, pdfIcon } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import { joinClasses } from '@utils/styles'
import { formatHtmlForStrapiText, formatStrapiText } from '@utils/methods'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { RequestImgUrl, Steps } from '@utils/request'
import * as pageUtils from '@components/ui/requestFiles/__index.utils'
import { formatFileSize } from '../Attachment/__index.utils'
import Text from '../text'

const RequestFiles: FC<pageUtils.Props> = ({
	name = '',
	children,
	hasError,
	title,
	subtitle,
	required,
	files,
	removeSectionMarginTop,
	removeSectionPaddingTop,
	maxFiles,
	namingValidation,
	formPosition,
	subSteps,
	onSetFiles,
	onSetHasError,
	isSummary,
	description,
	removePadding,
	equivalentMargin,
	isNewDesign = true,
	isDuplicate = false
}) => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const refFileInput = useRef<HTMLInputElement>(null)
	const [addTransitionClassContainer, setAddTransitionClassContainer] =
		useState<boolean>(false)
	const [notificationError, setNotificationError] = useState<boolean>(false)
	const [hasWrongFormatError, setHasWrongFormatError] = useState<boolean>(false)
	const [listImgUrl, setListImgUrl] = useState<RequestImgUrl[]>([])
	const [isSameFileUploaded, setIsSameFileUploaded] = useState<boolean>(false)
	const [isFileNameInvalid, setIsFileNameInvalid] = useState<boolean>(false)
	const [isDragOver, setIsDragOver] = useState(false)

	const onOpenUploadFiles = (e) => {
		e.preventDefault()
		if (maxFiles && files.size >= maxFiles) {
			return false
		}

		refFileInput?.current?.click()
	}

	const getFileId = (file: File): string =>
		`${file.size}-${file?.name.replace(/ /g, '')}`

	const getRequestImg = (file: File): RequestImgUrl => {
		let url: string = URL.createObjectURL(file)
		return {
			url,
			id: `${file.size}-${file.name}`,
			name: file.name,
			isPDF: file.type.includes('pdf')
		}
	}

	const validateFile = (file: File, then: (isValid: boolean) => void) => {
		const reader = new FileReader()
		reader.onload = async (e) => {
			if (
				file?.name.toLowerCase().match(config.request.file.acceptImageRegex)
			) {
				const img = new Image()
				img.onload = () => {
					then(true)
				}

				img.onerror = () => {
					then(false)
				}
				img.src = e.target?.result! as string
			} else if (file?.name.toLowerCase().match(/.pdf$/)) {
				then(true)
			}
		}

		reader.readAsDataURL(file)
	}

	useEffect(() => {
		if (notificationError) setNotificationError(false)
		if (hasWrongFormatError) setHasWrongFormatError(false)
		if (hasWrongFormatError) setIsSameFileUploaded(false)
	}, [files])

	useEffect(() => {
		if (subSteps !== undefined) {
			if (notificationError) setNotificationError(false)
			if (hasWrongFormatError) setHasWrongFormatError(false)
			if (isSameFileUploaded) setIsSameFileUploaded(false)
		}
	}, [subSteps])

	const onHandleFileChange = async (fichers?: FileList) => {
		if (notificationError) {
			setNotificationError(false)
		}

		if (hasWrongFormatError) {
			setHasWrongFormatError(false)
		}

		const list: File[] = Array.prototype.slice.call(
			fichers ?? refFileInput?.current?.files
		)

		let listSize: number = maxFiles ?? 1

		// To limit multiple files uploaded
		// Verify size of files already uploaded...
		listSize = listSize - files?.size

		if (list.length > 0) {
			for (let i = 0; i < listSize; i++) {
				const file = list[i]
				const extension: string = getFileExtension(file)
				const listExtensions: string[] = config.request.file.accept
					.replace(/ /g, '')
					.split(',')

				if (file && !listExtensions.includes(extension)) {
					setHasWrongFormatError(true)
					return
				}

				if (file && file.size > config.request.file.maxSize) {
					setNotificationError(true)
					return
				}

				const tmpFilesArray = [...files]
				const fileExist: File | undefined = tmpFilesArray.find(
					(elemFile) => elemFile?.name === file?.name
				)

				if (fileExist) {
					return setIsSameFileUploaded(true)
				}

				if (
					namingValidation &&
					namingValidation !== '' &&
					!file?.name.includes(namingValidation)
				) {
					setIsFileNameInvalid(true)
					return onSetHasError(true, name)
				}

				setIsSameFileUploaded(false)
				setIsFileNameInvalid(false)

				if (file) {
					validateFile(file, (valid) => {
						if (valid) {
							const listImgUrlTMP: RequestImgUrl[] = listImgUrl
							const imgFromFileCreated: RequestImgUrl = createImgUrl(file)

							listImgUrlTMP.push(imgFromFileCreated)

							setListImgUrl(listImgUrlTMP)
							files.add(file)
							onSetFiles(files)
						} else {
							onSetHasError(true, name)
						}
					})
				}
			}
		}
	}

	const createImgUrl = (file: File): RequestImgUrl => {
		const blobUrl: string = URL.createObjectURL(file)

		return {
			id: `${file.size}-${file.name}`,
			name: file.name,
			url: blobUrl,
			isPDF: file.type.toLowerCase().includes(config.request.file.pdf)
		}
	}

	const removeFileFromForm = (file: File) => (e) => {
		e.preventDefault()

		setListImgUrl(listImgUrl.filter((img) => img.id !== getFileId(file)))

		files.delete(file)

		onSetFiles(files)
	}

	const onOpenFileOnBrowser = (file: File) => (e) => {
		e.preventDefault()

		let url = URL.createObjectURL(file)

		window.open(url, '_blank')
	}

	const getFileExtension = (file: File): string =>
		`.${file?.name?.split('.').pop()}`

	const displayThumbnail = (requestImg: RequestImgUrl | undefined) => {
		if (!requestImg) {
			return <></>
		}

		if (requestImg.isPDF) {
			return (
				<img
					id={requestImg.id}
					src={pdfIcon}
					alt={requestImg.name}
					className={pageUtils.classes.img}
				/>
			)
		}
		return (
			<img
				id={requestImg.id}
				src={requestImg.url}
				alt={requestImg.name}
				className={pageUtils.classes.img}
			/>
		)
	}

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		setIsDragOver(true)
	}

	const handleDragLeave = () => {
		setIsDragOver(false)
	}

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		setIsDragOver(false)
		const files = e.dataTransfer.files

		onHandleFileChange(files)
	}

	useEffect(() => {
		// keep transition style if there is a file
		if (files.size > 0) {
			setAddTransitionClassContainer(true)
		}
	}, [])

	return (
		<section
			className={joinClasses([
				isSummary ? pageUtils.classes.section : '',
				removeSectionMarginTop ? pageUtils.classes.marginTop0 : '',
				removeSectionPaddingTop ? pageUtils.classes.paddingTop0 : '',
				removePadding ? pageUtils.classes.padding0 : '',
				equivalentMargin ? pageUtils.classes.equivalentMargin : ''
			])}
			style={{ padding: `${isSummary && '10px 0'}` }}
		>
			{title && (
				<h3 className={pageUtils.classes.h3}>{formatStrapiText(title)}</h3>
			)}
			{((isSummary && files.size > 0) || (!isSummary && subtitle)) && (
				<h4
					className={joinClasses([
						pageUtils.classes.h4,
						!title ? pageUtils.classes.marginTop0 : '',
						hasError && required ? pageUtils.classes.error : ''
					])}
					style={{ fontSize: isSummary ? '14px' : '16px' }}
				>
					{formatStrapiText(subtitle)}{' '}
					{required && !isSummary && <RequiredIcon />}
				</h4>
			)}

			{!isSummary && description && (
				<span
					className={joinClasses([
						pageUtils.classes.description,
						hasError ? pageUtils.classes.error : ''
					])}
				>
					<Text content={description} />
				</span>
			)}

			{hasError && required && (
				<Error
					hasError
					errorText={
						isDuplicate
							? pageData?.assets?.file_validation_same_file
							: pageData?.assets?.required_file
					}
				/>
			)}

			{isFileNameInvalid && (
				<Error
					hasError
					errorText={`${pageData?.assets?.file_validation_naming} <strong>${namingValidation}</strong>`}
				/>
			)}

			{children}

			{!isSummary && (
				<div style={{ display: 'none' }}>
					<input
						ref={refFileInput}
						type="file"
						name={name}
						onChange={() => onHandleFileChange()}
						onClick={(e) => (e.currentTarget.value = '')}
						accept={config.request.file.accept}
						multiple={(maxFiles && maxFiles > 1) || false}
					/>
				</div>
			)}

			{isNewDesign && !isSummary && (
				<div
					className={joinClasses([
						hasError || notificationError || (isSameFileUploaded && !isDragOver)
							? pageUtils.classes.errorBorder
							: '',
						pageUtils.classes.dragAndDropArea,
						isDragOver ? pageUtils.classes.dragOver : '',
						(maxFiles && files.size >= maxFiles) ||
						files.size >= config.request.file.maxSize
							? pageUtils.classes.disabled
							: ''
					])}
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
					onClick={(e) => onOpenUploadFiles(e)}
				>
					<div style={{ marginBottom: '0.25rem' }}>
						{pageData?.assets?.page_requestInfo_drop_file}
					</div>
					<Link
						id={name}
						className={joinClasses([
							pageUtils.classes.btn,
							pageUtils.classes.inputFileBtn,
							(maxFiles && files.size >= maxFiles) ||
							files.size >= config.request.file.maxSize
								? pageUtils.classes.btnDisabled
								: ''
						])}
						to="#"
						// onClick={(e) => onOpenUploadFiles(e)}
					>
						{formatStrapiText(pageData?.assets?.page_requestInfo_inputFile)}
					</Link>
				</div>
			)}

			{!isSummary && formPosition !== Steps.summary && !isNewDesign && (
				<Link
					id={name}
					className={joinClasses([
						pageUtils.classes.btn,
						pageUtils.classes.inputFileBtn,
						(maxFiles && files.size >= maxFiles) ||
						files.size >= config.request.file.maxSize
							? pageUtils.classes.btnDisabled
							: ''
					])}
					to="#"
					onClick={(e) => onOpenUploadFiles(e)}
				>
					<Icon
						src={downloadIcon}
						className={pageUtils.classes.inputFileIcon}
					/>

					{formatStrapiText(pageData?.assets?.page_requestInfo_inputFile)}
				</Link>
			)}

			{notificationError && (
				<div className={pageUtils.classes.notificationContainer}>
					<Notification
						type="error"
						text={pageData?.assets?.error_file_max_size}
						showExitBtn
						onClickCancelBtn={() => setNotificationError(false)}
					/>
				</div>
			)}

			{isSameFileUploaded && (
				<div className={pageUtils.classes.notificationContainer}>
					<Notification
						type="error"
						text={pageData?.assets?.file_validation_same_file}
						showExitBtn
						onClickCancelBtn={() => setIsSameFileUploaded(false)}
					/>
				</div>
			)}

			{hasWrongFormatError && (
				<div className={pageUtils.classes.notificationContainer}>
					<Notification
						type="error"
						text={pageData?.assets?.error_file_wrong_format}
						showExitBtn
						onClickCancelBtn={() => setHasWrongFormatError(false)}
					/>
				</div>
			)}

			<TransitionGroup
				className={
					addTransitionClassContainer ? pageUtils.classes.fileList : ''
				}
			>
				{files.size > 0 &&
					[...files].map((file: File, key: number) => (
						<CSSTransition
							timeout={config.transition.duration}
							key={key}
							classNames={{
								enter: pageUtils.classes.enter,
								enterActive: pageUtils.classes.enterActive,
								exit: pageUtils.classes.exit,
								exitActive: pageUtils.classes.exitActive
							}}
							onEnter={() => setAddTransitionClassContainer(true)}
							onExited={() => {
								if ([...files].length === 0) {
									setAddTransitionClassContainer(false)
								}
							}}
						>
							<div className={pageUtils.classes.fileItem} key={key}>
								<div
									className={pageUtils.classes.fileItemDetails}
									onClick={onOpenFileOnBrowser(file)}
								>
									{displayThumbnail(getRequestImg(file))}
									{file.name} {isSummary && `(${formatFileSize(file.size)})`}
								</div>
								{!isSummary && (
									<div
										className={pageUtils.classes.fileItemCloseIcon}
										onClick={removeFileFromForm(file)}
									>
										<Icon
											src={closeIcon}
											className={pageUtils.classes.imgCloseIcon}
										/>
									</div>
								)}
							</div>
						</CSSTransition>
					))}
			</TransitionGroup>
		</section>
	)
}

export default RequestFiles
