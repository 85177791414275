import React, { FC, useContext, useEffect, useState } from 'react'
import { joinClasses } from '@utils/styles'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import { classes, whoCanSeeThisPageLink } from './__footer.utils'

const CompactFooter: FC = () => {
	const { pageData } = useContext(AppStateContext)

	return (
		<footer className={joinClasses([classes.root, classes.compactRoot])}>
			<div className={joinClasses([classes.div, classes.compactDiv])}>
				<a
					className={classes.link}
					target="_blank"
					href={whoCanSeeThisPageLink}
				>
					{formatStrapiText(pageData?.assets?.who_can_view_this_page)}
				</a>
			</div>
		</footer>
	)
}

export default CompactFooter
