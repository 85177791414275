import axios, { AxiosError } from 'axios'
import apiClient from '@services/api.client'
import { IArticleDTO } from '@services/dtos/articles.dto'
import {
	AxiosResponse,
	IArticlesRepository,
	SearchArticleType
} from '@services/types'
import { BaseRepository } from '@services/repositories/_miscellaneous.repository'

export class ArticlesRepository
	extends BaseRepository
	implements IArticlesRepository
{
	constructor() {
		super()
		this.resource = 'knowledge_articles'
	}

	public async getArticlesById(id: string): Promise<IArticleDTO> {
		try {
			const response: AxiosResponse<IArticleDTO> = await apiClient.get('', {
				params: {
					resource: this.resource,
					resourceId: id,
					params: JSON.stringify({
						include_linked_incident_features_list: true
					})
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error(
					`Error while fetching the article with the id of [${id}] : ` +
						error.message
				)
			}

			throw error
		}
	}

	public async getArticles(): Promise<IArticleDTO[]> {
		try {
			const response: AxiosResponse<IArticleDTO[]> = await apiClient.get('', {
				params: {
					resource: this.resource,
					params: JSON.stringify({
						keyword: '%%%%',
						include_linked_incident_features_list: true
					})
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error(
					`Error while fetching the list articles : ` + error.message
				)
			}

			throw error
		}
	}

	public async searchArticles(
		searchType: SearchArticleType,
		search: string
	): Promise<IArticleDTO[]> {
		try {
			const response: AxiosResponse<IArticleDTO[]> = await apiClient.get('', {
				params: {
					resource: this.resource,
					params: JSON.stringify({
						[searchType]: search,
						include_linked_incident_features_list: true
					})
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error(
					`Error while fetching the list articles by ${
						searchType === 'keyword' ? 'keyword' : 'request ID'
					} [${search}] : ${error.message}`
				)
			}
			throw error
		}
	}
}
