import React, {
	forwardRef,
	ForwardRefRenderFunction,
	useCallback,
	useState
} from 'react'
import { TooltipProp, RadioList } from '@utils/request'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Error from '@components/requestForms/inputs/error.input'
import Tooltip from '@components/ui/tooltip'
import Breakpoints from '@utils/breakpoints'
import { Collapse } from 'react-collapse'
import Text from '@components/ui/text'
import { Direction } from '@services/constants'
import { chevronDownBlueIcon, chevronUpIcon } from '@images/icons'
import { debounce } from 'lodash'

type Props = TooltipProp & {
	name: string
	label?: string
	list: RadioList[]
	value: string
	required?: boolean
	hasError?: boolean
	disabled?: boolean
	description?: string
	onChange: (value: string, key: string) => void
	direction?: 'horizontal' | 'vertical'
	spanContainer?: boolean
	hasBackgroundWrapper?: boolean
	hideRequiredIcon?: boolean
	tooltipIsModal?: boolean
	classWrapper?: string
	alternateDisplay?: boolean
}

type Classes = {
	root: string
	textSection: string
	labelPopup: string
	toolTipPopup: string
	radio: string
	radioLabel: string
	error: string
	extraText: string
	radioLabelExtraText: string
	displayHorizontal: string
	alignTop: string
	list: string
	radioWrapper: string
	detailsNoMargin: string
	description: string
	detailsWrapper: string
	details: string
	radioShevronIcon: string
	radioShevronIconActive: string
	required: string
	contentRoot: string
	noWrap: string
	rootHorizontal: string
	spanContainer: string
	wrapTitle: string
	alignFlexStart: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '30px'
	},
	rootHorizontal: {
		marginBottom: '15px',
		width: '100%'
	},
	textSection: {
		display: 'inline-flex'
	},
	labelPopup: {
		display: 'inline-flex',
		alignItems: 'center',
		[Breakpoints.maxWidth('md')]: {
			justifyContent: 'space-between',
			width: '100%'
		}
	},
	toolTipPopup: {
		'& div span': {
			paddingRight: '0px !important'
		}
	},
	radio: {
		display: 'inline-flex',
		marginRight: '10px',
		position: 'relative',
		width: '13px',
		height: '13px',
		transform: 'scale(1.5)',
		boxSizing: 'border-box',
		padding: '0',
		outline: 'none',
		'&:hover': {
			cursor: 'pointer'
		},
		'&:before': {
			width: '13px',
			height: '13px',
			borderRadius: '100%',
			top: '-2px',
			left: '-1px',
			position: 'absolute',
			backgroundColor: Colors.white,
			content: "''",
			display: 'inline-block',
			visibility: 'visible',
			border: `1px solid ${Colors.grey}`
		},
		'&:checked': {
			'&:after': {
				content: "''",
				width: '7px',
				height: '7px',
				borderRadius: '100%',
				top: '2px',
				left: '3px',
				position: 'absolute',
				backgroundColor: Colors.secondary
			}
		}
	},
	radioLabel: {
		display: 'flex',
		alignItems: 'center',
		width: 'contain',
		marginTop: '5px',
		cursor: 'pointer',
		fontWeight: 'normal',
		color: Colors.lightBlack,
		'& div span': {
			display: 'flex',
			paddingRight: '1.4rem'
		},
		[Breakpoints.maxWidth('xs')]: {
			'column-gap': '3px'
		}
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		input: {
			'&:before': {
				borderColor: Colors.errorRed
			},
			'&:focus': {
				'&:before': {
					boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
				}
			}
		}
	},
	extraText: {
		maxWidth: '100%',
		color: Colors.lightGrey2,
		flexBasis: '100%',
		marginLeft: 28,
		marginTop: 5,
		p: {
			fontSize: 14,
			lineHeight: '18px'
			// margin: 0
		}
	},
	radioLabelExtraText: {
		flexWrap: 'wrap'
	},
	displayHorizontal: {
		display: 'flex',
		'& > :first-child': {
			marginRight: 10
		}
	},
	alignTop: {
		alignItems: 'flex-start !important',
		listStyleType: 'none'
	},
	list: {
		paddingLeft: '0',
		listStyle: 'none',
		margin: '5px 0 30px',
		marginBottom: '1rem!important',
		'& li': {
			paddingLeft: '15px',
			position: 'relative',
			fontFamily: '"Roboto", "Helvetica Neue", Arial',
			fontSize: '16px',
			fontWeight: '400',
			color: '#0a0a0a',
			lineHeight: '20px',
			verticalAlign: 'baseline',
			WebkitFontSmoothing: 'antialiased !important',
			':before': {
				content: '""',
				position: 'absolute',
				width: '4px',
				height: '4px',
				top: '7px',
				left: '2px',
				borderRadius: '50%',
				backgroundColor: Colors.secondary
			}
		}
	},
	radioWrapper: {
		display: 'block',
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '6px 12px',
		position: 'relative'
	},
	radioShevronIcon: {
		backgroundImage: `url(${chevronDownBlueIcon})`,
		backgroundRepeat: 'no-repeat,repeat',
		backgroundPosition: 'right 0.6em top 50%,0 0',
		backgroundSize: '24px',
		marginBottom: '0',
		marginTop: '5px'
	},
	radioShevronIconActive: {
		backgroundImage: `url(${chevronUpIcon}) !important`
	},
	detailsNoMargin: {
		marginBottom: '0'
	},
	description: {
		clear: 'both',
		display: 'block',
		marginBottom: '5px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		fontWeight: '400'
	},
	detailsWrapper: {
		marginBottom: '5px'
	},
	details: {
		maxWidth: '100%',
		background: '#fff',
		padding: '16px 16px 16px 16px',
		border: '1px solid #ddd',
		borderTop: 'none',
		color: '#0a0a0a',
		flexBasis: '100%',
		marginBottom: '5px',
		p: {
			fontSize: 12,
			lineHeight: '18px'
			// margin: 0
		}
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	},
	spanContainer: {
		flexGrow: 1
	},
	wrapTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
		'& .tooltip-content': {
			left: '0!important',
			right: 'auto !important'
		}
	},
	alignFlexStart: {
		alignItems: 'flex-start'
	}
})

const RadioInputForwardRef: ForwardRefRenderFunction<HTMLInputElement, Props> =
	(
		{
			name,
			label,
			list,
			value,
			required,
			hasError,
			disabled,
			showInfo,
			infoLabel,
			toolTipMedia,
			description,
			onChange,
			direction,
			hasBackgroundWrapper,
			spanContainer,
			hideRequiredIcon = false,
			tooltipIsModal,
			classWrapper,
			alternateDisplay = false
		},
		ref
	) => {
		const [isDescriptionsOpen, setIsDescriptionsOpen] = useState<boolean[]>(
			list?.map((item) => {
				return item.value === value
			})
		)

		const updateIsDescriptionsOpen = (e, index, shouldUpdate) => {
			let isDescOpen = JSON.parse(JSON.stringify(isDescriptionsOpen))

			if (isDescOpen[index] === true) {
				if (shouldUpdate === true || shouldUpdate === undefined)
					isDescOpen = isDescOpen.map((element) => false)
			} else {
				isDescOpen = isDescOpen.map((element, i) =>
					i === index ? true : false
				)
			}

			setIsDescriptionsOpen(isDescOpen)
		}

		const getLabel = () => {
			const hasQuestionMark = label?.endsWith('?')
			if (hasQuestionMark) {
				return (
					<div className={classes.contentRoot}>
						{label?.replace('?', '')}
						<span
							className={required && !hideRequiredIcon ? classes.noWrap : ''}
						>
							?
						</span>
					</div>
				)
			} else {
				return (
					<span
						className={required && !hideRequiredIcon ? classes.required : ''}
					>
						{label}
					</span>
				)
			}
		}
		return (
			<div
				className={joinClasses([
					direction !== Direction.horizontal
						? classes.root
						: classes.rootHorizontal,
					hasError ? classes.error : '',
					'no-margin',
					classWrapper || ''
				])}
			>
				<label>
					<div className={classes.wrapTitle}>
						{getLabel()}
						<div className={classes.toolTipPopup}>
							{showInfo && (
								<Tooltip
									showInfo
									infoLabel={infoLabel}
									toolTipMedia={toolTipMedia}
									isModal={tooltipIsModal}
								/>
							)}
						</div>
					</div>
					{/* {getLabel()} */}
					{/* <div className={classes.labelPopup}>
						{showInfo && infoLabel && (
							<Tooltip showInfo infoLabel={infoLabel} toolTipMedia={toolTipMedia} />
						)}
						<div className={classes.toolTipPopup}>
							{showInfo  && (
								<Tooltip showInfo infoLabel={infoLabel} toolTipMedia={toolTipMedia} />
							)}
						</div>
					</div> */}
					<Error hasError={hasError} />
				</label>
				{description && (
					<span className={classes.description}>
						<Text content={description} />
					</span>
				)}
				<div
					className={
						direction === Direction.horizontal ? classes.displayHorizontal : ''
					}
				>
					{list?.map((item, index) => (
						<>
							<span
								key={`option_${index}`}
								className={joinClasses([
									hasBackgroundWrapper ? classes.radioWrapper : '',
									item.details &&
									isDescriptionsOpen[index] === true &&
									!alternateDisplay
										? classes.detailsNoMargin
										: '',
									item.details &&
									item.updateDetails === undefined &&
									!alternateDisplay
										? classes.radioShevronIcon
										: '',
									item.details &&
									item.updateDetails === undefined &&
									isDescriptionsOpen[index] === true &&
									!alternateDisplay
										? classes.radioShevronIconActive
										: '',
									direction === Direction.horizontal && spanContainer
										? classes.spanContainer
										: ''
								])}
							>
								<label
									className={joinClasses([
										classes.radioLabel,
										item.extraText && !item.details
											? classes.radioLabelExtraText
											: '',
										Array.isArray(item.extraText) ? classes.alignTop : '',
										alternateDisplay ? classes.alignFlexStart : ''
									])}
									key={index}
									htmlFor={`${name}_${index}`}
									onClick={debounce((e) => {
										if (item.details || item.updateDetails !== undefined) {
											updateIsDescriptionsOpen(e, index, item.updateDetails)
										}
									}, 100)}
								>
									<input
										id={`${name}_${index}`}
										name={name}
										type="radio"
										className={classes.radio}
										value={item.value}
										required={required}
										disabled={disabled}
										checked={item.value === value}
										onChange={(e) => {
											onChange(item.value, item.key)
										}}
										{...(index === 0 ? { ref } : {})}
									/>

									{alternateDisplay ? (
										<>
											<span>
												<p style={{ width: '100%' }}>{item.label} </p>
												{item.extraText && typeof item.extraText === 'string' && (
													<div
														className={classes.extraText}
														style={{ margin: '0 20px 0 0' }}
													>
														<Text content={item.extraText} />
													</div>
												)}
											</span>
											{item.details}
										</>
									) : (
										<div className={classes.textSection}>
											<Text content={item.label} />
											<div className={classes.toolTipPopup}>
												{item.toolTip && item.toolTipLabel && (
													<Tooltip
														showInfo
														infoLabel={item.toolTipLabel}
														isModal={tooltipIsModal}
													/>
												)}
											</div>
											{item.extraText && Array.isArray(item.extraText) && (
												<ul className={classes.list}>
													{item.extraText.map((text, index) => (
														<li key={`${index}-${text}`}>{text}</li>
													))}
												</ul>
											)}
										</div>
									)}

									{item.extraText &&
										!alternateDisplay &&
										typeof item.extraText === 'string' && (
											<div className={classes.extraText}>
												<Text content={item.extraText} />
											</div>
										)}
								</label>
							</span>

							{item.details && !alternateDisplay && (
								<div
									key={`item-detail${index}`}
									className={
										item.details && isDescriptionsOpen[index] === true
											? classes.detailsWrapper
											: ''
									}
								>
									<Collapse isOpened={isDescriptionsOpen[index] === true}>
										<div className={classes.details}>{item.details}</div>
									</Collapse>
								</div>
							)}
						</>
					))}
				</div>
			</div>
		)
	}

const RadioInput = forwardRef(RadioInputForwardRef)

export default RadioInput
