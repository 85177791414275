'use strict'

import React from 'react'

type Props = {
	breakLabel: string | React.ReactNode
	breakLinkClassName: string
	breakClassName: string
	breakHandler: (event: any) => void
	getEventListener: (event: any) => void
}

const BreakView: React.FC<Props> = ({
	breakLabel,
	breakHandler,
	breakClassName,
	breakLinkClassName,
	getEventListener
}) => {
	const className = breakClassName || 'break'

	return (
		<li className={className}>
			<a
				className={breakLinkClassName}
				role="button"
				tabIndex={0}
				onKeyPress={breakHandler}
				{...getEventListener(breakHandler)}
			>
				{breakLabel}
			</a>
		</li>
	)
}

export default BreakView
