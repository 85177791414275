import React, {
	forwardRef,
	ForwardRefRenderFunction,
	RefAttributes,
	useEffect,
	useState
} from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors, Constants } from '@utils/css-variables'
import Error from '@components/requestForms/inputs/error.input'
import Tooltip from '@components/ui/tooltip'
import Breakpoints from '@utils/breakpoints'
import Text from '@components/ui/text'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'

type Props = {
	id: string
	label?: string
	value: string
	required?: boolean
	hasError?: boolean
	disabled?: boolean
	maxCharacters?: number
	preventPaste?: boolean
	type?: 'text' | 'number' | 'date' | 'email' | 'time'
	max?: string
	min?: string
	onChange: (selected: string, currentObject?: any) => void
	classWrapper?: string
	isMoneyInput?: boolean
	schema?: RegExp
	schemaErrorText?: string
	customErrorText?: string
	onSetError?: React.Dispatch<React.SetStateAction<string[]>>
	errors?: string[]
	requiredIcon?: boolean
	description?: string | RefAttributes<HTMLInputElement>
	placeholder?: string
	onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>
	onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
	autoComplete?: string
	hasCustomErrorWithoutText?: boolean
	customClassName?: string
	onFocus?: React.FocusEventHandler<HTMLInputElement>
	onBlur?: () => void
	disableErrorText?: boolean
	titleAndDescError?: boolean
	toolTip?: boolean
	toolTipLabel?: string
	tooltipMedia?: string
	showCheckbox?: boolean
	checkboxLable?: string
	checkboxId?: string
	checkboxValue?: any
	onClickCheckbox?: (value) => void
	suffixText?: string
	tooltipIsModal?: boolean
	acceptPositiveNumbers?: boolean
	disableNumberControl?: boolean
	hasHtmlLabel?: boolean
}

type Classes = {
	root: string
	input: string
	error: string
	moneyInput: string
	description: string
	required: string
	hasHtmlRequired: string
	contentRoot: string
	noWrap: string
	toolTipPopup: string
	wrapTitle: string
	checkbox2: string
	checkbox2Label: string
	checkbox2Container: string
	checkboxWrapper: string
	gridDisplay: string
	suffixWrapper: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '20px'
	},
	input: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',
		width: '100%',
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400,
		padding: '8px 12px',
		borderRadius: '0px',
		lineHeight: '26px',
		'&:focus': {
			outline: 'none'
		}
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		span: {
			color: Colors.errorRed
		},
		input: {
			borderColor: Colors.errorRed,
			'&:focus': {
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0px 3px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		fontWeight: '400'
	},
	moneyInput: {
		position: 'relative',
		'& span': {
			position: 'absolute',
			display: 'flex',
			width: '32px',
			height: '44px',
			background: Colors.lightGreyTransparent,
			justifyContent: 'center',
			alignItems: 'center'
		},
		'& input': {
			paddingLeft: '40px'
		},
		'& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
			display: 'none',
			WebkitUserModify: 'read-only !important',
			writingMode: 'inherit !important'
		},
		'& input[type=number]': {
			MozAppearance: 'text-field'
		},
		'& input::-webkit-textfield-decoration-container': {
			width: 'calc(100% - 28px)'
		}
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	hasHtmlRequired: {
		'& p::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	},
	toolTipPopup: {
		'& div span': {
			paddingRight: '0px !important'
		}
	},
	wrapTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
		'& .tooltip-content': {
			left: '0!important',
			right: 'auto !important'
		}
	},

	checkbox2: {
		width: '100%',
		position: 'absolute',
		verticalAlign: 'baseline',
		left: '11px',
		zIndex: '20',
		opacity: '0'
	},
	checkbox2Label: {
		fontSize: '16px',
		padding: '0',
		color: Colors.lightBlack,
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: `1px solid ${Colors.lightGrey3}`,
			background: Colors.white,
			outline: 'none !important'
		},
		':after': {
			content: "''",
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: Colors.secondary,
			outline: 'none',
			opacity: '0',
			transition: 'all .15s ease-out'
		},

		'&.error-check': {
			color: `${Colors.red} !important`,
			':before': {
				border: `1px solid ${Colors.red}`
			}
		}
	},
	checkbox2Container: {
		'& input[value=true] ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxWrapper: {
		display: 'block',
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '14px 12px',
		position: 'relative'
	},
	gridDisplay: {
		display: 'grid',
		gridTemplateColumns: '60%38%',
		alignItems: 'end',
		gap: '2%',
		[Breakpoints.maxWidth('lg')]: {
			gridTemplateColumns: '40%58%'
		},
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr',
			'&:nth-child(2)': {
				gridRow: 2
			}
		}
		// '& span': {
		// 	marginBottom: '20px !important'
		// }
	},
	suffixWrapper: {
		position: 'relative',
		'& p': {
			position: 'absolute',
			display: 'flex',
			top: 0,
			right: 0,
			width: '35px',
			height: '44px',
			background: Colors.lightGreyTransparent,
			justifyContent: 'center',
			alignItems: 'center'
		},
		'& input': {
			paddingRight: '40px'
		},
		'& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
			display: 'none',
			WebkitUserModify: 'read-only !important',
			writingMode: 'inherit !important'
		},
		'& input[type=number]': {
			MozAppearance: 'text-field'
		},
		'& input::-webkit-textfield-decoration-container': {
			width: 'calc(100% - 28px)'
		}
	}
})

const TextInputForwardRef: ForwardRefRenderFunction<HTMLInputElement, Props> = (
	{
		id,
		label,
		required,
		hasError,
		disabled,
		value,
		maxCharacters = 100,
		preventPaste = false,
		min,
		type = 'text',
		max,
		classWrapper,
		onChange,
		isMoneyInput,
		schema,
		schemaErrorText,
		customErrorText,
		onSetError,
		errors,
		requiredIcon = true,
		description,
		placeholder,
		onKeyUp,
		onKeyPress,
		autoComplete,
		hasCustomErrorWithoutText,
		customClassName,
		onFocus,
		onBlur,
		disableErrorText = false,
		titleAndDescError,
		toolTip,
		toolTipLabel,
		tooltipMedia,
		tooltipIsModal,
		showCheckbox,
		checkboxId,
		checkboxLable,
		checkboxValue,
		onClickCheckbox,
		suffixText,
		acceptPositiveNumbers,
		disableNumberControl,
		hasHtmlLabel = false
	},
	ref
) => {
	const displayCurrency = isMoneyInput && type == 'number'
	const [formatError, setFormatError] = useState<boolean>(false)

	const [isRequiredState, setIsRequiredState] = useState<boolean>(false)

	const handleInputChange = (event) => {
		const inputValue = event.target.value
		const regex = /^(\d+)?(\.\d{0,2})?$/

		if (regex.test(inputValue)) {
			const newInputValue = inputValue.replace(/e/gi, '')
			onChange(newInputValue)
		}
	}

	const checkValue = () => {
		if (onBlur) {
			onBlur()
		}

		if (type === 'email') {
			if (value !== '' && !schema?.test(value)) {
				onSetError && onSetError([schemaErrorText!, id])
				setFormatError(true)
			} else {
				onSetError && onSetError([])
				setFormatError(false)
			}
		}
	}

	useEffect(() => {
		if (required && requiredIcon) {
			setIsRequiredState(true)
		} else {
			setIsRequiredState(false)
		}
	}, [required, requiredIcon])

	const getLabel = () => {
		if (hasHtmlLabel) {
			return (
				<span className={isRequiredState ? classes.hasHtmlRequired : ''}>
					{parse(formatHtmlForStrapiText(label))}
				</span>
			)
		}

		const hasQuestionMark = label?.endsWith('?')
		if (hasQuestionMark) {
			return (
				<div className={classes.contentRoot}>
					{label?.replace('?', '')}
					<span className={isRequiredState ? classes.noWrap : ''}>?</span>
				</div>
			)
		} else {
			return (
				<span className={isRequiredState ? classes.required : ''}>{label}</span>
			)
		}
	}

	function isNegativeInteger(str: string): boolean {
		return /^-\d+$/.test(str)
	}

	const handleInput = (e: any) => {
		if (
			(e.target.value == '-' || isNegativeInteger(e.target.value)) &&
			type == 'number' &&
			acceptPositiveNumbers
		) {
			e.target.value = ''
		}
	}

	const onKeyPressNumberInput = (event) => {
		const charCode = event.which ? event.which : event.keyCode
		const char = String.fromCharCode(charCode)
		const value = event.target.value + char

		if (!/\d/.test(value)) {
			event.preventDefault()
			return
		}
	}

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		event.preventDefault() // Prevent pasting
	}

	return (
		<div
			className={joinClasses([
				classes.root,
				hasError || hasCustomErrorWithoutText ? classes.error : '',
				classWrapper || '',
				customClassName ? customClassName : ''
			])}
		>
			<label htmlFor={id}>
				<span className={titleAndDescError ? classes.error : ''}>
					<div className={classes.wrapTitle}>
						{getLabel()}
						<div className={classes.toolTipPopup}>
							{toolTip && (
								<Tooltip
									showInfo
									infoLabel={toolTipLabel}
									toolTipMedia={tooltipMedia}
									isModal={tooltipIsModal}
								/>
							)}
						</div>
					</div>

					{description && (
						<span className={classes.description}>{description}</span>
					)}
				</span>
				{!disableErrorText && (
					<Error
						hasError={hasError}
						errorText={
							!!customErrorText
								? customErrorText
								: formatError
								? schemaErrorText ?? undefined
								: undefined
						}
					/>
				)}
			</label>
			<div className={showCheckbox ? classes.gridDisplay : ''}>
				<div
					className={joinClasses([
						displayCurrency ? classes.moneyInput : '',
						suffixText ? classes.suffixWrapper : ''
					])}
				>
					{displayCurrency && <span>$</span>}
					<input
						name={id}
						className={classes.input}
						type={type}
						id={id}
						disabled={disabled}
						required={required}
						onChange={
							displayCurrency
								? handleInputChange
								: (e) => onChange(e.target.value)
						}
						autoComplete={autoComplete || 'off'}
						onBlur={(e: any) => checkValue()}
						onFocus={onFocus}
						value={value}
						maxLength={maxCharacters}
						ref={ref}
						max={max}
						min={displayCurrency ? min ?? '0' : min}
						placeholder={placeholder}
						onKeyUp={onKeyUp}
						onInput={handleInput}
						onPaste={preventPaste ? handlePaste : undefined}
						onKeyPress={
							disableNumberControl && !onKeyPress
								? onKeyPressNumberInput
								: onKeyPress
						}
						// step={type === 'time' ? '3600' : undefined}
					/>
					{suffixText && <Text content={suffixText} />}
				</div>
				{showCheckbox && (
					<div className={joinClasses([classes.checkbox2Container])}>
						<span
							className={classes.checkboxWrapper}
							style={{
								marginBottom: 0,
								padding: '12.5px 12px'
							}}
						>
							<input
								onChange={(e: any) => {
									if (onClickCheckbox) onClickCheckbox(e.target.checked)
								}}
								value={checkboxValue}
								className={classes.checkbox2}
								type="checkbox"
								name={checkboxId}
							/>

							<label className={classes.checkbox2Label} htmlFor={checkboxId}>
								{checkboxLable}
							</label>
						</span>
					</div>
				)}
			</div>
		</div>
	)
}

const TextInput = forwardRef(TextInputForwardRef)

export default TextInput
