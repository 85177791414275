import { IRequestConfigsState } from '@services/store/request/type'

export const REQUEST_CONFIG_KEY_RELATION: Record<
	keyof IRequestConfigsState,
	string
> = {
	propertyType: 'number',
	equipmentType: 'number',
	waterside: 'number',
	dangerousTree: 'number',
	taxInformation: 'number',
	intervantionType: 'number',
	natureCollecting: 'number',
	collectionLocation: 'number',
	collectionSector: 'number',
	collectionHousing: 'number',
	containerProvided: 'number',
	immediateAttention: 'boolean',
	emergencyType: 'number',
	newBin: 'number',
	numberOnBin: 'string',
	containerCollection: 'number',
	coveredSewerIntervention: 'number',
	placeType: 'number',
	locationPlace: 'number',
	uncleanOrigin: 'number',
	locationLighting: 'number',
	lightingIntervention: 'number',
	sectorOff: 'number',
	signalingIntervention: 'number',
	signalingType: 'number',
	droppedStopSign: 'number',
	harshSignage: 'number',
	requiredFiles: 'File[]',
	snowRemovalBy: 'number',
	injuryOccurred: 'number',
	placeTypeSnowRemoval: 'number',
	fireHydrantSowRemoval: 'number',
	subject: 'number',
	roadUsersSecurity: 'number',

	// FORM TN Fields
	subventionChosen: 'number',
	childFirstName: 'string',
	childLastName: 'string',
	childBirthDate: 'string',
	adultResidentNumber: 'number',
	childrenResidentNumber: 'number',
	numberOfBarrel: 'number',
	manufacturerName1: 'string',
	costBeforeTax1: 'number',
	nameAndModelNumber1: 'string',
	manufacturerName2: 'string',
	costBeforeTax2: 'number',
	costBeforeTax3: 'number',
	nameAndModelNumber2: 'string',
	accommodationType: 'number',
	numberOfAccommodationConverted: 'number',
	convertedAccommodationList: 'string',
	fuelSystemReplacedType: 'number',
	heatingReplacementSystemType: 'number',
	contactorLicenseNumber: 'string',
	subventionTotalValueRequested: 'number',
	buildingAddressSameHasApplicant: 'boolean',
	buildingAddress: 'string',
	buildingPostalCode: 'string',
	buildingCity: 'string',
	firstOwnersLastName: 'string',
	firstOwnersFirstName: 'string',
	firstOwnersEmail: 'string',
	firstOwnersPhoneNumber: 'string',
	secondOwnersLastName: 'string',
	secondOwnersFirstName: 'string',
	secondOwnersEmail: 'string',
	secondOwnersPhoneNumber: 'string',
	buildingType: 'number',
	buildingCategory: 'number',
	buildingNumber: 'number',
	workProgress: 'number',
	workDate: 'string',
	permitNumber: 'string',
	buildingCadastralNumber: 'string',
	coApplicant: 'string',
	isOverYearOwner: 'boolean',
	notarialRegistrationDate: 'string',
	notarialRegistrationNumber: 'string',
	isConcernedDiffFromApplicant: 'boolean',
	claimNature: 'number',
	materialDamage: 'boolean',
	isDamageLimitedToBin: 'boolean',
	isOwnerOfDamagedVehicle: 'boolean',
	actualOwnerOfDamagedVehicle: 'string',
	isOwnerOrRenter: 'number',
	incidentLocation: 'string',
	incidentDate: 'string',
	incidentTime: 'string',
	isDateAndTimeNotKnown: 'boolean',
	damageDiscoveryDate: 'string',
	policeReportNumber: 'string',
	request311Number: 'string',
	damageCauseDescription: 'string',
	damageDescription: 'string',
	concernedLastName: 'string',
	concernedFirstName: 'string',
	concernedHasLavalAdresse: 'boolean',
	concernedAdresse: 'string',
	concernedAppartement: 'string',
	concernedEmail: 'string',
	concernedPhoneNumber: 'string',
	concernedCity: 'string',
	concernedProvince: 'string',
	concernedPostalCode: 'string',
	concernedCountry: 'string',
	isSameBillingAdresse: 'boolean',
	lotRequestReason: 'number',
	isApplicantLotOwner: 'boolean',
	relationToOwner: 'string',
	registrationNumber: 'string',
	landAreaDimensions: 'number',
	landAreaMeasurements: 'number',
	requestObject: 'string',

	businessSameAsPersonal: 'boolean',
	buildingBatchNumber: 'string',
	buildingRegistrationNumber: 'string',
	isBuildingOwner: 'boolean',
	ownerFullName: 'string',
	isDeclarationForHeatingReplacement: 'boolean',
	installationDate: 'string',
	isOilHeatingSystem: 'boolean',
	isDualEnergySystem: 'boolean',
	convertedHeatingSystemType: 'number',
	heatingEnergySource: 'number',
	mainHeatingSystemType: 'number',
	approximateInstallYear: 'number',

	isOrganizationRequest: 'boolean',
	organizationName: 'string',
	applicantPurpose: 'string',
	specifiedDocuments: 'string',
	researchSubject: 'string',
	concernedSector: 'string',
	coveredSearchPeriodStart: 'string',
	coveredSearchPeriodEnd: 'string',
	requiresTechnicalSpecs: 'boolean',
	technicalSpecs: 'string',
	isInPersonAppointment: 'boolean',
	appointmentDate: 'string',
	appointmentTime: 'string',
	appointmentComment: 'string',
	areDocumentsDiffused: 'boolean',
	isLicenceRecipientApplicant: 'number',
	useType: 'string',
	// useTypeString: 'string',
	projectTitle: 'string',
	projectDescription: 'string',
	publishingHouse: 'string',
	releaseDate: 'string',
	ownerWaterCounterStatus: 'number',
	counterReadingDate: 'string',
	counterReadingValue: 'string',
	counterSerialNumber: 'string',
	occupantNumber: 'number',
	isContractWithCity: 'boolean',
	contractNumber: 'string',
	cityRespondentName: 'string',
	desiredDateForHydraulicTest: 'string',
	isRelatedToInstallingTemporaryNetwork: 'boolean',

	propertyIsOwner: 'string',
	declarationExistsOnAddress: 'boolean',
	subventionExistsOnAddress: 'boolean',
	contractorCompanyName: 'string',
	contractorPermisNumber: 'string',
	contractorTelephone: 'string',
	typeOfInfestation: 'string',
	typeOfInfestationOther: 'string',
	locationOfInfestation: 'string',
	spaceToBeTreated: 'string',
	spaceToBeTreatedOther: 'string',
	areaToBeTreated: 'string',
	expectedDateOfApplication: 'string',
	historyOfTheProblem: 'string',
	controlMethodsAlreadyApplied: 'string',
	specifyControlMethodsApplied: 'string',
	productTradeName: 'string',
	certificationNumber: 'string',
	activeIngredient: 'string',

	companyName: 'string',
	quebecBusinessNumber: 'string',
	representativeName: 'string',
	representativeTitle: 'string',
	companyAdresse: 'string',
	companyAppartement: 'string',
	companyCity: 'string',
	companyProvince: 'string',
	companyPostalCode: 'string',
	companyCountry: 'string',
	companyEmail: 'string',
	companyPhoneNumber: 'string',
	isSameInformations: 'boolean',
	permitLicenseNumber: 'string',
	permitExpiryDate: 'string',
	// pesticideCertificatesList: PesticideCertificates[]
	// vehicleInformationList: VehicleInformation[]

	startDate: 'string',
	endDate: 'string',
	whichFireHydrantToUse: 'string',
	contractorInChargeOfWorks: 'string',
	citySubmissionNumber: 'string',
	firmInChargeOfTemporaryNetwork: 'string',
	isCertified: 'boolean',

	whichFireHydrantsToUseList: 'string',
	typeOfUse: 'string',
	intendedWaterUsage: 'number',

	yearsOlder: 'number',
	whatToDo: 'number',
	coveredByException: 'boolean',
	specialPermit: 'number',

	firstHeatingSourcePercentage: 'number',
	secondHeatingSourcePercentage: 'number',
	declarationType: 'number',
	deviceReplacementType: 'number',
	fireplaceTypeToDeclare: 'number',
	frequencyOfUse: 'number',
	deviceLocation: 'number',
	isDeviceCertified: 'number',
	deviceParticleEmissionRate: 'number',

	eligibilityClause1: 'boolean',
	eligibilityClause2: 'boolean',
	eligibilityClause3: 'boolean',
	eligibilityClause4: 'boolean',
	eligibilityClause5: 'boolean',

	addressOption: 'number',
	applicantStatus: 'number',
	requestOrigin: 'number',
	evaluationRole: 'number',
	buildingValueToRole: 'number',

	isNewRequest: 'number',
	makingRequestAs: 'number',
	approximateWidthOfObstruction: 'number',
	approximateLengthOfObstruction: 'number',
	companyResponsibleForSignage: 'number',
	occupationDate: 'number',
	haveContractedCompany: 'boolean',
	occupancyRespectingExigences: 'boolean',
	haveAgreementWithCity: 'boolean',
	workFollowInsurance: 'boolean',
	personInchargeOfProject: 'boolean',
	isSupervisor: 'boolean',
	isFullOrPatialClosure: 'boolean',
	willRoadBeLimited: 'boolean',
	useStandardizedBoard: 'boolean',
	isLocatedNearIntersection: 'boolean',
	willAllowPassage: 'boolean',
	willMakeAnSTL: 'boolean',
	isATemporaryReduction: 'boolean',

	grantForWhom: 'number',
	beneficiaryFirstName: 'string',
	beneficiaryLastName: 'string',
	beneficiaryAddress: 'string',
	dependentFirstChildFirstName: 'string',
	dependentFirstChildLastName: 'string',
	dependentSecondChildFirstName: 'string',
	dependentSecondChildLastName: 'string',
	dependentThirdChildFirstName: 'string',
	dependentThirdChildLastName: 'string',
	buyingProducts: 'number',
	totalAmount: 'number',

	subsidyReceived: 'boolean',
	subscriptionAmount: 'number',
	previousSubscription: 'boolean',
	subscriptionType: 'number',

	sameAddressAsApplicant: 'boolean',
	transactionReason: 'number',
	replacingReason: 'number',

	oldDeviceMake: 'string',
	oldDeviceModel: 'string',
	newDeviceMake: 'string',
	newDeviceModel: 'string',
	installationCosts: 'number',

	registerForEmail: 'boolean',

	// whatToDo: 'number',
	requestAs: 'number',
	isLocationOwned: 'boolean',
	isPropertyJointOwnership: 'boolean',
	isPropertyLocatedOnWaterfront: 'boolean',
	installationType: 'number',
	isSpaLitersCondition: 'boolean',
	poolShape: 'number',
	poolDiameter: 'number',
	poolDiameterUnit: 'number',
	poolHeight: 'number',
	poolHeightUnit: 'number',
	poolWidth: 'number',
	poolWidthUnit: 'number',
	poolLength: 'number',
	poolLengthUnit: 'number',
	expectedInstallDate: 'string',
	installationCost: 'number',
	isTreeCutRequired: 'boolean',
	protectiveEnclosureType: 'number',
	protectiveEnclosurePurchaseDate: 'string',
	isProtectiveEnclosureHasWindows: 'boolean',

	// Building construction or addition permit
	isSubjectToPiiaApproval: 'boolean',
	hasBeenSubjectToApproval: 'boolean',
	whoWillBeInChargeOfWork: 'number',
	iaNumber: 'string',
	ecResolutionNumber: 'string',
	isRealEstateProject: 'boolean',
	projectNumber: 'string',
	// mainUseOfNewBuilding: 'number',
	isTheNewBuilding: 'number',
	isTheNewBuildingPartOfAProperty: 'boolean',
	structureType: 'number',
	buildingArea: 'number',
	buildingAreaUnit: 'number',
	totalGrossFloorArea: 'number',
	totalGrossFloorAreaUnit: 'number',
	isDeclaredAreaForResidentialUse: 'boolean',
	nonResidentialAreas: 'number',
	nonResidentialAreasUnit: 'number',
	numberOfFloors: 'number',
	numberOfResidentialUnits: 'number',
	hasGarage: 'boolean',
	garageLocation: 'number',
	nbrOfParkingSpacesExt: 'number',
	nbrOfParkingSpacesInt: 'number',
	nbrOfParkingSpacesVis: 'number',
	nbrOfBikeSpaces: 'number',
	isWorkDueToDisaster: 'boolean',
	workDueToDisasterPrecision: 'string',
	approximateCostOfWork: 'number',
	totalProjectValue: 'number',
	isTreeCuttingRequired: 'boolean',
	entrepreneurUnknown1: 'boolean',
	entrepreneurUnknown: 'boolean',
	mainUseOfExistingBuilding: 'number',
	existingMainBuildingIsShared: 'boolean',
	whatToBuildOrImprove: 'number',
	isWaterAndSewerConnectionRequired: 'boolean',
	constructionType: 'number',
	foundationType: 'number',
	placeOnTheTerrain: 'number',
	buildingWidth: 'number',
	buildingWidthUnit: 'number',
	buildingLength: 'number',
	buildingLengthUnit: 'number',
	buildingHeight: 'number',
	buildingHeightUnit: 'number',
	buildingSurfaceArea: 'number',
	buildingSurfaceAreaUnit: 'number',
	existingMainBuildingHasHousing: 'boolean',

	existingMainBuildingStructureType: 'number',
	existingMainBuildingIs: 'number',
	typeOfBuildingToDemolish: 'number',
	demolitionType: 'number',
	typeOfBuildingToMove: 'number',
	buildingToMoveIs: 'number',
	isLocationInLaval: 'boolean',

	isOwner: 'boolean',
	isWateringLimit: 'boolean',
	wateringPurpose: 'number',

	//enlargement permit
	mainUseOfBuilding: 'number',
	workTypeBuildingPrecision: 'string',
	workTypeBuilding: 'string',
	workBuildingArea: 'number',
	workBuildingAreaUnit: 'number',
	currentNumberHome: 'number',
	numberDwellingAdded: 'number',
	plumbingWork: 'boolean',
	stabLevelToStreet: 'string',
	mainBuildingHasHousing: 'boolean',

	// Renovation permit
	numberOfCurrentHousing: 'number',
	numberOfHousingAdded: 'number',
	isThereOrWillThereBeOpenings: 'boolean',
	numberOfCurrentHousingInt: 'number',
	numberOfHousingAddedInt: 'number',

	// LandDevelopmentPermit
	isResidentialOrOther: 'number',
	foundationTypeBalcony: 'number',
	locationBalcony: 'number',
	widthBalcony: 'number',
	widthUnitBalcony: 'number',
	depthBalcony: 'number',
	depthUnitBalcony: 'number',
	heightBalcony: 'number',
	heightUnitBalcony: 'number',
	areaBalcony: 'number',
	areaUnitBalcony: 'number',
	foundationTypeTerrace: 'number',
	heightTerrace: 'number',
	heightUnitTerrace: 'number',
	areaTerrace: 'number',
	areaUnitTerrace: 'number'
}
