import React, {
	CSSProperties,
	forwardRef,
	ForwardRefRenderFunction
} from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors, Constants } from '@utils/css-variables'
import Error from '@components/requestForms/inputs/error.input'
import Text from '@components/ui/text'

type Props = {
	id: string
	label: string
	value: string
	required?: boolean
	hasError?: boolean
	maxCharacters: number
	disabled?: boolean
	onChange: (selected: string) => void
	hasDescriptionLabel?: string
	descriptionAlignment?: string
	rows?: number
	displayDescriptionTop?: boolean
	placeholder?: string
}

type Classes = {
	root: string
	formInput: string
	error: string
	inputDescLabel: string
	fieldHeight: string
	required: string
	contentRoot: string
	noWrap: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '30px'
	},
	formInput: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',
		width: '100%',
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400 as any,
		padding: '12px',
		borderRadius: 0,
		lineHeight: '26px',
		marginBottom: '10px',
		'&:focus': {
			outline: 'none'
		}
	},
	inputDescLabel: {
		display: 'block',
		margin: '5px 0 0',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		textarea: {
			borderColor: Colors.errorRed,
			'&:focus': {
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		}
	},
	fieldHeight: {
		height: '150px'
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	}
})

const TextAreaInputForwardRef: ForwardRefRenderFunction<
	HTMLTextAreaElement,
	Props
> = (
	{
		id,
		label,
		required,
		hasError,
		disabled,
		value,
		onChange,
		maxCharacters = 500,
		hasDescriptionLabel,
		descriptionAlignment,
		rows,
		displayDescriptionTop,
		placeholder
	},
	ref
) => {
	const displayDescription = (
		descriptionAlignment?: string,
		addMargin?: boolean
	) => {
		if (hasDescriptionLabel) {
			return (
				<span
					className={classes.inputDescLabel}
					style={
						{
							textAlign: descriptionAlignment || 'right',
							marginBottom: addMargin ? '10px' : ''
						} as CSSProperties
					}
				>
					<Text content={hasDescriptionLabel} />
				</span>
			)
		}
	}

	const getLabel = () => {
		const hasQuestionMark = label?.endsWith('?')
		if (hasQuestionMark) {
			return (
				<div className={classes.contentRoot}>
					{label?.replace('?', '')}
					<span className={required ? classes.noWrap : ''}>?</span>
				</div>
			)
		} else {
			return <span className={required ? classes.required : ''}>{label}</span>
		}
	}
	return (
		<div className={joinClasses([classes.root, hasError ? classes.error : ''])}>
			<label htmlFor={id}>
				{getLabel()}
				<Error hasError={hasError} />
			</label>
			{displayDescriptionTop &&
				displayDescription(descriptionAlignment, displayDescriptionTop)}
			<textarea
				className={joinClasses([
					classes.formInput,
					!rows ? classes.fieldHeight : ''
				])}
				id={id}
				disabled={disabled}
				required={required}
				onChange={(e) => onChange(e.target.value)}
				value={value}
				rows={rows}
				maxLength={maxCharacters}
				ref={ref}
				placeholder={placeholder}
			/>
			{!displayDescriptionTop && displayDescription(descriptionAlignment)}
		</div>
	)
}

const TextAreaInput = forwardRef(TextAreaInputForwardRef)

export default TextAreaInput
